'use es6';

import { Map as ImmutableMap } from 'immutable';
import { SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_REVERTED_TO_PREVIOUS, INPAGE_UI_STATE_CLEARED, SELECTED_ITEM_CLEARED, TOGGLE_APPLY_CHANGES_MODAL_OPEN } from 'ContentEditorUI/redux/actions/actionTypes';
const ALL_NULL_VALUES = {
  selectedId: null,
  selectedContainerId: null,
  selectionInProgress: null
};
const initialState = new ImmutableMap({
  current: new ImmutableMap(ALL_NULL_VALUES),
  // Keep previous selection state for easy reverting to previous selection
  previous: new ImmutableMap(ALL_NULL_VALUES)
});
const setCurrentSelectionAndSavePrevious = (state, newValues) => {
  return state.set('previous', state.get('current')).mergeIn(['current'], Object.assign({}, ALL_NULL_VALUES, newValues));
};
const clearSelection = state => {
  return setCurrentSelectionAndSavePrevious(state, Object.assign({}, ALL_NULL_VALUES));
};
const revertToPreviousSelection = state => {
  return state.set('current', state.get('previous')).mergeIn(['previous'], Object.assign({}, ALL_NULL_VALUES));
};
const selectionReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          containerId
        } = payload;
        return setCurrentSelectionAndSavePrevious(state, {
          selectedId: id,
          selectedContainerId: containerId
        });
      }
    case INPAGE_UI_STATE_CLEARED:
      {
        const {
          focusedWidgetId
        } = payload;
        if (focusedWidgetId) {
          return setCurrentSelectionAndSavePrevious(state, {
            selectedId: focusedWidgetId
          });
        } else {
          return clearSelection(state);
        }
      }
    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        const selectionInProgresOpts = payload ? payload.selectionInProgress : null;
        return state.set('selectionInProgress', selectionInProgresOpts);
      }
    case SELECTED_ITEM_CLEARED:
      {
        return clearSelection(state);
      }
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      {
        return revertToPreviousSelection(state);
      }
    default:
      return state;
  }
};
export default selectionReducer;
import ModalDataTypes from 'ContentComponents/constants/ModalDataTypes';
export const ContentTypes = Object.freeze({
  BLOG_POST: 'BLOG_POST',
  EXTERNAL_PAGE: 'EXTERNAL_PAGE',
  LANDING_PAGE: 'LANDING_PAGE',
  SITE_PAGE: 'SITE_PAGE'
});
export const ContentTypeToModalDataType = Object.freeze({
  [ContentTypes.BLOG_POST]: ModalDataTypes.BLOG_POSTS,
  [ContentTypes.EXTERNAL_PAGE]: ModalDataTypes.PAGES,
  [ContentTypes.LANDING_PAGE]: ModalDataTypes.PAGES,
  [ContentTypes.SITE_PAGE]: ModalDataTypes.PAGES
});
export const ContentTypeToConvertedContentType = Object.freeze({
  [ContentTypes.BLOG_POST]: ContentTypes.BLOG_POST,
  [ContentTypes.EXTERNAL_PAGE]: ContentTypes.EXTERNAL_PAGE,
  [ContentTypes.LANDING_PAGE]: ContentTypes.SITE_PAGE,
  [ContentTypes.SITE_PAGE]: ContentTypes.LANDING_PAGE
});
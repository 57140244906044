import http from 'hub-http/clients/apiClient';
const ENDPOINT_URL = '/upgrade-catalog/v1/purchase-orders';
const offerBuilderApi = {
  post({
    offerPayload
  }) {
    return http.post(ENDPOINT_URL, {
      data: offerPayload
    });
  }
};
export default offerBuilderApi;
export const EmbedTypes = Object.freeze({
  OEMBED: 'oembed',
  HTML: 'html',
  MEDIA_BRIDGE: 'media_bridge'
});
export const oEmbedTypes = Object.freeze({
  PHOTO: 'photo',
  VIDEO: 'video',
  LINK: 'link',
  RICH: 'rich'
});
import http from 'hub-http/clients/apiClient';
const ATTACHED_CONTENT_WITH_CONTEXT_PATH = 'content-clusters/v1/attachedContentWithContext';
export const getAttachedContentWithContext = ({
  contentId
}) => {
  return http.get(ATTACHED_CONTENT_WITH_CONTEXT_PATH, {
    query: {
      contentId
    }
  });
};
import produce from 'immer';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, SET_DIRTY_FLAG, SET_MODULE_BODY, SET_MODULE_SMART_OBJECT_BODY, MERGE_MODULE_BODY, SET_DEPRECATED_STYLES_FLAGS, MERGE_MODULE_SMART_OBJECT_BODY, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_COLUMN_DELETED, LAYOUT_SECTION_ROW_DELETED, UPDATED_MODULE_SMART_RULES, REMOVED_MODULE_SMART_RULES, SET_BREAKPOINT_STYLES_FOR_TREE_NODE, MODULE_SET_LAYOUT_STYLES, LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES, LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES, SET_MODULE_BREAKPOINT_STYLES, SET_MODULE_STYLES, LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT, PERMANENTLY_DISABLE_SAVING, CONTENT_SAVE_REQUEST_STARTED, CONTENT_SAVE_REQUEST_SUCCEEDED, CONTENT_SAVE_REQUEST_FAILED, RELOAD_WITHOUT_WARNING, LAYOUT_SECTION_COLUMN_INSERTED_AFTER, LAYOUT_SECTION_COLUMN_INSERTED_BEFORE, LAYOUT_SECTION_ROW_INSERTED_AFTER, LAYOUT_SECTION_ROW_INSERTED_BEFORE, LAYOUT_SECTION_ROW_CLONED_BELOW, LAYOUT_SECTION_CELL_CLONED_TO_RIGHT, UPDATE_CONTENT_MODEL_HTML_TITLE } from 'ContentEditorUI/redux/actions/actionTypes';
// @ts-expect-error Not typed
import { isLocked } from 'ContentEditorUI/utils/errorUtils';
const initialState = {
  saveRequestId: 0,
  permanentlyDisabled: false,
  isDirty: false,
  isHardSavePending: false,
  lastSaveSuccessful: undefined,
  lastHardSaveTs: 0,
  preventUnsavedChangesWarning: false,
  saveRequested: false,
  lastSaveWasAutoSave: false,
  shouldSaveImmediately: false
};

// The saveRequestId is just an arbritrary number that increments each time an action that would could set
// `isDirty` to true is fired. This lets us track when those actions stop coming in, so we can better
// debounce trigger an autosave.
const setSaveState = (draft, options) => {
  const {
    isDirty,
    isHardSavePending,
    isFromAutoSaveContainer,
    shouldSaveImmediately = false
  } = options;
  const newSaveRequestId = isDirty && !isFromAutoSaveContainer ? draft.saveRequestId + 1 : draft.saveRequestId;
  const newIsHardSavePending = draft.isHardSavePending || isHardSavePending;
  draft.isDirty = isDirty;
  draft.saveRequestId = newSaveRequestId;
  draft.isHardSavePending = newIsHardSavePending;
  draft.shouldSaveImmediately = shouldSaveImmediately;
  return draft;
};
const saveDataReducer = (state = initialState, {
  type,
  payload,
  response,
  error
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return setSaveState(draft, {
        isDirty: response.isDirty || false,
        shouldSaveImmediately: false
      });
    case SET_DIRTY_FLAG:
      return setSaveState(draft, {
        isDirty: payload.isDirty,
        isHardSavePending: payload.isHardSavePending,
        isFromAutoSaveContainer: payload.isFromAutoSaveContainer
      });
    case SET_MODULE_BODY:
    case SET_MODULE_SMART_OBJECT_BODY:
    case MERGE_MODULE_BODY:
    case SET_DEPRECATED_STYLES_FLAGS:
    case MERGE_MODULE_SMART_OBJECT_BODY:
    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
    case UPDATED_MODULE_SMART_RULES:
    case REMOVED_MODULE_SMART_RULES:
    case SET_BREAKPOINT_STYLES_FOR_TREE_NODE:
    case MODULE_SET_LAYOUT_STYLES:
    case LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES:
    case LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES:
    case SET_MODULE_BREAKPOINT_STYLES:
    case SET_MODULE_STYLES:
    case LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT:
    case LAYOUT_SECTION_COLUMN_INSERTED_AFTER:
    case LAYOUT_SECTION_COLUMN_INSERTED_BEFORE:
    case LAYOUT_SECTION_ROW_INSERTED_AFTER:
    case LAYOUT_SECTION_ROW_INSERTED_BEFORE:
      return setSaveState(draft, {
        isDirty: true
      });
    case LAYOUT_SECTION_ROW_CLONED_BELOW:
    case LAYOUT_SECTION_CELL_CLONED_TO_RIGHT:
    case UPDATE_CONTENT_MODEL_HTML_TITLE:
      return setSaveState(draft, {
        isDirty: true,
        shouldSaveImmediately: true
      });
    case PERMANENTLY_DISABLE_SAVING:
      draft.permanentlyDisabled = true;
      return draft;
    case CONTENT_SAVE_REQUEST_STARTED:
      {
        draft.saveRequested = true;
        return draft;
      }
    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          opts: {
            buffer,
            isAutoSave
          }
        } = payload;
        draft.isDirty = false;
        draft.isHardSavePending = false;
        draft.lastHardSaveTs = buffer ? draft.lastHardSaveTs : Date.now();
        draft.lastSaveSuccessful = true;
        draft.lastSaveWasAutoSave = isAutoSave;
        return draft;
      }
    case CONTENT_SAVE_REQUEST_FAILED:
      draft.permanentlyDisabled = isLocked(error.response);
      draft.lastSaveSuccessful = false;
      return draft;
    case RELOAD_WITHOUT_WARNING:
      draft.preventUnsavedChangesWarning = true;
      return draft;
    default:
      return draft;
  }
});
export default saveDataReducer;
'use es6';

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { activateDeveloperMode } from 'ContentEditorUI/redux/actions/queryParamActions';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
const DEVELOPER_MODE = 'developerMode';
const KNOWN_PARAMS = [DEVELOPER_MODE];
const SyncQueryParamsToReduxContainer = ({
  path
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    KNOWN_PARAMS.forEach(param => {
      if (path.includes(`${param}=true`)) {
        if (param === DEVELOPER_MODE) {
          UsageTracker.trackEditorInteraction({
            action: 'Developer Mode',
            event: 'loaded-in-dev-mode'
          });
          dispatch(activateDeveloperMode());
        }
      }
    });
  }, [dispatch, path]);
  return null;
};
SyncQueryParamsToReduxContainer.propTypes = {
  path: PropTypes.string.isRequired
};
export default SyncQueryParamsToReduxContainer;
'use es6';

import { UPLOAD_FEATURED_IMAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import { Set as ImmutableSet } from 'immutable';
const uploadedFeaturedImageReducer = (state = ImmutableSet(), {
  type,
  payload
}) => {
  switch (type) {
    case UPLOAD_FEATURED_IMAGE:
      return state.add(payload);
    default:
      return state;
  }
};
export default uploadedFeaturedImageReducer;
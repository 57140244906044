import { ArtifactsWithNoGatherer, CompletionStatuses } from '../../../constants';
const MIN_WORD_COUNT = 300;
export const increaseWordCountAudit = artifacts => {
  const postBody = artifacts[ArtifactsWithNoGatherer.POST_BODY_NO_MARKUP];
  const count = postBody ? postBody.split(' ').length : 0;
  let status;
  if (count < MIN_WORD_COUNT) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }
  return {
    status,
    count
  };
};
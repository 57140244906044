import * as ActionTypes from '../actions/ActionTypes';
import { RequestStatus } from '../../constants/types';
import { hasPendingOrFailedStatus, setRequestStatusState } from './utils';
import { produce } from 'immer';
export const DEFAULT = {
  instanceEditingModeActive: false,
  currentHubDbRowId: null,
  currentInstance: null,
  hubDbTableId: null,
  tableSchema: null,
  contentTypeSchema: null,
  tableRows: [],
  instanceEditingRequestStatus: RequestStatus.UNINITIALIZED,
  hubDbTable: null,
  allTables: [],
  requestStatusTracker: {},
  dataAuthoringModeActive: false,
  inlineEditingMetaData: {}
};
function incrementEditVersion(state, instanceProperty) {
  const editVersion = state.inlineEditingMetaData[instanceProperty].editVersion;
  if (editVersion != null) {
    state.inlineEditingMetaData[instanceProperty].editVersion = editVersion + 1;
  }
  state.inlineEditingMetaData[instanceProperty].editVersion = 1;
}
function mergeInlineEditingMetaData(state, instanceProperty, metaData) {
  const currentMetaData = state.inlineEditingMetaData[instanceProperty];
  if (!currentMetaData.inlineRichTextFieldEditingMetaData) {
    currentMetaData.inlineRichTextFieldEditingMetaData = Object.assign({}, metaData);
  } else {
    Object.assign(currentMetaData.inlineRichTextFieldEditingMetaData, metaData);
  }
}
function touchInlineEditingMetaData(state, instanceProperty) {
  if (!state.inlineEditingMetaData[instanceProperty]) {
    state.inlineEditingMetaData[instanceProperty] = {};
  }
}
const instanceEditingReducer = produce((state = DEFAULT, action) => {
  const {
    type,
    payload,
    meta
  } = action;
  const isRequestWithEarlyReturn = hasPendingOrFailedStatus(action);
  if (meta && meta.status) {
    state.requestStatusTracker = setRequestStatusState(state.requestStatusTracker, action);
  }
  if (isRequestWithEarlyReturn) return state;
  switch (type) {
    case ActionTypes.FETCH_ROW:
      {
        state.currentInstance = payload.row;
        return state;
      }
    case ActionTypes.FETCH_ALL_ROWS:
      {
        state.tableRows = payload.rows;
        return state;
      }
    case ActionTypes.FETCH_HUBDB_TABLE:
      {
        state.hubDbTable = payload.hubDbTable;
        return state;
      }
    case ActionTypes.FETCH_MODULE_SCHEMA:
      {
        state.tableSchema = payload.tableSchema;
        return state;
      }
    case ActionTypes.UPDATE_INSTANCE_VALUES:
      {
        state.currentInstance = payload.row;
        return state;
      }
    case ActionTypes.SET_ACTIVE_HUBDB_ROW:
      {
        state.currentHubDbRowId = payload.rowId;
        state.instanceEditingModeActive = true;
        return state;
      }
    case ActionTypes.CLEAR_ACTIVE_HUBDB_ROW:
      {
        state.currentHubDbRowId = null;
        state.instanceEditingModeActive = false;
        return state;
      }
    case ActionTypes.SET_HUBDB_ROWS_AND_SCHEMA:
      {
        state.currentInstance = payload.row;
        state.tableSchema = payload.tableSchema;
        state.hubDbTable = payload.hubDbTable;
        return state;
      }
    case ActionTypes.SET_INSTANCE_DATA_SUCCEEDED:
      {
        state.currentInstance = payload;
        return state;
      }
    case ActionTypes.SET_TABLE_AS_MODULE_SCHEMA_SUCCEEDED:
      {
        state.tableSchema = payload;
        return state;
      }
    case ActionTypes.SET_CONTENT_TYPE_SCHEMA_SUCCEEDED:
      {
        state.contentTypeSchema = payload;
        return state;
      }
    case ActionTypes.UPDATE_TABLE_ROWS:
      {
        state.tableRows = payload.rows;
        return state;
      }
    case ActionTypes.UPDATE_CURRENT_INSTANCE_VALUE:
      {
        const {
          fieldKey,
          newVal,
          metaData
        } = payload;
        if (state.currentInstance) {
          state.currentInstance.values[fieldKey] = newVal;
          touchInlineEditingMetaData(state, fieldKey);
          incrementEditVersion(state, fieldKey);
          if (metaData && metaData.inlineRichTextFieldEditingMetaData) {
            mergeInlineEditingMetaData(state, fieldKey, metaData.inlineRichTextFieldEditingMetaData);
          }
        }
        return state;
      }
    case ActionTypes.UPDATE_INSTANCE_PROPERTY_META_DATA:
      {
        const {
          fieldKey,
          metaData
        } = payload;
        touchInlineEditingMetaData(state, fieldKey);
        if (metaData && metaData.inlineRichTextFieldEditingMetaData) {
          mergeInlineEditingMetaData(state, fieldKey, metaData.inlineRichTextFieldEditingMetaData);
        }
        return state;
      }
    default:
      return state;
  }
});
export default instanceEditingReducer;
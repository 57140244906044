import { useMemo } from 'react';
import { CompletionStatuses } from '../constants';
export const useGenerateAuditResults = ({
  config,
  artifacts
}) => {
  const auditResults = useMemo(() => {
    const allAuditResults = {};
    config.recommendations.map(rec => {
      const {
        audit
      } = rec;
      if (audit) {
        allAuditResults[rec.type] = audit(artifacts);
      }
      return allAuditResults;
    });
    return allAuditResults;
  }, [artifacts, config]);
  return auditResults;
};
export const getGroupIncompleteCount = ({
  auditResults,
  groupRecs
}) => {
  const incompleteCount = groupRecs.filter(rec => {
    const auditResult = auditResults[rec.type];
    const status = auditResult && auditResult.status;
    return status === CompletionStatuses.INCOMPLETE || status === CompletionStatuses.BLOCKED;
  }).length;
  return incompleteCount;
};
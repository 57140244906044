export const ALIGNMENT = 'alignment';
export const AUDIO = 'audioplayer';
export const BACKGROUND_IMAGE = 'backgroundimage';
export const BLOG = 'blog';
export const BOOLEAN = 'boolean';
export const BORDER = 'border';
export const CHOICE = 'choice';
export const COLOR = 'color';
export const CRM_OBJECT = 'crmobject';
export const CRM_OBJECT_PROPERTY = 'crmobjectproperty';
export const CTA = 'cta';
export const DATE = 'date';
export const DATE_TIME = 'datetime';
export const EMAIL = 'email';
export const EMBED = 'embed';
export const FILE = 'file';
export const FOLLOW_UP_EMAIL = 'followupemail';
export const FONT = 'font';
export const FORM = 'form';
export const GRADIENT = 'gradient';
export const GROUP = 'group';
export const HTML = 'html';
export const HUBDB_ROW = 'hubdbrow';
export const HUBDB_TABLE = 'hubdbtable';
export const HUBL = 'hubl';
export const ICON = 'icon';
export const IMAGE = 'image';
export const LINK = 'link';
export const LOCATION = 'location';
export const LOGO = 'logo';
export const MEETING = 'meeting';
export const MENU = 'menu';
export const NUMBER = 'number';
export const PAGE = 'page';
export const PAYMENT = 'payment';
export const PODCAST = 'podcast';
export const PRODUCT = 'product';
export const RICH_TEXT = 'richtext';
export const SFDC_CAMPAIGN = 'salesforcecampaign';
export const SIMPLE_MENU = 'simplemenu';
export const SPACING = 'spacing';
export const SURVEY = 'survey';
export const TAG = 'tag';
export const TEXT = 'text';
export const TEXT_ALIGNMENT = 'textalignment';
export const URL = 'url';
export const VIDEO = 'videoplayer';
export const WORKFLOW = 'workflow';
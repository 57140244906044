import * as optinClient from 'ContentData/api/Optin';
import * as optinSelectors from 'ContentData/selectors/optin';
import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import { OPTIN_FETCH, OPTIN_UPDATE } from 'ContentData/actions/ActionTypes';
const fetchOptinAction = createGenericFetchAction(OPTIN_FETCH, optinClient.fetchOptin);
export const fetchOptin = name => fetchOptinAction(name);
export const optinUpdate = response => ({
  type: OPTIN_UPDATE,
  response
});
const updateOptIn = (name, newState) => (dispatch, getState) => {
  const optin = optinSelectors.getOptin(getState(), name);
  if (!optin) return Promise.resolve();
  return optinClient.updateOptin(name, newState, optin.stateActionAt).then(() => {
    dispatch(optinUpdate(Object.assign({}, optin, {
      optedIn: true
    })));
    // To refreash timestamp for concurrency checks
    dispatch(fetchOptin(name));
  });
};
export const optIn = name => updateOptIn(name, 'OPTED_IN');
export const optOut = name => updateOptIn(name, 'OPTED_OUT');
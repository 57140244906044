import { createSelector } from 'reselect';
import { CREATE_CLUSTER_SUCCEEDED, FETCH_CLUSTERS_SUCCEEDED } from '../actionTypes';
import { MAX_SUBTOPICS } from '../topicEditor/constants';
import { selectSelectedClusterId } from '../topicEditor/slice';
const initialState = {};
export const clustersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLUSTER_SUCCEEDED:
      {
        const newCluster = action.payload;
        return Object.assign({}, state, {
          [newCluster.id]: newCluster
        });
      }
    case FETCH_CLUSTERS_SUCCEEDED:
      {
        return action.payload.reduce((newClusters, cluster) => {
          newClusters[cluster.id] = cluster;
          return newClusters;
        }, {});
      }
    default:
      return state;
  }
};
export const selectClustersState = state => state.clusters;
export const selectSelectedCluster = createSelector([selectClustersState, selectSelectedClusterId], (clusters, selectedClusterId) => {
  return selectedClusterId ? clusters[selectedClusterId] : null;
});
export const selectHasClusters = createSelector(selectClustersState, clusters => Object.keys(clusters).length > 0);
export const selectClusterDropdownOptions = createSelector(selectClustersState, clusters => Object.values(clusters).map(cluster => ({
  text: cluster.coreTopic.phrase,
  disabled: cluster.attachedContentCount >= MAX_SUBTOPICS,
  value: cluster.id
})));
import { range, isNumber } from 'underscore';
import { getDefaultScalarValue, valueIsArray } from 'ContentUtils/helpers/FieldHelpers';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
// determines if a field has a default or min occurrence set and
// its default value is an array. If it's an array type field like
// simple menu it should be a 2-dimensional array
export function defaultValueIsOccurrenceArray(field) {
  return field.occurrence && field.default && Array.isArray(field.default) && (!valueIsArray(field) || Array.isArray(field.default[0]));
}
export function getNewOccurrenceValue(field, isTopLevel = true) {
  const {
    occurrence = {}
  } = field;
  const defaultOccurrence = occurrence.default || occurrence.min || field.type === FieldTypes.GROUP && field.occurrence && 0;
  if (field.type === FieldTypes.GROUP && field.children) {
    const newOccurrenceValue = field.children.reduce((valueMap, _child) => {
      valueMap[_child.name] = getNewOccurrenceValue(_child, false);
      return valueMap;
    }, {});
    if (isNumber(defaultOccurrence) && !isTopLevel) {
      return range(defaultOccurrence).map(() => newOccurrenceValue);
    }
    return newOccurrenceValue;
  }
  const defaultValue = getDefaultScalarValue(field);
  if (isNumber(defaultOccurrence) && !isTopLevel) {
    return range(defaultOccurrence).map(() => defaultValue);
  }
  return defaultValue;
}
export function isOccurrenceKey(fieldKey) {
  return fieldKey && typeof fieldKey[fieldKey.length - 1] === 'number';
}
export function isOccurrenceOrChildOfOccurrenceKey(fieldKey) {
  return fieldKey && fieldKey.some(key => typeof key === 'number');
}
export function isRepeatingOrHasRepeatingChild(field) {
  const {
    occurrence,
    children
  } = field || {};
  if (occurrence) return true;
  if (children) return children.some(child => isRepeatingOrHasRepeatingChild(child));
  return false;
}
export const getOccurrenceFieldKey = fieldKey => {
  let occurrenceFieldKey = [];
  for (let i = fieldKey.length - 1; i > 0; i--) {
    const currentFieldKey = fieldKey[i];
    if (typeof currentFieldKey === 'number') {
      occurrenceFieldKey = fieldKey.slice(0, i + 1);
      break;
    }
  }
  return occurrenceFieldKey;
};
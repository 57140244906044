export const POST_DEEP_LINK_QUERIES = {
  GENERAL: {
    NAME: 'general.name',
    TITLE: 'general.htmlTitle',
    BLOG_SELECT: 'general.selectBlog',
    SLUG: 'general.slug',
    TAGS: 'general.tags',
    AUTHOR: 'general.author'
  },
  IMAGE: {
    FEATURED_IMAGE: 'image.featuredImage'
  },
  METADATA: {
    META_DESCRIPTION: 'metadata.metaDescription',
    CAMPAIGN: 'metadata.campaign'
  },
  TEMPLATE: {
    POST_TEMPLATE: 'template.postTemplate'
  },
  LANG_AND_REGION: {
    LANGUAGE: 'langAndRegion.language'
  },
  ACCESS_CONTROL: {
    ACCESS_CONTROL: 'accessControl.accessControl'
  },
  ADVANCED: {
    CODE_SNIPPETS: 'advanced.codeSnippets',
    NOTIFICATION_EMAILS: 'advanced.notificationEmails',
    CANONICAL_URL: 'advanced.canonicalUrl'
  }
};
import { getAudienceAccessType } from 'ContentUtils/helpers/AudienceAccessHelpers';
import { FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED, FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED, FETCH_HUBDB_TABLES_SUCCEEDED, FETCH_DATA_QUERIES_SUCCEEDED, FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED, FETCH_CONTACT_LISTS_SUCCEEDED, UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS, UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED, FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED, FETCH_CRM_OBJECT_TYPES_SUCCEEDED, FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED
// @ts-expect-error not yet migrated
} from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_DOMAIN } from 'ContentEditorUI/redux/actions/actionTypes';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import withBaseSettings, { reducedReducersImmer as withBaseSettingsImmer
// @ts-expect-error not yet migrated
} from 'ContentEditorUI/redux/reducers/settings/withBaseSettings';
import produce from 'immer';

//most of this reducer supports audience access settings which has types set
//in ContentUILib in their own files but will be deprecated soon in favor of
// types from the memberships library which is where we want to import from

const initialStateAsJSObject = {
  featuredImageThumbnailUrls: {},
  registeredContacts: 0,
  unregisteredContacts: 0
};
const initialState = ImmutableMap({
  featuredImageThumbnailUrls: ImmutableMap(),
  registeredContacts: 0,
  unregisteredContacts: 0
});
function mergeNeededListData(updatedSelectedLists,
// @ts-expect-error not yet migrated
oldNeededListData = new List(),
// @ts-expect-error not yet migrated
allLists = new List()) {
  return updatedSelectedLists.reduce((acc, listId) => {
    if (!acc.find(list => list.get('listId') === listId)) {
      const listData = allLists.find(list => list.get('listId') === listId);
      if (listData) {
        return acc.push(listData);
      }
    }
    return acc;
  }, oldNeededListData);
}
const mergeNeedListDataForImmer = (updatedSelectedLists, oldNeededListData = [], allLists = []) => {
  return updatedSelectedLists.reduce((acc, listId) => {
    if (!acc.find(list => list['listId'] === listId)) {
      const listData = allLists.find(list => list['listId'] === listId);
      if (listData) {
        return acc.push(listData);
      }
    }
    return acc;
  }, oldNeededListData);
};
const pageSettingsReducerAsJSObject = (state = initialStateAsJSObject, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED:
      draft.availablePageStylesheets = payload;
      return draft;
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content,
          settings
        } = response;
        return Object.assign(draft, {
          audienceAccess: getAudienceAccessType(content),
          domain: content.resolvedDomain,
          domainStylesheets: settings.attachedStylesheets,
          masterPagesForMultiLang: [],
          // Save this without any parsing (we use the simple name in the content model until save/fetch)
          originalDynamicPageSourceValue: content.dynamicPageDataSourceId
        });
      }
    case FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED:
      return Object.assign(draft, {
        templateStylesheets: payload.layout_attached_stylesheets,
        domainStylesheets: payload.domain_attached_stylesheets
      });
    case FETCH_HUBDB_TABLES_SUCCEEDED:
      draft.availableHubDbTables = payload;
      return draft;
    case FETCH_DATA_QUERIES_SUCCEEDED:
      draft.availableDataQueries = payload;
      return draft;
    case FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED:
      draft.contactListsForContent = payload.lists;
      return draft;
    case FETCH_CONTACT_LISTS_SUCCEEDED:
      draft.contactLists = payload.lists.map(l => l.list);
      return draft;
    case UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS:
      draft.audienceAccess = payload;
      return draft;
    case UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS:
      {
        //updateAudienceAccessLists returns immutable set
        const plainJSPayload = List.isList(payload) ? payload.toJS() : payload;
        draft.contactListsForContent = mergeNeedListDataForImmer(plainJSPayload, draft.contactListsForContent, draft.contactLists);
        return draft;
      }
    case UPDATE_CONTENT_MODEL_DOMAIN:
      draft.domain = payload.domain;
      return draft;
    case FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED:
      draft.featuredImageThumbnailUrls = payload;
      return draft;
    case FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED:
      return Object.assign(draft, {
        emailsNotSent: payload.emailsNotSent,
        emailsSent: payload.emailsSent,
        registeredContacts: payload.registered,
        unregisteredContacts: payload.unregistered
      });
    case FETCH_CRM_OBJECT_TYPES_SUCCEEDED:
      draft.availableCrmObjectTypes = payload;
      return draft;
    case FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED:
      draft.crmObjectSchema = payload;
      return draft;
    default:
      return draft;
  }
});
const pageSettingsReducer = (state = initialState, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED:
      return state.set('availablePageStylesheets', fromJS(payload));
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content,
          settings
        } = response;
        return state.merge({
          // @ts-expect-error not yet migrated
          audienceAccess: getAudienceAccessType(content),
          domain: content.resolvedDomain,
          domainStylesheets: settings.attachedStylesheets,
          masterPagesForMultiLang: List.of(),
          // Save this without any parsing (we use the simple name in the content model until save/fetch)
          originalDynamicPageSourceValue: content.dynamicPageDataSourceId
        });
      }
    case FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED:
      return state.merge({
        templateStylesheets: payload.layout_attached_stylesheets,
        domainStylesheets: payload.domain_attached_stylesheets
      });
    case FETCH_HUBDB_TABLES_SUCCEEDED:
      return state.set('availableHubDbTables', fromJS(payload));
    case FETCH_DATA_QUERIES_SUCCEEDED:
      return state.set('availableDataQueries', fromJS(payload));
    case FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED:
      return state.set('contactListsForContent', fromJS(payload.lists));
    case FETCH_CONTACT_LISTS_SUCCEEDED:
      return state.set('contactLists', fromJS(payload.lists.map(l => l.list)));
    case UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS:
      return state.set('audienceAccess', payload);
    case UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS:
      return state.set('contactListsForContent', mergeNeededListData(payload, state.get('contactListsForContent'), state.get('contactLists')));
    case UPDATE_CONTENT_MODEL_DOMAIN:
      return state.set('domain', payload.domain);
    case FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED:
      return state.set('featuredImageThumbnailUrls', ImmutableMap(payload));
    case FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED:
      return state.merge({
        emailsNotSent: payload.emailsNotSent,
        emailsSent: payload.emailsSent,
        registeredContacts: payload.registered,
        unregisteredContacts: payload.unregistered
      });
    case FETCH_CRM_OBJECT_TYPES_SUCCEEDED:
      return state.set('availableCrmObjectTypes', fromJS(payload));
    case FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED:
      return state.set('crmObjectSchema', fromJS(payload));
    default:
      return state;
  }
};
export const pageSettingsImmerReducer = withBaseSettingsImmer(pageSettingsReducerAsJSObject);
export default withBaseSettings(pageSettingsReducer);
'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import I18n from 'I18n';
import AudienceAccessTypes from 'ContentUtils/constants/AudienceAccessTypes';
import { requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { hubdbTable } from 'ContentUtils/Routes';
import PortalIdParser from 'PortalIdParser';
import pageSettingsErrors from 'ContentEditorUI/data/pageSettingsErrors';
// @ts-expect-error untyped
import { getContent } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getSiteSettingsBodyOverrideByDomain, getSiteSettings } from 'ContentEditorUI/redux/selectors/siteSettingsSelectors';
import { getActiveDomain, getContentModelHasValidDomain, getPagePrimaryDomain, getActiveDomainIsInternal } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getHasAudienceAccessScope, getHasHubDbCustomDomainAccess, getHasDynamicPageAccess } from 'page-editor-ui/redux/selectors/authSelectors';
import { getActiveDomainName, getContentModel, getContentModelFeaturedImage, getContentModelFeaturedImageAltText, getLanguage } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentModelAudienceAccessLists, getContentModelPageStylesheets, getContentModelDynamicPageSlug, getContentModelDynamicPageTitle, getContentModelDynamicPageImage, getContentModelDynamicPageMeta, getContentModelDynamicPageUrl } from 'page-editor-ui/redux/selectors/pageContentModelSelectors';
import { buildFullUrl, buildLanguageString } from 'ContentEditorUI/utils/contentModelURLUtils';
import { getModuleAndUploadedImages } from 'ContentEditorUI/redux/selectors/featuredImageSelectors';
import { getImages } from 'ContentEditorUI/utils/helpers';
import { getHasCurrentLanguageInTranslatedContent } from 'ContentEditorUI/js/redux/selectors/translationSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsBlogListingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import * as CrmObjectTypes from 'page-editor-ui/constants/CrmObjectTypes';
import * as CrmObjectMetaTypes from 'page-editor-ui/constants/CrmObjectMetaTypes';
import { RESTRICTED_STANDARD_OBJECTS } from 'page-editor-ui/constants/CrmObjectTypes';
import { getContentModelDynamicPageSourceValue, getContentModelPassword, getContentModelRequirePassword, getDynamicPageSourceType } from './pageContentModelSelectors';
import { filterCrmObjectPropertiesUsableAsSlug, formatCrmObjectDynamicPageDataSourceIdForSave, isCustomCrmObjectDynamicPageSourceId } from '../../utils/dynamicPagesUtils';
import { DataSourceTypes, CRM_SOURCE_TYPE, DATA_QUERY_TYPE, HUBDB_SOURCE_TYPE } from 'page-editor-ui/constants/DynamicPagesDataSource';
import { getHasCrmDynamicPageAccess, getHasPasswordProtectionAccess, getHasAnyStylesheetAccess } from './authSelectors';
import { getAuth, getIsUngatedForPatagonia, getHasLandingPagesMultiLangAccess, getHasSitePagesMultiLangAccess, getUserHasPublishPrivateContentAccess, getPortalIsUngatedForAccessGroups } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct } from 'ContentEditorUI/js/redux/selectors/portalDomainsSelectors';
import pageSettingsWarnings from 'ContentEditorUI/js/data/pageSettingsWarnings';
import { getCrmObjectTypesRequest, getHubDbTablesRequest } from './pageRequestStatesSelectors';
import { getIsPublishOptionsModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { getDomainLanguage } from 'ContentComponents/utils/contentActionModalUtils';
import { checkIsLanguageSupportedByDeepL } from 'ContentComponents/utils/hubspotTranslateUtils';
export const getPageSettings = basicSelector(state => state.pageSettings);
const createPageSettingsSelector = field => createSelector([getPageSettings], pageSettings => pageSettings.get(field));
export const getPageSettingsAvailableHubDbTables = createPageSettingsSelector('availableHubDbTables');
export const getPageSettingsCrmObjectTypes = createPageSettingsSelector('availableCrmObjectTypes');
export const getPageSettingsDataQueries = createPageSettingsSelector('availableDataQueries');
export const getOriginalDynamicPageSourceValue = createPageSettingsSelector('originalDynamicPageSourceValue');
export const getIsDynamicPageSourceValuePortalSpecificCrmObject = createSelector([getDynamicPageSourceType, getPageSettingsCrmObjectTypes, getContentModelDynamicPageSourceValue], (dynamicPageSourceType, crmObjectTypes, contentModelDynamicPageSourceValue) => {
  if (dynamicPageSourceType === CRM_SOURCE_TYPE && crmObjectTypes) {
    const matchingObjectType = crmObjectTypes.find(crmObject => crmObject.get('name') === contentModelDynamicPageSourceValue);
    if (matchingObjectType) {
      return matchingObjectType.get('metaType') === CrmObjectMetaTypes.PORTAL_SPECIFIC;
    }
  }
  return false;
});

// Formats dynamicPageSourceId with p_ needed to fetch schema if we determine it is a custom object
export const getDynamicPageSourceIdToFetch = createSelector([getContentModelDynamicPageSourceValue, getIsDynamicPageSourceValuePortalSpecificCrmObject], (contentModelDynamicPageSourceValue, isDynamicPageSourceValuePortalSpecificCrmObject) => {
  return formatCrmObjectDynamicPageDataSourceIdForSave(contentModelDynamicPageSourceValue, isDynamicPageSourceValuePortalSpecificCrmObject);
});
export const getPageSettingsCrmObjectTypesOptions = createSelector([getPageSettingsCrmObjectTypes, getHasAudienceAccessScope, getHasPasswordProtectionAccess], (objectTypes, hasAudienceAccess, hasPasswordProtectionAccess) => {
  if (objectTypes) {
    return objectTypes.map(objectType => {
      const objectName = objectType.get('name');
      const objectNameUpperCased = objectName.toUpperCase();
      const objectTypeId = objectType.get('objectTypeId');
      const value = objectName;
      if (!hasAudienceAccess && !hasPasswordProtectionAccess && RESTRICTED_STANDARD_OBJECTS.includes(objectName)) {
        return null;
      }
      if (objectType.get('metaType') === CrmObjectMetaTypes.HUBSPOT && CrmObjectTypes[objectNameUpperCased]) {
        const text = I18n.text(`crmObjectTypes.${objectNameUpperCased}.singular`);
        return {
          text,
          value,
          objectTypeId
        };
      } else if (objectType.get('metaType') === CrmObjectMetaTypes.PORTAL_SPECIFIC) {
        const text = objectType.get('singularForm') || objectName;
        return {
          text,
          value,
          objectTypeId
        };
      }
      return null;
    }).filter(Boolean).sort((objectTypeA, objectTypeB) => {
      if (objectTypeA.text < objectTypeB.text) {
        return -1;
      }
      if (objectTypeA.text > objectTypeB.text) {
        return 1;
      }
      return 0;
    }).toArray();
  }
  return [];
});

/*
  Temporary hack until crm-object-schemas/v3/schemas/{objectType} can fetch
  custom object types by simple names again. In the meantime we use the response
  from inbounddb-meta/v1/object-types/for-portal to get the FQN of the current
  objectType (stored by simple name on content model) that we can use to fetch
  the object schema.
*/
export const getDynamicPageSourceValueFullyQualifiedName = createSelector([getIsDynamicPageSourceValuePortalSpecificCrmObject, getContentModelDynamicPageSourceValue, getPageSettingsCrmObjectTypes], (isDynamicPageSourceValuePortalSpecificCrmObject, objectType, objectTypes) => {
  // To support pages that don't yet have the "p_" in their dynamicPageSourceId field, we still need to fetch by FQN
  if (isDynamicPageSourceValuePortalSpecificCrmObject && isCustomCrmObjectDynamicPageSourceId(objectType)) {
    return '';
  }
  if (objectTypes) {
    const objectTypeSchema = objectTypes.find(schema => {
      return schema.get('fullyQualifiedName') === objectType || schema.get('name') === objectType;
    });
    return objectTypeSchema ? objectTypeSchema.get('fullyQualifiedName') : '';
  }
  return '';
});
export const getDynamicPagesDataQuery = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue, getPageSettingsDataQueries], (dynamicPageSourceType, contentModelDynamicPageSourceValue, dataQueries) => {
  if (dynamicPageSourceType !== DATA_QUERY_TYPE) {
    return undefined;
  }
  return dataQueries.find(q => q.get('path') === contentModelDynamicPageSourceValue);
});
export const getDynamicPageSourceLocation = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue, getPageSettingsCrmObjectTypesOptions, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageSourceValue, crmObjectTypesOptions, dynamicPagesDataQuery) => {
  const PageSourceLocationGetterMap = {
    [HUBDB_SOURCE_TYPE]: value => hubdbTable(value),
    [CRM_SOURCE_TYPE]: value => {
      const PORTAL_ID = PortalIdParser.get();
      const crmObjectOption = crmObjectTypesOptions.find(option => option.value === value);
      if (!crmObjectOption) {
        return false;
      }

      // Kind of gross, but there are different URL patterns for these objects
      if (value === 'QUOTE') {
        return `/quotes/${PORTAL_ID}`;
      } else if (value === 'PRODUCT') {
        return `/settings/${PORTAL_ID}/sales/products`;
      }
      const {
        objectTypeId
      } = crmObjectOption;
      return `/contacts/${PORTAL_ID}/objects/${objectTypeId}`;
    },
    [DATA_QUERY_TYPE]: () => {
      const query = dynamicPagesDataQuery;
      const isCRM = query ? query.get('dataSourceType') === DataSourceTypes.CRM_OBJECT : false;
      if (isCRM) {
        return `/contacts/${PortalIdParser.get()}/objects/${query.get('dataSourceInternalId')}`;
      }
      return false;
    }
  };
  return PageSourceLocationGetterMap[dynamicPageSourceType] ? PageSourceLocationGetterMap[dynamicPageSourceType](contentModelDynamicPageSourceValue) : false;
});
export const getPageSettingsDataQueryOptions = createSelector([getPageSettingsDataQueries], availableDataQueries => {
  if (!availableDataQueries) {
    return [];
  }
  return availableDataQueries.map(query => {
    const dataSourceLabel = query.get('dataSourceType') === DataSourceTypes.CRM_OBJECT ? `CRM: ${query.get('dataSourceLabel')}` : query.get('dataSourceLabel');
    return {
      dataSourceLabel,
      text: query.get('label'),
      value: query.get('path')
    };
  }).toJS();
});
export const getDynamicPagesDataSourceOptions = createSelector([getPageSettingsAvailableHubDbTables, getPageSettingsCrmObjectTypesOptions, getPageSettingsDataQueryOptions, getHasCrmDynamicPageAccess], (availableHubDbTables, crmObjectOptions, availableDataQueriesOptions, hasCrmDynamicPageAccess) => {
  const options = [];
  if (availableHubDbTables && crmObjectOptions) {
    const hubDbOptions = availableHubDbTables.map(table => {
      return {
        text: table.get('label'),
        value: table.get('id').toString()
      };
    }).toJS();
    options.push({
      text: 'HubDB',
      options: hubDbOptions
    });
  }
  if (availableDataQueriesOptions.length) {
    options.push({
      text: 'Data Queries',
      options: availableDataQueriesOptions
    });
  }
  if (hasCrmDynamicPageAccess && crmObjectOptions) {
    options.push({
      text: 'CRM',
      options: crmObjectOptions
    });
  }
  return options;
});
export const getCrmObjectSchema = createPageSettingsSelector('crmObjectSchema');
const getCrmObjectProperties = createSelector([getCrmObjectSchema], schema => {
  if (schema) {
    return schema.get('properties').filter(p => !p.get('hidden')).sort((propertyA, propertyB) => {
      const propertyALabel = propertyA.get('label');
      const propertyBLabel = propertyB.get('label');
      if (propertyALabel < propertyBLabel) {
        return -1;
      }
      if (propertyALabel > propertyBLabel) {
        return 1;
      }
      return 0;
    });
  }
  return null;
});
export const getCrmObjectSlugPropertiesOptions = createSelector([getCrmObjectProperties, getCrmObjectSchema], (properties, objectSchema) => {
  if (properties) {
    return properties.filter(prop => filterCrmObjectPropertiesUsableAsSlug(prop, objectSchema)).map(property => ({
      text: property.get('label'),
      value: property.get('name')
    })).toArray();
  }
  return null;
});
export const getCrmObjectPropertiesTextTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.get('type') === 'string' && property.get('fieldType') === 'text').map(property => ({
      text: property.get('label'),
      value: property.get('name')
    })).toArray();
  }
  return [];
});
export const getCrmObjectPropertiesMultilineTextTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.get('type') === 'string' && (property.get('fieldType') === 'text' || property.get('fieldType') === 'textarea')).map(property => ({
      text: property.get('label'),
      value: property.get('name')
    })).toArray();
  }
  return [];
});
export const getCrmObjectPropertiesFileTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.get('type') === 'string' && property.get('fieldType') === 'file').map(property => ({
      text: property.get('label'),
      value: property.get('name')
    })).toArray();
  }
  return [];
});
export const getPageSettingsAvailablePageStylesheets = createPageSettingsSelector('availablePageStylesheets');
export const getCurrentDomain = createPageSettingsSelector('domain');
export const getPageSettingsDomainStylesheets = createSelector([getCurrentDomain, getSiteSettingsBodyOverrideByDomain, getPageSettings], (currentDomain, bodyOverrideByDomain, pageSettings) => {
  const bodyOverride = bodyOverrideByDomain[currentDomain];
  if (!bodyOverride) {
    return pageSettings['domainStylesheets'];
  }
  const attachedStylesheets = bodyOverride['attachedStylesheets'];
  if (attachedStylesheets) {
    return attachedStylesheets;
  }
  return List();
});
export const getIsAllowedToPublishWithHubdb = createSelector([getHasHubDbCustomDomainAccess, getActiveDomainIsInternal, getDynamicPageSourceType, getContentModelDynamicPageSourceValue], (hasHubDbCustomDomainAccess, activeDomainIsInternal, dynamicPageSourceType, contentModelDynamicPageSourceValue) => {
  // If its not using a hubDB table, this is irrelevant
  if (!(dynamicPageSourceType === HUBDB_SOURCE_TYPE && contentModelDynamicPageSourceValue)) {
    return true;
  }
  // If they are not allowed to publish to a custom domain using hubdb,
  // and the active domain is custom, than disable the button.
  return activeDomainIsInternal || hasHubDbCustomDomainAccess;
});
export const getFeaturedImageThumbnailUrls = createPageSettingsSelector('featuredImageThumbnailUrls');
export const getPageSettingsSlugRaw = createPageSettingsSelector('slugRaw');
export const getPageSettingsFullUrl = createSelector([getSiteSettingsBodyOverrideByDomain, getActiveDomain, getPageSettingsSlugRaw, getLanguage], buildFullUrl);
export const getPageLanguageSlug = createSelector([getSiteSettingsBodyOverrideByDomain, getLanguage, getActiveDomainName], buildLanguageString);
export const getPageSettingslinkSlugToTitle = createPageSettingsSelector('linkSlugToTitle');
export const getPageSettingsPageExpiryRedirectName = createPageSettingsSelector('pageExpiryRedirectName');
export const getPageSettingsShowAllErrors = createPageSettingsSelector('showAllErrors');
export const getScrollToElement = createPageSettingsSelector('scrollToElement');
export const getPageSettingsTemplateStylesheets = createPageSettingsSelector('templateStylesheets');
export const getPageSettingsPageStylesheets = createSelector([getPageSettingsAvailablePageStylesheets, getContentModelPageStylesheets], (availablePageStylesheets, contentModelPageStylesheets) => {
  if (availablePageStylesheets) {
    return contentModelPageStylesheets.map(contentModelPageStylesheet => {
      const stylesheetWithPath = availablePageStylesheets.find(availablePageStylesheet => availablePageStylesheet.get('id') === contentModelPageStylesheet.get('id'));
      return stylesheetWithPath || contentModelPageStylesheet;
    });
  }
  return contentModelPageStylesheets;
});
export const getPageSettingsContactLists = createPageSettingsSelector('contactLists');
export const getPageSettingsContactListsForContent = createPageSettingsSelector('contactListsForContent');
export const getPageSettingsAudienceAccess = createPageSettingsSelector('audienceAccess');
export const getNumRegisteredContactsForContent = createPageSettingsSelector('registeredContacts');
export const getNumUnregisteredContactsForContent = createPageSettingsSelector('unregisteredContacts');
export const getNumEmailsNotSentForContent = createPageSettingsSelector('emailsNotSent');
export const getNumEmailsSentForContent = createPageSettingsSelector('emailsSent');
const listHasId = listId => list => list.get('listId') === listId;
export const getPageSettingsNumContactsFromLists = createSelector([getContentModelAudienceAccessLists, getPageSettingsContactLists, getPageSettingsContactListsForContent], (selectedListIds, contactLists = new List(), contactListsForContent = new List()) => {
  return selectedListIds.reduce((numContacts, listId) => {
    const foundList = contactListsForContent.find(listHasId(listId)) || contactLists.find(listHasId(listId));
    const listSize = foundList ? foundList.getIn(['metaData', 'size']) : null;
    if (listSize) {
      return numContacts + listSize;
    }
    return numContacts;
  }, 0);
});
export const getFeaturedImageOptions = createSelector([getContentModelFeaturedImage, getContentModelFeaturedImageAltText, getModuleAndUploadedImages, getFeaturedImageThumbnailUrls, getAuth], (featuredImage, featuredImageAltText, images, featuredImageThumbnailUrls, auth) => getImages(images, featuredImage, featuredImageAltText, featuredImageThumbnailUrls, auth));
export const getHasInvalidDynamicPageSourceValue = createSelector([getDynamicPagesDataSourceOptions, getContentModelDynamicPageSourceValue, getHubDbTablesRequest, getCrmObjectTypesRequest], (dynamicPagesDataSourceOptions, contentModelDynamicPageSourceValue, hubDbTablesRequest, crmObjectTypesRequest) => {
  if (!contentModelDynamicPageSourceValue || !requestSucceeded(hubDbTablesRequest) || !requestSucceeded(crmObjectTypesRequest)) return false;
  const allOptions = dynamicPagesDataSourceOptions.reduce((acc, dataSourceOption) => acc.concat(dataSourceOption.options), []);
  let foundOption = false;
  for (let j = 0; j < allOptions.length && !foundOption; j++) {
    if (allOptions[j].value === contentModelDynamicPageSourceValue) {
      foundOption = true;
    }
  }
  return !foundOption;
});
export const getCannotUseCrmObjectTypeBecauseRestricted = createSelector([getPageSettingsAudienceAccess, getContentModelPassword, getContentModelRequirePassword, getContentModelDynamicPageSourceValue, getDynamicPageSourceType], (pageAudienceAccess, contentModelPassword, contentModelRequirePassword, contentModelDynamicPageSourceValue, dynamicPageSourceType) => dynamicPageSourceType === CRM_SOURCE_TYPE && RESTRICTED_STANDARD_OBJECTS.includes(contentModelDynamicPageSourceValue) && (pageAudienceAccess === AudienceAccessTypes.ALL || !pageAudienceAccess) && !(contentModelRequirePassword && contentModelPassword));
export const getPageSettingsErrors = createSelector([getContentModel, getPageSettings, getPagePrimaryDomain, getContentModelHasValidDomain, getIsAllowedToPublishWithHubdb, getHasCurrentLanguageInTranslatedContent, getIsBlogListingPage, getHasInvalidDynamicPageSourceValue, getCannotUseCrmObjectTypeBecauseRestricted, getIsPublishOptionsModalOpen, getIsUngatedForPatagonia, getHasAudienceAccessScope, getUserHasPublishPrivateContentAccess, getPortalIsUngatedForAccessGroups], (contentModel, pageSettings, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen, isUngatedForPatagonia, hasAudienceAccessWrite, userHasPublishPrivateContentAccess, isUngatedForAccessGroups) => {
  const authData = new ImmutableMap({
    isUngatedForPatagonia,
    hasAudienceAccessWrite,
    userHasPublishPrivateContentAccess,
    isUngatedForAccessGroups
  });
  return pageSettingsErrors(contentModel, pageSettings, authData, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, !isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen);
});
export const getPageSettingsWarnings = createSelector([getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct], shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct => pageSettingsWarnings(shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct));
export const getCanSetPageExpiry = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
export const getCanSetPageAudience = createSelector([getHasAudienceAccessScope, getIsBlogListingPage], (hasAudienceAccessWrite, isBlogListingPage) => hasAudienceAccessWrite && !isBlogListingPage);
export const getCanSetDynamicPages = createSelector([getHasDynamicPageAccess, getIsBlogListingPage], (hasDynamicPageAccess, isBlogListingPage) => hasDynamicPageAccess && !isBlogListingPage);
export const getCanSetMetaDescription = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
const getHasMultilangAccessForPageType = createSelector([getIsSitePage, getHasLandingPagesMultiLangAccess, getHasSitePagesMultiLangAccess], (isSitePage, hasLandingPagesMultiLangAccess, hasSitePagesMultiLangAccess) => isSitePage ? hasSitePagesMultiLangAccess : hasLandingPagesMultiLangAccess);
export const getCanSetPageLanguage = createSelector([getIsBlogListingPage, getLanguage, getHasMultilangAccessForPageType], (isBlogListingPage, contentModelLanguage, hasMultilangAccessForPageType) => !isBlogListingPage && (Boolean(contentModelLanguage) || hasMultilangAccessForPageType));
export const getCanSetCanonicalUrl = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
export const getIsHtmlTitleDisabled = createSelector([getIsBlogListingPage], isBlogListingPage => isBlogListingPage);
export const getDynamicPageSlug = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSlug, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageSlug, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return dynamicPagesDataQuery.getIn(['propertyDefinitionForPageSlug', 'label']);
  }
  return contentModelDynamicPageSlug;
});
export const getDynamicPageTitle = createSelector([getDynamicPageSourceType, getContentModelDynamicPageTitle, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageTitle, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return dynamicPagesDataQuery.getIn(['propertyDefinitionForTitle', 'label']);
  }
  return contentModelDynamicPageTitle;
});
export const getDynamicPageMetaDescription = createSelector([getDynamicPageSourceType, getContentModelDynamicPageMeta, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPagesMeta, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return dynamicPagesDataQuery.getIn(['propertyDefinitionForMetaDescription', 'label']);
  }
  return contentModelDynamicPagesMeta;
});
export const getDynamicPageFeaturedImage = createSelector([getDynamicPageSourceType, getContentModelDynamicPageImage, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageFeaturedImage, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return dynamicPagesDataQuery.getIn(['propertyDefinitionForFeaturedImage', 'label']);
  }
  return contentModelDynamicPageFeaturedImage;
});
export const getDynamicPageCanonicalUrl = createSelector([getDynamicPageSourceType, getContentModelDynamicPageUrl, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageCanonicalUrl, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return dynamicPagesDataQuery.getIn(['propertyDefinitionForCanonicalUrl', 'label']);
  }
  return contentModelDynamicPageCanonicalUrl;
});
export const getHasAnyStylesheetsToEdit = createSelector([getPageSettingsDomainStylesheets, getPageSettingsPageStylesheets, getPageSettingsTemplateStylesheets], (domainStylesheets, pageStylesheets, templateStylesheets) => Boolean(domainStylesheets && domainStylesheets.size > 0 || templateStylesheets && templateStylesheets.size > 0 || pageStylesheets && pageStylesheets.size > 0));
export const getCanEditStylesheets = createSelector([getHasAnyStylesheetAccess, getHasAnyStylesheetsToEdit], (hasAnyStylesheetsAccess, hasAnyStylesheetsToEdit) => Boolean(hasAnyStylesheetsAccess || hasAnyStylesheetsToEdit));
export const getCanUseAutotranslateForContentLanguage = createSelector([getContent, getLanguage, getSiteSettings], (content, contentLanguage, siteSettings) => {
  const language = contentLanguage || getDomainLanguage(content, siteSettings);
  return !language || checkIsLanguageSupportedByDeepL({
    language
  });
});
'use es6';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestCompleted, requestSucceeded, requestUninitialized } from 'redux-request-state/util/requestStateCheckers';
import { getTemplatePath } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getShouldRequestTemplateInfo } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getTemplateInfoRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { fetchTemplateInfo } from 'ContentEditorUI/redux/actions/templateInfoActions';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { openModal, togglePublishOptionsModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { setScrollTo, updatePatagoniaSettingsModalActiveNavItem } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { getCurrentTabId } from 'ContentEditorUI/redux/selectors/routingSelectors';
import { useQueryParams } from 'ContentEditorUI/utils/routingUtils';
export function useTemplateInfoRequestState() {
  const dispatch = useDispatch();
  const templatePath = useSelector(getTemplatePath);
  const templateRequest = useSelector(getTemplateInfoRequest);
  const shouldRequestTemplateInfo = useSelector(getShouldRequestTemplateInfo);
  useEffect(() => {
    if (shouldRequestTemplateInfo && requestUninitialized(templateRequest)) {
      dispatch(fetchTemplateInfo(templatePath));
    }
  }, [templateRequest, templatePath, dispatch, shouldRequestTemplateInfo]);
  return {
    templateInfoRequestCompleted: requestCompleted(templateRequest),
    templateInfoRequestSucceeded: requestSucceeded(templateRequest)
  };
}
export function useOpenPatagoniaSettingsModalIfNecessary(optimizationDeepLinkConfig) {
  const {
    optimizationType,
    deepLinkQuery
  } = useQueryParams();
  const isUngatedForPatagonia = useSelector(getIsUngatedForPatagonia);
  const currentTabId = useSelector(getCurrentTabId);
  const dispatch = useDispatch();
  const optimizedItemDeepLinkQuery = isUngatedForPatagonia && optimizationDeepLinkConfig[optimizationType] ? optimizationDeepLinkConfig[optimizationType] : null;
  useEffect(() => {
    if (!optimizedItemDeepLinkQuery) {
      return;
    }
    dispatch(setScrollTo(optimizedItemDeepLinkQuery));
    dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
  }, [optimizedItemDeepLinkQuery, dispatch]);
  useEffect(() => {
    if (isUngatedForPatagonia && currentTabId === 'settings') {
      if (deepLinkQuery) {
        dispatch(setScrollTo(deepLinkQuery));
      } else {
        dispatch(updatePatagoniaSettingsModalActiveNavItem('general'));
      }
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
    }
  }, [currentTabId, dispatch, isUngatedForPatagonia, deepLinkQuery]);
}
export function useOpenPatagoniaPublishingModalIfNecessary() {
  const isUngatedForPatagonia = useSelector(getIsUngatedForPatagonia);
  const currentTabId = useSelector(getCurrentTabId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isUngatedForPatagonia && currentTabId === 'publishing') {
      dispatch(togglePublishOptionsModalOpen(true));
    }
  }, [currentTabId, dispatch, isUngatedForPatagonia]);
}
import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
const MAX_H1_COUNT = 1;
export const addH1TagsAudit = artifacts => {
  const pageTitle = artifacts[GathererKeys.PAGE_TITLE];
  const postBody = artifacts[GathererKeys.POST_BODY];
  const h1Elements = artifacts[GathererKeys.H1_ELEMENTS];
  const allModules = artifacts[GathererKeys.ALL_MODULES];
  let status;
  if (h1Elements && h1Elements.length === MAX_H1_COUNT) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }
  return {
    status,
    h1Elements,
    pageTitle,
    postBody,
    allModules: !allModules ? null : allModules.toJS()
  };
};
import { FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_MLANG_PAGES_LIMIT_SUCCEEDED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../a... Remove this comment to see the full error message
} from '../actions/actionTypes';
const DEFAULT_STATE = {
  pageLimit: null,
  quantityUsed: 0,
  hasExceededContentTypeLimit: false,
  mlangPagesLimit: null,
  mlangPagesQuantityUsed: 0,
  isApplyingSharedPageLimit: false
};
const pageLimitReducer = (state = DEFAULT_STATE, {
  payload,
  type
}) => {
  switch (type) {
    case FETCH_PAGE_LIMIT_SUCCEEDED:
      {
        if (payload && payload.pageLimit) {
          return Object.assign({}, state, {
            pageLimit: payload.pageLimit,
            quantityUsed: payload.quantityUsed,
            hasExceededContentTypeLimit: payload.quantityUsed > payload.pageLimit,
            isApplyingSharedPageLimit: payload.isApplyingSharedPageLimit !== undefined ? payload.isApplyingSharedPageLimit : false
          });
        }
        return state;
      }
    case FETCH_MLANG_PAGES_LIMIT_SUCCEEDED:
      {
        if (payload && payload.limit) {
          return Object.assign({}, state, {
            mlangPagesLimit: payload.limit,
            mlangPagesQuantityUsed: payload.quantityUsed
          });
        }
        return state;
      }
    default:
      return state;
  }
};
export default pageLimitReducer;
'use es6';

import { createSelector } from 'reselect';
import { List, Map as ImmutableMap } from 'immutable';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { getAnyModulesHaveSmartContent } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getHasMABAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getIsAbTest, getIsABTestActivelyRunning } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import I18n from 'I18n';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const MAB_DISABLED_REASONS = {
  ab: 'ab',
  noAccess: 'noAccess',
  smartContent: 'smartContent',
  translation: 'translation'
};
const METRIC_ID_TO_METRIC = ImmutableMap({
  0: 'submissions'
});
export const getMABExperiment = basicSelector(state => state.mabExperiment || ImmutableMap());
const getMABExperimentData = createSelector([getMABExperiment], mabExperiment => mabExperiment.get('mabExperiment', ImmutableMap()));
export const getMABExperimentLoading = createSelector([getMABExperiment], mabExperiment => mabExperiment.get('loading', false));
export const getMABExperimentId = createSelector([getMABExperimentData], mabExperiment => mabExperiment.get('id'));
export const getIsMABExperiment = createSelector([getMABExperimentId], mabExperimentId => {
  return Boolean(mabExperimentId);
});
export const getMABExperimentMasterVariant = createSelector([getMABExperiment], mabExperiment => mabExperiment.get('master', ImmutableMap()));
export const getMABExperimentMasterVariantId = createSelector([getMABExperimentMasterVariant], masterVariant => masterVariant.get('id'));
export const getMABExperimentVariants = createSelector([getMABExperiment], mabExperiment => mabExperiment.get('variants', List()));
export const getMABExperimentVariantsWithMaster = createSelector([getMABExperimentVariants, getMABExperimentMasterVariant], (mabExperimentVariants, masterVariant) => mabExperimentVariants.unshift(masterVariant));
export const getMABExperimentVariantsSize = createSelector([getMABExperimentVariantsWithMaster], variants => variants.size);
export const getIsMABMaster = createSelector([getIsMABExperiment, getMABExperiment], (isMabExperiment, mabExperiment) => isMabExperiment && mabExperiment.get('isMabMaster'));
export const getIsMabVariant = createSelector([getIsMABExperiment, getMABExperiment], (isMabExperiment, mabExperiment) => isMabExperiment && mabExperiment.get('isMabVariant'));
export const getIsMabVariantWithoutAccess = createSelector(getIsMabVariant, getHasMABAccess, (isMabVariant, hasMABAccess) => isMabVariant && !hasMABAccess);
export const getMABCreatableMap = createSelector([getHasMABAccess, getIsAbTest, getAnyModulesHaveSmartContent], (hasMABAccess, isAbTest, hasSmartContent) => {
  let reason = '';
  if (!hasMABAccess) {
    reason = MAB_DISABLED_REASONS.noAccess;
  } else if (isAbTest) {
    reason = MAB_DISABLED_REASONS.ab;
  } else if (hasSmartContent) {
    reason = MAB_DISABLED_REASONS.smartContent;
  }
  return ImmutableMap({
    disabled: reason.length > 0,
    reason
  });
});
export const getIsAnyTestRunning = createSelector([getIsABTestActivelyRunning, getIsMABExperiment], (isABTestActivelyRunning, isMabExperiment) => isABTestActivelyRunning || isMabExperiment);
export const getIsMABEditable = createSelector([getHasMABAccess, getIsMABExperiment], (hasMABAccess, isMabExperiment) => hasMABAccess && isMabExperiment);
export const getIsMABWithoutAccess = createSelector([getHasMABAccess, getIsMABExperiment], (hasMABAccess, isMabExperiment) => !hasMABAccess && isMabExperiment);
export const getMABExperimentMetric = createSelector([getMABExperimentData], mabExperiment => METRIC_ID_TO_METRIC.get(`${mabExperiment.get('metricId')}`, METRIC_ID_TO_METRIC.first()));
export const getMABExperimentIsStarted = createSelector([getMABExperimentData], mabExperiment => mabExperiment.get('startedAt', 0) > 0);
export const getMABExperimentIsMasterPublished = createSelector([getMABExperimentMasterVariant], masterVariant => StateStatusGroups.PUBLISHED.includes(masterVariant.get('state')));

// When landing pages are either published or scheduled, their state is
// PUBLISHED_OR_SCHEDULED so isPublished and isScheduled are both true.
// We need this to tell which one of them it is
export const getMABExperimentIsMasterScheduled = createSelector([getMABExperimentMasterVariant], masterVariant => {
  const isPublishedImmediately = masterVariant.get('publishImmediately');
  const publishDate = masterVariant.get('publishDate');
  const now = I18n.moment.portalTz();
  if (typeof isPublishedImmediately === 'boolean') {
    return !isPublishedImmediately && publishDate > now;
  }
  return publishDate > now;
});
export const getMABExperimentIsMasterLive = createSelector([getMABExperimentIsMasterPublished, getMABExperimentIsMasterScheduled], (isPublished, isScheduled) => isPublished && !isScheduled);
export const getIsMABExperimentWithoutAccess = createSelector([getHasMABAccess, getIsMABExperiment], (hasMABAccess, isMabExperiment) => isMabExperiment && !hasMABAccess);
export const getMABExperimentPrimayVariantName = createSelector([getMABExperimentMasterVariant, getIsMABExperiment], (mabPrimary, isMabExperiment) => isMabExperiment ? mabPrimary.get('name') : '');
import { SET_XRAY_INPAGE_GATHERER_KEYS } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  inpageGathererKeys: []
};
const inpageOptimizationsReducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case SET_XRAY_INPAGE_GATHERER_KEYS:
      {
        const {
          inpageGathererKeys
        } = payload;
        return Object.assign({}, state, {
          inpageGathererKeys
        });
      }
    default:
      return state;
  }
};
export default inpageOptimizationsReducer;
import { HS_PLUGINS } from './constants/hsSettings';
import { HS_FORMATTING, HS_PERSONALIZE } from './constants/plugins';
import { LINK_SETTINGS } from './constants/hslink';
import { ANCHOR_SETTINGS } from './constants/hsanchor';
export function hasPlugin(editor, plugin) {
  return editor.settings.plugins.includes(plugin);
}
export function getPluginSettings(editorSettings) {
  if (!editorSettings) {
    return null;
  }
  return editorSettings[HS_PLUGINS];
}
export function getPluginSetting(editor, setting, defaultValue = null) {
  const pluginSettings = getPluginSettings(editor.settings);
  if (!pluginSettings || typeof pluginSettings[setting] === 'undefined') {
    return defaultValue;
  }
  return pluginSettings[setting];
}
export function getAnchorPluginSetting(editor, setting) {
  const anchorPluginSettings = getPluginSetting(editor, ANCHOR_SETTINGS);
  return !!anchorPluginSettings && anchorPluginSettings[setting];
}
export function getLinkPluginSetting(editor, setting) {
  const linkPluginSettings = getPluginSetting(editor, LINK_SETTINGS);
  if (linkPluginSettings) {
    return linkPluginSettings[setting];
  }
  return null;
}
export function getHsformattingSettings(editorSettings, pluginType, defaultValue = null) {
  const pluginSettings = getPluginSettings(editorSettings);
  if (!pluginSettings || !pluginSettings[HS_FORMATTING] || !pluginSettings[HS_FORMATTING][pluginType]) {
    return defaultValue;
  }
  return pluginSettings[HS_FORMATTING][pluginType];
}
export function getHsPersonalizeSettings(editor) {
  const pluginSettings = editor.settings ? editor.settings[HS_PLUGINS] : null;
  if (!pluginSettings || !pluginSettings[HS_PERSONALIZE]) {
    return null;
  }
  return pluginSettings[HS_PERSONALIZE];
}
let uniqueContainerId = 0;
export function appendDivToEditor(editor, id) {
  const editorContainer = editor.getContainer();
  if (editorContainer) {
    const container = document.createElement('div');
    container.id = id || `modal-container-${++uniqueContainerId}`;
    return editorContainer.appendChild(container);
  }
  return null;
}
export function isWhitespace(input = '') {
  return !input.trim();
}
export function isLikelyUrlWithoutProtocol(possibleUrl) {
  const splitUrl = possibleUrl.split(/([/?].*)/);
  const domainString = splitUrl[0];
  const pathString = splitUrl[1] || '';
  const domainReg = /^([a-zA-Z0-9-]{1,61}\.){0,126}[a-zA-Z0-9-]{1,61}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?\/{0,1}$/;
  const pathReg = /^\/([^\s/]+?\/{0,1})*(\?[^\s/]*?)*?$|^$|^\?[^\s]*?$/;
  return domainReg.test(domainString) && pathReg.test(pathString);
}
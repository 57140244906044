'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getLayoutData = basicSelector(state => state.layoutData);
export const getHasLayoutData = createSelector(getLayoutData, layoutData => !layoutData.isEmpty());
export const getIsLayoutBuiltIn = createSelector(getLayoutData, layoutData => layoutData.get('builtin'));
export const getIsLayoutPurchased = createSelector(getLayoutData, layoutData => layoutData.get('purchased'));
export const getIsLayoutReadOnly = createSelector(getLayoutData, layoutData => layoutData.get('isReadOnly'));
export const getIsLayoutBuiltInWithBadData = createSelector([getLayoutData], layoutData => {
  return layoutData.get('authorAt') === 0 && layoutData.get('id') === 0 && layoutData.get('created') === 0 && layoutData.get('updated') === 0;
});
export const getLayoutId = createSelector([getLayoutData], layoutData => layoutData.get('id'));
export const getLayoutBuiltInId = createSelector([getLayoutData], layoutData => layoutData.get('builtinId'));
export const getHasMoreThanOnePageWithTemplate = createSelector([getLayoutData], layoutData => layoutData.get('changeInfo').get('numContents') > 1);
'use es6';

/* global hubspot */
import Interframe from 'interframe';
import { mergeModuleBody, updateContainers, mergeModuleMetaData, mergeModuleSmartObjectBody } from 'ContentEditorUI/redux/actions/moduleActions';
import { setDirtyFlag } from 'ContentEditorUI/redux/actions/appActions';
import { formatContainersFromStoreForSave } from 'ContentEditorUI/data/ContainerTransforms';
import { getDefaultType } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import inpageActionTypes from 'ContentEditorUI/redux/actions/inpageActionTypes';
import { logLameErrorComponentMessageToConsole, sendErrorToNewRelicAndSentry } from 'ContentEditorUI/components/lameErrorHandling';
import { captureMessage } from '../exceptions';
import { updateComputedStylesForSelectors } from 'ContentEditorUI/redux/actions/inpageActions';
import { getPreviewDomain } from 'ContentEditorUI/js/redux/selectors/iframesSelectors';
import { isValidAppRedirect } from 'ContentEditorUI/utils/urlUtils';
class InpageMessenger {
  constructor() {
    this.create = otherWindow => {
      this.messenger = new Interframe(`${document.location.protocol}//${getPreviewDomain(this.store.getState())}`, otherWindow);
      this.messenger.subscribe(this.receiveMessage);
    };
    this.postMessage = (message, callback) => {
      this.messenger.postMessage(message, callback);
    };
    this.setStore = store => {
      this.store = store;
    };
    this.receiveMessage = message => {
      let response = {};
      switch (message.action) {
        case 'changeLocation':
          if (!message.payload.url) {
            console.error(message, 'changeLocation bus event requires a url');
            break;
          }
          if (!isValidAppRedirect(message.payload.url)) {
            console.error(message.payload.url, 'Invalid redirect url');
            break;
          }
          if (message.payload.newTab) {
            window.open(message.payload.url, `_newtab_${Date.now()}`);
            break;
          }
          window.location = message.payload.url;
          break;
        case 'reduxAction':
          this.store.dispatch(message);
          break;
        case inpageActionTypes.UPDATE_CONTAINERS:
          this.store.dispatch(updateContainers(message.containers));
          window.hubspot.ContentEditorUI.eventBus.trigger('post:saveContainers', {
            containers: formatContainersFromStoreForSave()
          });
          break;
        case inpageActionTypes.UPDATE_WIDGET:
          {
            const moduleId = typeof message.id === 'string' ? message.id : `widget_${message.id}`;
            const moduleType = getDefaultType(this.store.getState(), moduleId);
            const shouldUpdateSmartObjects = message.isSmartContentPreview && message.selectedSmartRuleId && message.matchingSmartObjectIndex > -1;
            switch (moduleType) {
              case 'rich_text':
              case 'email_resubscribe':
              case 'email_optin':
              case 'email_body':
                {
                  const action = shouldUpdateSmartObjects ? mergeModuleSmartObjectBody(moduleId, {
                    html: message.value
                  }, message.matchingSmartObjectIndex, {
                    metaData: message.metaData,
                    updateEditVersion: message.updateEditVersion
                  }) : mergeModuleBody(moduleId, {
                    html: message.value
                  }, {
                    metaData: message.metaData,
                    undoData: message.undoData
                  });
                  response = this.store.dispatch(action);
                  break;
                }
              case 'section_header':
                {
                  const action = shouldUpdateSmartObjects ? mergeModuleSmartObjectBody(moduleId, message.value, message.matchingSmartObjectIndex, {
                    metaData: message.metaData,
                    updateEditVersion: message.updateEditVersion
                  }) : mergeModuleBody(moduleId, message.value, {
                    inlineEdited: true
                  });
                  response = this.store.dispatch(action);
                  break;
                }
              default:
                {
                  const action = shouldUpdateSmartObjects ? mergeModuleSmartObjectBody(moduleId, {
                    value: message.value
                  }, message.matchingSmartObjectIndex, {
                    metaData: message.metaData,
                    updateEditVersion: message.updateEditVersion
                  }) : mergeModuleBody(moduleId, {
                    value: message.value
                  }, {
                    metaData: message.metaData,
                    undoData: message.undoData
                  });
                  response = this.store.dispatch(action);
                  break;
                }
            }
            break;
          }
        case inpageActionTypes.INPAGE_MERGE_MODULE_BODY:
          {
            const moduleId = typeof message.id === 'string' ? message.id : `widget_${message.id}`;
            const shouldUpdateSmartObjects = message.isSmartContentPreview && message.selectedSmartRuleId && message.matchingSmartObjectIndex > -1;
            const action = shouldUpdateSmartObjects ? mergeModuleSmartObjectBody(moduleId, message.value, message.matchingSmartObjectIndex, {
              metaData: message.metaData,
              updateEditVersion: message.updateEditVersion
            }) : mergeModuleBody(moduleId, message.value, {
              metaData: message.metaData,
              undoData: message.undoData
            });
            response = this.store.dispatch(action);
            break;
          }
        case inpageActionTypes.WIDGET_UPDATE_PENDING:
          this.store.dispatch(setDirtyFlag(true));
          break;
        case inpageActionTypes.UPDATE_MODULE_META_DATA:
          this.store.dispatch(mergeModuleMetaData(message.id, message.metaData));
          break;
        case inpageActionTypes.LOG_LAME_ERROR_MESSAGE_TO_CONSOLE:
          logLameErrorComponentMessageToConsole();
          break;
        case inpageActionTypes.REACT_ERROR_IN_IFRAME:
          sendErrorToNewRelicAndSentry({
            error: message.error,
            info: message.info,
            isFromIframe: true
          });
          break;
        case inpageActionTypes.UPDATE_COMPUTED_STYLES_FOR_SELECTORS:
          this.store.dispatch(updateComputedStylesForSelectors(message.computedStylesBySelector));
          break;
        case inpageActionTypes.SENTRY_MESSAGE_IN_IFRAME:
          captureMessage(message.sentryMessage, {
            extra: {
              info: message.info
            }
          });
          break;
        default:
          try {
            if (localStorage.INPAGE_MESSENGER_DEBUG) {
              console.log('Unhandled IPEUI action:', message.action, message);
            }
          } catch (e) {
            return;
          }
      }
      if (message.needsResponse) {
        this.messenger.postResponse(message, response);
      }
    };
  }
}
const messenger = new InpageMessenger();
export default messenger;
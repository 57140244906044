import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { UENameToIdMap } from '../constants/unifiedEventTypeIds';
const BASE_URL = 'events/internal/v0/read/app-events';
const portalId = PortalIdParser.get();
export default {
  fetchUnifiedEvents: (approvalType, approvableObjectId, after) => {
    const data = {
      portalId,
      startTimestamp: 0,
      objectType: 'Any',
      objectFilter: null,
      limit: 100,
      sortDir: 'DESC',
      eventFilters: Object.values(UENameToIdMap).map(innerId => ({
        typeId: {
          innerId,
          metaTypeId: 4
        },
        propertyMatch: {
          hs_approval_type: approvalType,
          hs_approvable_object_id: approvableObjectId
        }
      }))
    };
    return http.post(`${BASE_URL}`, {
      data,
      query: {
        after
      }
    });
  }
};
'use es6';

import { getDefaultDisplayStyleType, getTreeNodeDomProperties } from '../helpers';
export const calculateBreakpointHiddenClassesForTreeNode = (treeNode, styleValue, breakpoint, options) => {
  // Check to see if we need to use display: flex or display: block
  const {
    styles
  } = options;
  const displayValue = getDefaultDisplayStyleType(styles);

  // If the value is null, this style is "unset". If the value isn't explicitly true or false, then don't do anything, because we don't want to coerce values.
  if (styleValue === null || styleValue !== true && styleValue !== false) {
    if (styleValue !== null) {
      console.warn(`Style value "${styleValue}" for hidden must be a boolean of true or false`);
    }
    return [];
  }
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);

  // TODO: Might have to get creative here in how the explicit "show" property is set. Block works fine for most cases, but in cases where the tree node has alignment and is display: flex, we need to detect that and set that instead
  const cssProperties = {
    display: styleValue ? 'none !important' : displayValue
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-hidden`,
    cssSelector: `.${rendererName}-hidden`,
    cssProperties,
    mediaQuery: breakpoint.mediaQuery
  }];
};
'use es6';

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getModuleErrorsRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { fetchModuleErrors as fetchModuleErrorsAction } from 'ContentEditorUI/redux/actions/moduleErrorActions';
import { requestUninitialized } from 'redux-request-state/util/requestStateCheckers';
const mapStateToProps = state => {
  return {
    moduleErrorsRequest: getModuleErrorsRequest(state),
    contentId: getContentModelId(state)
  };
};
const mapDispatchToProps = {
  fetchModuleErrors: fetchModuleErrorsAction
};
const FetchModuleErrors = ({
  moduleErrorsRequest,
  contentId,
  fetchModuleErrors
}) => {
  useEffect(() => {
    if (requestUninitialized(moduleErrorsRequest)) {
      fetchModuleErrors(contentId);
    }
  }, [moduleErrorsRequest, contentId, fetchModuleErrors]);
  return null;
};
FetchModuleErrors.propTypes = {
  moduleErrorsRequest: PropTypes.object.isRequired,
  fetchModuleErrors: PropTypes.func.isRequired,
  contentId: PropTypes.number.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(FetchModuleErrors);
import PortalIdParser from 'PortalIdParser';
export const getTrialGuideUrl = (upgradeProduct, source) => {
  return `/trial-guide/${PortalIdParser.get()}/${upgradeProduct}${source ? `?source=${source}` : ''}`;
};
export const getTrialGuideUrlFromApiName = (apiName, source) => {
  return `/trial-guide/${PortalIdParser.get()}/${apiName}${source ? `?source=${source}` : ''}`;
};
export const getReportsDashboardUrl = () => {
  return `/reports-dashboard/${PortalIdParser.get()}/sales`;
};
export const refresh = () => {
  window.location.reload();
};
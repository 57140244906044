'use es6';

import { CELL_ATTRIBUTE, ROW_ATTRIBUTE, STATIC_SECTION_ATTRIBUTE, ROW_TREE_ATTRIBUTE, EMPTY_PLACEHOLDER_ID_PARTIAL, STATIC_SECTION_CLASS_NAME, EMBED_ATTRIBUTE } from '../constants/dndAreaDomConstants';
export const isDndAreaColumn = element => {
  return element && element.hasAttribute(CELL_ATTRIBUTE);
};
export const isStaticSectionElement = element => {
  return element && element.classList.contains(STATIC_SECTION_CLASS_NAME);
};
export const isEmbedElement = element => {
  return element && element.hasAttribute(EMBED_ATTRIBUTE);
};
export const isDndAreaRow = element => {
  return element && element.hasAttribute(ROW_ATTRIBUTE);
};
export const isDndAreaEmptyPlaceholderElement = element => {
  return element && element.id.includes(EMPTY_PLACEHOLDER_ID_PARTIAL);
};
export const buildDndAreaRowId = (parentColumnId, index) => {
  return `${parentColumnId}-row-${index}`;
};
export const buildDndAreaRowTreeId = (parentColumnId, rowId) => {
  return `${parentColumnId}-${rowId}`;
};
export const buildDndAreaRowSelector = rowId => {
  return `[${ROW_ATTRIBUTE}="${rowId}"]`;
};
export const buildDndAreaUniqueRowSelector = (parentName, rowName) => {
  return `[${ROW_TREE_ATTRIBUTE}="${buildDndAreaRowTreeId(parentName, rowName)}"]`;
};
export const buildRenderCompatibleRowChildDomSelector = treeNode => {
  const rendererRowName = treeNode.getRendererCompatibleRowName();
  return `${buildDndAreaRowSelector(rendererRowName)} > .row-fluid`;
};
export const buildDndAreaCellSelector = cellId => {
  return `[${CELL_ATTRIBUTE}="${cellId}"]`;
};
export const getDndAreaElement = layoutTreeId => {
  return document.querySelector(`[${CELL_ATTRIBUTE}="${layoutTreeId}"]`);
};
export const getDndAreaCellElement = id => {
  let element = document.querySelector(`[${CELL_ATTRIBUTE}="${id}"]`);
  if (element) {
    return element;
  }
  // Edge case for static section modules
  // which don't have a `data-hs-cell-id` attribute
  element = document.querySelector(`[id="hs_cos_wrapper_${id}"]`);
  if (element) {
    return element;
  }
  console.log(`Cannot find cell in DOM: ${id}`);
  return undefined;
};
export const getDndAreaRowElementByIdInTree = (parentColumnId, id) => {
  return document.querySelector(`[${ROW_TREE_ATTRIBUTE}="${buildDndAreaRowTreeId(parentColumnId, id)}"]`);
};
export const getDndAreaStaticSectionElementByIdInTree = id => {
  return document.querySelector(`[${STATIC_SECTION_ATTRIBUTE}=${id}]`);
};
export const getDndAreaEmbedElementByIdInTree = rowId => {
  return document.querySelector(`[${EMBED_ATTRIBUTE}=${rowId}]`);
};
export const getDndAreaRowElementByIndex = (parentColumnId, rowIndex) => {
  const element = document.querySelector(
  // e.g. `${parentColumnId}-row-${index}`;
  buildDndAreaRowSelector(buildDndAreaRowId(parentColumnId, rowIndex)));
  if (element) {
    return element;
  }
  return undefined;
};
export const getFirstChildElementOfColumn = el => {
  if (isDndAreaColumn(el)) {
    return el.firstElementChild;
  }
  return getFirstChildElementOfColumn(el.firstElementChild);
};

// Should be used for one off requests, and not in loops iterating over the whole tree
// (to prevent waste re-looking up the root tree element)
// This can only be run AFTER addTreeIdToLayer adds the data-hs-cell-id to the dndAreas.
export const getDndAreaElementForNode = treeNode => {
  if (treeNode.isStaticSection()) {
    return getDndAreaStaticSectionElementByIdInTree(treeNode.getName());
  }
  if (treeNode.isEmbed()) {
    return getDndAreaEmbedElementByIdInTree(treeNode.getName());
  }
  if (treeNode.isRow()) {
    return getDndAreaRowElementByIdInTree(treeNode.getParentName(), treeNode.getName());
  }
  return getDndAreaCellElement(treeNode.getName());
};
export const getDndAreaRowInnerWrapperElement = rowElement => {
  return Array.from(rowElement.childNodes).filter(el => el.nodeType === 1 && el.classList.contains('row-fluid'))[0];
};
import { getAttachedContentWithContext } from './client';
import { FETCH_ATTACHED_CLUSTER_FAILED, FETCH_ATTACHED_CLUSTER_STARTED, FETCH_ATTACHED_CLUSTER_SUCCEEDED } from '../actionTypes';
export const fetchAttachedContentWithContext = ({
  contentId
}) => dispatch => {
  dispatch({
    type: FETCH_ATTACHED_CLUSTER_STARTED
  });
  return getAttachedContentWithContext({
    contentId
  }).then(response => {
    dispatch({
      type: FETCH_ATTACHED_CLUSTER_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: FETCH_ATTACHED_CLUSTER_FAILED,
      payload: error
    });
  });
};
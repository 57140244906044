import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as salesforceClient from 'ContentData/api/Salesforce';
import { SALESFORCE_FETCH_CAMPAIGN, SALESFORCE_FETCH_CAMPAIGNS, SALESFORCE_FETCH_INTEGRATION_STATUS } from 'ContentData/actions/ActionTypes';
const fetchCampaignAction = createGenericFetchAction(SALESFORCE_FETCH_CAMPAIGN, salesforceClient.fetchCampaign);
const fetchCampaignsAction = createGenericFetchAction(SALESFORCE_FETCH_CAMPAIGNS, salesforceClient.fetchCampaigns);
const fetchIntegrationStatusAction = createGenericFetchAction(SALESFORCE_FETCH_INTEGRATION_STATUS, salesforceClient.fetchIntegrationStatus);
export const fetchCampaign = id => {
  return fetchCampaignAction(id);
};
export const fetchCampaigns = (...rest) => {
  return fetchCampaignsAction(rest);
};
export const fetchIntegrationStatus = (...rest) => {
  return fetchIntegrationStatusAction(rest);
};
export const getIncorrectlySizedImagesInImageModules = (images, allModules, moduleIdOfImageModule) => {
  if (!images) {
    return [];
  }
  return images.filter(img => img.naturalWidth > img.width * 2).filter(img => {
    if (!img.moduleId) {
      return false;
    }
    const module = allModules[img.moduleId];
    if (!module || !module.body) {
      return false;
    }
    const moduleInternalId = module.module_id;
    if (moduleIdOfImageModule !== moduleInternalId) {
      return false;
    }
    const moduleImage = module.body.img;
    if (!moduleImage) {
      return false;
    }
    return moduleImage.size_type === 'auto';
  });
};
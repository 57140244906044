export const COMMENT_FILTER_VALUES = {
  open: 'open',
  all: 'all',
  resolved: 'resolved',
  unread: 'unread'
};
export const COMMENT_FILTER_TO_USAGE_TRACKING_FILTER = {
  open: 'Unresolved comments',
  all: 'All comments',
  resolved: 'Resolved comments',
  unread: 'Unread comments'
};
export const FETCH_ATTACHED_CLUSTER_STARTED = 'FETCH_ATTACHED_CLUSTER_STARTED';
export const FETCH_ATTACHED_CLUSTER_SUCCEEDED = 'FETCH_ATTACHED_CLUSTER_SUCCEEDED';
export const FETCH_ATTACHED_CLUSTER_FAILED = 'FETCH_ATTACHED_CLUSTER_FAILED';
export const CREATE_ATTACHED_CONTENT_STARTED = 'CREATE_ATTACHED_CONTENT_STARTED';
export const CREATE_ATTACHED_CONTENT_SUCCEEDED = 'CREATE_ATTACHED_CONTENT_SUCCEEDED';
export const CREATE_ATTACHED_CONTENT_FAILED = 'CREATE_ATTACHED_CONTENT_FAILED';
export const FETCH_CLUSTERS_STARTED = 'FETCH_CLUSTERS_STARTED';
export const FETCH_CLUSTERS_SUCCEEDED = 'FETCH_CLUSTERS_SUCCEEDED';
export const FETCH_CLUSTERS_FAILED = 'FETCH_CLUSTERS_FAILED';
export const CREATE_CLUSTER_STARTED = 'CREATE_CLUSTER_STARTED';
export const CREATE_CLUSTER_SUCCEEDED = 'CREATE_CLUSTER_SUCCEEDED';
export const CREATE_CLUSTER_FAILED = 'CREATE_CLUSTER_FAILED';
export const CREATE_SUBTOPIC_STARTED = 'CREATE_SUBTOPIC_STARTED';
export const CREATE_SUBTOPIC_SUCCEEDED = 'CREATE_SUBTOPIC_SUCCEEDED';
export const CREATE_SUBTOPIC_FAILED = 'CREATE_SUBTOPIC_FAILED';
export const REMOVE_ATTACHED_CONTENT_STARTED = 'REMOVE_ATTACHED_CONTENT_STARTED';
export const REMOVE_ATTACHED_CONTENT_SUCCEEDED = 'REMOVE_ATTACHED_CONTENT_SUCCEEDED';
export const REMOVE_ATTACHED_CONTENT_FAILED = 'REMOVE_ATTACHED_CONTENT_FAILED';
export const SELECTED_CLUSTER_CHANGED = 'SELECTED_CLUSTER_CHANGED';
export const SELECTED_SUBTOPIC_CHANGED = 'SELECTED_SUBTOPIC_CHANGED';
export const FETCH_TOPICS_STARTED = 'FETCH_TOPICS_STARTED';
export const FETCH_TOPICS_SUCCEEDED = 'FETCH_TOPICS_SUCCEEDED';
export const FETCH_TOPICS_FAILED = 'FETCH_TOPICS_FAILED';
export const FETCH_THINGS_PEOPLE_COVER_STARTED = 'FETCH_THINGS_PEOPLE_COVER_STARTED';
export const FETCH_THINGS_PEOPLE_COVER_SUCCEEDED = 'FETCH_THINGS_PEOPLE_COVER_SUCCEEDED';
export const FETCH_THINGS_PEOPLE_COVER_FAILED = 'FETCH_THINGS_PEOPLE_COVER_FAILED';
export const INIT_TOPICS_GROUP_STORE = 'INIT_TOPICS_GROUP_STORE';
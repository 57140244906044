'use es6';

import { createSelector } from 'reselect';
import { getActiveDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getAbsoluteUrl, getFullCategory, getFullCategoryName } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getSupportedCrmObjectFQNs } from 'ContentEditorUI/redux/selectors/featureSelectors';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getBrandColors } from './brandSettingSelectors';
export const getDomainGlobalsForTinyMCE = createSelector([getActiveDomain, getAbsoluteUrl], (activeDomain, absoluteUrl) => ({
  absoluteUrl,
  contentDomain: activeDomain.domain,
  domainIsSsl: activeDomain.is_ssl_only
}));
export const getTinyMCEGlobals = createSelector([getDomainGlobalsForTinyMCE, getSupportedCrmObjectFQNs, getFullCategoryName, getFullCategory, getSubcategory, getBrandColors], (domainGlobalParams, supportedCrmObjectFQNs, categoryName, fullCategory, subcategory, brandColors) => {
  const includeInternalContactRecord = subcategory === 'automated';
  const hspersonalize_enable_deal_tokens_warning = subcategory !== 'automated_for_deal';
  const categoryFieldName = fullCategory ? fullCategory.field_name : null;
  const defaults = {
    appContext: 'edit',
    category_name: categoryName,
    custom_colors: brandColors,
    category: categoryFieldName,
    includeInternalContactRecord,
    hspersonalize_enable_deal_tokens_warning,
    subcategory,
    supportedCrmObjectFQNs
  };
  return Object.assign({}, defaults, domainGlobalParams);
});
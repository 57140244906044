'use es6';

import http from 'hub-http/clients/apiClient';
import Categories from 'ContentUtils/constants/Categories';
import { CONTENT_ITEMS_PER_PAGE } from 'ContentEditorUI/constants/contentManagement';
const getUri = contentTypeId => {
  switch (contentTypeId) {
    case Categories.blog_listing_page.id:
      return 'blogs/v3/blog-listing-pages/crm-search/search';
    case Categories.blog_post.id:
      return 'blogs/v3/blog-posts/crm-search/search';
    case Categories.site_page.id:
      return 'cospages/v2/dashboard/site-pages/crm-search/search';
    case Categories.landing_page.id:
      return 'cospages/v2/dashboard/landing-pages/crm-search/search';
    default:
      return '';
  }
};
const DEFAULT_OPTIONS = {
  objectTypeId: 'content',
  offset: 0,
  count: CONTENT_ITEMS_PER_PAGE
};
const getRequiredFilters = contentTypeId => {
  const requiredFilters = [{
    property: 'hs_translated_from_id',
    operator: 'NOT_HAS_PROPERTY'
  }, {
    property: 'hs_archived',
    operator: 'EQ',
    value: false
  }];
  if (contentTypeId === Categories.landing_page.id || contentTypeId === Categories.site_page.id) {
    requiredFilters.push({
      property: 'hs_ab_status',
      operator: 'NOT_IN',
      values: ['mab_variant', 'variant', 'loser_variant']
    });
  }
  return requiredFilters;
};
export const fetchContent = ({
  contentTypeId,
  query,
  sorts,
  domainFilter,
  page,
  excludedContentIds
}) => {
  const filters = [...getRequiredFilters(contentTypeId), {
    property: 'hs_resolved_domain',
    operator: 'IN',
    values: [domainFilter]
  }];
  if (excludedContentIds && excludedContentIds.length > 0) {
    filters.push({
      property: 'hs_content_id',
      operator: 'NOT_IN',
      values: excludedContentIds
    });
  }
  const options = Object.assign({}, DEFAULT_OPTIONS, {
    filterGroups: [{
      filters
    }],
    query,
    sorts: [{
      property: sorts.selectedColumn,
      order: sorts.sortDir
    }]
  });
  if (page) {
    options.offset = CONTENT_ITEMS_PER_PAGE * (page - 1);
  }
  return http.post(getUri(contentTypeId), {
    data: options
  });
};
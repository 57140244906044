import I18n from 'I18n';
import { useMemo } from 'react';
export default function useButtonA11yProps({
  i18nKey,
  onClick,
  allowTabbing = true
}) {
  return useMemo(() => Object.assign({
    role: 'button',
    'aria-label': I18n.text(i18nKey)
  }, allowTabbing ? {
    tabIndex: '0'
  } : {}, {
    onKeyDown: e => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(e);
      }
    }
  }), [i18nKey, onClick, allowTabbing]);
}
import http from 'hub-http/clients/apiClient';
import createEarlyRequestPromiseOrMakeRequest from './createEarlyRequestPromiseOrMakeRequest';
export default function createRequestClient(requestUrl, requestData, earlyRequestKey = null) {
  let earlyRequestRemoved = false;
  if (!requestUrl) {
    throw new Error('Error creating request client, consumer must provide a request url');
  }
  return () => {
    let url;
    if (typeof requestUrl === 'function') {
      url = requestUrl();
    } else if (typeof requestUrl === 'string') {
      url = requestUrl;
    } else {
      throw new Error(`requestUrl must be of type function or string, instead received type of ${typeof requestUrl}`);
    }
    if (!earlyRequestKey || earlyRequestRemoved) {
      return http.get(url, requestData);
    }
    // Optimistically set earlyRequestRemoved even though it hasn't actually been removed yet
    // But, this leads to less overhead and the odds of another fetch occuring while the
    // early request is still available is pretty small
    earlyRequestRemoved = true;
    return createEarlyRequestPromiseOrMakeRequest(url, requestData, earlyRequestKey);
  };
}
'use es6';

import { LOG_SENTRY_WITH_HISTORY, SNAPSHOT_STATE_IN_TIME } from 'ContentEditorUI/redux/actions/actionTypes';
export const logSentryWithRecentReduxHistory = (message, extra, pageAction) => ({
  type: LOG_SENTRY_WITH_HISTORY,
  message,
  extra,
  pageAction
});
export const takeSnapshotOfLastAction = () => ({
  type: SNAPSHOT_STATE_IN_TIME
});
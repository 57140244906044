'use es6';

// PAGE CONTENT MODEL
export const ADD_PAGE_CONTENT_MODEL_PAGE_STYLESHEET = 'ADD_PAGE_CONTENT_MODEL_PAGE_STYLESHEET';
export const REMOVE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET = 'REMOVE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET';
export const UPDATE_PAGE_CONTENT_MODEL_ENABLE_DEFAULT_CSS_STYLESHEETS = 'UPDATE_PAGE_CONTENT_MODEL_ENABLE_DEFAULT_CSS_STYLESHEETS';
export const UPDATE_PAGE_CONTENT_MODEL_ENABLE_DOMAIN_STYLESHEETS = 'UPDATE_PAGE_CONTENT_MODEL_ENABLE_DOMAIN_STYLESHEETS';
export const UPDATE_PAGE_CONTENT_MODEL_ENABLE_LAYOUT_STYLESHEETS = 'UPDATE_PAGE_CONTENT_MODEL_ENABLE_LAYOUT_STYLESHEETS';
export const UPDATE_PAGE_CONTENT_MODEL_FOOTER_HTML = 'UPDATE_PAGE_CONTENT_MODEL_FOOTER_HTML';
export const UPDATE_PAGE_CONTENT_MODEL_HEAD_HTML = 'UPDATE_PAGE_CONTENT_MODEL_HEAD_HTML';
export const UPDATE_PAGE_CONTENT_MODEL_HUBDB_TABLE = 'UPDATE_PAGE_CONTENT_MODEL_HUBDB_TABLE';
export const UPDATE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET_ORDER = 'UPDATE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET_ORDER';
export const UPDATE_PAGE_CONTENT_MODEL_PASSWORD = 'UPDATE_PAGE_CONTENT_MODEL_PASSWORD';
export const UPDATE_PAGE_CONTENT_MODEL_REQUIRE_PASSWORD = 'UPDATE_PAGE_CONTENT_MODEL_REQUIRE_PASSWORD';
export const UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS = 'UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS';
export const UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES = 'UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES';
export const UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES_ENABLED = 'UPDATE_PAGE_CONTENT_MODEL_PUBLIC_ACCESS_RULES_ENABLED';
export const UPDATE_PAGE_CONTENT_MODEL_LANGUAGE = 'UPDATE_PAGE_CONTENT_MODEL_LANGUAGE';
export const UPDATE_PAGE_CONTENT_MODEL_LANGUAGE_MASTER = 'UPDATE_PAGE_CONTENT_MODEL_LANGUAGE_MASTER';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_TYPE = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_TYPE';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_ID = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_ID';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SLUG = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SLUG';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_TITLE = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_TITLE';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_META = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_META';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_URL = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_URL';
export const UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_IMAGE = 'UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_IMAGE';

// PAGE_SETTINGS UI
export const FETCH_AVAILABLE_PAGE_STYLESHEETS_FAILED = 'FETCH_AVAILABLE_PAGE_STYLESHEETS_FAILED';
export const FETCH_AVAILABLE_PAGE_STYLESHEETS_STARTED = 'FETCH_AVAILABLE_PAGE_STYLESHEETS_STARTED';
export const FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED = 'FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED';
export const FETCH_HUBDB_TABLES_FAILED = 'FETCH_HUBDB_TABLES_FAILED';
export const FETCH_HUBDB_TABLES_STARTED = 'FETCH_HUBDB_TABLES_STARTED';
export const FETCH_HUBDB_TABLES_SUCCEEDED = 'FETCH_HUBDB_TABLES_SUCCEEDED';
export const FETCH_DATA_QUERIES_FAILED = 'FETCH_DATA_QUERIES_FAILED';
export const FETCH_DATA_QUERIES_STARTED = 'FETCH_DATA_QUERIES_STARTED';
export const FETCH_DATA_QUERIES_SUCCEEDED = 'FETCH_DATA_QUERIES_SUCCEEDED';
export const FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_FAILED = 'FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_FAILED';
export const FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_STARTED = 'FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_STARTED';
export const FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED = 'FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED';
export const FETCH_PAGE_SETTINGS_SLUG_FAIL = 'FETCH_PAGE_SETTINGS_SLUG_FAIL';
export const FETCH_PAGE_SETTINGS_SLUG_REQUEST = 'FETCH_PAGE_SETTINGS_SLUG_REQUEST';
export const FETCH_PAGE_STYLESHEET_INFO_FAILED = 'FETCH_PAGE_STYLESHEET_INFO_FAILED';
export const FETCH_PAGE_STYLESHEET_INFO_STARTED = 'FETCH_PAGE_STYLESHEET_INFO_STARTED';
export const FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED = 'FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED';
export const FETCH_CONTACT_LISTS_FAILED = 'FETCH_CONTACT_LISTS_FAILED';
export const FETCH_CONTACT_LISTS_STARTED = 'FETCH_CONTACT_LISTS_STARTED';
export const FETCH_CONTACT_LISTS_SUCCEEDED = 'FETCH_CONTACT_LISTS_SUCCEEDED';
export const FETCH_CONTACT_LISTS_FOR_CONTENT_FAILED = 'FETCH_CONTACT_LISTS_FOR_CONTENT_FAILED';
export const FETCH_CONTACT_LISTS_FOR_CONTENT_STARTED = 'FETCH_CONTACT_LISTS_FOR_CONTENT_STARTED';
export const FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED = 'FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED';
export const FETCH_CONTACT_LIST_REGISTRATIONS_FAILED = 'FETCH_CONTACT_LIST_REGISTRATIONS_FAILED';
export const FETCH_CONTACT_LIST_REGISTRATIONS_STARTED = 'FETCH_CONTACT_LIST_REGISTRATIONS_STARTED';
export const FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED = 'FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED';
export const PAGE_SETTINGS_SAVED = 'PAGE_SETTINGS_SAVED';
export const SET_PAGE_SETTINGS = 'SET_PAGE_SETTINGS';
export const UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS = 'UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS';
export const FETCH_CRM_OBJECT_TYPES_STARTED = 'FETCH_CRM_OBJECT_TYPES_STARTED';
export const FETCH_CRM_OBJECT_TYPES_FAILED = 'FETCH_CRM_OBJECT_TYPES_FAILED';
export const FETCH_CRM_OBJECT_TYPES_SUCCEEDED = 'FETCH_CRM_OBJECT_TYPES_SUCCEEDED';
export const FETCH_CRM_OBJECT_SCHEMA_STARTED = 'FETCH_CRM_OBJECT_SCHEMA_STARTED';
export const FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED = 'FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED';
export const FETCH_CRM_OBJECT_SCHEMA_FAILED = 'FETCH_CRM_OBJECT_SCHEMA_FAILED';

// PAGE LIMIT INFO
export const FETCH_PAGE_LIMIT_STARTED = 'FETCH_PAGE_LIMIT_STARTED';
export const FETCH_PAGE_LIMIT_SUCCEEDED = 'FETCH_PAGE_LIMIT_SUCCEEDED';
export const FETCH_PAGE_LIMIT_FAILED = 'FETCH_PAGE_LIMIT_FAILED';

// MLANG SITE PAGES LIMIT INFO
export const FETCH_MLANG_PAGES_LIMIT_STARTED = 'FETCH_MLANG_PAGES_LIMIT_STARTED';
export const FETCH_MLANG_PAGES_LIMIT_SUCCEEDED = 'FETCH_MLANG_PAGES_LIMIT_SUCCEEDED';
export const FETCH_MLANG_PAGES_LIMIT_FAILED = 'FETCH_MLANG_PAGES_LIMIT_FAILED';
import PropTypes from 'prop-types';
import { upgradeDataPropsInterface } from 'ui-addon-upgrades/_core/common/data/upgradeData/interfaces/upgradeDataPropsInterface';
export const PricingPageInterstitialButtonInterface = Object.assign({}, upgradeDataPropsInterface, {
  className: PropTypes.string,
  style: PropTypes.object,
  seleniumId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
  trackingSource: PropTypes.string.isRequired,
  use: PropTypes.string,
  children: PropTypes.node
});
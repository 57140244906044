import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const tracker = createTracker({
  events
});
function getApp() {
  return window.location.pathname.split('/')[1];
}
export const trackInteraction = (interaction, approvalType, extra) => tracker.track('productApprovalsInteraction', Object.assign({
  app: getApp(),
  interaction,
  type: approvalType
}, extra));
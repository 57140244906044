import { ListBasedPublicAccessRuleTypes } from '../constants/PublicAccessRuleTypes';
import { MIN_PASSWORD_LENGTH } from '../constants/contentAccessControl';
import { isAccessGroupsBasedPublicAccessRuleType } from './publicAccessRuleTypes';
export const isEmptyPassword = password => {
  return !password;
};

// Returns true (invalid) if chars >=1 but < MIN_PASSWORD_LENGTH
export const isPasswordTooShort = password => {
  return !!password && password.length < MIN_PASSWORD_LENGTH;
};
export const isInvalidNoListsSelected = publicAccessRules => publicAccessRules.some(publicAccessRule => ListBasedPublicAccessRuleTypes.includes(publicAccessRule.type) && publicAccessRule.ids.length === 0);
export const hasNoAccessGroupsSelected = publicAccessRules => publicAccessRules.some(publicAccessRule => isAccessGroupsBasedPublicAccessRuleType(publicAccessRule.type) && publicAccessRule.ids.length === 0);
'use es6';

import { Map as ImmutableMap } from 'immutable';
const testVariationTourStatusReducer = (state = ImmutableMap({
  shouldDisplayTour: false
}), {
  type
}) => {
  switch (type) {
    case 'SHOW_APPROVALS_TEST_VARIATIONS_TOUR':
      return state.set('shouldDisplayTour', true);
    case 'HIDE_APPROVALS_TEST_VARIATIONS_TOUR':
      return state.set('shouldDisplayTour', false);
    default:
      return state;
  }
};
export default testVariationTourStatusReducer;
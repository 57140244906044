'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["type", "method", "url", "data"];
import { MessageTypes } from '../Constants';
const isRequestAllowed = (request, whitelist) => {
  const {
    method,
    url
  } = request;
  const len = whitelist.length;
  for (let i = 0; i < len; i++) {
    const rule = whitelist[i];
    const {
      regex,
      methods
    } = rule;
    if (methods.includes(method) && regex.test(url)) {
      return true;
    }
  }
  return false;
};
const methodMap = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
};
export const apiResponderMiddleware = (apiClient, whitelist = []) => {
  return (message, postResponse) => {
    let parsedData;
    const {
        type,
        method,
        url,
        data
      } = message,
      body = _objectWithoutPropertiesLoose(message, _excluded);
    if (type !== MessageTypes.API_REQUEST) {
      return message;
    }
    if (typeof data !== 'undefined') {
      try {
        parsedData = JSON.parse(data);
      } catch (e) {
        postResponse(message, e.message, MessageTypes.ERROR);
        return message;
      }
      body.data = parsedData;
    }
    if (isRequestAllowed(message, whitelist)) {
      const requestMethod = methodMap[method];
      if (requestMethod) {
        apiClient[requestMethod](url, body).then(response => {
          postResponse(message, response, MessageTypes.API_RESPONSE);
        }).catch(error => {
          const {
            code,
            data: errorData,
            headers,
            message: errorMessage,
            responseJSON,
            responseText,
            status
          } = error;
          const response = {
            code,
            data: errorData,
            headers,
            message: errorMessage,
            responseJSON,
            responseText,
            status
          };
          postResponse(message, response, MessageTypes.ERROR);
        });
        return null;
      }
    }
    return message;
  };
};
import { PAGES_FETCH_PAGE, PAGES_FETCH_PAGES, PAGES_SAVE_PAGE_CACHE, PAGES_CLEAR_CACHE } from 'ContentData/actions/ActionTypes';
import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as pagesClient from 'ContentData/api/Pages';
import { getPageCache } from 'ContentData/selectors/pages';
const fetchPageAction = createGenericFetchAction(PAGES_FETCH_PAGE, pagesClient.fetchPage);
const fetchPagesAction = createGenericFetchAction(PAGES_FETCH_PAGES, pagesClient.fetchPages);
export function fetchPage(id) {
  return fetchPageAction(id);
}
export function fetchPages(query) {
  return fetchPagesAction(query);
}
export function saveCache(id) {
  return (dispatch, getState) => {
    const page = getPageCache(getState(), {
      id
    });
    dispatch({
      type: PAGES_SAVE_PAGE_CACHE,
      page
    });
  };
}
export function clearCache() {
  return {
    type: PAGES_CLEAR_CACHE
  };
}
'use es6';

import { createSelector } from 'reselect';
import { getHasLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { getContentModelId, getContentModelName, getLanguage, getTranslatedFromId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getTranslatedContent, getTranslatedContentValuesArray, getContentHasAB, getContentHasMAB } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getIsABVariant, getAbMasterContent } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getIsMabVariant, getMABExperimentMasterVariant } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
const getMasterFromContentModel = createSelector([getContentModelId, getContentModelName, getTranslatedFromId, getLanguage, getContentHasAB, getContentHasMAB], (id, name, translatedFromId, language, ab, mab) => ({
  id,
  language,
  name,
  masterId: translatedFromId,
  translatedFromId,
  ab,
  mab
}));
const getCurrentlySelectedContentOrContentMaster = createSelector([getIsABVariant, getIsMabVariant, getAbMasterContent, getMABExperimentMasterVariant, getMasterFromContentModel], (isABVariant, isMABVariant, abMaster, mabMaster, masterFromContentModel) => {
  if (isABVariant && abMaster) {
    // When the AB variation is selected
    return {
      id: abMaster.id,
      language: abMaster.language,
      name: abMaster.name,
      masterId: abMaster.translatedFromId,
      translatedFromId: abMaster.translatedFromId,
      ab: true,
      mab: false
    };
  } else if (isMABVariant && mabMaster) {
    // when a MAB variant is selected
    return {
      id: mabMaster.id,
      language: mabMaster.language,
      name: mabMaster.name,
      masterId: mabMaster.translatedFromId,
      translatedFromId: mabMaster.translatedFromId,
      ab: true,
      mab: false
    };
  }

  // when master is selected
  // or
  // when only non-test translations exist
  return masterFromContentModel;
});
export const getCurrentTranslations = createSelector([getHasLanguage, getTranslatedContentValuesArray, getCurrentlySelectedContentOrContentMaster], (hasLanguage, translatedContent, currentlySelectedContentOrContentMaster) => {
  if (hasLanguage && translatedContent.length > 0) {
    // Convert translated content map to array and append master translation
    return [currentlySelectedContentOrContentMaster, ...translatedContent];
  }
  return [];
});
export const getHasCurrentTranslations = createSelector([getCurrentTranslations], currentTranslations => currentTranslations.length > 0);
export const getSelectedContentId = createSelector(getCurrentlySelectedContentOrContentMaster, currentlySelectedContentOrContentMaster => currentlySelectedContentOrContentMaster.id);
export const getExistingTranslatedContentLanguages = createSelector(getTranslatedContent, currentTranslatedContent => Object.keys(currentTranslatedContent));
export const getHasCurrentLanguageInTranslatedContent = createSelector([getLanguage, getTranslatedFromId, getExistingTranslatedContentLanguages], (language, translatedFromId, currentTranslatedContentLanguages) => {
  const hasCurrentLanguageInCurrentTranslatedContent = translatedFromId && currentTranslatedContentLanguages.includes(language);
  return !!(language && hasCurrentLanguageInCurrentTranslatedContent);
});
export const getContentPrimaryLanguage = createSelector([getLanguage, getTranslatedFromId, getTranslatedContent], (language, translatedFromId, translatedContent) => {
  if (!translatedFromId) {
    return language;
  }
  return Object.keys(translatedContent).find(langKey => {
    const content = translatedContent[langKey];
    return !!content && !content.masterId;
  });
});
'use es6';

import http from 'hub-http/clients/apiClient';
const COSEMAIL_URI = 'cosemail/v1/emails';
const publish = ({
  baseContentUrl,
  timeout,
  unpublish = false
}) => {
  const actionType = unpublish ? 'cancel-publish' : 'schedule-publish';
  const publishUrl = `${baseContentUrl}/publish-action`;
  return http.postWithResponse(publishUrl, {
    data: {
      action: actionType
    },
    timeout
  });
};
const cancelSend = emailId => {
  const cancelURI = `${COSEMAIL_URI}/${emailId}/publish-action`;
  return http.postWithResponse(cancelURI, {
    data: {
      action: 'cancel-publish'
    }
  });
};
export default {
  publish,
  cancelSend
};
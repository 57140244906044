import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as menusClient from 'ContentData/api/Menus';
import { getMenuCache } from '../selectors/menus';
import { createGenericCreateAction } from 'ContentData/helpers/reduxHelpers';
import { MENUS_CREATE_MENU, MENUS_FETCH_MENU, MENUS_FETCH_MENUS, MENUS_SAVE_MENU_CACHE, MENUS_CLEAR_CACHE } from 'ContentData/actions/ActionTypes';
const fetchMenuAction = createGenericFetchAction(MENUS_FETCH_MENU, menusClient.fetchMenu);
const fetchMenuByNameAction = createGenericFetchAction(MENUS_FETCH_MENU, menusClient.fetchMenuByName);
const fetchMenusActions = createGenericFetchAction(MENUS_FETCH_MENUS, menusClient.fetchMenus);
const createMenuAction = createGenericCreateAction(MENUS_CREATE_MENU, menusClient.createMenu);
export function createMenu(name) {
  return createMenuAction(name);
}
export const fetchMenu = idOrName => {
  const isName = idOrName && typeof idOrName === 'string';
  if (isName) return fetchMenuByNameAction(idOrName);
  return fetchMenuAction(idOrName);
};
export const fetchMenus = query => fetchMenusActions(query);
export const saveCache = id => {
  return (dispatch, getState) => {
    const menu = getMenuCache(getState(), {
      id
    });
    dispatch({
      type: MENUS_SAVE_MENU_CACHE,
      menu
    });
  };
};
export const clearCache = () => {
  return {
    type: MENUS_CLEAR_CACHE
  };
};
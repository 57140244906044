'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, EDIT_FORM_FOLLOWUP_EMAIL } from 'ContentEditorUI/redux/actions/actionTypes';
const makeNewFollowupEmailEntry = formFieldData => {
  const {
    formId,
    emailId,
    formFieldPath,
    criterionId
  } = formFieldData || {};
  return {
    formId,
    emailId,
    formFieldPath,
    criterionId: criterionId || null
  };
};
const contentSchemaReducer = (state = {}, {
  type,
  response,
  payload
}) => {
  const moduleMissingErrors = [];
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      if (response.schema.template_errors) {
        response.schema.template_errors.forEach(err => {
          if (err.category === 'CUSTOM_WIDGET_NOT_FOUND') {
            moduleMissingErrors.push(err.categoryErrors.widget_name);
          }
          if (err.category === 'MODULE_NOT_FOUND') {
            moduleMissingErrors.push(err.categoryErrors.module_id);
          }
        });
      }
      response.schema.module_missing_errors = moduleMissingErrors;
      return response;
    case EDIT_FORM_FOLLOWUP_EMAIL:
      {
        const {
          modules_with_followup_emails: modulesWithFollowupEmails
        } = state.schema || {};
        if (modulesWithFollowupEmails) {
          const {
            id,
            formFieldData
          } = payload || {};
          const {
            formId,
            emailId,
            prevFormId,
            isSmartModule,
            criterionId
          } = formFieldData || {};
          let followupEmailsForModule = modulesWithFollowupEmails[id];
          if (followupEmailsForModule) {
            // If changing form ID, the followup email gets cleared
            // If changing the form ID in a smart rule, make sure we only clear the followup email from
            // modulesWithFollowupEmails if that smart rule contained the email
            if (prevFormId) {
              followupEmailsForModule = followupEmailsForModule.filter(_formFieldData => {
                return isSmartModule && _formFieldData.criterionId !== criterionId || _formFieldData.formId !== prevFormId;
              });
            }
            // If removing an email, clear it from modules_with_followup_emails
            else if (!emailId) {
              followupEmailsForModule = followupEmailsForModule.filter(_formFieldData => {
                return _formFieldData.formId !== formId;
              });
            } else {
              const alreadyHasEmailForForm = followupEmailsForModule.find(_formFieldData => {
                return _formFieldData.formId === formId;
              });
              // If changing an email, update the corresponding emailId in modules_with_followup_emails
              if (alreadyHasEmailForForm) {
                followupEmailsForModule = followupEmailsForModule.map(_formFieldData => {
                  if (_formFieldData.formId === formId) {
                    return Object.assign({}, _formFieldData, {
                      emailId
                    });
                  }
                  return _formFieldData;
                });
                // If adding an email and there's already entries for that module,
                // add to entries in modules_with_followup_emails
              } else {
                followupEmailsForModule = followupEmailsForModule.concat([makeNewFollowupEmailEntry(formFieldData)]);
              }
            }
            // Otherwise, if adding an email for a module that doesn't have other followup emails,
            // create a new array
          } else if (emailId) {
            followupEmailsForModule = [makeNewFollowupEmailEntry(formFieldData)];
          }
          if (followupEmailsForModule) {
            return Object.assign({}, state, {
              schema: Object.assign({}, state.schema, {
                modules_with_followup_emails: Object.assign({}, modulesWithFollowupEmails, {
                  [id]: followupEmailsForModule
                })
              })
            });
          }
        }
        return state;
      }
    default:
      return state;
  }
};
export default contentSchemaReducer;
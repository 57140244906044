'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const getSmartContentClonePath = definitionId => `targeted/v1/definitions/${definitionId}/clone`;
export function cloneSmartContentRulesForModule({
  moduleName,
  definitionId
}) {
  return http.post(getSmartContentClonePath(definitionId), {
    timeout: 15000,
    data: {
      definitionId
    },
    query: {
      portalId: PortalIdParser.get()
    }
  }).then(clonedSmartContentData => {
    return {
      moduleName,
      clonedSmartContentData
    };
  });
}
import { debug } from './debugUtil';
import { getTourIdFromQueryParams } from './urlUtils';
import SentryManager from '../manager/SentryManager';
const ONBOARDING_NEXT_TOUR_KEY = 'ONBOARDING_NEXT_TOUR';
const ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES = 'ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES';
export const IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY = 'Growth:InAppSidePanel:ShowSidepanel';
export const IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY = 'Growth:InAppSidePanel:OriginatingCrmCustomizationTour';
export const CRM_CUSTOMIZATIONS_CONTEXT = 'crmCustomizations';
export const USER_GUIDE_NAV_TOOLTIP_CONTEXT = 'userGuideNavTooltip';
const wrappedSessionStorage = {
  setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (error) {
      SentryManager.reportSentry('setSessionStorage', error.message, {
        key,
        value
      });
    }
  },
  getItem(key) {
    try {
      return window.sessionStorage.getItem(key);
    } catch (error) {
      SentryManager.reportSentry('getSessionStorage', error.message, {
        key
      });
      return null;
    }
  },
  removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      SentryManager.reportSentry('removeSessionStorage', error.message, {
        key
      });
    }
  }
};
const buildTourItem = (tourId, prevTourId, prevTourLength, linkedToursTotalStepCount, returnUrl) => {
  return {
    tourId,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  };
};
export const parseJSON = candidateString => {
  if (candidateString === null) {
    return null;
  }
  try {
    return JSON.parse(candidateString);
  } catch (error) {
    SentryManager.reportSentry('parseJSON', error.message, {
      candidateString
    });
    return null;
  }
};
export const removeNextTourToStorage = () => {
  wrappedSessionStorage.removeItem(ONBOARDING_NEXT_TOUR_KEY);
};
const getTourValueFromStorage = () => {
  const nextTourValue = wrappedSessionStorage.getItem(ONBOARDING_NEXT_TOUR_KEY);
  return nextTourValue;
};
export const getNextTourIdFromStorage = () => {
  try {
    const nextTourValue = getTourValueFromStorage();
    if (nextTourValue) {
      const nextTourData = parseJSON(nextTourValue);
      return nextTourData.tourId;
    }
  } catch (_unused) {
    // Ignore error
  }
  return null;
};
export const getNextTourFromStorage = () => {
  const nextTourValue = getTourValueFromStorage();
  if (!nextTourValue) {
    return {};
  }
  const nextTourData = parseJSON(nextTourValue);
  if (!nextTourData) {
    removeNextTourToStorage();
    return {};
  }
  const {
    tourId,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  } = nextTourData;
  const [nextTour, targetPageRegex] = tourId.split(':');
  if (targetPageRegex && !document.location.pathname.match(targetPageRegex)) {
    // Not remove tour storage if current page is not matched target page
    return {};
  }
  return {
    tourId: nextTour,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  };
};
export const setNextTourToStorage = (nextTourId, currentTourId, completedStepsCount, linkedToursTotalStepCount, returnUrl) => {
  debug('Setting next tour show: ', nextTourId);
  const tourItem = JSON.stringify(buildTourItem(nextTourId, currentTourId, completedStepsCount, linkedToursTotalStepCount, returnUrl));
  wrappedSessionStorage.setItem(ONBOARDING_NEXT_TOUR_KEY, tourItem);
};
export const getShouldEnableTour = () => {
  if (getTourIdFromQueryParams()) {
    return true;
  }
  const tourId = getNextTourIdFromStorage();
  return Boolean(tourId);
};
export const getAllTourContentOverrides = () => {
  const sessionContentOverrides = wrappedSessionStorage.getItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES);
  const allContentOverrides = parseJSON(sessionContentOverrides);
  return allContentOverrides || {};
};
export const getTourStepContentOverrides = (tourId, stepId) => {
  if (!tourId || !stepId) {
    return {};
  }
  const allContentOverrides = getAllTourContentOverrides();
  const tourContentOverrides = allContentOverrides && allContentOverrides[tourId];
  const stepContentOverrides = tourContentOverrides && tourContentOverrides[stepId];
  return stepContentOverrides || {};
};
export const setTourStepContentOverrides = (...overrides) => {
  const existingTourOverrides = getAllTourContentOverrides();
  const allOverrides = overrides.reduce((mergedOverrides, {
    tourId,
    stepId,
    overrides: stepContentOverrides
  }) => {
    return Object.assign({}, mergedOverrides, {
      [tourId]: Object.assign({}, mergedOverrides[tourId], {
        [stepId]: stepContentOverrides
      })
    });
  }, existingTourOverrides);
  wrappedSessionStorage.setItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES, JSON.stringify(allOverrides));
};
export const removeTourStepContentOverrides = () => {
  wrappedSessionStorage.removeItem(ONBOARDING_TOUR_STEP_CONTENT_OVERRIDES);
};
export const openCrmCustomizationsSidepanel = tourId => {
  wrappedSessionStorage.setItem(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY, tourId);
  wrappedSessionStorage.setItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, CRM_CUSTOMIZATIONS_CONTEXT);
};
export const hasOpenedCrmCustomizationsSidepanel = tourId => {
  return wrappedSessionStorage.getItem(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY) === tourId && wrappedSessionStorage.getItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY) === CRM_CUSTOMIZATIONS_CONTEXT;
};
export const openUserGuideNavTooltip = () => {
  wrappedSessionStorage.setItem(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, USER_GUIDE_NAV_TOOLTIP_CONTEXT);
};
export default Object.freeze([{
  regex: new RegExp('^contacts/v1/segment-ui/all$'),
  methods: ['GET']
}, {
  regex: new RegExp('^contacts/v1/properties/lifecyclestage$'),
  methods: ['GET']
}, {
  regex: new RegExp('^targeted/v1/criteria/(/[0-9]+)?$'),
  methods: ['PUT', 'DELETE']
}, {
  regex: new RegExp('^targeted/v1/criteria$'),
  methods: ['POST']
}, {
  regex: new RegExp('^targeted/v1/criteria/bulk$'),
  methods: ['DELETE']
}, {
  regex: new RegExp('^targeted/v1/criteria/([0-9]+)?$'),
  methods: ['DELETE']
}, {
  regex: new RegExp('^targeted/v1/definitions/combined$'),
  methods: ['POST']
}, {
  regex: new RegExp('^targeted/v1/definitions/([0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^targeted/v1/definitions/[0-9]+/clone$'),
  methods: ['POST']
}, {
  regex: new RegExp('^contacts/v1/lists/([0-9]+)?$'),
  methods: ['GET']
}]);
'use es6';

import { isValidRGBA } from 'ContentUtils/helpers/ColorsHelpers';
import { getTreeNodeDomProperties } from '../helpers';
export const generateBackgroundColorCss = styleValue => {
  if (!styleValue || !isValidRGBA(styleValue)) {
    console.warn('Background color value must be an object in the format { r: (0-255), g: (0-255), b: (0-255), a: (0-1)}');
    return null;
  }
  const cssProperties = {
    backgroundColor: `rgba(${styleValue.r}, ${styleValue.g}, ${styleValue.b}, ${styleValue.a})`
  };
  return cssProperties;
};
export const calculateBackgroundColorClasses = (treeNode, styleValue) => {
  const backgroundColorCss = generateBackgroundColorCss(styleValue);
  if (!backgroundColorCss) return [];
  const {
    backgroundColor
  } = backgroundColorCss;
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  const cssProperties = {
    'background-color': `${backgroundColor} !important`
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-color`,
    cssSelector: `.${rendererName}-background-color`,
    cssProperties
  }];
};
import { FETCH_CMS_SETTINGS_SUCCESS, UPDATE_HAS_DISMISSED_ONE_CLICK_PUBLISH_TOUR_CMS_SETTING_LOCALLY } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const DEFAULT_STATE = {
  customDomainSetupNextReminderDate: null,
  hasDismissedOneClickPublishTour: false,
  autotranslationCreatedSuccessMessageHidden: false
};
const cmsSettingsReducer = (state = DEFAULT_STATE, action) => produce(state, draft => {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case FETCH_CMS_SETTINGS_SUCCESS:
      {
        draft.customDomainSetupNextReminderDate = payload.customDomainSetupNextReminderDate;
        draft.hasDismissedOneClickPublishTour = payload.hasDismissedOneClickPublishTour || DEFAULT_STATE.hasDismissedOneClickPublishTour;
        return draft;
      }
    case UPDATE_HAS_DISMISSED_ONE_CLICK_PUBLISH_TOUR_CMS_SETTING_LOCALLY:
      {
        draft.hasDismissedOneClickPublishTour = payload;
        draft.autotranslationCreatedSuccessMessageHidden = payload.autotranslationCreatedSuccessMessageHidden;
        return draft;
      }
    default:
      return draft;
  }
});
export default cmsSettingsReducer;
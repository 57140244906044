import { CREATE_CLUSTER_FAILED, CREATE_CLUSTER_STARTED, CREATE_CLUSTER_SUCCEEDED, FETCH_CLUSTERS_FAILED, FETCH_CLUSTERS_STARTED, FETCH_CLUSTERS_SUCCEEDED } from '../actionTypes';
import { getClusters, postCluster } from './client';
export const fetchClusters = () => dispatch => {
  dispatch({
    type: FETCH_CLUSTERS_STARTED
  });
  return getClusters({
    getCoreTopicOnly: true
  }).then(response => {
    dispatch({
      type: FETCH_CLUSTERS_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: FETCH_CLUSTERS_FAILED,
      payload: {
        status: error.status
      }
    });
  });
};
export const createCluster = phrase => dispatch => {
  dispatch({
    type: CREATE_CLUSTER_STARTED
  });
  return postCluster(phrase).then(response => {
    dispatch({
      type: CREATE_CLUSTER_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: CREATE_CLUSTER_FAILED,
      payload: {
        status: error.status
      }
    });
  });
};
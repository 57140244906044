import Raven from 'raven-js';
import { sendSeatRequest } from '../common/api/seatsApi';
import { sendRequestAccessNotification } from '../common/api/requestNotificationsApi';
import * as tracker from '../common/eventTracking/tracker';
import wrapWithErrorMonitoring from '../common/reliability/wrapWithErrorMonitoring';
export const requestSeatUpgrade = wrapWithErrorMonitoring('requestSeatUpgrade', ({
  apiName,
  requestSeatText,
  requestedSeat
}) => sendSeatRequest({
  apiName,
  requestReason: requestSeatText,
  requestedSeatType: requestedSeat
}).then(() => sendRequestAccessNotification(apiName, requestSeatText, requestedSeat)).then(() => {
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  tracker.track('requestUpgradeInteraction', {
    action: 'request seat succeeded',
    apiName,
    requestSeatText,
    requestedSeat
  });
}).catch(e => {
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  tracker.track('requestUpgradeInteraction', {
    action: 'request seat failed',
    apiName,
    requestSeatText,
    requestedSeat
  });
  Raven.captureException(e);
  throw e;
}));
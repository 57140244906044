'use es6';

import { createSelector } from 'reselect';
import { getIsPublished } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getEditingInfo = basicSelector(state => state.editingInfo);
export const getLastSaveDate = createSelector([getEditingInfo], editingInfo => {
  if (editingInfo.hasSaved || !editingInfo.last_edit_ticks) {
    return editingInfo.lastModifiedDate;
  }
  return new Date(Number(editingInfo.last_edit_ticks));
});
export const getLastAuthorId = createSelector([getEditingInfo], editingInfo => editingInfo.updatedById);
export const getWasLastSaveAnAutoSave = createSelector([getEditingInfo], editingInfo => editingInfo.lastSaveType === 'autosave');
export const getHasBeenSavedOnceThisSession = createSelector([getEditingInfo], editingInfo => editingInfo.lastSaveType != null);
export const getHasBufferedChanges = createSelector([getEditingInfo], editingInfo => editingInfo.has_buffered_changes);
export const getHasUnpublishedChanges = createSelector([getIsPublished, getHasBufferedChanges], (isPublished, hasBufferedChanges) => isPublished && hasBufferedChanges);
export const getHasSaveError = createSelector([getEditingInfo], editingInfo => editingInfo.hasSaveError || false);
export const isSkuOwnedAdapter = (ownedProducts, skuId) => {
  const isOwnedProductSku = ownedProducts.some(({
    skuId: ownedSkuId
  }) => ownedSkuId === skuId);
  const isOwnedQuantityPackSku = ownedProducts.some(({
    quantityPackDetails
  }) => quantityPackDetails.some(({
    skuId: ownedQuantitySkuId
  }) => ownedQuantitySkuId === skuId));
  return isOwnedProductSku || isOwnedQuantityPackSku;
};
export const isApiNameOwnedAdapter = (ownedProducts, apiName) => {
  return ownedProducts.some(({
    productApiName: ownedApiName
  }) => ownedApiName === apiName);
};
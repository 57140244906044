import { useSelector } from 'react-redux';
import { fetchContentAssistanceGeneratedContent } from 'content-assistance-lib/api/contentAssistanceApi';
import http from 'hub-http/clients/apiClient';
import { useGetLocaleLanguageCode } from '../hooks/useGetLocaleLanguageCode';
// @ts-expect-error Untyped selector
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { useState, useCallback, useMemo } from 'react';
import { useEditControllerMethod } from 'ContentEditorUI/hooks/useEditControllerMethod';
const noop = __context => {};
export const useCurrentContentContext = (targetSelector = 'body', onSuccess = noop) => {
  const handleGetHtmlTextContentSuccess = useCallback(response => onSuccess(response.textContent), [onSuccess]);
  const getHTMLTextContextParams = useMemo(() => {
    return {
      editorSelector: targetSelector
    };
  }, [targetSelector]);
  const {
    fetch,
    result
  } = useEditControllerMethod({
    action: 'getHTMLTextContext',
    parameters: getHTMLTextContextParams,
    onSuccess: handleGetHtmlTextContentSuccess
  });
  return {
    currentContext: result && result.textContent,
    getCurrentContentContext: fetch
  };
};
export const useExecutePromptForCurrentContext = ({
  commandId,
  targetSelector = 'body',
  onSuccess = noop
}) => {
  const language = useGetLocaleLanguageCode();
  const contentId = useSelector(getContentModelId);
  const [response, setResponse] = useState('');
  const onFetchCurrentContextSucccess = useCallback(currentContext => {
    fetchContentAssistanceGeneratedContent({
      http,
      commandId,
      parameters: {
        editorContext: currentContext
      },
      resultCount: 1,
      language,
      objectId: contentId
    }).then(data => {
      if (data.length && data[0].text) {
        const promptResponse = data[0].text;
        setResponse(promptResponse);
        onSuccess(promptResponse);
      }
    }).catch(e => {
      console.log(e);
    });
  }, [commandId, contentId, language, onSuccess]);
  const {
    getCurrentContentContext
  } = useCurrentContentContext(targetSelector, onFetchCurrentContextSucccess);
  return {
    response,
    executePromptForContext: getCurrentContentContext
  };
};
export const hasCurrentTimePassedDateToShowAgain = ({
  lastSeen,
  showAgainAfterDates
}) => {
  let shouldShow = !lastSeen;
  const currentTime = Date.now();
  if (showAgainAfterDates && showAgainAfterDates.length) {
    shouldShow = showAgainAfterDates.some(showAgainAfterDate => lastSeen < showAgainAfterDate && showAgainAfterDate < currentTime);
  }
  return shouldShow;
};
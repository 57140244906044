// TODO: TS migrate this file
//  (did quick change, so static sections can benefit from row inheritance)

import Row from '../CellAndRowsTree/Row';
// @ts-expect-error Untyped module
import { sortByXField, hasAnyLayoutStyleData } from './helpers';
export default class RowWithSortedColumns extends Row {
  getColumnNames() {
    return this.getColumns().map(c => c.getName());
  }
  getColumns() {
    return this._columnNames.map(colName => this._treeRef.findCell(colName)).sort((c1, c2) => sortByXField(c1.getValue(), c2.getValue()));
  }
  shouldAutoDeleteWhenEmpty() {
    // Don't auto delete any rows that have custom styles
    return !hasAnyLayoutStyleData(this);
  }
  getLayoutStyleData() {
    if (this.hasLayoutStyles()) {
      return this.getValue().styles;
    }
    return null;
  }
  hasLayoutStyles() {
    return this.hasValue() && !!this.getValue().styles && Object.keys(this.getValue().styles).length > 0;
  }
  hasSmartContent() {
    return this.getColumns().some(c => c.hasSmartContent());
  }
  isModule() {
    return false;
  }
  hasRoomForMoreColumns() {
    return this.getNumberColumns() < this._treeRef.getNumberColumnsInGrid();
  }
  getRendererCompatibleRowName() {
    const parentCell = this.getParent();
    return `${parentCell.getName()}-row-${parentCell.getRowNames().indexOf(this.getName())}`;
  }

  // TODO and use in export ... (untested)
  // getColumnsXIndex(columnName) {
  //   const index = this.getColumnNames().indexOf(columnName);
  //   return this.columns().reduce((x, col, i) => {
  //     if (i < index) {
  //       return x + col.getWidth();
  //     }
  //     return x;
  //   }, 0);
  // }
}
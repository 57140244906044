export function getReleaseStatus(approvalType) {
  switch (approvalType) {
    case 'BLOG_POST':
      return 'NEW';
    case 'LANDING_PAGE':
      return 'NEW';
    case 'SITE_PAGE':
      return 'NEW';
    default:
      return null;
  }
}
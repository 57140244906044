'use es6';

import Immutable from 'immutable';
let DEBUG_ENABLED = false;
export const GROUP_SPLIT_TYPES = {
  SPLIT_NOW: {
    now: true
  },
  SPLIT_NEXT: {
    next: true
  },
  DO_NOT_SPLIT: {}
};
const logMessage = message => {
  if (DEBUG_ENABLED) {
    console.log(`Undo/Redo: ${message}`);
  }
};
const logStatus = (message, history, action) => {
  if (DEBUG_ENABLED) {
    console.groupCollapsed(`Undo/Redo: ${message} from ${action.type}`);
    console.log('Action', action);
    console.log('Past', history.get('past'));
    console.log('Present', history.get('present'));
    console.log('Future', history.get('future'));
    if (history.get('groupKey')) {
      console.log('Grouping by', history.get('groupKey'));
    }
    console.log('Current present created at', history.get('currentPresentStart'));
    console.groupEnd();
  }
};
const getInitialState = (initialPresent, {
  ignoreInitialState,
  skipActionTracking
}) => {
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: Immutable.List(),
    pastActionTypes: Immutable.List()
  };
  return Immutable.Map(Object.assign({
    _skipPastUpdate: ignoreInitialState,
    currentPresentStart: null,
    future: Immutable.List(),
    groupKey: null,
    past: Immutable.List(),
    present: initialPresent,
    redoCount: 0,
    undoCount: 0
  }, optionalProps));
};
const handleInsert = (history, present, limit, groupKey, actionType, skipActionTracking) => {
  let newPast = history.get('past');
  let _skipPastUpdate = history.get('_skipPastUpdate');
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: Immutable.List(),
    pastActionTypes: history.get('pastActionTypes')
  };

  // Trim off least recent item (index 0) if we've exceeded the limit
  if (limit && history.get('past').size >= limit) {
    newPast = newPast.rest();
    if (!skipActionTracking) optionalProps.pastActionTypes = optionalProps.pastActionTypes.rest();
  }
  if (!_skipPastUpdate) {
    newPast = newPast.push(history.get('present'));
    if (!skipActionTracking) optionalProps.pastActionTypes = optionalProps.pastActionTypes.push(actionType);
  } else {
    logMessage('leaving past unaltered during update (_skipPastUpdate = true)');
    _skipPastUpdate = false;
  }
  return Object.assign({
    _skipPastUpdate,
    currentPresentStart: Date.now(),
    // Previous present pushed to past, update the start time for new present
    future: Immutable.List(),
    // Clear future because it is no longer relevant
    groupKey,
    past: newPast,
    present
  }, optionalProps);
};
const undoRedoHelper = () => ({
  groupKey: null,
  currentPresentStart: null
});
const handleUndo = (history, skipActionTracking) => {
  if (history.get('past').size === 0) {
    return history;
  }
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: history.get('futureActionTypes').unshift(history.get('pastActionTypes').last()),
    pastActionTypes: history.get('pastActionTypes').butLast()
  };
  return Object.assign({
    future: history.get('future').unshift(history.get('present')),
    past: history.get('past').butLast(),
    present: history.get('past').last(),
    undoCount: history.get('undoCount') + 1
  }, optionalProps, undoRedoHelper());
};
const handleRedo = (history, skipActionTracking) => {
  if (history.get('future').size === 0) {
    return history;
  }
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: history.get('futureActionTypes').rest(),
    pastActionTypes: history.get('pastActionTypes').push(history.get('futureActionTypes').first())
  };
  return Object.assign({
    future: history.get('future').rest(),
    past: history.get('past').push(history.get('present')),
    present: history.get('future').first(),
    redoCount: history.get('redoCount') + 1
  }, optionalProps, undoRedoHelper());
};

// higher order reducer to add history to redux state
export const withUndoableHistory = (reducer, opts = {}) => {
  if (opts.debug) {
    DEBUG_ENABLED = true;
  }
  const config = {
    filter: opts.filter || (() => true),
    groupBy: opts.groupBy || (() => null),
    ignoreInitialState: opts.ignoreInitialState || false,
    limit: opts.limit,
    redoType: opts.redoType,
    splitGrouping: opts.splitGrouping || (() => GROUP_SPLIT_TYPES.DO_NOT_SPLIT),
    undoType: opts.undoType,
    clearUndoRedoTypes: opts.clearUndoRedoTypes || [],
    skipActionTracking: opts.skipActionTracking || false
  };
  if (!config.undoType || !config.redoType) {
    logMessage('Missing required options, undoType/redoType');
  }

  // Forces the next grouped action to split the grouping. Allows an action to
  // be grouped in "present", but still cause a split.
  let _splitNextGroupedAction = false;
  return (state, action) => {
    let history = state;
    if (!state) {
      logMessage('history uninitialized, creating initial state');
      const initialPresent = reducer(state, {});
      history = getInitialState(initialPresent, config);
    }
    if (config.clearUndoRedoTypes.includes(action.type)) {
      const optionalProps = config.skipActionTracking ? {} : {
        futureActionTypes: Immutable.List(),
        pastActionTypes: Immutable.List()
      };
      const updatedHistory = history.merge(Object.assign({
        past: Immutable.List(),
        future: Immutable.List(),
        present: reducer(history.get('present'), action),
        undoCount: 0,
        redoCount: 0
      }, optionalProps));
      logStatus('Clear undo/redo action', updatedHistory, action);
      return updatedHistory;
    }
    switch (action.type) {
      case config.undoType:
        {
          const updatedHistory = history.merge(handleUndo(history, config.skipActionTracking));
          const message = config.skipActionTracking ? 'Undo action' : `Undo ${updatedHistory.get('futureActionTypes').first()}`;
          logStatus(message, updatedHistory, action);
          return updatedHistory;
        }
      case config.redoType:
        {
          const updatedHistory = history.merge(handleRedo(history, config.skipActionTracking));
          const message = config.skipActionTracking ? 'Redo action' : `Redo ${updatedHistory.get('pastActionTypes').last()}`;
          logStatus(message, updatedHistory, action);
          return updatedHistory;
        }
      default:
        {
          const newPresent = reducer(history.get('present'), action);
          if (history.get('present') === newPresent) {
            // Undoable state was not altered
            return history;
          }
          const isAllowedToUpdatePast = config.filter(action);
          if (!isAllowedToUpdatePast) {
            // Filtered, only update present
            const updatedHistory = history.merge({
              present: newPresent
            });
            logStatus('filtered update', updatedHistory, action);
            return updatedHistory;
          }
          const newGroupKey = config.groupBy(action);
          let splitGroupType = GROUP_SPLIT_TYPES.DO_NOT_SPLIT;
          if (newGroupKey) {
            splitGroupType = config.splitGrouping(action, history);
          }
          if (!!newGroupKey && newGroupKey === history.get('groupKey')) {
            // This is typically a grouped action, check if it needs to be split
            if (!splitGroupType.now && !_splitNextGroupedAction) {
              // Update splitNextGroupedAction so it applies to next grouped action, not this one
              _splitNextGroupedAction = splitGroupType.next;

              // Grouped, only update present
              const updatedHistory = history.merge({
                present: newPresent
              });
              logStatus('grouped update', updatedHistory, action);
              return updatedHistory;
            }
          }

          // Perform a standard update
          const updatedHistory = history.merge(handleInsert(history, newPresent, config.limit, newGroupKey, action.type, config.skipActionTracking));
          let message = 'standard update';
          if (splitGroupType.now) {
            message = `grouping split, ${message}`;
          } else if (_splitNextGroupedAction) {
            message = `grouping split (via splitNextGroupedAction), ${message}`;
          }
          logStatus(message, updatedHistory, action);

          // Update splitNextGroupedAction because the next action might attempt to group
          _splitNextGroupedAction = splitGroupType.next;
          return updatedHistory;
        }
    }
  };
};
const getStorageKey = (storeNamespace, key) => `${storeNamespace}.${key}`;
export const LocalStoreHandlers = {
  getStore: (win, storeKeys, storeNamespace) => {
    const store = {};
    try {
      storeKeys.forEach(key => {
        const value = win.localStorage[getStorageKey(storeNamespace, key)];
        store[key] = value ? JSON.parse(value) : null;
      });
    } catch (e) {
      console.warn(e);
    }
    return store;
  },
  setValue: (win, storeKeys, storeNamespace, {
    key,
    value
  }) => {
    if (!storeKeys.includes(key)) return;
    try {
      win.localStorage[getStorageKey(storeNamespace, key)] = JSON.stringify(value);
    } catch (e) {
      console.warn(e);
    }
  }
};
export const HUB_MARKETING = 'marketing';
export const HUB_SALES = 'sales';
export const HUB_CMS = 'cmsHub';
export const HUB_SERVICE = 'service';
export const HUB_OPERATIONS = 'operations';
export default {
  HUB_MARKETING,
  HUB_SALES,
  HUB_CMS,
  HUB_SERVICE,
  HUB_OPERATIONS
};
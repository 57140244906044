import { useEffect } from 'react';
import { CommentObjectType } from '../constants';
import { setTourStepContentOverrides } from 'onboarding-tours/util/tourStorage';
import { COMMENT_ONBOARDING_TOUR_ID } from '../constants/tourIDs';
import I18n from 'I18n';
export default function useOverrideTourContent({
  objectType
}) {
  useEffect(() => {
    let tourTextOverrideKey = 'default';
    if (objectType === CommentObjectType.SOCIAL_BROADCAST) {
      tourTextOverrideKey = 'text-social';
    }
    if (objectType === CommentObjectType.SOCIAL_BROADCAST || objectType === CommentObjectType.COMMENTING_SANDBOX) {
      setTourStepContentOverrides({
        tourId: COMMENT_ONBOARDING_TOUR_ID,
        stepId: 'step 1',
        overrides: {
          textOptions: {
            text: I18n.text(`collaborationSidebar.commentOnboardingText.${tourTextOverrideKey}`)
          }
        }
      });
    }
  }, [objectType]);
}
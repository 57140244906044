import Categories from 'ContentUtils/constants/Categories';
import TemplateTypes from 'ContentUtils/constants/TemplateTypes';
const DRAFT_STATES = ['DRAFT', 'DRAFT_AB'];
const PUBLISHED_STATES = ['PUBLISHED', 'PUBLISHED_OR_SCHEDULED', 'PUBLISHED_AB'];
const SCHEDULED_STATES = ['SCHEDULED', 'PUBLISHED_OR_SCHEDULED', 'SCHEDULED_AB'];
const VIOLATION_STATES = ['FLAGGED_FOR_REVIEW', 'SECURITY_VIOLATION'];
const SIMPLE_TEMPLATE_TYPE = TemplateTypes.byName.simple_landing_page_template.id;
export const getIsSitePage = content => {
  return content.subcategory === 'site_page';
};
export const getContentTypeId = content => {
  const isSitePage = getIsSitePage(content);
  if (isSitePage) {
    return Categories.site_page.id;
  }
  return content.categoryId;
};
export const getContentTypeForUsageTracker = contentTypeId => {
  switch (contentTypeId) {
    case Categories.blog_post.id:
      return 'blogPost';
    case Categories.landing_page.id:
      return 'landingPage';
    case Categories.site_page.id:
    default:
      return 'sitePage';
  }
};
const getContentState = content => {
  const contentState = content.categoryId === Categories.blog_post.id ? content.state : content.currentState;
  if (!contentState) {
    return 'default';
  }
  return contentState;
};
const getIsContentDraft = content => {
  const contentState = getContentState(content);
  return DRAFT_STATES.includes(contentState);
};
const getContentIsScheduled = content => {
  const contentState = getContentState(content);
  return SCHEDULED_STATES.includes(contentState);
};
export const getIsContentPublished = content => {
  const contentState = getContentState(content);
  return PUBLISHED_STATES.includes(contentState);
};
export const getContentIsPublishedWithScheduledUpdate = content => {
  return getIsContentPublished(content) && !!content.scheduledUpdateDate;
};
export const getContentIsInViolation = content => {
  return content.securityState ? VIOLATION_STATES.includes(content.securityState) : false;
};
export const getContentStatusType = content => {
  if (getContentIsInViolation(content)) {
    return 'danger';
  }
  if (getIsContentDraft(content)) {
    return 'draft';
  }
  if (getContentIsScheduled(content)) {
    return 'scheduled';
  }
  if (getIsContentPublished(content)) {
    if (!content.scheduledUpdateDate) {
      return 'published';
    }
    return 'publishedWithScheduledUpdate';
  }
  return 'default';
};
export const ContentStatusToUIStatusTagMap = {
  danger: 'danger',
  draft: 'draft',
  scheduled: 'scheduled',
  published: 'published',
  publishedWithScheduledUpdate: 'published',
  default: 'default'
};
export const getShareablePreviewURLForContent = content => {
  const {
    absoluteUrl,
    id,
    previewKey
  } = content;
  if (!previewKey || getIsContentPublished(content)) {
    return absoluteUrl;
  }
  return `${absoluteUrl}?hs_preview=${previewKey}-${id}`;
};
export const getModalLangKey = (postFixKey, contentTypeId) => {
  const prefix = contentTypeId === Categories.blog_post.id ? 'blogPosts' : 'pages';
  return `contentManagementPanel.modals.${prefix}.${postFixKey}`;
};
export const getSocialComposerOptions = content => {
  const {
    campaign,
    categoryId,
    id,
    name,
    title,
    url
  } = content;
  const isSitePage = getIsSitePage(content);
  const socialShareContentType = isSitePage ? 'site-page' : categoryId === Categories.blog_post.id ? 'blog-post' : 'landing-page';
  return {
    body: title || name,
    campaignGuid: campaign || undefined,
    clientTag: 'ContentEditorUI',
    contentId: id,
    contentType: socialShareContentType,
    link: encodeURI(url) || ''
  };
};
export const getIsAdvancedPageTemplate = content => {
  return content.templateType && content.templateType !== SIMPLE_TEMPLATE_TYPE;
};
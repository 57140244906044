import http from 'hub-http/clients/apiClient';
export const sendSeatRequest = ({
  apiName,
  requestReason,
  requestedSeatType
}) => http.post('monetization/v3/request/seat', {
  data: {
    apiName,
    requestReason,
    requestedSeatType
  }
});
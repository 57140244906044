import { useCallback, useEffect, useMemo } from 'react';
import useFetchComments from './useFetchComments';
import useCollaborationSidebarState from './useCollaborationSidebarState';
import useIsCommentsDataStale from './useIsCommentsDataStale';
import useTrackLiveRefresh from './useTrackLiveRefresh';
import { createModuleRepliesMap } from '../utils/commentingUtils';
export default function useHasUnreadComments(objectType, objectId) {
  const {
    fetchComments,
    comments,
    loading,
    updateCache: updateCommentsCache
  } = useFetchComments();
  const {
    assetsLastUpdated,
    onUpdateCollaborationSidebarState
  } = useCollaborationSidebarState();
  const isCommentsDataStale = useIsCommentsDataStale({
    objectType,
    objectId,
    assetsLastUpdated
  });
  useEffect(() => {
    fetchComments({
      objectType,
      objectId,
      refetch: isCommentsDataStale
    });
  }, [fetchComments, objectId, objectType, isCommentsDataStale]);
  const unreadComments = useMemo(() => comments.filter(comment => !comment.read), [comments]);
  const unreadCommentsCount = useMemo(() => unreadComments.length, [unreadComments]);
  const hasUnreadComments = unreadCommentsCount > 0;
  const markCommentAsRead = useCallback(updatedCommentId => {
    if (!objectId) {
      return;
    }
    updateCommentsCache({
      objectType,
      objectId,
      updatedCommentId
    });
  }, [objectId, objectType, updateCommentsCache]);
  useTrackLiveRefresh({
    unreadCommentsCount,
    unreadCommentsLoading: loading
  });
  useEffect(() => {
    onUpdateCollaborationSidebarState({
      unreadRepliesMap: createModuleRepliesMap(unreadComments)
    });
  }, [unreadComments, onUpdateCollaborationSidebarState]);
  return {
    hasUnreadComments,
    unreadCommentsCount,
    refetchComments: () => fetchComments({
      objectType,
      objectId,
      refetch: true
    }),
    markCommentAsRead
  };
}
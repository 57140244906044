import { LOCATION_CHANGE } from 'ContentEditorUI/redux/actions/actionTypes';

// HACK: This is a workaround that makes sure that the Redux store updates
// when the `history` listener is fired. That way, our selectors in
// `routingSelectors` can continue to update on location change as expected
// Inspired by https://git.hubteam.com/HubSpot/PropertySettingsUI/pull/2401/files/dcde76c387ab90cfad7de219e863290fe91825f1#diff-0fdc7423e6d78e704565c4780c52f2d8408b27f7f3dcbb3a114ebc3c793e7343R65-R66

// Marking this as any since we don't really care about the action or state
// for this reducer
export default function locationReducer(state = {}, action) {
  return action.type === LOCATION_CHANGE ? action.payload : state;
}
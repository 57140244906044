import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
export const removeDomainFromTitleAudit = artifacts => {
  const pageTitle = artifacts[GathererKeys.PAGE_TITLE];
  const apexDomain = artifacts[GathererKeys.APEX_DOMAIN];
  let status;
  if (pageTitle === '' || pageTitle === undefined) {
    status = CompletionStatuses.BLOCKED;
  } else if (apexDomain && pageTitle.includes(apexDomain)) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }
  return {
    status
  };
};
module.exports = {
  "collabSidebarInteraction": {
    "name": "collab sidebar interaction",
    "class": "interaction",
    "properties": {
      "objectType": {
        "type": [
          "ENGAGEMENT",
          "UNKNOWN",
          "FLOW_ACTION",
          "COMMENTING_SANDBOX",
          "MARKETING_EMAIL",
          "FORM",
          "LEAD_FLOW",
          "LANDING_PAGE",
          "WEBSITE_PAGE",
          "BLOG_POST",
          "SOCIAL_BROADCAST",
          "AD_CAMPAIGN",
          "CAMPAIGN",
          "CTA",
          "WORKFLOW",
          "REPORTING_DASHBOARD",
          "BLOG_LISTING_PAGE",
          "MARKETING_EVENT",
          "WEB_INTERACTIVE",
          "MARKETING_SMS",
          "USER_AUDIT_LOG",
          "OBJECT_LIST",
          "DEAL",
          "KNOWLEDGE_ARTICLE"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "hidden": {
        "type": "boolean",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "position": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "shown deep commenting onboarding",
        "start deep commenting onboarding",
        "delay deep commenting onboarding",
        "dismiss deep commenting onboarding",
        "toggle hide sidebar",
        "move floating sidebar",
        "New comments while viewing asset",
        "close comment mode",
        "click update global notification settings"
      ],
      "disabledReason": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "collab-sidebar",
    "version": "1"
  }
};
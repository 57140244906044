import http from 'hub-http/clients/apiClient';
const TOPICS_PATH = 'content-clusters/v1/topics';
export const getTopicsByClusterId = ({
  clusterId
}) => http.get(TOPICS_PATH, {
  query: {
    clusterId
  }
});
export const postTopic = (clusterId, phrase) => http.post(TOPICS_PATH, {
  data: [{
    clusterId,
    phrase
  }]
});
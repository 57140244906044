import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getHasBrandVoiceAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
export const getContentAssistant = basicSelector(state => state.contentAssistant);
export const getIsContentAssistanceEnabled = createSelector([getContentAssistant], contentAssistant => {
  return Boolean(contentAssistant && contentAssistant.contentAssistanceEnabled);
});
export const getHasAccessToBrandVoice = createSelector([getHasBrandVoiceAccess], hasBrandVoiceAccess => {
  return hasBrandVoiceAccess;
});
export const getBlogBrandVoice = createSelector([getContentAssistant], contentAssistant => {
  return contentAssistant && contentAssistant.blogBrandVoice;
});
export const getPageBrandVoice = createSelector([getContentAssistant], contentAssistant => {
  return contentAssistant && contentAssistant.pageBrandVoice;
});
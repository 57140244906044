import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET content/api/v4/contents/linkable-pages/{id}
 * @param {number} id Page id
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch page by id
 */
export function fetchPage(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`content/api/v4/contents/linkable-pages/${id}`);
}

/**
 * GET content/api/v4/contents/linkable-pages
 * @param {object} query Query params
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch all pages (paginated)
 */
export function fetchPages(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('content/api/v4/contents/linkable-pages', {
    query: Object.assign({}, query, {
      includeBlogs: false
    })
  });
}
import { createSelector } from 'reselect';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message
import { getLanguage } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';

// Move off any when baseContentModelSelectors is migrated to immer

export const getLanguages = basicSelector(state => state.languages);
export const getSupportedLanguages = createSelector([getLanguages], languages => languages.supportedLanguages);
export const getRenderLanguage = createSelector([getLanguages], languages => languages.renderLanguage);
export const getHasLanguage = createSelector([getLanguage], language => !!language);
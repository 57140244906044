const States = {
  AUTOMATED_DRAFT: 'AUTOMATED_DRAFT',
  AUTOMATED_DRAFT_AB: 'AUTOMATED_DRAFT_AB',
  AUTOMATED_DRAFT_ABVARIANT: 'AUTOMATED_DRAFT_ABVARIANT',
  AUTOMATED_FOR_FORM_BUFFER: 'AUTOMATED_FOR_FORM_BUFFER',
  AUTOMATED_FOR_FORM_DRAFT: 'AUTOMATED_FOR_FORM_DRAFT',
  BLOG_EMAIL_DRAFT: 'BLOG_EMAIL_DRAFT',
  DISCARDABLE_STUB: 'DISCARDABLE_STUB',
  DRAFT: 'DRAFT',
  DRAFT_AB: 'DRAFT_AB',
  DRAFT_AB_VARIANT: 'DRAFT_AB_VARIANT',
  ERROR: 'ERROR',
  LOSER_AB_VARIANT: 'LOSER_AB_VARIANT',
  PAGE_STUB: 'PAGE_STUB',
  RSS_TO_EMAIL_DRAFT: 'RSS_TO_EMAIL_DRAFT',
  AUTOMATED: 'AUTOMATED',
  AUTOMATED_AB: 'AUTOMATED_AB',
  AUTOMATED_AB_VARIANT: 'AUTOMATED_AB_VARIANT',
  AUTOMATED_FOR_FORM: 'AUTOMATED_FOR_FORM',
  AUTOMATED_SENDING: 'AUTOMATED_SENDING',
  BLOG_EMAIL_PUBLISHED: 'BLOG_EMAIL_PUBLISHED',
  PRE_PROCESSING: 'PRE_PROCESSING',
  PROCESSING: 'PROCESSING',
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_AB: 'PUBLISHED_AB',
  PUBLISHED_AB_VARIANT: 'PUBLISHED_AB_VARIANT',
  PUBLISHED_NO_CONTACTS: 'PUBLISHED_NO_CONTACTS',
  PUBLISHED_OR_SCHEDULED: 'PUBLISHED_OR_SCHEDULED',
  RSS_TO_EMAIL_PUBLISHED: 'RSS_TO_EMAIL_PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  SCHEDULED_AB: 'SCHEDULED_AB'
};
export default States;
export const getAssetKeyFromAsset = ({
  objectType,
  objectId
}) => `${objectType}:${objectId}`;
export function getAssetFromAssetKey(assetKey) {
  const [objectType, objectId] = assetKey.split(/:(.+)/);
  return {
    objectType: objectType,
    objectId
  };
}
'use es6';

import http from 'hub-http/clients/apiClient';
const TRIAL_ENDPOINT_BASE = 'trials/v1/trials';
const HUB_TRIAL_ENDPOINT_BASE = 'trials/v1/hub-trials/app';
let _cachedAvailable;
let _cachedTrials;
export const TrialsApi = {
  getTrialState() {
    if (_cachedTrials) {
      return _cachedTrials;
    }
    _cachedTrials = http.get(`${HUB_TRIAL_ENDPOINT_BASE}/all`);
    return _cachedTrials;
  },
  getAvailableTrials() {
    if (_cachedAvailable) {
      return _cachedAvailable;
    }
    _cachedAvailable = http.get(`${TRIAL_ENDPOINT_BASE}/available`);
    return _cachedAvailable;
  }
};
import { createProperty } from 'ui-addon-upgrades/_core/common/data/upgradeData/transformers/createProperty';
import { sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
const PROPERTY_KEY = 'source';

/**
 * @param {Object} upgradeData
 * @param {String} upgradeSource
 * @return {Object}
 */

export const getPropertySource = ({
  app,
  screen,
  uniqueId
}, source) => {
  const propertyValue = `${app}-${screen}-${sources[source]}-${uniqueId}`;
  return createProperty(PROPERTY_KEY, propertyValue);
};
export const getSource = ({
  app,
  screen,
  uniqueId
}, source) => {
  return `${app}-${screen}-${sources[source]}-${uniqueId}`;
};
import requestStates from '../requestStates';
const {
  FAILED,
  STARTED,
  SUCCEEDED,
  UNINITIALIZED
} = requestStates;
export const requestFailed = artifact => artifact && artifact.requestState === FAILED;
export const requestStarted = artifact => artifact && artifact.requestState === STARTED;
export const requestSucceeded = artifact => artifact && artifact.requestState === SUCCEEDED;
export const requestUninitialized = artifact => !artifact || artifact.requestState === UNINITIALIZED;
export const requestFailedOrUninitialized = artifact => requestFailed(artifact) || requestUninitialized(artifact);
export const requestCompleted = artifact => requestSucceeded(artifact) || requestFailed(artifact);
export const requestUpdated = (oldArtifact, newArtifact) => !oldArtifact && newArtifact || oldArtifact.requestState !== newArtifact.requestState;
export const anyRequestStarted = artifactList => artifactList.reduce((started, artifact) => started || requestStarted(artifact), false);
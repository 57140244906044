export let DefaultInstanceColumns;
(function (DefaultInstanceColumns) {
  DefaultInstanceColumns["hs_access_rule_type"] = "hs_access_rule_type";
  DefaultInstanceColumns["hs_access_rule_ids"] = "hs_access_rule_ids";
  DefaultInstanceColumns["hs_canonical_url"] = "hs_canonical_url";
  DefaultInstanceColumns["hs_head_html"] = "hs_head_html";
  DefaultInstanceColumns["hs_meta_description"] = "hs_meta_description";
  DefaultInstanceColumns["hs_name"] = "hs_name";
  DefaultInstanceColumns["hs_path"] = "hs_path";
})(DefaultInstanceColumns || (DefaultInstanceColumns = {}));
export default DefaultInstanceColumns;
export const NO_ALIGNMENT = 'NONE';
export const VERTICAL_ALIGNMENTS = Object.freeze({
  TOP: 'TOP',
  MIDDLE: 'MIDDLE',
  BOTTOM: 'BOTTOM'
});
export const HORIZONTAL_ALIGNMENTS = Object.freeze({
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
});
export const ALIGNMENTS = Object.freeze({
  TOP_LEFT: `${VERTICAL_ALIGNMENTS.TOP}_${HORIZONTAL_ALIGNMENTS.LEFT}`,
  TOP_CENTER: `${VERTICAL_ALIGNMENTS.TOP}_${HORIZONTAL_ALIGNMENTS.CENTER}`,
  TOP_RIGHT: `${VERTICAL_ALIGNMENTS.TOP}_${HORIZONTAL_ALIGNMENTS.RIGHT}`,
  MIDDLE_LEFT: `${VERTICAL_ALIGNMENTS.MIDDLE}_${HORIZONTAL_ALIGNMENTS.LEFT}`,
  MIDDLE_CENTER: `${VERTICAL_ALIGNMENTS.MIDDLE}_${HORIZONTAL_ALIGNMENTS.CENTER}`,
  MIDDLE_RIGHT: `${VERTICAL_ALIGNMENTS.MIDDLE}_${HORIZONTAL_ALIGNMENTS.RIGHT}`,
  BOTTOM_LEFT: `${VERTICAL_ALIGNMENTS.BOTTOM}_${HORIZONTAL_ALIGNMENTS.LEFT}`,
  BOTTOM_CENTER: `${VERTICAL_ALIGNMENTS.BOTTOM}_${HORIZONTAL_ALIGNMENTS.CENTER}`,
  BOTTOM_RIGHT: `${VERTICAL_ALIGNMENTS.BOTTOM}_${HORIZONTAL_ALIGNMENTS.RIGHT}`
});
import { useCallback, useMemo, useState } from 'react';
import productApprovalsApi from '../data/productApprovalsApi';
import { reportRequestException } from '../utils/sentryUtils';
export const useApprovalSkipConfig = (approvalAction, approvalType) => {
  const [skipConfig, setSkipConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchSkipConfig = useCallback(() => {
    setIsLoading(true);
    productApprovalsApi.fetchSkipConfig(approvalType, approvalAction).then(data => {
      setSkipConfig(data);
    }).catch(errorResponse => reportRequestException('fetchSkipConfig', errorResponse)).finally(() => setIsLoading(false)).catch(() => console.error('Error fetching skip config'));
  }, [approvalAction, approvalType]);
  return useMemo(() => ({
    skipConfig,
    fetchSkipConfig,
    isLoading
  }), [skipConfig, fetchSkipConfig, isLoading]);
};
'use es6';

import { SET_SCROLL_TO_IN_MODULE_LIST, SELECTED_CATEGORY_UPDATE, SELECTED_SUB_CATEGORY_UPDATE, UPDATE_SIDEBAR_SEARCH_QUERY } from 'ContentEditorUI/redux/actions/actionTypes';
export const setScrollToInModuleList = moduleId => ({
  type: SET_SCROLL_TO_IN_MODULE_LIST,
  payload: {
    moduleId
  }
});
export const updateSelectedCategory = (category, subCategory) => ({
  type: SELECTED_CATEGORY_UPDATE,
  payload: {
    category,
    subCategory
  }
});
export const updateSelectedSubCategory = subCategory => ({
  type: SELECTED_SUB_CATEGORY_UPDATE,
  payload: {
    subCategory
  }
});
export const updateSidebarSearchQuery = searchQuery => ({
  type: UPDATE_SIDEBAR_SEARCH_QUERY,
  payload: {
    searchQuery
  }
});
import { useMemo } from 'react';
import { TourCompletionStatus } from '../constants/TourCompletionStatus';
import { shouldHideOnboardingForSelenium } from '../utils/env';
import onboardingTourBlockListedObjectTypes from '../constants/onboardingTourBlockListedObjectTypes';
import { getIsWorkflowActionsUrl } from 'collaboration-sidebar/utils/urls';
import useOverrideTourContent from './useOverrideTourContent';
import useTourStatus from './useTourStatus';
export default function useOnboardingTour({
  tourId,
  objectType,
  featureIsVisible = true
}) {
  const isWorkflowActionsUrl = getIsWorkflowActionsUrl(); // onboarding likely to overlap workflow actions panel

  useOverrideTourContent({
    objectType
  });
  const {
    tourStatus,
    loading
  } = useTourStatus({
    tourId
  });
  const showTour = useMemo(() => {
    const tourBlockList = onboardingTourBlockListedObjectTypes[tourId];
    if (tourBlockList.includes(objectType)) {
      return false;
    }
    if (isWorkflowActionsUrl) {
      return false;
    }
    if (!featureIsVisible || shouldHideOnboardingForSelenium()) {
      return false;
    }
    if (loading) {
      return false;
    }
    const searchParams = new URLSearchParams(document.location.search);
    const onboardingTourId = searchParams.get('onboardingTourId');
    const isTourInQueryParam = onboardingTourId === tourId;
    return isTourInQueryParam || tourStatus.status !== TourCompletionStatus.COMPLETE;
  }, [featureIsVisible, tourId, loading, tourStatus, objectType, isWorkflowActionsUrl]);
  return showTour;
}
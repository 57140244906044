'use es6';

import { fetchProductPurchaseMotionData } from 'self-service-api/core/api/purchaseMotionApi';
import { validateApiName } from 'self-service-api/core/validators';
import { productPurchaseMotionsAdapter } from '../adapters/productPurchaseMotionsAdapter';
export const getLocationAwareProductPurchaseMotionData = ({
  apiNames = [],
  locations,
  apiGetter
}) => {
  apiNames.forEach(validateApiName);
  return fetchProductPurchaseMotionData({
    apiNames,
    locations,
    apiGetter
  });
};
export const getProductPurchaseMotionData = ({
  apiNames,
  locations = [],
  apiGetter
}) => ({
  location,
  apiName
}) => getLocationAwareProductPurchaseMotionData({
  apiNames,
  locations,
  apiGetter
}).then(purchaseMotionData => productPurchaseMotionsAdapter(purchaseMotionData, location, apiName));
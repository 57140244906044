import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
import { getIncorrectlySizedImagesInImageModules } from './useCorrectlySizedImagesUtils';
export const useCorrectlySizedImagesAudit = artifacts => {
  const images = artifacts[GathererKeys.IMAGES];
  const allModules = artifacts[GathererKeys.ALL_MODULES];
  const moduleIdsByBuiltInType = artifacts[GathererKeys.MODULE_IDS_BY_BUILT_IN_TYPE];
  let incorrectlySizedImagesInImageModules = [];
  if (allModules) {
    incorrectlySizedImagesInImageModules = getIncorrectlySizedImagesInImageModules(images, allModules.toJS(), moduleIdsByBuiltInType.linked_image);
  }
  return {
    status: incorrectlySizedImagesInImageModules.length === 0 ? CompletionStatuses.COMPLETE : CompletionStatuses.INCOMPLETE,
    incorrectlySizedImages: incorrectlySizedImagesInImageModules
  };
};
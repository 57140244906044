import http from 'hub-http/clients/apiClient';
const url = 'users/v2/app/hub-users';
export function fetch() {
  return http.get(url, {
    query: {
      active: true,
      activePermissions: true,
      visible: true
    }
  });
}
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET feedback/v1/{surveyType}/{surveyId}
 * @param {string} surveyType Survey Type
 * @param {string} id Survey Id
 * @return {Promise} fetch a single single by survey type
 */
export function fetchSurvey({
  surveyType,
  id
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`feedback/v1/${surveyType.toLowerCase()}/${id}`);
}

/**
 * GET feedback/v1/survey/page/{query}
 * @param {object} query Query params
 * @return {Promise} fetch multiple surveys
 */
export function fetchSurveys(query = {
  pageSize: 15,
  pageNumber: 1
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('feedback/v1/survey/page', {
    query
  });
}
/**
 * PUT feedback/v1/{surveyType}/enable/{surveyId}
 * @param {object} surveyType Survey type of the survey to disable
 * @param {object} surveyId Id of the survey
 * @return {Promise} status of the update
 */
export function enableSurvey({
  surveyType,
  surveyId
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`feedback/v1/${surveyType.toLowerCase()}/enable/${surveyId}`);
}

/**
 * PUT feedback/v1/{surveyType}/disable/{surveyId}
 * @param {object} surveyType Survey type of the survey to disable
 * @param {object} surveyId Id of the survey
 * @return {Promise} status of the update
 */
export function disableSurvey({
  surveyType,
  surveyId
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`feedback/v1/${surveyType.toLowerCase()}/disable/${surveyId}`);
}
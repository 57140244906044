import { SITE_SETTINGS_FETCH } from 'ContentData/actions/ActionTypes';
export default ((state = {}, action) => {
  switch (action.type) {
    case SITE_SETTINGS_FETCH:
      {
        const {
          siteSettings
        } = action;
        if (!siteSettings) return state;
        return siteSettings;
      }
    default:
      return state;
  }
});
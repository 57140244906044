'use es6';

import { Set as ImmutableSet, List } from 'immutable';

// From https://github.com/immutable-js/immutable-js/wiki/Predicates
export function keyIn(...keys) {
  const keySet = ImmutableSet(keys);
  return function (v, k) {
    return keySet.has(k);
  };
}

// Originally from https://github.com/immutable-js/immutable-js/issues/762
export function deepMergeWithoutListConcatenation(mapOrValueA, mapOrValueB) {
  if (mapOrValueA && mapOrValueA.mergeWith && !List.isList(mapOrValueA) && !List.isList(mapOrValueB)) {
    return mapOrValueA.mergeWith(deepMergeWithoutListConcatenation, mapOrValueB);
  }
  return mapOrValueB;
}
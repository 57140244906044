'use es6';

import http from 'hub-http/clients/apiClient';
const url = 'blog-settings/v1/blogs';
function fetch() {
  return http.get(url, {
    query: {
      limit: 1000,
      property: ['name', 'id', 'itemTemplatePath', 'rootUrl', 'slug', 'resolvedDomain', 'instantNotificationEmailId', 'dailyNotificationEmailId', 'weeklyNotificationEmailId', 'monthlyNotificationEmailId', 'language', 'translations', 'translatedFromId', 'publicAccessRulesEnabled', 'listingPageId']
    }
  });
}
function createLanguageVariation({
  masterLanguage,
  contentGroupId,
  language
}) {
  return http.post(`blog-settings/v1/blogs/${contentGroupId}/create-language-variation`, {
    data: {
      masterLanguage,
      language
    }
  });
}
function updateBlog(blogId, data) {
  return http.put(`${url}/${blogId}`, {
    data
  });
}
export default {
  fetch,
  createLanguageVariation,
  updateBlog
};
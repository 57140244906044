export default class Cell {
  constructor(treeRef, {
    name,
    rowNames = [],
    parentRowName,
    value
  }) {
    this._name = name;
    this._value = value;
    this._rowNames = rowNames;
    this._parentRowName = parentRowName;
    this._treeRef = treeRef;
  }
  cloneAndChange({
    name,
    rowNames,
    parentRowName,
    value,
    treeRef
  }) {
    name = name || this._name;
    treeRef = treeRef || this._treeRef;
    rowNames = rowNames != null ? rowNames : this._rowNames;
    parentRowName = parentRowName != null ? parentRowName : this._parentRowName;
    value = value != null ? value : this._value;
    return treeRef.createNewCell({
      name,
      rowNames,
      parentRowName,
      value
    });
  }
  createFullClone(params) {
    return this.cloneAndChange(params);
  }
  getName() {
    return this._name;
  }
  isCell() {
    return true;
  }
  isRow() {
    return false;
  }
  isStaticSection() {
    return false;
  }
  isEmbed() {
    return false;
  }
  getRowNames() {
    return this._rowNames;
  }
  getNumberRows() {
    return this._rowNames.length;
  }
  getRows() {
    return this._rowNames.map(r => this._treeRef.findRow(r));
  }
  isLeaf() {
    return this._rowNames.length === 0;
  }
  hasRows() {
    return !this.isLeaf();
  }
  getRootName() {
    return this._treeRef.getRootName();
  }
  isRoot() {
    return this._name === this._treeRef.getRootName();
  }
  getParentName() {
    return this._parentRowName;
  }
  getParent() {
    return !!this._parentRowName && this._treeRef.findRow(this._parentRowName);
  }
  hasValue() {
    return this._value != null;
  }
  getValue() {
    return this._value;
  }
  indexOfRow(rowName) {
    return this.getRowNames().indexOf(rowName);
  }

  // Added so subclasses can implement behavior to prevent specific cells (or rows) from being
  // automatically deleted when their sole child is deleted
  shouldAutoDeleteWhenEmpty() {
    return true;
  }

  // Added so subclasses can implement behavior to enforce that specific cells can't have rows. And
  // if a cell isn't allowed to have rows, inserting a row into it will split the cell into a new wrapper
  // with two columns
  shouldSplitInsteadOfInsertingRow() {
    return false;
  }
  isStaticSectionModule() {
    return Boolean(this.getParent()) && this.getParent().isStaticSection();
  }
}
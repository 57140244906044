'use es6';

export const PUBLISH_TABS = {
  LOCALTIME: 'localtime',
  PUBLISH_NOW: 'publish_now',
  SCHEDULE: 'schedule'
};
export const STATE_KEYS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled'
};
export const TAB_ICONS = {
  publish_now: {
    blog: 'landing-pages',
    pages: 'landing-pages',
    email: 'email'
  },
  schedule: {
    blog: 'clock',
    pages: 'clock',
    email: 'clock'
  },
  localtime: {
    email: 'globe'
  }
};
export const CUSTOMIZED_MESSAGING = {
  publish_now: ['email']
};
import * as hubDBClient from 'ContentData/api/HubDb';
import { getCachedHubDbRow, getHubDbTableCache } from 'ContentData/selectors/hubDb';
import { HUBDB_CLEAR_CACHE, HUBDB_FETCH_ROW, HUBDB_FETCH_ROWS, HUBDB_FETCH_TABLE, HUBDB_FETCH_TABLE_BY_NAME, HUBDB_FETCH_TABLES, HUBDB_SAVE_ROW_CACHE, HUBDB_SAVE_TABLE_CACHE, HUBDB_SEARCH_ROWS } from 'ContentData/actions/ActionTypes';
import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
const fetchHubDbTableAction = createGenericFetchAction(HUBDB_FETCH_TABLE, hubDBClient.fetchHubDbTable);
const fetchHubDbTableByNameAction = createGenericFetchAction(HUBDB_FETCH_TABLE_BY_NAME, hubDBClient.fetchHubDbTables);
const fetchHubDbTablesAction = createGenericFetchAction(HUBDB_FETCH_TABLES, hubDBClient.fetchHubDbTables);
const fetchHubdbRowAction = createGenericFetchAction(HUBDB_FETCH_ROW, hubDBClient.fetchHubDbRow);
const fetchHubdbRowsAction = createGenericFetchAction(HUBDB_FETCH_ROWS, hubDBClient.fetchHubDbRows);
const searchHubDbRowsAction = createGenericFetchAction(HUBDB_SEARCH_ROWS, hubDBClient.searchHubDbRows);
export const fetchHubDbTable = id => {
  if (isNaN(parseInt(id, 10))) {
    return fetchHubDbTableByNameAction({
      name: id
    });
  }
  return fetchHubDbTableAction(id);
};
export const fetchHubDbTables = query => {
  return fetchHubDbTablesAction(query);
};
export const fetchHubDbRow = query => {
  return fetchHubdbRowAction(query);
};
export const fetchHubDbRows = query => {
  return fetchHubdbRowsAction(query);
};
export const saveCache = id => {
  return (dispatch, getState) => {
    const table = getHubDbTableCache(getState(), {
      id
    });
    dispatch({
      type: HUBDB_SAVE_TABLE_CACHE,
      table
    });
  };
};
export const saveHubDbRowCache = (table_id, row_id) => {
  return (dispatch, getState) => {
    const row = getCachedHubDbRow(getState(), {
      tableId: table_id,
      rowId: row_id
    });
    dispatch({
      type: HUBDB_SAVE_ROW_CACHE,
      tableId: table_id,
      row
    });
  };
};
export const clearCache = () => {
  return {
    type: HUBDB_CLEAR_CACHE
  };
};
export const searchHubDbRows = query => {
  return searchHubDbRowsAction(query);
};
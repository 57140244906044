export const countChangeBetweenLists = (listA, listB) => {
  // Loop over original selected users and check if those users are in the new list.
  // If an original user is not in the new list, there must have been a change.
  // If the original user is in the new list, add that user to an array that is used
  // to find the remaining difference between the selected users.
  let numberOfChanges = 0;
  const selectedUsersNoChange = [];
  for (let i = 0; i < listA.length; i++) {
    if (listB.includes(listA[i])) {
      selectedUsersNoChange.push(listA[i]);
    } else {
      numberOfChanges++;
    }
  }

  // Remaining difference between original and new list.
  numberOfChanges += Math.abs(selectedUsersNoChange.length - listB.length);
  return numberOfChanges;
};
'use es6';

import enviro from 'enviro';
// import Perf from 'react-addons-perf';
// TODO react-addons-perf is no longer supported in react 16 https://reactjs.org/docs/perf.html

const NOOP_FUNCTION = () => {};
const SUPPORTS_USER_TIMING = !!window.performance;
let perfDebuggingOn = enviro.debug('dnd-area-perf');
export default class PerformanceStatsGatherer {
  constructor() {
    // Instance method aliases the static `now` method
    this.now = PerformanceStatsGatherer.now;
    // Being careful to only use the bits of Map supported by IE 11, so...
    this.subscriptions = new Map();

    // Re-check after intializaing the singleton
    perfDebuggingOn = enviro.debug('dnd-area-perf');

    // If performance debugging is not on, make all of the "public" APIs a no-op
    if (!perfDebuggingOn) {
      this.subscribe = this.now = this.startCollectingReactMeasurements = this.incrementCounter = this.incrementCounterAndMeasure = NOOP_FUNCTION;
    }
    this.stats = {
      counters: {},
      durations: {},
      calculations: {}
    };
    this.reactPerfStackCounter = 0;
    this.shouldCollectReactStats = false;
    this.reactPerfMethodsToCall = {
      printWasted: true,
      printInclusive: true,
      printExclusive: false,
      printOperations: false
    };
  }
  static getSingletonInstance() {
    if (!PerformanceStatsGatherer._singletonInstance) {
      window.hs.perfGatherer = PerformanceStatsGatherer._singletonInstance = new PerformanceStatsGatherer();
    }
    return PerformanceStatsGatherer._singletonInstance;
  }
  static isGatheringStats() {
    return perfDebuggingOn;
  }
  getStats({
    forceFlushCalcuations = true
  } = {}) {
    if (forceFlushCalcuations) {
      this.calculateStatsOnDurationArrays();
    }
    return this.stats;
  }
  subscribe(func) {
    this.subscriptions.set(func, func);
  }
  unsubscribe(func) {
    this.subscriptions.delete(func);
  }
  triggerSubscribers({
    isFor
  } = {}) {
    this.subscriptions.forEach(func => func({
      isFor,
      stats: this.stats
    }));
  }
  static now() {
    return SUPPORTS_USER_TIMING ? performance.now() : Date.now();
  }
  incrementCounterHelper(metricName) {
    if (!this.stats.counters[metricName]) {
      this.stats.counters[metricName] = 0;
    }
    this.stats.counters[metricName] += 1;
  }
  incrementCounter(metricName) {
    this.incrementCounterHelper(metricName);
    this.triggerSubscribers();
  }
  incrementCounterAndMeasure(metricName, duration) {
    this.incrementCounterHelper(metricName);
    const now = Date.now();
    const durations = this.stats.durations[metricName] = this.stats.durations[metricName] || [];
    durations.push(duration);
    clearTimeout(this.durationCalcTimeout);
    if (!this.timestampSinceLastCollection) {
      this.timestampSinceLastCollection = now;
      this.setupTimeoutToFlushDurationCalculations(1000);
    } else if (now - this.timestampSinceLastCollection > 1000) {
      this.timestampSinceLastCollection = now;
      this.calculateStatsOnDurationArrays();
    } else {
      this.setupTimeoutToFlushDurationCalculations(1000 - (now - this.timestampSinceLastCollection));
    }
    this.triggerSubscribers();
  }
  calculateStatsOnDurationArrays() {
    for (const metricName of Object.keys(this.stats.durations)) {
      const durations = this.stats.durations[metricName];
      if (durations && durations.length > 0) {
        const totalCount = this.stats.counters[metricName];
        const calculations = this.stats.calculations[metricName] = this.stats.calculations[metricName] || {};
        const lastCount = calculations.lastCount;
        const currentTotalDuration = durations.reduce((total, x) => total + x, 0);
        const lastAvg = currentTotalDuration / durations.length;
        const previousCount = totalCount - lastCount;
        if (lastCount != null && previousCount > 0) {
          calculations.avg = (calculations.avg * previousCount + currentTotalDuration) / totalCount;
        } else {
          calculations.avg = lastAvg;
        }
        calculations.lastAvg = lastAvg;
        calculations.lastValue = durations.length === 1 ? lastAvg : null;
        calculations.lastCount = durations.length;
        const previousMax = calculations.max != null ? calculations.max : -1 * Infinity;
        const previousMin = calculations.min != null ? calculations.min : Infinity;
        calculations.max = Math.max(previousMax, ...durations);
        calculations.min = Math.min(previousMin, ...durations);
        this.stats.durations[metricName] = [];
      }
    }
  }
  setupTimeoutToFlushDurationCalculations(delayMs) {
    this.durationCalcTimeout = setTimeout(() => {
      this.calculateStatsOnDurationArrays();
    }, delayMs);
  }
  isSetToProfileReact() {
    return this.shouldCollectReactStats;
  }
  changeIsSetToProfileReact(value) {
    this.shouldCollectReactStats = value;
    if (value === false) {
      this.cancelExistingReactCollectionTimeout();
    }
    this.triggerSubscribers({
      isFor: 'meta'
    });
  }
  getReactPerfMethodsToCall() {
    return this.reactPerfMethodsToCall;
  }
  setReactPerfMethodsToCall(obj) {
    Object.assign(this.reactPerfMethodsToCall, obj);
    this.triggerSubscribers({
      isFor: 'meta'
    });
  }
  isCurrentlyProfilingReact() {
    return this.shouldCollectReactStats && (this.reactPerfStackCounter > 0 || !!this.reactCancelReactPerfCollectionTimeout);
  }
  cancelExistingReactCollectionTimeout() {
    clearTimeout(this.reactCancelReactPerfCollectionTimeout);
    delete this.reactCancelReactPerfCollectionTimeout;
  }
  startCollectingReactMeasurements() {
    if (!this.shouldCollectReactStats) {
      return;
    }
    this.reactPerfStackCounter += 1;
    if (this.reactPerfStackCounter === 1) {
      this.cancelExistingReactCollectionTimeout();

      // Perf.start();
      this.triggerSubscribers({
        isFor: 'meta'
      });
    }
  }
  stopCollectingReactMeasurements() {
    if (!this.shouldCollectReactStats) {
      return;
    }
    this.reactPerfStackCounter -= 1;
    if (this.reactPerfStackCounter === 0) {
      this.reactCancelReactPerfCollectionTimeout = setTimeout(() => {
        // Perf.stop();
        const measurements = null; //Perf.getLastMeasurements();
        const {
          printWasted,
          printInclusive,
          printExclusive,
          printOperations
        } = this.getReactPerfMethodsToCall();
        if (!printWasted && !printInclusive && !printExclusive && !printOperations) {
          console.log('Perf.getLastMeasurements()', measurements);
        }
        if (printWasted) {
          console.log('React Perf.printWasted');
          // Perf.printWasted(measurements);
        }
        if (printInclusive) {
          console.log('React Perf.printInclusive');
          // Perf.printInclusive(measurements);
        }
        if (printExclusive) {
          console.log('React Perf.printExclusive');
          // Perf.printExclusive(measurements);
        }
        if (printOperations) {
          console.log('React Perf.printOperations');
          // Perf.printOperations(measurements);
        }
        delete this.reactCancelReactPerfCollectionTimeout;
        this.triggerSubscribers({
          isFor: 'meta'
        });
      }, 1000);
    }
  }
}
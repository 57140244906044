'use es6';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { requestFailedOrUninitialized, requestStarted, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { sanitizeSlugInput } from 'ContentEditorUI/utils/helpers';
import { getContentModelId, getContentModelHtmlTitle } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { fetchEditableSlugInfo } from 'ContentEditorUI/redux/actions/slugInfoActions';
import { updateSlugRaw } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { getEditableSlugRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { getPageSettingsSlugRaw, getPageLanguageSlug } from 'page-editor-ui/redux/selectors/pageSettingsSelectors';
const mapStateToProps = state => ({
  contentModelId: getContentModelId(state),
  editableSlugRequest: getEditableSlugRequest(state),
  slugRaw: getPageSettingsSlugRaw(state),
  languageSlug: getPageLanguageSlug(state),
  htmlTitle: getContentModelHtmlTitle(state)
});

// Fetches editable slug info and corrects the page slug for its
// language slug accordingly, on load (if needed - doesn't change anything if not)
class FetchAndCorrectPageSlugContainer extends Component {
  componentDidMount() {
    const {
      contentModelId,
      editableSlugRequest
    } = this.props;
    if (requestSucceeded(editableSlugRequest)) {
      this.correctSlug();
    } else if (requestFailedOrUninitialized(editableSlugRequest) && !requestStarted(editableSlugRequest)) {
      this.props.fetchEditableSlugInfo(contentModelId);
    }
  }
  componentDidUpdate(prevProps) {
    const {
      editableSlugRequest
    } = this.props;
    if (!requestSucceeded(prevProps.editableSlugRequest) && requestSucceeded(editableSlugRequest)) {
      this.correctSlug();
    }
  }

  // Correct slug with lang slug if needed based on
  // bodyOverrideByDomain.addDomainLangToSlug setting changing underneath
  // Need this in order to force a correction to the slug if user loads page,
  // rather than the user needing to make a change to the slug to trigger the correction.
  correctSlug() {
    const {
      languageSlug,
      slugRaw,
      htmlTitle
    } = this.props;
    const sanitizedValue = sanitizeSlugInput(slugRaw);
    this.props.updateSlugRaw(sanitizedValue, languageSlug, htmlTitle);
  }
  render() {
    return null;
  }
}
FetchAndCorrectPageSlugContainer.propTypes = {
  contentModelId: PropTypes.number.isRequired,
  editableSlugRequest: PropTypes.object.isRequired,
  slugRaw: PropTypes.string,
  languageSlug: PropTypes.string,
  fetchEditableSlugInfo: PropTypes.func.isRequired,
  updateSlugRaw: PropTypes.func.isRequired,
  htmlTitle: PropTypes.string
};
export default connect(mapStateToProps, {
  fetchEditableSlugInfo,
  updateSlugRaw
})(FetchAndCorrectPageSlugContainer);
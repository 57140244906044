import { isEmpty } from 'underscore';
import Routes from 'ContentUtils/Routes';
import { ACCESS_OPERATORS, BOOLEAN_OPERATORS, OPERATORS, VISIBILITY_RULES } from 'ContentUtils/constants/DisplayConditions';
import { getFieldPath, getKeyfromPathString } from '../utils';
import { getDeepValue, isNullOrUndefined, isObject } from 'ContentUtils/helpers/ObjectHelpers';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
function getFieldAncestorsByPath(fields, pathString) {
  const fieldDotPath = getKeyfromPathString(pathString);
  let fieldsCurrent = [...fields];
  const fieldPath = [];
  fieldDotPath.forEach((fieldName, i) => {
    const field = fieldsCurrent.find(_field => _field.name === fieldName);
    if (field) {
      fieldPath.push(field);
      if (fieldDotPath.length > i + 1 && field.children) {
        fieldsCurrent = field.children;
      }
    }
  });
  return fieldPath;
}
export function checkSimpleVisibility(visibility, allFields, module, userScopes = [], groupOccurrenceOptions) {
  const {
    controlling_field: controllingFieldId,
    controlling_field_path: controllingFieldPath,
    controlling_value_regex: controllingRegex,
    operator,
    access,
    property
  } = visibility;
  const {
    isCheckingIfFieldDisabled,
    groupValue
  } = groupOccurrenceOptions;
  if (controllingFieldPath || controllingFieldId) {
    // if we're searching within a group occurrence
    // look for controlling field/value within occurrence first
    // then in entire module
    const valueToSearch = groupValue ? Object.assign({}, module, groupValue) : module;
    const fieldKey = controllingFieldPath ? getFieldAncestorsByPath(allFields, controllingFieldPath) : getFieldPath(allFields, controllingFieldId);

    // When checking if a field is disabled, returns false if the controlling
    // field cannot be found. When checking visibility, returns true if the
    // controlling field cannot be found.
    if (!fieldKey.length) return !isCheckingIfFieldDisabled;
    const controllingField = fieldKey[fieldKey.length - 1];
    let fieldValue = getDeepValue(valueToSearch, controllingFieldPath ? getKeyfromPathString(controllingFieldPath) : fieldKey.map(_field => _field.name));
    fieldValue = fieldValue === undefined ? controllingField.default : fieldValue;
    if (!isNullOrUndefined(fieldValue)) {
      if (isObject(fieldValue)) {
        fieldValue = property ? getDeepValue(fieldValue, getKeyfromPathString(property)) : JSON.stringify(fieldValue);
      } else {
        fieldValue = fieldValue.toString();
      }
    } else {
      fieldValue = '';
    }
    if (operator) {
      switch (operator) {
        case OPERATORS.EMPTY:
          return !fieldValue;
        case OPERATORS.EQUAL:
          return fieldValue === controllingRegex;
        case OPERATORS.NOT_EMPTY:
          return !!fieldValue;
        case OPERATORS.NOT_EQUAL:
          return fieldValue !== controllingRegex;
        case OPERATORS.MATCHES_REGEX:
          return fieldValue.match(controllingRegex);
        default:
          return true;
      }
    } else if (!operator && controllingRegex && fieldValue) {
      /* Some old modules have controlling regex and value, but no operator */
      return fieldValue.match(controllingRegex);
    } else if (!controllingRegex && !fieldValue) {
      // if a controlling field is set but no regex
      // just check that the value is not empty
      return false;
    }
  }
  if (access) {
    const {
      operator: accessOperator,
      scopes = [],
      gates = []
    } = access;
    if (!(scopes.length || gates.length)) return false;
    switch (accessOperator) {
      case ACCESS_OPERATORS.HAS_ALL:
        {
          const scopesPass = !scopes.length || scopes.every(scope => userScopes.includes(scope));
          const gatesPass = !gates.length || gates.every(gate => Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }
      case ACCESS_OPERATORS.HAS_ANY:
        {
          const scopesPass = !scopes.length || scopes.some(scope => userScopes.includes(scope));
          const gatesPass = !gates.length || gates.some(gate => Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }
      case ACCESS_OPERATORS.HAS_NONE:
        {
          const scopesPass = !scopes.length || scopes.every(scope => !userScopes.includes(scope));
          const gatesPass = !gates.length || gates.every(gate => !Routes.isUngated(gate));
          return scopesPass && gatesPass;
        }
      default:
        return false;
    }
  }
  return true;
}
export function checkAdvancedVisibility(advancedVisibility, allFields, module, scopes, groupOccurrenceOptions) {
  const {
    boolean_operator: booleanOperator,
    children,
    criteria
  } = advancedVisibility;
  if (!(booleanOperator && criteria)) return true;
  let areCriteriaValid = false;
  let areChildrenValid = false;
  const hasChildrenVisibilityRules = children && children.length;

  // If the criteria list is empty and there's no children, just exit early.
  if (!(criteria.length || hasChildrenVisibilityRules)) return true;

  // Depending on the boolean operator, check that some or every criteria is valid
  // Then if there are children, recursively check each child and compare with main
  // criteria visibility.
  if (booleanOperator === BOOLEAN_OPERATORS.AND) {
    // If checking for the AND boolean operator, we need to make sure that all
    // visibility objects in criteria come back true, as well as the children.
    areCriteriaValid = criteria.every(_criteria => checkSimpleVisibility(_criteria, allFields, module, scopes, groupOccurrenceOptions));

    // For the AND operator, if there are no children or the children list is empty
    // we want to default it to true so that we determine if it's visible based on
    // the result of the criteria.every check above.
    areChildrenValid = hasChildrenVisibilityRules ? children.every(child => checkAdvancedVisibility(child, allFields, module, scopes, groupOccurrenceOptions)) : true;
    return areCriteriaValid && areChildrenValid;
  }
  if (booleanOperator === BOOLEAN_OPERATORS.OR) {
    // We only need to check that one of the visibility objects in criteria is valid,
    // Array.some will return false in case the criteria list is empty. If the list is
    // empty, this will return the result of the children.
    areCriteriaValid = criteria.some(_criteria => checkSimpleVisibility(_criteria, allFields, module, scopes, groupOccurrenceOptions));

    // This needs to default to false if hasChildrenVisibilityRules is false, otherwise
    // empty children lists will cause the OR case to always return true.
    areChildrenValid = hasChildrenVisibilityRules ? children.some(child => checkAdvancedVisibility(child, allFields, module, scopes, groupOccurrenceOptions)) : false;
    return areCriteriaValid || areChildrenValid;
  }
  return true;
}
export function isVisible(field, allFields, module, scopes, groupOccurrenceOptions = {}) {
  const {
    advanced_visibility: advancedVisibility,
    visibility: simpleVisibility,
    visibility_rules: visibilityRules
  } = field;
  if (!visibilityRules || visibilityRules === VISIBILITY_RULES.SIMPLE) {
    if (!simpleVisibility) return true;
    return checkSimpleVisibility(simpleVisibility, allFields, module, scopes, groupOccurrenceOptions);
  }
  if (visibilityRules === VISIBILITY_RULES.ADVANCED) {
    if (!advancedVisibility) return true;
    return checkAdvancedVisibility(advancedVisibility, allFields, module, scopes, groupOccurrenceOptions);
  }
  return true;
}
export const areFieldsLocked = fields => {
  return fields.length > 0 && fields.every(field => {
    return field.type === FieldTypes.GROUP ? field.locked || areFieldsLocked(field.children) : field.locked;
  });
};
export function isFieldDisabled(field, allFields, module, scopes, groupOccurrenceOptions = {}) {
  const {
    disabled_controls
  } = field;
  if (disabled_controls && !isEmpty(disabled_controls.rules)) {
    const {
      rules
    } = disabled_controls;
    const {
      boolean_operator: booleanOperator
    } = rules;
    const visibilityRules = !booleanOperator ? Object.assign({}, rules, {
      boolean_operator: BOOLEAN_OPERATORS.AND
    }) : rules;
    return checkAdvancedVisibility(visibilityRules, allFields, module, scopes, Object.assign({}, groupOccurrenceOptions, {
      isCheckingIfFieldDisabled: true
    }));
  }
  return false;
}
'use es6';

import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import { buildTrialState } from 'self-service-api/core/utilities/trialUtils';
export const trialStateAdapter = trialState => {
  if (trialState.length === 0) return trialState;
  const transformedTrialState = {};
  trialState.forEach(hubTrial => {
    const {
      trials,
      createdAt,
      endsAt,
      removedAt
    } = hubTrial;
    trials.forEach(trial => {
      const {
        name,
        id
      } = trial;
      const label = UpgradeProductToApiNameMap[name];
      transformedTrialState[label] = buildTrialState(createdAt, endsAt, removedAt, id);
    });
  });
  return transformedTrialState;
};
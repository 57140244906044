import { getUpgradeDataTrackingProperties } from '../../ums/utils';
import { setSurveyResponses } from '../common/api/surveyResponseApi';
import { tracker } from '../common/eventTracking/tracker';
import { upgradeProductSurveyResponseMap } from '../trial/SurveyResponses';
export const handleSubmitSurveyResponses = async (apiName, surveyResponses, upgradeData) => {
  const updatedSurveyResponses = Object.assign({}, upgradeProductSurveyResponseMap, {
    [apiName]: surveyResponses
  });
  const selectedResponses = Object.entries(surveyResponses).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }
    return acc;
  }, []);
  tracker.track('trialActivationInteraction', Object.assign({
    action: 'survey submission successful'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    apiName,
    features: selectedResponses
  }));
  await setSurveyResponses(updatedSurveyResponses);
};
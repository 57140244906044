import * as EmbedApi from 'ContentEditorUI/api/EmbedApi';
import { FETCH_EMBEDS_STARTED, FETCH_EMBEDS_FAILED, FETCH_EMBEDS_SUCCEEDED } from './actionTypes';
const fetchEmbedsSucceeded = embeds => ({
  type: FETCH_EMBEDS_SUCCEEDED,
  payload: embeds
});
const fetchEmbedsFailed = () => ({
  type: FETCH_EMBEDS_FAILED
});
export const fetchEmbeds = (themePath, parentThemePath) => dispatch => {
  dispatch({
    type: FETCH_EMBEDS_STARTED
  });
  return EmbedApi.fetchEmbeds().then(res => {
    // Currently, the BE returns all of the embeds in the portal. This filter keeps those
    // that _don't_ have a theme associated with theme and those that have a theme and that theme
    // is either the theme of the current page or the parent theme of the current page
    const filteredEmbeds = res.embedData.filter(embed => !embed.themePath || embed.themePath === themePath || embed.themePath === parentThemePath);
    dispatch(fetchEmbedsSucceeded(filteredEmbeds));
  }).catch(__err => {
    dispatch(fetchEmbedsFailed());
  });
};
'use es6';

const AB_NEW_TEST_LOCAL_STORAGE_KEY = 'IS_NEW_AB_TEST';
const AB_TEST_SETUP_LOCAL_STORAGE_KEY = 'TEST_SETUP';
const MAB_NEW_TEST_LOCAL_STORAGE_KEY = 'IS_NEW_MAB_TEST';
export const wasTemporaryNewAbTestBitSet = () => {
  try {
    return localStorage.getItem(AB_NEW_TEST_LOCAL_STORAGE_KEY) != null;
  } catch (e) {
    return false;
  }
};
export const setTemporaryNewAbTestBit = () => {
  if (wasTemporaryNewAbTestBitSet()) {
    return;
  }
  try {
    localStorage.setItem(AB_NEW_TEST_LOCAL_STORAGE_KEY, true);
  } catch (e) {
    return;
  }
};
export const isNewAbTest = () => {
  if (!wasTemporaryNewAbTestBitSet()) {
    return false;
  }
  try {
    return localStorage.getItem(AB_NEW_TEST_LOCAL_STORAGE_KEY) === 'true';
  } catch (e) {
    return false;
  }
};
export const removeTemporaryNewAbTestBitIfNeeded = () => {
  if (isNewAbTest()) {
    try {
      localStorage.setItem(AB_NEW_TEST_LOCAL_STORAGE_KEY, false);
    } catch (e) {
      return;
    }
  }
};
export const wasTemporaryNewAbTestSetupSet = () => {
  try {
    const setup = localStorage.getItem(AB_TEST_SETUP_LOCAL_STORAGE_KEY);
    return setup !== null && setup !== 'null';
  } catch (e) {
    return false;
  }
};
export const setTemporaryNewAbTestSetup = abTestConfig => {
  if (wasTemporaryNewAbTestSetupSet()) {
    return;
  }
  try {
    localStorage.setItem(AB_TEST_SETUP_LOCAL_STORAGE_KEY, JSON.stringify(abTestConfig));
  } catch (e) {
    return;
  }
};
export const getAbTestSetup = () => {
  if (!wasTemporaryNewAbTestSetupSet()) {
    return null;
  }
  try {
    const setup = localStorage.getItem(AB_TEST_SETUP_LOCAL_STORAGE_KEY);
    return JSON.parse(setup);
  } catch (e) {
    return null;
  }
};
export const removeTemporaryNewAbTestSetupIfNeeded = () => {
  if (wasTemporaryNewAbTestSetupSet()) {
    try {
      localStorage.setItem(AB_TEST_SETUP_LOCAL_STORAGE_KEY, null);
    } catch (e) {
      return;
    }
  }
};
const wasTemporaryNewMABTestBitSet = () => {
  try {
    return localStorage.getItem(MAB_NEW_TEST_LOCAL_STORAGE_KEY) != null;
  } catch (e) {
    return false;
  }
};
export const setTemporaryNewMABTestBit = () => {
  if (wasTemporaryNewMABTestBitSet()) {
    return;
  }
  try {
    localStorage.setItem(MAB_NEW_TEST_LOCAL_STORAGE_KEY, true);
  } catch (e) {
    return;
  }
};
export const isNewMabTest = () => {
  if (!wasTemporaryNewMABTestBitSet()) {
    return false;
  }
  try {
    return localStorage.getItem(MAB_NEW_TEST_LOCAL_STORAGE_KEY) === 'true';
  } catch (e) {
    return false;
  }
};
export const removeTemporaryNewMABTestBitIfNeeded = () => {
  if (isNewMabTest()) {
    try {
      localStorage.setItem(MAB_NEW_TEST_LOCAL_STORAGE_KEY, false);
    } catch (e) {
      return;
    }
  }
};
export const showSaveAction = () => {
  try {
    return localStorage.getItem('SHOW_EMAIL_DD_SAVE') != null;
  } catch (e) {
    return false;
  }
};
export const showTemplateCreationAction = () => {
  try {
    return localStorage.getItem('SHOW_EMAIL_DND_TEMPLATE_CREATION') != null;
  } catch (e) {
    return false;
  }
};
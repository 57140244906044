import { useContext, useCallback, useState } from 'react';
// @ts-expect-error Not typed yet
import EditorActionsAndEventsContext from 'ContentEditorUI/context/EditorActionsAndEventsContext';

/***
 * 1. Add methods, of same name to `InpageEditorUI/static/js/controllers/EditController.js`
 *    a. add to bus events e.g. `'post:myExampleMethod': this.myExampleMethod,`
 *    b. create your method
 *    ```
 *    getHTMLTextContext = message => {
 *      // calculate myCustomResponse
 *      this.bus.postMessage({
 *        ...message,
 *        response: myCustomResponse,
 *      });
 *    };
 *    ```
 * * 2. Use method of same name:`const { fetch, result } =  useEditControllerMethod("myExampleMethod")`;
 * `fetch();`
 * 3. Profit
 */

export const useEditControllerMethod = ({
  action,
  parameters = {},
  onSuccess = () => {}
}) => {
  const [result, setResult] = useState(null);
  const {
    bus
  } = useContext(EditorActionsAndEventsContext);
  const fetch = useCallback(() => {
    bus.postMessage(Object.assign({
      action
    }, parameters)).then(res => {
      setResult(res);
      onSuccess(res);
    }).catch(e => {
      console.error(`Something went wrong in "${action}"`, e);
    });
  }, [action, bus, onSuccess, parameters]);
  return {
    result,
    fetch
  };
};
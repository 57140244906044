import PropTypes from 'prop-types';
import { upgradeDataPropsInterface } from 'ui-addon-upgrades/_core/common/data/upgradeData/interfaces/upgradeDataPropsInterface';
export const RequestSeatButtonPropsInterface = Object.assign({}, upgradeDataPropsInterface, {
  use: PropTypes.string,
  showModal: PropTypes.bool,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  usageLimitBannerData: PropTypes.object
});
export const AssignSeatButtonPropsInterface = Object.assign({}, upgradeDataPropsInterface, {
  use: PropTypes.string,
  usageLimitBannerData: PropTypes.object
});
import http from 'hub-http/clients/apiClient';
const CLUSTERS_PATH = 'content-clusters/v1/clusters';
export const getClusters = ({
  intitiateCrawl = true,
  getCoreTopicOnly = true
}) => {
  return http.get(CLUSTERS_PATH, {
    query: {
      intitiateCrawl,
      getCoreTopicOnly
    }
  });
};
export const postCluster = phrase => {
  return http.post(CLUSTERS_PATH, {
    data: {
      coreTopic: {
        phrase
      }
    }
  });
};
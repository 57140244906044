export const getSurvey = (state, props) => {
  const surveys = state.resources.surveys.surveys[props.surveyType];
  if (!surveys) return null;
  return surveys[props.id];
};
export const getSurveys = state => state.resources.surveys.surveys;
export const getSurveyCache = (state, props) => {
  const surveysCache = state.resources.surveys.surveysCache[props.surveyType];
  if (!surveysCache) return null;
  return surveysCache[props.id];
};
export const getSurveysCache = state => state.resources.surveys.surveysCache;
export const getSurveysCacheRequestStatus = state => state.resources.surveys.surveysCacheRequestStatus;
export const getSurveyFetchStatus = (state, props) => (state.resources.surveys.requestStatus[props.surveyType] || {})[props.id];
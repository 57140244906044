// @ts-expect-error Untyped module
import { getLanguage } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { useSelector } from 'react-redux';
import getLangLocale from 'I18n/utils/getLangLocale';
export const useGetLocaleLanguageCode = () => {
  try {
    return useSelector(getLanguage);
  } catch (e) {
    console.warn('Provider is not setup. Using I18n as fallback. If you want to use Content Objects langauge code make sure to wrap component in a Provider with react-redux content.');
    return getLangLocale(); // e.g. en-us
  }
};
import { CREATE_CLUSTER_SUCCEEDED, CREATE_SUBTOPIC_SUCCEEDED, FETCH_ATTACHED_CLUSTER_SUCCEEDED, REMOVE_ATTACHED_CONTENT_SUCCEEDED, SELECTED_CLUSTER_CHANGED, SELECTED_SUBTOPIC_CHANGED } from '../actionTypes';
import { createSelector } from 'reselect';
const initialState = {
  selectedClusterId: null,
  selectedSubtopicId: null,
  attachedClusters: null
};
export const topicEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_CLUSTER_CHANGED:
      {
        const {
          selectedClusterId
        } = action.payload;
        return Object.assign({}, state, {
          selectedClusterId,
          selectedSubtopicId: null,
          attachedClusters: null
        });
      }
    case SELECTED_SUBTOPIC_CHANGED:
      {
        const {
          selectedSubtopicId
        } = action.payload;
        return Object.assign({}, state, {
          selectedSubtopicId
        });
      }
    case CREATE_CLUSTER_SUCCEEDED:
      {
        const {
          id
        } = action.payload;
        return Object.assign({}, state, {
          selectedClusterId: id,
          selectedSubtopicId: null
        });
      }
    case CREATE_SUBTOPIC_SUCCEEDED:
      {
        const {
          id
        } = action.payload;
        return Object.assign({}, state, {
          selectedSubtopicId: id
        });
      }
    case FETCH_ATTACHED_CLUSTER_SUCCEEDED:
      {
        const attachedClusters = action.payload.map(cluster => {
          const {
            topic,
            coreTopic,
            attachedContent,
            pillarPage
          } = cluster;
          return {
            topicId: topic && topic.id,
            clusterId: coreTopic && coreTopic.clusterId,
            clusterPhrase: coreTopic && coreTopic.phrase,
            subtopicPhrase: topic && coreTopic && topic.id !== coreTopic.id ? topic.phrase : null,
            attachedContentId: attachedContent && attachedContent.id,
            pillarPageUrl: pillarPage && pillarPage.url
          };
        });
        return Object.assign({}, state, {
          attachedClusters
        });
      }
    case REMOVE_ATTACHED_CONTENT_SUCCEEDED:
      {
        return Object.assign({}, state, {
          selectedClusterId: null,
          selectedSubtopicId: null,
          attachedClusters: null
        });
      }
    default:
      return state;
  }
};
const selectTopicEditorState = state => state.topicEditor;
export const selectAttachedCluster = createSelector(selectTopicEditorState, topicEditor => {
  const attachedClusters = topicEditor.attachedClusters;
  return attachedClusters ? attachedClusters[0] : null;
});
export const selectSelectedClusterId = createSelector(selectTopicEditorState, topicEditor => topicEditor.selectedClusterId);
export const selectSelectedSubtopicId = createSelector(selectTopicEditorState, topicEditor => topicEditor.selectedSubtopicId);
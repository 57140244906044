import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS, UPDATE_CONTENT_MODEL_DOMAIN } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const portalDomainsReducer = (state = {
  domains: [],
  domainExclusionReason: null
}, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const domains = response.domains;
        if (domains) draft.domains = domains;
        return draft;
      }
    case FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS:
      {
        draft.domainExclusionReason = payload;
        return draft;
      }
    case UPDATE_CONTENT_MODEL_DOMAIN:
      {
        draft.domainExclusionReason = null;
        return draft;
      }
    default:
      return draft;
  }
});
export default portalDomainsReducer;
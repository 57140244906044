export const GraphQLScalars = Object.freeze({
  Enum: 'HS_Enum',
  Int: 'Int',
  String: 'String',
  Date: 'Date',
  DateTime: 'DateTime',
  Float: 'Float',
  ID: 'ID',
  Boolean: 'Boolean',
  Number: 'Number',
  Currency: 'Currency'
});
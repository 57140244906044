import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { EMAILS_FETCH_EMAIL, EMAILS_FETCH_EMAIL_BUFFER, EMAILS_FETCH_EMAILS, EMAILS_FETCH_AUTOMATED_EMAILS, EMAILS_SAVE_EMAIL_CACHE, EMAILS_CLEAR_CACHE, EMAILS_CREATE_EMAIL, EMAILS_UPDATE_EMAIL, EMAILS_FETCH_IS_LEGACY_EMAIL_CONVERTIBLE } from 'ContentData/actions/ActionTypes';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
function emailBuffers(state = {}, action) {
  switch (action.type) {
    case EMAILS_FETCH_EMAIL_BUFFER:
    case EMAILS_CREATE_EMAIL:
    case EMAILS_UPDATE_EMAIL:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }
    default:
      return state;
  }
}
function emails(state = {}, action) {
  switch (action.type) {
    case EMAILS_FETCH_EMAIL:
    case EMAILS_CREATE_EMAIL:
    case EMAILS_UPDATE_EMAIL:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }
    case EMAILS_SAVE_EMAIL_CACHE:
      {
        const {
          email
        } = action;
        if (!email) return state;
        return Object.assign({}, state, {
          [email.id]: email
        });
      }
    default:
      return state;
  }
}
function emailsCache(state = {}, action) {
  switch (action.type) {
    case EMAILS_FETCH_EMAILS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          objects
        } = response;
        if (!objects) return state;
        return objects.reduce((hash, email) => {
          hash[email.id] = email;
          return hash;
        }, Object.assign({}, state));
      }
    case EMAILS_FETCH_AUTOMATED_EMAILS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          results
        } = response;
        if (!results) return state;
        return results.reduce((hash, email) => {
          hash[email.id] = email;
          return hash;
        }, Object.assign({}, state));
      }
    case EMAILS_CREATE_EMAIL:
    case EMAILS_UPDATE_EMAIL:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }
    case EMAILS_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }
    default:
      return state;
  }
}
function emailsCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case EMAILS_FETCH_EMAILS:
    case EMAILS_FETCH_AUTOMATED_EMAILS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;
    default:
      return state;
  }
}
function isLegacyEmailConvertible(state = {}, action) {
  switch (action.type) {
    case EMAILS_FETCH_IS_LEGACY_EMAIL_CONVERTIBLE:
      {
        const {
          options: emailId,
          response,
          error
        } = action;
        if (!response && !error) return state;
        return Object.assign({}, state, {
          [emailId]: response ? response.isConvertible : false
        });
      }
    default:
      return state;
  }
}
export default combineReducers({
  emailBuffers,
  emails,
  emailsCache,
  emailsCacheRequestStatus,
  isLegacyEmailConvertible,
  requestStatus: createGenericRequestStatusReducer(EMAILS_FETCH_EMAIL)
});
import { createSelector } from 'reselect';
import { isScheduledState, publishDateIsScheduled, isPublishedState, isAutomatedState
// @ts-expect-error not yet migrated
} from 'ContentEditorUI/utils/publishUtils';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getHardSavedContentModel = basicSelector(state => state.hardSavedContentModel);
const createHardSavedContentModelSelector = field => createSelector([getHardSavedContentModel], contentModel => contentModel[field]);
export const getHardSavedContentModelState = createHardSavedContentModelSelector('state');
export const getHardSavedContentModelPublishDate = createHardSavedContentModelSelector('publishDate');
export const getHardSavedContentModelPublishImmediately = createHardSavedContentModelSelector('publishImmediately');
export const getHardSavedContentModelScheduledUpdateDate = createHardSavedContentModelSelector('scheduledUpdateDate');
export const getHardSavedContentModelIsScheduled = createSelector([getHardSavedContentModelState, getHardSavedContentModelPublishDate, getHardSavedContentModelPublishImmediately], (state, publishDate, publishImmediately) =>
// Pages use PUBLISHED_OR_SCHEDULED for both, which
// classifies under published states in StateStatusGroups
(isPublishedState(state) || isScheduledState(state)) && publishDateIsScheduled(publishDate, publishImmediately));
export const getHardSavedContentModelIsAutomated = createSelector([getHardSavedContentModelState], state => isAutomatedState(state));
export const getHardSavedContentModelIsPublishedNotScheduled = createSelector([getHardSavedContentModelState, getHardSavedContentModelIsScheduled], (state, isScheduled) => isPublishedState(state) && !isScheduled);
'use es6';

import { injectDynamicMiddleware } from 'ContentEditorUI/redux/configureStore';
import { CONTENT_SAVE_REQUEST_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';

// Helper to inject middleware needed by optimizer panel
export const injectOptimizationsSubMiddleware = middlewareImportPromise => {
  return middlewareImportPromise.then(mod => {
    const {
      optimizationsSubMiddleware
    } = mod;
    injectDynamicMiddleware(optimizationsSubMiddleware([CONTENT_SAVE_REQUEST_SUCCEEDED]));
  }).catch(() => {
    throw new Error('Error loading optimizationsSubMiddleware');
  });
};
import { AccountVerificationState, AccountVerificationProgressStatus } from '../enums';
const {
  NOT_APPLICABLE,
  NEEDS_VERIFICATION,
  PENDING_VERIFICATION,
  REJECTED_VERIFICATION,
  ACCEPTED_VERIFICATION
} = AccountVerificationProgressStatus;
const {
  PENDING,
  ACCEPTED,
  REJECTED
} = AccountVerificationState;
const getNeedsVerificationStatus = bannerType => {
  return bannerType ? AccountVerificationProgressStatus[`NEEDS_${bannerType}`] : NEEDS_VERIFICATION;
};
const mapScopeStatusToAccountVerificationProgressStatus = bannerInfo => {
  if (!bannerInfo) return [NOT_APPLICABLE, 'warning'];
  const {
    suspended,
    appealState,
    bannerType,
    redirectUrl
  } = bannerInfo;
  if (suspended) {
    if (redirectUrl) {
      return [getNeedsVerificationStatus(bannerType), 'danger'];
    }
    if (appealState === PENDING) {
      return [PENDING_VERIFICATION, 'warning'];
    } else if (appealState === REJECTED) {
      return [REJECTED_VERIFICATION, 'danger'];
    } else {
      return [NOT_APPLICABLE, 'warning'];
    }
  } else if (appealState === ACCEPTED) {
    return [ACCEPTED_VERIFICATION, 'success'];
  }
  return [NOT_APPLICABLE, 'warning'];
};
export default mapScopeStatusToAccountVerificationProgressStatus;
export const getReadabilityIssues = () => {
  // placeholder until we implement calling the Readability api and storing the returned results in the state
  return [{
    moduleName: 'widget_123',
    category: 'GRAMMAR',
    subcategory: 'PUNCTUATION',
    description: 'A short message about the problem',
    offset: 0,
    length: 8,
    text: 'Nowadays',
    suggestions: ['Nowadays,']
  }, {
    moduleName: 'widget_123',
    category: 'GRAMMAR',
    subcategory: 'GRAMMAR',
    description: 'A short message about the problem',
    offset: 2,
    length: 2,
    text: 'is',
    suggestions: ['am']
  }];
};
'use es6';

import { getTreeNodeDomProperties } from '../helpers';
const allowedDirections = ['top', 'bottom', 'left', 'right'];
const allowedUnits = ['topUnits', 'bottomUnits', 'leftUnits', 'rightUnits'];

/*
 * Supports two formats, the "old" one looks like
 * { "top": 50, "right": 50, "bottom": 50, "left": 50, topUnits: "px" ...etc }
 *
 * The "new" format is nested and looks like
 * {
 *  "top": { value: 50, units: "px" }
 *  "bottom": { value: 50, units: "px" }
 *  "left": { value: 50, units: "px" }
 *  "right": { value: 50, units: "px" }
 * }
 *
 * We need to support both until the new padding structure normalization done on the backend
 * is ungated to all. Afterwhich we can drop support for the old structure.
 */
const isValidPaddingObject = paddingObject => {
  const paddingObjectKeys = Object.keys(paddingObject);
  // Only allowedDirections and allowedUnits are valid top level keys
  const hasValidKeys = paddingObjectKeys.every(keyEntry => allowedDirections.includes(keyEntry) || allowedUnits.includes(keyEntry));
  const isNestedPaddingFormat = paddingObjectKeys.some(keyEntry => typeof paddingObject[keyEntry] === 'object');
  let hasValidValues;
  let hasValidUnits;
  if (isNestedPaddingFormat) {
    hasValidValues = true;
    hasValidUnits = true;
    paddingObjectKeys.forEach(keyEntry => {
      if (typeof paddingObject[keyEntry].value !== 'number') {
        hasValidUnits = false;
        return;
      }
      if (paddingObject[keyEntry].units && typeof paddingObject[keyEntry].units !== 'string') {
        hasValidUnits = false;
        return;
      }
    });
  } else {
    // Supporting the "old" format should be temporary until data normalization is ungated to all on the backend
    hasValidValues = allowedDirections.every(keyEntry => paddingObject[keyEntry] === undefined || typeof paddingObject[keyEntry] === 'number' || paddingObject[keyEntry] === 'number');
    hasValidUnits = allowedUnits.every(keyEntry => paddingObject[keyEntry] === undefined || typeof paddingObject[keyEntry] === 'string');
  }
  if (!hasValidKeys || !hasValidValues || !hasValidUnits) {
    console.warn('Padding value must only include either top, bottom, left or right keys, all of which should be numbers or objects');
    return false;
  }
  return true;
};
const calculateCssProperties = styleValue => {
  const cssProperties = {};
  allowedDirections.forEach((key, index) => {
    if (styleValue[key] !== undefined) {
      const valueToSet = styleValue[key].value !== undefined // because 0 is falsey
      ? styleValue[key].value : styleValue[key];
      let unit = styleValue[key].value !== undefined ? styleValue[key].units : styleValue[allowedUnits[index]];
      if (unit === undefined) {
        unit = 'px';
      }
      cssProperties[`padding-${key}`] = `${valueToSet}${unit} !important`;
    }
  });
  return cssProperties;
};
const calculateCommonPaddingDataForTreeNode = (treeNode, styleValue) => {
  if (!isValidPaddingObject(styleValue)) {
    return false;
  }
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  return {
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-padding`,
    cssSelector: `.${rendererName}-padding`,
    cssProperties: calculateCssProperties(styleValue)
  };
};
const calculateCommonPaddingDataForModule = (module, styleValue) => {
  if (!isValidPaddingObject(styleValue)) {
    return false;
  }
  return {
    nodeName: module.get('name'),
    domNodeSelector: `#hs_cos_wrapper_${module.get('id')}`,
    className: '',
    cssSelector: `#hs_cos_wrapper_${module.get('id')}`,
    cssProperties: calculateCssProperties(styleValue)
  };
};
export const calculatePaddingClasses = (treeNode, styleValue) => {
  const paddingObject = calculateCommonPaddingDataForTreeNode(treeNode, styleValue);
  if (paddingObject) {
    return [paddingObject];
  } else {
    return [];
  }
};
export const calculateBreakpointPaddingClassesForTreeNode = (treeNode, styleValue, breakpoint) => {
  const paddingObject = styleValue === null ? null : calculateCommonPaddingDataForTreeNode(treeNode, styleValue);
  if (paddingObject) {
    paddingObject.mediaQuery = breakpoint.mediaQuery;
    if (treeNode.isStaticSectionModule()) {
      // dnd modules create an extra div wrapper with the #hs_cos_wrapper_{id} selector
      // But Static Section Modules do not - as they come back as a single chunk
      // When the renderDndAreas.js#calculateBreakpointPaddingClassesForTreeNode is applied, for static section modules
      // there is no extra div wrapper, so we need to adjust the selector accordingly
      const newSelector = `${paddingObject.cssSelector}#hs_cos_wrapper_${paddingObject.nodeName}`;
      paddingObject.cssSelector = newSelector;
    } else if (treeNode.isModule()) {
      const newSelector = `${paddingObject.cssSelector}>#hs_cos_wrapper_${paddingObject.nodeName}`;
      paddingObject.cssSelector = newSelector;
    }
    return [paddingObject];
  } else {
    return [];
  }
};
export const calculateBreakpointPaddingClassesForModule = (module, styleValue, breakpoint) => {
  const paddingObject = styleValue === null ? null : calculateCommonPaddingDataForModule(module, styleValue);
  if (paddingObject) {
    paddingObject.mediaQuery = breakpoint.mediaQuery;
    return [paddingObject];
  } else {
    return [];
  }
};
'use es6';

import { toCamelCase } from 'ContentEditorUI/utils/helpers';
import { routeToPage } from 'ContentEditorUI/redux/actions/routingActions';
import { updateHighlightedWidgetId } from 'ContentEditorUI/redux/actions/inpageActions';
import { updateSelectedItem } from 'ContentEditorUI/redux/actions/selectionActions';
import { setScrollTo } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { createUpdateFocusedItem } from 'ContentEditorUI/redux/actions/baseFocusActions';
import { openPanel } from 'ContentEditorUI/redux/actions/panelActions';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { getCurrentTab } from 'page-editor-ui/redux/selectors/navSelectors';
import { clearInpageUIState } from 'ContentEditorUI/redux/actions/inpageActions';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { openModal } from 'ContentEditorUI/redux/actions/appActions';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
const PAGE_SETTINGS_REDUX_FIELDS = ['name', 'htmlTitle', 'slug', 'password', 'pageExpiryDate', 'pageExpiryRedirectUrl', 'language', 'audienceAccess', 'featuredImage'];
const pageFocusLogic = (focusedItem, dispatch, state, options) => {
  const camelCaseFocusedItem = toCamelCase(focusedItem);
  const currentTab = getCurrentTab(state);
  const isUngatedForPatagonia = getIsUngatedForPatagonia(state);
  if (options.moduleId) {
    if (currentTab.id !== 'content') {
      dispatch(clearInpageUIState());
    }
    if (focusedItem === 'contentPane') {
      dispatch(routeToPage('content'));
      dispatch(openPanel(SIDEBAR_PANELS.widgetList, {
        scrollToModuleInList: options.moduleId
      }));
      dispatch(updateHighlightedWidgetId(options.moduleId));
    } else {
      dispatch(routeToPage('content'));
      dispatch(updateHighlightedWidgetId(options.moduleId));
      dispatch(updateSelectedItem(options.moduleId, {
        activeTab: 'edit'
      }));
    }
  } else if (focusedItem === 'publishDate') {
    if (currentTab.id !== 'publishing') {
      dispatch(clearInpageUIState());
    }
    dispatch(routeToPage('publishing'));
  } else if (PAGE_SETTINGS_REDUX_FIELDS.includes(camelCaseFocusedItem)) {
    if (currentTab.id !== 'settings' && !isUngatedForPatagonia) {
      dispatch(clearInpageUIState());
    }
    if (!isUngatedForPatagonia) {
      dispatch(routeToPage('settings'));
    } else {
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
    }
    const fieldToScrollTo = !isUngatedForPatagonia ? camelCaseFocusedItem : options.settingsModalQuery;
    dispatch(setScrollTo(fieldToScrollTo));
  } else if (focusedItem === 'dynamicPages' || focusedItem === 'noValueForDynamicPageSlug') {
    if (currentTab.id !== 'settings' && !isUngatedForPatagonia) {
      dispatch(clearInpageUIState());
    }
    if (!isUngatedForPatagonia) {
      dispatch(routeToPage('settings'));
      dispatch(setScrollTo('dynamicPages'));
    } else {
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
      dispatch(setScrollTo(options.settingsModalQuery));
    }
  } else if (focusedItem === 'pageUnpublishDate' || focusedItem === 'pageUnpublishRedirectUrl') {
    if (currentTab.id !== 'publishing') {
      dispatch(clearInpageUIState());
    }
    dispatch(routeToPage('publishing'));
    dispatch(setScrollTo(focusedItem));
  }
};
export const updateFocusedItem = createUpdateFocusedItem(pageFocusLogic);
import I18n from 'I18n';
const oneSecondDuration = () => I18n.moment.duration(1, 'seconds').asMilliseconds();
export const fiveSecondDuration = () => I18n.moment.duration(5, 'seconds').asMilliseconds();
const thirtySecondsDuration = () => I18n.moment.duration(30, 'seconds').asMilliseconds();
const oneMinuteDuration = () => I18n.moment.duration(1, 'minutes').asMilliseconds();
const thirtyMinutesDuration = () => I18n.moment.duration(30, 'minutes').asMilliseconds();
const oneHourDuration = () => I18n.moment.duration(1, 'hours').asMilliseconds();
const oneDayDuration = () => I18n.moment.duration(1, 'days').asMilliseconds();
/**
 * Typescript throws errors when doing arithmetic operations on dates
 * Using the + operator to coerce those values into numbers
 * https://github.com/microsoft/TypeScript/issues/5710#issuecomment-157886246
 *
 */

export const isLastSaveAfterThreshold = ({
  lastSave,
  now,
  threshold
}) => {
  if (!lastSave) {
    return false;
  }
  return +now - +lastSave > threshold;
};
export const isLastSaveToday = ({
  lastSave,
  now
}) => {
  if (!lastSave) {
    return false;
  }
  return I18n.moment.portalTz(lastSave.toString()).isSame(I18n.moment.portalTz(now.toString()), 'day');
};
export const getNextDurationChange = ({
  lastSave,
  now,
  showTimestampAfter
}) => {
  const lastSaveMoment = I18n.moment.portalTz(lastSave.toString());
  const durationSinceSave = I18n.moment.duration(+now - +lastSave);
  const milliSecondsSinceLastSave = durationSinceSave.asMilliseconds();
  if (milliSecondsSinceLastSave < showTimestampAfter) {
    // Check again when the timeout for the "saving/autosaving" message runs out
    return +showTimestampAfter - milliSecondsSinceLastSave;
  } else if (milliSecondsSinceLastSave < oneMinuteDuration()) {
    // Then we'll update at the 1 minute mark
    return oneMinuteDuration() - durationSinceSave.milliseconds();
  } else if (milliSecondsSinceLastSave < oneHourDuration()) {
    // Now we have to do some wacky things to deal with the rounding that I18n does
    // Basically, we are trying to update on the :30 second mark
    const timeUntilNextMinute = oneMinuteDuration() - durationSinceSave.seconds() * oneSecondDuration() - durationSinceSave.milliseconds();
    if (timeUntilNextMinute <= thirtySecondsDuration()) {
      return timeUntilNextMinute + thirtySecondsDuration();
    } else {
      return timeUntilNextMinute - thirtySecondsDuration();
    }
  } else if (milliSecondsSinceLastSave < oneDayDuration()) {
    // If we've already crossed midnight, we're showing the real date anyway
    const timeUntilMidnight = lastSaveMoment.add(1, 'day').startOf('day').valueOf() - +now;
    if (timeUntilMidnight < 0) {
      return null;
    }

    // Same as above but on the :30 minute mark
    const timeUntilNextHour = oneHourDuration() - durationSinceSave.minutes() * oneMinuteDuration() - durationSinceSave.seconds() * oneSecondDuration() - durationSinceSave.milliseconds();
    if (timeUntilNextHour > timeUntilMidnight) {
      return timeUntilMidnight;
    }
    if (timeUntilNextHour <= thirtyMinutesDuration()) {
      return timeUntilNextHour + thirtyMinutesDuration();
    } else {
      return timeUntilNextHour - thirtyMinutesDuration();
    }
  }
  // After one day we just display the date, so we don't need to update it in real time
  return null;
};
export const ANY = 'ANY';
export const LANDING_PAGE = 'LANDING_PAGE';
export const SITE_PAGE = 'SITE_PAGE';
export const BLOG_POST = 'BLOG_POST';
export const BLOG_LISTING = 'BLOG_LISTING';
export const EMAIL = 'EMAIL';
export const KNOWLEDGE_BASE = 'KNOWLEDGE_BASE';
export const QUOTE_TEMPLATE = 'QUOTE_TEMPLATE';
export const CUSTOMER_PORTAL = 'CUSTOMER_PORTAL';
export const WEB_INTERACTIVE = 'WEB_INTERACTIVE';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const MEMBERSHIP = 'MEMBERSHIP';
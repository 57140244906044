'use es6';

export const TOP = 'TOP';
export const BOTTOM = 'BOTTOM';
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const HORIZONTAL = 'HORIZONTAL';
export const VERTICAL = 'VERTICAL';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';
export const BOUNDS_DELEGATE_ATTRIBUTE = 'data-hs-experimental-bounds-delegate';
export const BOUNDS_DELEGATE_ATTRIBUTE_SELECTOR = `[${BOUNDS_DELEGATE_ATTRIBUTE}]`;
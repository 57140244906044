import { userInfoSync } from 'hub-http/userInfo';
import COMMENT_TYPES from 'collaboration-sidebar/constants/CommentTypes';
import { COMMENTABLE_AREA_IDENTIFIER, COMMENT_INDICATOR_IDENTIFIER } from 'collaboration-sidebar/constants/identifiers';
// Required to stop propogation when clicking a comment
// TODO: Can be removed by adding tracking of if a single
// commentable area is active or not.
export const isTargetCommentElement = target => {
  if (!target) {
    return false;
  }

  // If the click is in the main commentable area
  if (target.className.includes(COMMENTABLE_AREA_IDENTIFIER)) {
    return true;
  }

  // If the click has no parent we can't check anything
  // else if it's a commentable area
  if (!target.parentElement) {
    return false;
  }

  // If the parent is the comment indicator icon, capture it as a click.
  return target.parentElement.className.includes(COMMENT_INDICATOR_IDENTIFIER) || target.parentElement.id === COMMENT_INDICATOR_IDENTIFIER;
};
export function isFromCurrentUser(comment) {
  return comment.userId === userInfoSync().user.user_id;
}
export function getTimeSincePreviousCommentIfFromDifferentUser(comments) {
  const previousComment = comments.slice().reverse().find(comment => comment.type !== COMMENT_TYPES.REPLY);
  if (!previousComment || isFromCurrentUser(previousComment)) {
    return null;
  }
  return Date.now() - Date.parse(previousComment.createdAt);
}
export function mapCommentResolveStatusToType(commentResolvedStatus) {
  if (commentResolvedStatus === true) {
    return 'resolved';
  }
  return 'unresolved';
}
export function createModuleRepliesMap(unreadComments) {
  return unreadComments.reduce((repliesMap, comment) => {
    const parentId = comment.parentId;
    const isUnreadReply = comment.type === COMMENT_TYPES.REPLY && !!parentId;
    if (!isUnreadReply) {
      return repliesMap;
    }
    if (repliesMap[parentId]) {
      repliesMap[parentId].push(comment);
    } else {
      repliesMap[parentId] = [comment];
    }
    return repliesMap;
  }, {});
}
export const HUBSPOT = 'HUBSPOT';
export const TICKET_FORM = 'TICKET_FORM';
export const BLOG_COMMENT = 'BLOG_COMMENT';
export const VIDEO_FORM = 'VIDEO_FORM';
export const IDS_BY_TYPE = Object.freeze({
  HUBSPOT: 0,
  FLOW: 1,
  CAPTURED: 2,
  FACEBOOK_LEAD_AD: 3,
  BLOG_COMMENT: 4,
  MEETING: 5,
  FEEDBACK: 6,
  TICKET_FORM: 7,
  DOCUMENT: 8,
  VIDEO_FORM: 9,
  QUESTIONNAIRE: 10,
  SURVEY: 11,
  PAYMENT: 12
});
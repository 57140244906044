import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const portalId = PortalIdParser.get();
const ATTACHED_CONTENT_PATH = 'content-clusters/v1/attachedContent';
export const postAttachedContent = (contentId, clusterId, topicId) => {
  return http.post(ATTACHED_CONTENT_PATH, {
    data: [{
      contentId,
      clusterId,
      topicId,
      portalId
    }]
  });
};
export const deleteAttachedContent = ({
  attachedContentId
}) => {
  return http.delete(`${ATTACHED_CONTENT_PATH}/${attachedContentId}`);
};
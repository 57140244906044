import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import http from 'hub-http/clients/apiClient';
import { getActiveDomainName, getActiveApexDomainName
// @ts-expect-error Untyped module
} from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import Categories from 'ContentUtils/constants/Categories';
const fetchPrimaryDomains = () => {
  return http.get('cos-domains/v1/domains').then(({
    objects
  }) => objects);
};
const useDomainFilterForContentManagementPanel = ({
  isContentTypeFilterSameAsEditedContentType,
  currentContentTypeId
}) => {
  const [contentTypeIdToPrimaryDomainMap, setContentTypeIdToPrimaryDomainMap] = useState({});
  const [domainsRequestStatus, setDomainsRequestStatus] = useState('UNINITIALIZED');
  const domainName = useSelector(getActiveDomainName);
  const apexDomainName = useSelector(getActiveApexDomainName);
  const isCurrentlyEditedContentOnSubdomain = domainName !== apexDomainName;
  useEffect(() => {
    if (isCurrentlyEditedContentOnSubdomain && domainsRequestStatus === 'UNINITIALIZED') {
      setDomainsRequestStatus('PENDING');
      fetchPrimaryDomains().then(domains => {
        const domainsMap = domains.reduce((acc, domain) => {
          const {
            domain: primaryDomainName
          } = domain;
          if (domain.primaryBlogPost) {
            acc[Categories.blog_post.id] = primaryDomainName;
            acc[Categories.blog_listing_page.id] = primaryDomainName;
          } else if (domain.primarySitePage) {
            acc[Categories.site_page.id] = primaryDomainName;
          } else if (domain.primaryLandingPage) {
            acc[Categories.landing_page.id] = primaryDomainName;
          }
          return acc;
        }, {
          [Categories.blog_post.id]: '',
          [Categories.blog_listing_page.id]: '',
          [Categories.site_page.id]: '',
          [Categories.landing_page.id]: ''
        });
        setContentTypeIdToPrimaryDomainMap(domainsMap);
        setDomainsRequestStatus('SUCCEEDED');
      }).catch(() => setDomainsRequestStatus('FAILED'));
    }
  }, [isCurrentlyEditedContentOnSubdomain, currentContentTypeId, domainsRequestStatus, domainName]);

  // Use the content type specific primary domain as the domain filter if the
  // currently edited page/post is on a subdomain AND the content type of
  // the edited page/post is different from the selected content type filter.
  // This is to prevent an empty state in the case when the customer has
  // content type specific subdomains configured. Otherwise,
  // default to using the domain of the currently edited content.
  if (!isContentTypeFilterSameAsEditedContentType && isCurrentlyEditedContentOnSubdomain && contentTypeIdToPrimaryDomainMap[currentContentTypeId]) {
    return contentTypeIdToPrimaryDomainMap[currentContentTypeId];
  }
  return domainName;
};
export default useDomainFilterForContentManagementPanel;
'use es6';

import Categories from 'ContentUtils/constants/Categories';
import Routes from 'ContentUtils/Routes';
import { createSelector } from 'reselect';
import { currentProject } from 'hubspot.bender';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getIsEmail, getIsBlogPost, getIsPage, getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContent } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
export const getBaseEditorUrlForCurrentType = createSelector([getContentModelId, getCategoryId], (contentModelId, contentCategory) => {
  return Routes.inpageEdit(contentModelId, contentCategory);
});
export const getIsAtCorrectUrlForContentType = createSelector([getIsEmail, getIsBlogPost, getIsPage], (isEmail, isBlogPost, isPage) => {
  // If we're on a scalable editor ignore redirect URLs
  if (EditorConfigSingleton.getIsOnScalableEditor()) {
    return true;
  }
  if (isEmail && currentProject !== 'EmailDragDropEditorUI') {
    return false;
  } else if (isBlogPost && currentProject !== 'blog-editor-ui') {
    return false;
  } else if (isPage && currentProject !== 'ContentEditorUI' && currentProject !== 'page-editor-ui') {
    return false;
  }
  return true;
});
export const getExitEmailEditorUrl = createSelector([getContent], ({
  id,
  folderId
}) => {
  const emailDashboardUrl = Routes.emailDashboard();
  const emailDetailsUrl = Routes.details(id, Categories.email.id);
  const emailFolderUrl = folderId && Routes.emailFolder(folderId);
  if (document.referrer) {
    try {
      const referrer = new URL(document.referrer);
      if (referrer.pathname === emailFolderUrl) {
        return emailFolderUrl;
      }
      if (referrer.pathname === emailDetailsUrl) {
        return emailDetailsUrl;
      }
    } catch (_) {
      return emailDashboardUrl;
    }
  }
  return emailDashboardUrl;
});
import { CompletionStatuses } from '../../../constants';
import { getMetaDescriptionFromArtifacts } from '../../utils/metaTags';
export const addMetaDescriptionAudit = artifacts => {
  const metaDescription = getMetaDescriptionFromArtifacts(artifacts);
  let status;
  if (!metaDescription) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }
  return {
    status
  };
};
'use es6';

import { getPreventUnsavedChangesWarning, getHasUnsavedChanges, getIsSavePermanentlyDisabled } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import I18n from 'I18n';
import { getIsDraft } from 'ContentEditorUI/redux/selectors/publishSelectors';
import contentEditLocalStorage from 'ContentEditorUI/utils/contentEditLocalStorage';
export default function setupBeforeUnloadCallback(saveAction) {
  if (contentEditLocalStorage('unloadWarningEnabled') === false) {
    return;
  }
  window.onbeforeunload = e => {
    const state = window.hubspot.ContentEditorUI.store.getState();
    const dispatch = window.hubspot.ContentEditorUI.store.dispatch;
    if (!getPreventUnsavedChangesWarning(state) && !getIsSavePermanentlyDisabled(state) && getHasUnsavedChanges(state)) {
      // we want to hard save the content when the tab is closed because we cannot know which key is been updated
      // and if we need to hardsave, We want to avoid that for published content though as it will change the published
      // version
      dispatch(saveAction({
        buffer: !getIsDraft(state)
      }));
      const text = 'editor.messages.unsavedChanges';
      e.returnValue = I18n.text(text);
      return I18n.text(text);
    }
    return undefined;
  };
}
import { ApprovalOperation } from '../types/ApprovalOperation';
export const maxNumberOfApprovers = 10;
export const shouldPrimaryButtonBeDisabled = (approvalOperation, error, note, approversCount) => {
  if (error !== null) {
    return true;
  }
  switch (approvalOperation) {
    case ApprovalOperation.REQUEST:
      return !approversCount || approversCount > maxNumberOfApprovers;
    case ApprovalOperation.RECALL:
      return !note;
    case ApprovalOperation.REJECT:
      return !note;
    case ApprovalOperation.APPROVE:
    default:
      return false;
  }
};
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["folderId", "folderPath", "fileName"];
/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
/* eslint-disable hubspot-dev/no-async-await */

import { List, Map as ImmutableMap, fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import getApiUrl from '../utils/getApiUrl';
import { ObjectCategory } from '../Constants';
import { FileAccessValues } from '../enums/InternalFileManagerFileAccess';
const BASE_URI = 'filemanager/api/v2/files';
const BASE_V3_URI = 'filemanager/api/v3/files';
const ATTACHMENT_QUERY_PARAM = {
  'response-content-disposition': 'attachment'
};
export const buildFileFromAttrs = attrs => {
  attrs.category = ObjectCategory.FILE;
  return fromJS(attrs);
};
const buildFilesPayload = resp => ImmutableMap(Object.assign({}, resp, {
  objects: List(resp.objects.map(buildFileFromAttrs))
}));
export function archiveFile(fileId) {
  const URI = `${BASE_URI}/${fileId}/archive`;
  return http.post(URI, {
    data: {
      archived: true
    }
  }).then(buildFileFromAttrs);
}
export function unarchiveFile(fileId) {
  const URI = `${BASE_URI}/${fileId}/archive`;
  return http.post(URI, {
    data: {
      archived: false
    }
  }).then(buildFileFromAttrs);
}
export async function fetchSingleFile(fileId) {
  return http.get(`${BASE_V3_URI}/${fileId}`).then(buildFileFromAttrs);
}
export async function move(fileId, options) {
  const URI = `${BASE_URI}/${fileId}/move-file`;
  return http.post(URI, {
    data: options
  }).then(buildFileFromAttrs);
}
const sanitizeFileName = fileName => fileName.replace(/\./gm, '-');
const COMMON_FILE_UPLOAD_PARAMS = {
  timeout: 0,
  headers: {
    'content-type': false
  }
};
export const uploadFileV3 = (file, uploadedFileAccess, fileUploadOptions = {}, {
  updateProgress,
  xhrCallback,
  httpClient = http
} = {}) => {
  if (!file) {
    throw new Error('`file` arg is required and must be a local File');
  }
  if (!Object.keys(FileAccessValues).includes(uploadedFileAccess)) {
    throw new Error('[FileManagerCore/uploadFile(V3)] Missing or invalid uploadedFileAccess param. Visit https://product.hubteam.com/docs/file-manager-manual/Frontend/index.html for details.');
  }
  const {
      folderId,
      folderPath,
      fileName
    } = fileUploadOptions,
    extraOptions = _objectWithoutPropertiesLoose(fileUploadOptions, _excluded);
  extraOptions.access = uploadedFileAccess;
  let preNormalizedFileName;
  if (fileName) {
    preNormalizedFileName = fileName;
  } else if (file instanceof File && file.name) {
    preNormalizedFileName = file.name;
  }
  const formData = new FormData();
  if (preNormalizedFileName && !fileName) {
    const normalizedFileName = preNormalizedFileName.normalize('NFKC');
    formData.append('file', file, normalizedFileName);
  } else {
    formData.append('file', file);
  }
  if (folderId) {
    formData.append('folderId', folderId.toString());
  }
  if (folderPath) {
    formData.append('folderPath', folderPath);
  }
  if (fileName) {
    formData.append('fileName', fileUploadOptions.preserveExtension ? fileName : sanitizeFileName(fileName));
  }
  formData.append('options', JSON.stringify(extraOptions));
  return httpClient.post(`${BASE_V3_URI}/upload`, Object.assign({}, COMMON_FILE_UPLOAD_PARAMS, {
    data: formData,
    withXhr: xhr => {
      xhr.upload.addEventListener('progress', updateProgress, false);
      if (xhrCallback) {
        xhrCallback(xhr);
      }
    }
  })).then(buildFilesPayload);
};
export const uploadFile = uploadFileV3;
export const replaceFile = async (fileId, file, uploadedFileAccess, {
  updateProgress
} = {}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('options', JSON.stringify({
    access: uploadedFileAccess
  }));
  return http.post(`${BASE_V3_URI}/${fileId}/replace`, Object.assign({}, COMMON_FILE_UPLOAD_PARAMS, {
    data: formData,
    withXhr: xhr => {
      xhr.upload.addEventListener('progress', updateProgress, false);
    }
  })).then(buildFilesPayload);
};
export async function uploadResizedImage(fileId, newWidth) {
  return http.post(`${BASE_URI}/${fileId}/resize-image`, {
    timeout: 0,
    query: {
      width: newWidth
    }
  }).then(buildFileFromAttrs);
}
export async function recalculateImageDimensions(fileId) {
  return http.post(`${BASE_V3_URI}/${fileId}/recalculate-image-dimensions`, {
    timeout: 5000
  }).then(buildFileFromAttrs);
}
export async function exportFiles(emailAddress, filesAndFolders = {}) {
  const uri = `${BASE_URI}/export-all-files`;
  return http.post(uri, {
    data: Object.assign({
      email_address: emailAddress
    }, filesAndFolders)
  }).then(fromJS);
}
export async function downloadFromUrl(fileDownloadOptions = {}, url, uploadedFileAccess) {
  if (!Object.keys(FileAccessValues).includes(uploadedFileAccess)) {
    throw new Error('[FileManagerCore/downloadFromUrl] Missing or invalid uploadedFileAccess param. Visit https://product.hubteam.com/docs/file-manager-manual/Frontend/index.html for details.');
  }
  return http.post(`${BASE_V3_URI}/synchronous-download-from-url`, {
    timeout: 60000,
    data: Object.assign({}, fileDownloadOptions, {
      access: uploadedFileAccess,
      url
    })
  }).then(buildFileFromAttrs);
}
export function remove(fileId) {
  return http.delete(`${BASE_URI}/${fileId}`);
}
export function getDownloadUrl(path, version) {
  return getApiUrl(`${BASE_URI}/download/${path}`, {
    version
  });
}
export async function removeCanvaId(fileId) {
  return http.delete(`${BASE_V3_URI}/${fileId}/canva`).then(buildFileFromAttrs);
}
export async function setCanvaId(fileId, canvaId) {
  return http.put(`${BASE_V3_URI}/${fileId}/canva`, {
    data: {
      canvaId
    }
  }).then(buildFileFromAttrs);
}
export function getSignedUrl(fileId, query = {}) {
  return http.get(`${BASE_URI}/${fileId}/signed-url`, {
    query
  });
}
export function getDownloadableSignedUrl(fileId) {
  return getSignedUrl(fileId, ATTACHMENT_QUERY_PARAM);
}
export function getSignedUrlRedirectViewUrl(fileId) {
  return getApiUrl(`${BASE_URI}/${fileId}/signed-url-redirect`);
}
export function getSignedUrlRedirectDownloadUrl(fileId) {
  return getApiUrl(`${BASE_URI}/${fileId}/signed-url-redirect`, ATTACHMENT_QUERY_PARAM);
}
import { createSelector } from 'reselect';
import { AccountVerificationStatuses } from 'ContentEditorUI/constants/AccountVerificationStatuses';
import { basicSelector } from './helpers';
const getAccountVerificationStatusData = basicSelector(state => state.accountVerificationStatus);
export const getIsAccountWriteAccessSuspended = createSelector([getAccountVerificationStatusData], status => status.suspended);
const getAccountAppealStatus = createSelector([getAccountVerificationStatusData], status => status.appealState);
const getAccountCanAppealSuspension = createSelector([getAccountVerificationStatusData], status => status.canAppeal);
export const getAccountVerificationStatus = createSelector([getIsAccountWriteAccessSuspended, getAccountAppealStatus, getAccountCanAppealSuspension], (isSuspended, appealStatus, canAppeal) => {
  if (!isSuspended) {
    return AccountVerificationStatuses.NOT_SUSPENDED;
  }
  if (canAppeal) {
    if (appealStatus === 'UNINITIATED') {
      return AccountVerificationStatuses.SUSPENDED;
    } else if (appealStatus === 'INITIATED') {
      return AccountVerificationStatuses.APPEALED;
    }
  }
  return AccountVerificationStatuses.BLOCKED;
});
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getPortalState = basicSelector(state => state.portal);
export const getPortalScopes = createSelector([getPortalState], portalState => portalState.scopes);
export const getPortalInfo = createSelector([getPortalState], portalState => portalState.portalInfo);
export const getPortalCreatedDate = createSelector([getPortalInfo], portalInfo => portalInfo.createdAt);
export const getIsSandboxPortal = createSelector([getPortalInfo], portalInfo => portalInfo.hubType === 11);
const createHasPortalScopeSelector = scope => createSelector([getPortalScopes], portalScopes => portalScopes.includes(scope));
export const getPortalHasABTesting = createHasPortalScopeSelector('content-ab-testing');
export const getPortalHasDesignManager = createHasPortalScopeSelector('design-manager-access');
export const getPortalHasMeetingsEmbed = createHasPortalScopeSelector('sales-meetings-access');
export const getPortalHasSitePagesCustomDomainAccess = createHasPortalScopeSelector('sitepages-custom-domain-access');
export const getPortalHasBlogCustomDomainAccess = createHasPortalScopeSelector('blog-custom-domain-access');
export const getPortalHasSitePagesHsSitesDomainAccess = createHasPortalScopeSelector('sitepages-hs-sites-domain-write');
export const getPortalHasBlogHsSitesDomainAccess = createHasPortalScopeSelector('blog-hs-sites-domain-write');
const getMultiDomainPublishingScopeChangeInfo = createSelector([getPortalState], portalState => portalState && portalState.multiDomainPublishingScopeChangeInfo || {});
const getMultiDomainPublishingScopeStatus = createSelector([getMultiDomainPublishingScopeChangeInfo], scopeChangeInfo => scopeChangeInfo.status);
export const getMultiDomainPublishingScopePurgeTime = createSelector([getMultiDomainPublishingScopeChangeInfo], scopeChangeInfo => scopeChangeInfo.purgedTime);
export const getAllowDomainChangeForNinetyDaysAfterDowngrade = createSelector([getMultiDomainPublishingScopeStatus], scopeStatus => scopeStatus === 'TO_BE_PURGED');
export const getCustomDomainsPurgeDate = createSelector([getPortalState], portalState => portalState.customDomainsPurgeDateForFreeLP);
export const getSitePagesPasswordProtectionPurgeDate = createSelector([getPortalState], portalState => portalState.sitePagesPasswordProtectionPurgeDate);
export const getLandingPagesPasswordProtectionPurgeDate = createSelector([getPortalState], portalState => portalState.landingPagesPasswordProtectionPurgeDate);
export const getAudienceAccessPurgeDate = createSelector([getPortalState], portalState => portalState.audienceAccessPurgeDate);
export const getDynamicPageAccessPurgeDate = createSelector([getPortalState], portalState => portalState.dynamicPageAccessPurgeDate);
export const getHasLandingPagesAutoTranslateAccess = createHasPortalScopeSelector('landingpages-auto-translate');
export const getHasSitePagesAutoTranslateAccess = createHasPortalScopeSelector('sitepages-auto-translate');
export const getHasBlogPostsAutoTranslateAccess = createHasPortalScopeSelector('blog-posts-auto-translate');
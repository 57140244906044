import { useEffect, useState } from 'react';
import usePrevious from './usePrevious';
import { UsageTracker } from 'collaboration-sidebar/utils/usageTracker';
export default function useTrackLiveRefresh({
  unreadCommentsCount: count,
  unreadCommentsLoading: loading
}) {
  const [hasLoadedInitialCount, setHasLoadedInitialCount] = useState(false);
  const fetchedCount = hasLoadedInitialCount ? count : null;
  const prevFetchedCount = usePrevious(fetchedCount);
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (prevLoading && !loading) {
      setHasLoadedInitialCount(true);
    }
  }, [prevLoading, loading]);
  useEffect(() => {
    if (typeof fetchedCount === 'number' && typeof prevFetchedCount === 'number' && fetchedCount > prevFetchedCount) {
      UsageTracker.track('collabSidebarInteraction', {
        action: 'New comments while viewing asset'
      });
    }
  }, [fetchedCount, prevFetchedCount]);
}
/* Imports for resources */

import { getForm, getFormFetchStatus } from 'ContentData/selectors/forms';
import { fetchForm } from 'ContentData/actions/Forms';
import { getPage, getPageFetchStatus } from 'ContentData/selectors/pages';
import { fetchPage } from 'ContentData/actions/Pages';
import { getEmail, getEmailFetchStatus } from 'ContentData/selectors/emails';
import { fetchEmail } from 'ContentData/actions/Emails';
import { getMenu, getMenuFetchStatus } from 'ContentData/selectors/menus';
import { fetchMenu } from 'ContentData/actions/Menus';
import { getTag, getTagFetchStatus } from 'ContentData/selectors/tags';
import { fetchTag } from 'ContentData/actions/Tags';
import { getBlog, getBlogFetchStatus } from 'ContentData/selectors/blogs';
import { fetchBlog } from 'ContentData/actions/Blogs';
import { getCta, getCtaFetchStatus } from 'ContentData/selectors/ctas';
import { fetchCta } from 'ContentData/actions/Ctas';
import { getIcon, getIconFetchStatus } from 'ContentData/selectors/Icons';
import { fetchIcon } from 'ContentData/actions/Icons';
import { getHubDbRowFetchStatus, getHubDbRowLabel, getHubDbTable, getHubDbTableFetchStatus, getIsUnpublished } from 'ContentData/selectors/hubDb';
import { fetchHubDbRow, fetchHubDbTable } from 'ContentData/actions/HubDb';
import { formatRowLabel } from 'ContentComponents/cmv2/hubDb/HubDbHelpers';
import { getWorkflow, getWorkflowFetchStatus } from 'ContentData/selectors/workflows';
import { fetchWorkflow } from 'ContentData/actions/Workflows';
import { getMeeting, getMeetingFetchStatus } from 'ContentData/selectors/meetings';
import { fetchMeeting } from 'ContentData/actions/Meetings';
import { getDefaultMeetingName } from 'ContentUtils/js/helpers/meetingsName';
import { getCampaign, getCampaignFetchStatus } from 'ContentData/selectors/salesforce';
import { fetchCampaign } from 'ContentData/actions/Salesforce';
import { getSurvey, getSurveyFetchStatus } from 'ContentData/selectors/surveys';
import { fetchSurvey } from 'ContentData/actions/Surveys';
import { getVideo, getVideoFetchStatus } from 'ContentData/selectors/videos';
import { fetchVideoByPlayerId } from 'ContentData/actions/Videos';
import { getCrmObject, getCrmObjectFetchStatus, getCrmObjectPropertyLabel, getCrmObjectSchema, getCrmObjectSchemaFetchStatus } from 'ContentData/selectors/crmObjects';
import { fetchCrmObjectSchema, fetchCrmObject } from 'ContentData/actions/CrmObjects';
import { getCrmObjectLabel } from 'ContentData/helpers/crmObjectHelpers';
import { getPayment, getPaymentFetchStatus } from 'ContentData/selectors/payments';
import { fetchPayment } from 'ContentData/actions/Payments';
import UrlTypes from 'ContentUtils/enums/UrlTypes';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
/* End imports */

export const RESOURCES_TO_FETCH = Object.freeze({
  [FieldTypes.BLOG]: FieldTypes.BLOG,
  [FieldTypes.CRM_OBJECT]: FieldTypes.CRM_OBJECT,
  [FieldTypes.CRM_OBJECT_PROPERTY]: FieldTypes.CRM_OBJECT_PROPERTY,
  [FieldTypes.CTA]: FieldTypes.CTA,
  [FieldTypes.FOLLOW_UP_EMAIL]: FieldTypes.FOLLOW_UP_EMAIL,
  [FieldTypes.FORM]: FieldTypes.FORM,
  [FieldTypes.HUBDB_ROW]: FieldTypes.HUBDB_ROW,
  [FieldTypes.HUBDB_TABLE]: FieldTypes.HUBDB_TABLE,
  [FieldTypes.ICON]: FieldTypes.ICON,
  [FieldTypes.LINK]: FieldTypes.LINK,
  [FieldTypes.MEETING]: FieldTypes.MEETING,
  [FieldTypes.MENU]: FieldTypes.MENU,
  [FieldTypes.PAGE]: FieldTypes.PAGE,
  [FieldTypes.PAYMENT]: FieldTypes.PAYMENT,
  [FieldTypes.SFDC_CAMPAIGN]: FieldTypes.SFDC_CAMPAIGN,
  [FieldTypes.SURVEY]: FieldTypes.SURVEY,
  [FieldTypes.TAG]: FieldTypes.TAG,
  [FieldTypes.URL]: FieldTypes.URL,
  [FieldTypes.VIDEO]: FieldTypes.VIDEO,
  [FieldTypes.WORKFLOW]: FieldTypes.WORKFLOW
});
export const RESOURCE_GETTERS = {
  [FieldTypes.FORM]: (state, id) => getForm(state, {
    formGuid: id
  }),
  [FieldTypes.PAGE]: (state, id) => getPage(state, {
    id
  }),
  [FieldTypes.FOLLOW_UP_EMAIL]: (state, id) => getEmail(state, {
    id
  }),
  [FieldTypes.MENU]: (state, id) => getMenu(state, {
    id
  }),
  [FieldTypes.TAG]: (state, {
    value,
    tagValue
  }) => getTag(state, {
    value,
    tagValue
  }),
  [FieldTypes.BLOG]: (state, id) => getBlog(state, {
    id
  }),
  [FieldTypes.CTA]: (state, guid) => getCta(state, {
    guid
  }),
  [FieldTypes.ICON]: (state, {
    name
  }) => getIcon(state, {
    name
  }),
  [FieldTypes.HUBDB_TABLE]: (state, id) => getHubDbTable(state, {
    id
  }),
  [FieldTypes.WORKFLOW]: (state, id) => getWorkflow(state, {
    id
  }),
  [FieldTypes.MEETING]: (state, link) => {
    const meeting = getMeeting(state, {
      link
    });
    return meeting && {
      label: getDefaultMeetingName(meeting)
    };
  },
  [FieldTypes.SFDC_CAMPAIGN]: (state, id) => getCampaign(state, {
    id
  }),
  [FieldTypes.SURVEY]: (state, {
    id,
    surveyType
  }) => getSurvey(state, {
    id,
    surveyType
  }),
  [FieldTypes.VIDEO]: (state, playerId) => getVideo(state, {
    playerId
  }),
  [FieldTypes.URL]: (state, value) => {
    const {
      type,
      content_id
    } = value || {};
    if (type === UrlTypes.BLOG) {
      return getBlog(state, {
        id: content_id
      });
    }
    if (type === UrlTypes.CONTENT) {
      return getPage(state, {
        id: content_id
      });
    }
    return null;
  },
  [FieldTypes.LINK]: (state, value) => {
    const {
      type,
      content_id
    } = value && value.url ? value.url : {};
    if (type === UrlTypes.BLOG) {
      return getBlog(state, {
        id: content_id
      });
    }
    if (type === UrlTypes.CONTENT) {
      return getPage(state, {
        id: content_id
      });
    }
    return null;
  },
  [FieldTypes.CRM_OBJECT]: (state, value) => {
    const {
      display_format: displayFormat,
      display_properties: displayProperties,
      id,
      object_type: objectType
    } = value || {};
    const crmObject = getCrmObject(state, {
      id,
      objectType
    });
    const crmObjectSchema = getCrmObjectSchema(state, {
      objectType
    });
    return crmObject && {
      label: getCrmObjectLabel(crmObject, crmObjectSchema, {
        displayFormat,
        displayProperties
      })
    };
  },
  [FieldTypes.CRM_OBJECT_PROPERTY]: (state, value) => {
    const {
      property,
      object_type: objectType
    } = value || {};
    const crmObjectSchema = getCrmObjectSchema(state, {
      objectType
    });
    return crmObjectSchema && property && {
      label: getCrmObjectPropertyLabel(state, {
        objectType,
        property
      })
    };
  },
  [FieldTypes.HUBDB_ROW]: (state, value) => {
    const tableId = 'id';
    const option = getHubDbRowLabel(state, {
      displayColumns: value.displayColumns,
      displayFormat: value.displayFormat,
      [tableId]: value[tableId],
      rowId: value.rowId
    });
    const label = option && formatRowLabel(option);
    return label && label.length && {
      label
    };
  },
  [FieldTypes.PAYMENT]: (state, id) => getPayment(state, {
    id
  })
};
export const FETCH_STATUS_GETTERS = {
  [FieldTypes.FORM]: (state, id) => getFormFetchStatus(state, {
    formGuid: id
  }),
  [FieldTypes.PAGE]: (state, id) => getPageFetchStatus(state, {
    id
  }),
  [FieldTypes.FOLLOW_UP_EMAIL]: (state, id) => getEmailFetchStatus(state, {
    id
  }),
  [FieldTypes.MENU]: (state, id) => getMenuFetchStatus(state, {
    id
  }),
  [FieldTypes.TAG]: (state, {
    value
  }) => getTagFetchStatus(state, {
    key: value
  }),
  [FieldTypes.BLOG]: (state, id) => getBlogFetchStatus(state, {
    id
  }),
  [FieldTypes.CTA]: (state, guid) => getCtaFetchStatus(state, {
    guid
  }),
  [FieldTypes.ICON]: (state, {
    name
  }) => getIconFetchStatus(state, {
    name
  }),
  [FieldTypes.HUBDB_TABLE]: (state, id) => getHubDbTableFetchStatus(state, {
    id
  }),
  [FieldTypes.WORKFLOW]: (state, id) => getWorkflowFetchStatus(state, {
    id
  }),
  [FieldTypes.MEETING]: (state, link) => getMeetingFetchStatus(state, {
    link
  }),
  [FieldTypes.SFDC_CAMPAIGN]: (state, id) => getCampaignFetchStatus(state, {
    id
  }),
  [FieldTypes.SURVEY]: (state, {
    id,
    surveyType
  }) => getSurveyFetchStatus(state, {
    id,
    surveyType
  }),
  [FieldTypes.VIDEO]: (state, playerId) => getVideoFetchStatus(state, {
    playerId
  }),
  [FieldTypes.URL]: (state, value) => {
    const {
      type,
      content_id
    } = value || {};
    if (type === UrlTypes.BLOG) {
      return getBlogFetchStatus(state, {
        id: content_id
      });
    }
    if (type === UrlTypes.CONTENT) {
      return getPageFetchStatus(state, {
        id: content_id
      });
    }
    return null;
  },
  [FieldTypes.LINK]: (state, value) => {
    const {
      type,
      content_id
    } = value && value.url ? value.url : {};
    if (type === UrlTypes.BLOG) {
      return getBlogFetchStatus(state, {
        id: content_id
      });
    }
    if (type === UrlTypes.CONTENT) {
      return getPageFetchStatus(state, {
        id: content_id
      });
    }
    return null;
  },
  [FieldTypes.CRM_OBJECT]: (state, value) => {
    const {
      id,
      object_type: objectType
    } = value || {};
    return getCrmObjectFetchStatus(state, {
      id,
      objectType
    });
  },
  [FieldTypes.CRM_OBJECT_PROPERTY]: (state, value) => {
    const {
      object_type: objectType
    } = value || {};
    return getCrmObjectSchemaFetchStatus(state, {
      objectType
    });
  },
  [FieldTypes.HUBDB_ROW]: (state, value) => {
    const {
      rowId
    } = value || {};
    return getHubDbRowFetchStatus(state, {
      id: rowId
    });
  },
  [FieldTypes.PAYMENT]: (state, id) => getPaymentFetchStatus(state, {
    id
  })
};
export const RESOURCE_FETCHERS = {
  [FieldTypes.FORM]: fetchForm,
  [FieldTypes.PAGE]: fetchPage,
  [FieldTypes.FOLLOW_UP_EMAIL]: fetchEmail,
  [FieldTypes.MENU]: fetchMenu,
  [FieldTypes.TAG]: ({
    value,
    tagValue
  }) => fetchTag(value, tagValue),
  [FieldTypes.BLOG]: fetchBlog,
  [FieldTypes.CTA]: fetchCta,
  [FieldTypes.ICON]: icon => {
    const {
      name,
      icon_set
    } = icon || {};
    return fetchIcon(icon_set, {
      search: name
    });
  },
  [FieldTypes.HUBDB_TABLE]: fetchHubDbTable,
  [FieldTypes.WORKFLOW]: fetchWorkflow,
  [FieldTypes.MEETING]: fetchMeeting,
  [FieldTypes.SFDC_CAMPAIGN]: fetchCampaign,
  [FieldTypes.SURVEY]: fetchSurvey,
  [FieldTypes.VIDEO]: fetchVideoByPlayerId,
  [FieldTypes.URL]: value => {
    const {
      type,
      content_id
    } = value || {};
    if (type === UrlTypes.BLOG) {
      return fetchBlog(content_id);
    }
    if (type === UrlTypes.CONTENT) {
      return fetchPage(content_id);
    }
    return () => {};
  },
  [FieldTypes.LINK]: value => {
    const {
      type,
      content_id
    } = value && value.url ? value.url : {};
    if (type === UrlTypes.BLOG) {
      return fetchBlog(content_id);
    }
    if (type === UrlTypes.CONTENT) {
      return fetchPage(content_id);
    }
    return () => {};
  },
  [FieldTypes.CRM_OBJECT]: value => {
    const {
      id,
      display_properties: displayProperties,
      object_type: objectType
    } = value || {};
    if (!objectType || !id) return () => {};
    return (dispatch, getState) => {
      const crmObjectSchema = getCrmObjectSchema(getState(), {
        objectType
      });
      if (crmObjectSchema) {
        return dispatch(fetchCrmObject(objectType, displayProperties, id));
      } else {
        dispatch(fetchCrmObjectSchema(objectType)).then(() => {
          dispatch(fetchCrmObject(objectType, displayProperties, id));
        }).catch(err => {
          setTimeout(() => {
            throw err;
          });
        });
        return undefined;
      }
    };
  },
  [FieldTypes.CRM_OBJECT_PROPERTY]: value => {
    const {
      object_type: objectType
    } = value || {};
    if (!objectType) return () => {};
    return (dispatch, getState) => {
      const crmObjectSchema = getCrmObjectSchema(getState(), {
        objectType
      });
      if (!crmObjectSchema) {
        return dispatch(fetchCrmObjectSchema(objectType));
      }
      return crmObjectSchema;
    };
  },
  [FieldTypes.HUBDB_ROW]: value => {
    const {
      rowId,
      id: tableId
    } = value || {};
    if (!tableId || !rowId) return () => {};
    return (dispatch, getState) => {
      const state = getState();
      const table = getHubDbTable(state, {
        id: tableId
      });
      const isUnpublished = getIsUnpublished(state, {
        id: tableId
      });
      if (table && !isUnpublished) {
        return dispatch(fetchHubDbRow({
          rowId,
          tableId
        }));
      }
      dispatch(fetchHubDbTable(tableId)).then(response => {
        // fetching by name uses endpoint that returns object with array of matching tables
        const _HubDbTable = isNaN(parseInt(tableId, 10)) ? response.objects[0] : response;
        const isValidTable = _HubDbTable && !getIsUnpublished(state, {
          id: _HubDbTable.id
        });
        if (isValidTable) {
          const hubDBTableId = _HubDbTable && _HubDbTable.id;
          dispatch(fetchHubDbRow({
            rowId,
            tableId: hubDBTableId
          }));
        }
      }).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
      return undefined;
    };
  },
  [FieldTypes.PAYMENT]: fetchPayment
};
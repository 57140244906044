export default Object.freeze({
  EXTERNAL: 'EXTERNAL',
  CONTENT: 'CONTENT',
  FILE: 'FILE',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  BLOG: 'BLOG',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  PHONE_NUMBER: 'PHONE_NUMBER',
  WHATSAPP_NUMBER: 'WHATSAPP_NUMBER',
  PAYMENT: 'PAYMENT'
});
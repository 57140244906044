export const getLanguageWithoutLocale = language => language ? language.split('-').at(0) : '';
const DEEPL_SUPPORTED_LANGUAGES = new Set(['ar', 'bg', 'zh', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'id', 'it', 'ja', 'ko', 'lv', 'lt', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'es', 'sv', 'tr', 'uk']);
const CHINESE_SIMPLIFIED_CODE = 'zh-cn';

// TODO: remove languageLocaleCode in favor of just using language, and make
// language a required prop. I'm having this be undefined so that this is
// backwards compatible until all the other PRs are merged.
export const checkIsLanguageSupportedByDeepL = ({
  languageLocaleCode,
  language
}) => {
  if (language) {
    const languageWithoutLocale = getLanguageWithoutLocale(language);
    return language === CHINESE_SIMPLIFIED_CODE ||
    // TODO: remove 'zh' from DEEPL_SUPPORTED_LANGUAGES
    languageWithoutLocale !== 'zh' && DEEPL_SUPPORTED_LANGUAGES.has(languageWithoutLocale);
  }
  return languageLocaleCode && DEEPL_SUPPORTED_LANGUAGES.has(languageLocaleCode);
};
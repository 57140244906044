import PortalIdParser from 'PortalIdParser';
import { parse, stringify } from 'hub-http/helpers/params';
import { ONBOARDING_TOUR_ID, TOUR_ID } from '../constants/URL';
import { getHublet, isHublet } from './hublet';

// Taken from https://stackoverflow.com/a/31432012
const RELATIVE_URL_REGEX = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
export const localOr = (overrideKey, subDomain = 'api') => {
  let result;
  try {
    if (window.localStorage[overrideKey] === 'local') {
      result = 'local';
    }
  } catch (error) {
    /* Noop */
  }
  if (!result) {
    result = subDomain;
  }
  if (isHublet()) {
    result = `${result}-${getHublet()}`;
  }
  return result;
};
export const isQA = () => {
  return window.location.host.indexOf('hubspotqa') > -1;
};
export const getBaseUrl = opts => {
  const {
    localOverride,
    subDomain,
    domain
  } = opts;
  const subDomainOverride = localOr(localOverride, subDomain);
  const domainOverride = domain || 'hubspot';
  return isQA() ? `https://${subDomainOverride}.${domainOverride}qa.com` : `https://${subDomainOverride}.${domainOverride}.com`;
};
const isRelativeUrl = url => {
  return RELATIVE_URL_REGEX.test(url);
};
const getUrlWithPortalId = url => {
  if (!url.includes('%portalId%')) {
    return url;
  }
  return url.replace(/%portalId%/g, String(PortalIdParser.get()));
};
export const parseReturnUrl = url => {
  if (!url) {
    return undefined;
  }
  if (!isRelativeUrl(url)) {
    return undefined;
  }
  return getUrlWithPortalId(url);
};
export const getQueryParam = key => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(key);
};
export const getTourIdFromQueryParams = () => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(TOUR_ID) || searchParams.get(ONBOARDING_TOUR_ID);
};
const replaceQueryParams = queryParams => {
  let search = '';
  // format query params object to query string
  const queryString = stringify(queryParams);
  if (queryString) {
    // Remove `=undefined` for query parameter without value
    // For example: foo=undefined&bar=1 will be foo&bar=1
    search = `?${queryString}`.replace(/=undefined(&|#|$)/, '$1');
  }
  // Use replaceState to change url which won't cause page re-rendering
  const {
    history,
    location
  } = window;
  history.replaceState(history.state, '', `${location.pathname}${search}`);
};
export const setQueryParams = queryParamsMap => {
  const queryParams = parse(window.location.search.slice(1));
  for (const [key, value] of Object.entries(queryParamsMap)) {
    queryParams[key] = value;
  }
  replaceQueryParams(queryParams);
};
export const removeQueryParams = queryParamKeys => {
  // Get query params object and remove params by argument
  let shouldUpdateUrl = false;
  const queryParams = parse(window.location.search.slice(1));
  queryParamKeys.forEach(queryParamKey => {
    if (queryParams[queryParamKey]) {
      shouldUpdateUrl = true;
      delete queryParams[queryParamKey];
    }
  });
  if (shouldUpdateUrl) {
    replaceQueryParams(queryParams);
  }
};
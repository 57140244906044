'use es6';

import { selectModuleForXray, openSettingsForXRay } from 'ContentEditorUI/redux/actions/xrayActions';
import { TITLE_OPTIMIZATION_TYPES, META_DESCRIPTION_OPTIMIZATION_TYPES } from 'ContentEditorUI/constants/OptimizationTypes';
import { getIsCustomCmv2Module, getIsDefaultModule, getIsGlobalModuleGroupOrPartial } from '../selectors/moduleSelectors';
import { openConfirmEditGlobal } from './appActions';
export const openImageModule = ({
  moduleId,
  globalResourcePath
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const isGlobalModuleGroupOrPartial = getIsGlobalModuleGroupOrPartial(state, globalResourcePath);
    const isDefaultModule = getIsDefaultModule(state, moduleId);
    const isCustomModule = getIsCustomCmv2Module(state, moduleId);
    if (isDefaultModule) {
      dispatch(selectModuleForXray(moduleId));
    } else if (isGlobalModuleGroupOrPartial) {
      dispatch(openConfirmEditGlobal(globalResourcePath));
    } else if (isCustomModule) {
      dispatch(selectModuleForXray(moduleId));
    }
  };
};
export const addPageTitle = () => {
  return dispatch => dispatch(openSettingsForXRay(TITLE_OPTIMIZATION_TYPES.ADD_TITLE));
};
export const shortenPageTitle = () => {
  return dispatch => dispatch(openSettingsForXRay(TITLE_OPTIMIZATION_TYPES.SHORTEN_TITLE));
};
export const removeDomainFromTitle = () => {
  return dispatch => dispatch(openSettingsForXRay(TITLE_OPTIMIZATION_TYPES.REMOVE_DOMAIN_FROM_TITLE));
};
export const addMetaDescription = () => {
  return dispatch => dispatch(openSettingsForXRay(META_DESCRIPTION_OPTIMIZATION_TYPES.ADD_META_DESCRIPTION));
};
export const removeTitleFromMetaDescription = () => {
  return dispatch => dispatch(openSettingsForXRay(META_DESCRIPTION_OPTIMIZATION_TYPES.REMOVE_TITLE_FROM_META_DESCRIPTION));
};
export const shortenMetaDescription = () => {
  return dispatch => dispatch(openSettingsForXRay(META_DESCRIPTION_OPTIMIZATION_TYPES.SHORTEN_META_DESCRIPTION));
};
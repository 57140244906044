/* eslint-disable prefer-rest-params */
// Trying out a vendored version of reselect 4.0.0 before creating a repackage
//
// ... though, is it even needed if there is not a signficant cost to the custom additions
// in custom-reselect?

/*
 *
 * Reselect Data Types
 *
 */

/** A standard selector function, which takes three generic type arguments:
 * @param State The first value, often a Redux root state object
 * @param Result The final result returned by the selector
 * @param Params All additional arguments passed into the selector
 */

/** Selectors generated by Reselect have several additional fields attached: */

/** Represents the actual selectors generated by `createSelector`.
 * The selector is:
 * - "a function that takes this state + params and returns a result"
 * - plus the attached additional fields
 */

/** A selector that is assumed to have one additional argument, such as
 * the props from a React component
 */

/** A generated selector that is assumed to have one additional argument */

/** An array of input selectors */

/** A standard function returning true if two values are considered equal */

/*
 *
 * Reselect Internal Types
 *
 */

/** Extracts an array of all return types from all input selectors */

/** Determines the combined single "State" type (first arg) from all input selectors */

/** Determines the combined  "Params" type (all remaining args) from all input selectors */

/*
 *
 * Reselect Internal Utility Types
 *
 */

/** Any function with arguments */

/** Extract the return type from all functions as a tuple */

/** First item in an array */

/** All other items in an array */

/** All elements in array except last
 *
 * Recursion makes this work also when rest syntax has been used
 * Runs _ReverseTail twice, because first pass turns last element into "never", and second pass removes it.
 **/

/*
 *
 * External/Copied Utility Types
 *
 */

/** The infamous "convert a union type to an intersection type" hack
 * Source: https://github.com/sindresorhus/type-fest/blob/main/source/union-to-intersection.d.ts
 * Reference: https://github.com/microsoft/TypeScript/issues/29594
 */

/**
 * Assorted util types for type-level conditional logic
 * Source: https://github.com/KiaraGrouwstra/typical
 */

/**
 * Code to convert a union of values into a tuple.
 * Source: https://stackoverflow.com/a/55128956/62937
 */

// TS4.1+

/**
 * Converts "the values of an object" into a tuple, like a type-level `Object.values()`
 * Source: https://stackoverflow.com/a/68695508/62937
 */

/** Utility type to infer the type of "all params of a function except the first", so we can determine what arguments a memoize function accepts */

/**
 * Expand an item a single level, or recursively.
 * Source: https://stackoverflow.com/a/69288824/62937
 */

/** Given a set of input selectors, extracts the intersected parameters to determine
 * what values can actually be passed to all of the input selectors at once
 * WARNING: "you are not expected to understand this" :)
 */

/*
 *
 * Reselect Internal Utility Types
 *
 */

/*
 *
 * Reselect Internal Utility Types
 *
 */

/** An object with no fields */

/** Extract the parameters from all functions as a tuple */

/** Recursively expand all fields in an object for easier reading */

/** Select the longer of two arrays */

/** Recurse over a nested array to locate the longest one.
 * Acts like a type-level `reduce()`
 */

/** Recursive type for intersecting together all items in a tuple, to determine
 *  the final parameter type at a given argument index in the generated selector. */

/** Intersect a pair of types together, for use in parameter type calculation.
 * This is made much more complex because we need to correctly handle cases
 * where a function has fewer parameters and the type is `undefined`, as well as
 * optional params or params that have `null` or `undefined` as part of a union.
 *
 * If the next type by itself is `null` or `undefined`, we exclude it and return
 * the other type. Otherwise, intersect them together.
 */

/*
 *
 * External/Copied Utility Types
 *
 */

/**
 * Removes field names from a tuple
 * Source: https://stackoverflow.com/a/63571175/62937
 */

/**
 * Transposes nested arrays
 * Source: https://stackoverflow.com/a/66303933/62937
 */

function defaultEqualityCheck(a, b) {
  return a === b;
}
function areArgumentsShallowlyEqual(equalityCheck, prev, next) {
  if (prev === null || next === null || prev.length !== next.length) {
    return false;
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  const length = prev.length;
  for (let i = 0; i < length; i++) {
    if (!equalityCheck(prev[i], next[i])) {
      return false;
    }
  }
  return true;
}
export function defaultMemoize(func, equalityCheck = defaultEqualityCheck) {
  let lastArgs = null;
  let lastResult = null;
  // we reference arguments instead of spreading them for performance reasons
  function memoized() {
    if (!areArgumentsShallowlyEqual(equalityCheck, lastArgs, arguments)) {
      // apply arguments instead of spreading for performance.
      // @ts-expect-error reselect ignores this
      lastResult = func.apply(null, arguments);
    }
    lastArgs = arguments;
    return lastResult;
  }
  return memoized;
}
function getDependencies(funcs) {
  const dependencies = Array.isArray(funcs[0]) ? funcs[0] : funcs;
  if (!dependencies.every(dep => typeof dep === 'function')) {
    const dependencyTypes = dependencies.map(dep => typeof dep).join(', ');
    throw new Error('Selector creators expect all input-selectors to be functions, ' + `instead received the following types: [${dependencyTypes}]`);
  }
  return dependencies;
}
export function createSelectorCreator(memoize, ...memoizeOptions) {
  const createSelector = (...funcs) => {
    let recomputations = 0;
    const resultFunc = funcs.pop();
    const dependencies = getDependencies(funcs);
    const memoizedResultFunc = memoize(function recomputationWrapper() {
      recomputations++;
      // apply arguments instead of spreading for performance.
      return resultFunc.apply(null, arguments);
    }, ...memoizeOptions);

    // If a selector is called with the exact same arguments we don't need to traverse our dependencies again.
    const selector = memoize(function dependenciesChecker() {
      const params = [];
      const length = dependencies.length;
      for (let i = 0; i < length; i++) {
        // apply arguments instead of spreading and mutate a local list of params for performance.
        params.push(dependencies[i].apply(null, arguments));
      }

      // apply arguments instead of spreading for performance.
      return memoizedResultFunc.apply(null, params);
    });
    Object.assign(selector, {
      resultFunc,
      memoizedResultFunc,
      dependencies,
      recomputations: () => recomputations,
      resetRecomputations: () => recomputations = 0
    });
    return selector;
  };
  // @ts-expect-error reselect ignores this
  return createSelector;
}
export const createSelector = /* #__PURE__ */createSelectorCreator(defaultMemoize);
export const createStructuredSelector = (selectors, selectorCreator = createSelector) => {
  if (typeof selectors !== 'object') {
    throw new Error('createStructuredSelector expects first argument to be an object ' + `where each property is a selector, instead received a ${typeof selectors}`);
  }
  const objectKeys = Object.keys(selectors);
  return selectorCreator(objectKeys.map(key => selectors[key]), (...values) => {
    return values.reduce((composition, value, index) => {
      composition[objectKeys[index]] = value;
      return composition;
    }, {});
  });
};
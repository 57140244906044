import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHasTheme, getThemePath } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { getThemeRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
// @ts-expect-error not typed yet
import { fetchThemeAndSettingsByPath } from 'ContentEditorUI/redux/actions/themeActions';
import { requestUninitialized } from 'redux-request-state/util/requestStateCheckers';
export default function useFetchTheme() {
  const hasTheme = useSelector(getHasTheme);
  const themeRequest = useSelector(getThemeRequest);
  const themePath = useSelector(getThemePath);
  const dispatch = useDispatch();
  useEffect(() => {
    if (hasTheme && requestUninitialized(themeRequest)) {
      dispatch(fetchThemeAndSettingsByPath(themePath));
    }
  }, [dispatch, hasTheme, themePath, themeRequest]);
}
'use es6';

import Raven from 'raven-js';
import { LOG_SENTRY_WITH_HISTORY, SNAPSHOT_STATE_IN_TIME } from 'ContentEditorUI/redux/actions/actionTypes';
import { is } from 'immutable';
import { calculateDiff } from 'ContentEditorUI/utils/diffTools';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
const calculateDiffsForActions = statesAndActions => {
  return statesAndActions.map(s => ({
    action: s.action,
    diff: calculateDiff(s.prevState, s.nextState)
  }));
};
export default function errorHandlingMiddleware(selectorOverState) {
  let lastActions = [];
  let lastStateSnapshot = null;
  return store => next => action => {
    if (!action || !action.type) {
      Raven.captureException(new Error('Missing action or action type'), {
        extra: {
          action
        }
      });
      return null;
    }
    switch (action.type) {
      case SNAPSHOT_STATE_IN_TIME:
        lastStateSnapshot = lastActions[lastActions.length - 1];
        return null;
      case LOG_SENTRY_WITH_HISTORY:
        {
          try {
            const recentDiffs = calculateDiffsForActions(lastActions);
            const lastStateSnapshotDiff = lastStateSnapshot && calculateDiffsForActions([lastStateSnapshot]);
            captureMessage(action.message, {
              extra: {
                recentDiffs,
                lastStateSnapshotDiff,
                extra: action.extra
              }
            });
            if (action.pageAction && window.newrelic) {
              window.newrelic.addPageAction(action.pageAction, {
                recentDiffs,
                lastStateSnapshotDiff,
                extra: action.extra
              });
            }
            return null;
          } catch (errorMessage) {
            console.warn(errorMessage);
            return null;
          }
        }
      default:
        break;
    }
    const prevState = selectorOverState ? selectorOverState(store.getState()) : store.getState();
    const result = next(action);
    const nextState = selectorOverState ? selectorOverState(store.getState()) : store.getState();
    if (lastActions.length === 5) {
      lastActions = lastActions.slice(1);
    }
    if (!is(prevState, nextState)) {
      lastActions.push({
        action,
        prevState,
        nextState,
        time: new Date().toTimeString()
      });
    }
    return result;
  };
}
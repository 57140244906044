import * as helpers from './common/helpers';
import * as schemas from './schemas';
import * as loggersInterface from './loggers';
import * as eventInterface from './event';
import * as identifiersInterface from './identifiers';
import * as dictionaryInterface from './dictionary';
export const createEventTracker = config => {
  const proxyLogger = helpers.proxyLogger({
    'tracker.client': config.clientName,
    'tracker.name': config.trackerName
  });
  const logDebug = loggersInterface.createDebugLogger(config.logMessage);
  const logError = loggersInterface.createErrorLogger(config.logError, proxyLogger(config.onError));
  const definitionStorage = dictionaryInterface.createDictionaryStorage(config.events);

  // Mutates the Default Tracker Properties so that their RESOLVED values
  // Are only allowed to be what they're defined to be when resolved
  // This includes `nullable` values for some. Some might also be optional fields
  const mutatedDefaultProperties = schemas.trackerPropertiesSchema.mutate(schema => ({
    email: Object.assign({}, schema.email, {
      types: ['string', 'null']
    }),
    hubId: Object.assign({}, schema.hubId, {
      types: ['number', 'null']
    }),
    hstc: Object.assign({}, schema.hstc, {
      types: ['string', 'null']
    }),
    lang: Object.assign({}, schema.lang, {
      types: ['string', 'null']
    }),
    deviceId: Object.assign({}, schema.deviceId, {
      types: ['string']
    })
  }));
  const isDebugEnabled = typeof config.debug === 'function' ? config.debug() : config.debug;
  const validateProperties = (eventKey, eventProperties) => {
    try {
      // This will derive a schema based on the types of the properties from the Event definition
      // and also add all unknown properties to be validated against all allowed Event Property types
      const propertySchema = definitionStorage.createPropertySchema(eventKey, eventProperties);

      // This mutates the definition schema + unknown properties merging the schema of default properties
      // So that the default properties are also validate against their own shchemas instead of the
      // mutation of "unknown properties" as "default properties" technically do not belong to an event definition
      propertySchema.mutate(schema => Object.assign({}, schema, mutatedDefaultProperties._peek())).validate(eventProperties, `Event "${eventKey}"`);
      return true;
    } catch (error) {
      logError(error, {
        extra: {
          eventKey,
          eventProperties: helpers.replaceSentryValues(eventProperties)
        },
        fingerprint: ['usage-tracker-js', 'tracker:validateProperties', `event:${eventKey}`]
      });
      return false;
    }
  };
  const getDefinition = eventKey => {
    try {
      return definitionStorage.getDefinition(eventKey);
    } catch (error) {
      logError(error, {
        extra: {
          eventKey
        },
        fingerprint: ['usage-tracker-js', 'tracker:getDefinition', `event:${eventKey}`]
      });
      return null;
    }
  };
  const getIdentifiers = (eventKey, eventProperties) => {
    const eventRawIdentifiers = {
      email: eventProperties.email,
      userId: eventProperties.userId,
      hubId: eventProperties.hubId,
      hstc: eventProperties.hstc,
      // If a `deviceId` property is manually provided (such as by the anonymous tracker)
      // then we are allowed to use the deviceId as an identifier. Since by default we do not
      // allow the internally generated `device_id` (metaProperties) to be used as an identifier
      deviceId: eventProperties.deviceId
    };
    const eventConfig = {
      allowUnauthed: config.allowUnauthed,
      isExternalHost: config.isExternalHost
    };
    try {
      return identifiersInterface.createIdentifiers(eventRawIdentifiers, eventConfig);
    } catch (error) {
      logError(error, {
        extra: Object.assign({
          eventRawIdentifiers: helpers.replaceSentryValues(eventRawIdentifiers),
          eventProperties: helpers.replaceSentryValues(eventProperties)
        }, eventConfig),
        fingerprint: ['usage-tracker-js', 'tracker:getIdentifiers', `event:${eventKey}`]
      });
      return null;
    }
  };
  const createEvent = (eventKey, eventDefinition, eventProperties, eventIdentifiers) => {
    try {
      return eventInterface.createEventPayload(eventDefinition, eventProperties, eventIdentifiers);
    } catch (error) {
      logError(error, {
        extra: {
          eventKey,
          eventProperties: helpers.replaceSentryValues(eventProperties),
          eventIdentifiers: helpers.replaceSentryValues(eventIdentifiers ? eventIdentifiers.raw : undefined)
        },
        fingerprint: ['usage-tracker-js', 'tracker:createEvent', `event:${eventKey}`]
      });
      return null;
    }
  };
  const dispatchEvent = (eventKey, eventObject, eventIdentifiers) => {
    try {
      // `config.scheduleEvent` should always be defined, hecne if this throws an error
      // we should definitely throw the error here as it's a configuration error
      config.scheduleEvent(eventKey, eventObject, helpers.pick(config, ['bypassPool', 'isBeforeUnload', 'isExternalHost']));
      helpers.dispatchFunctionAsync(() =>
      // If an user provided an `onScheduled` callback, we should call it
      // this allows users to listen when events get scheduled
      helpers.ensureFn(config.onScheduled)(eventKey));
      return true;
    } catch (error) {
      logError(error, {
        extra: {
          eventKey,
          eventIdentifiers: helpers.replaceSentryValues(eventIdentifiers.raw)
        },
        fingerprint: ['usage-tracker-js', 'tracker:dispatchEvent', `event:${eventKey}`]
      });
      return false;
    }
  };
  const trackEventDefinition = (eventKey, definition, metaProperties, extraProperties) => {
    // This merges all the filtered properties with the meta properties
    // Creating the combined full data that is going to be appended to
    // the `what_extra_json` property from the Event Payload
    const mergedProperties = helpers.defaults(extraProperties, metaProperties);

    // The `propertiesAreValid` will ensure that all our required identifiers
    // Are scalar values and completely valid and that they follow their schema
    // E.g.: HubID is only allowed to be a number and not a string.
    const identifiers = getIdentifiers(eventKey, mergedProperties);

    // These are the properties to be provided to the Event Payload
    // Note that `event.ts` does extra filtering of properties
    const eventProperties = Object.assign({}, mergedProperties, {
      eventKey
    });

    // We create the Event with all the merged properties and the EventKey
    // As it is also passed within the network as part of the Event payload
    const event = createEvent(eventKey, definition, eventProperties, identifiers || undefined);
    if (identifiers && event) {
      if (isDebugEnabled) {
        logDebug(eventKey, 'Event is being dispatched to be sent.', event);
      }
      return dispatchEvent(eventKey, event, identifiers);
    }
    if (isDebugEnabled) {
      logDebug(eventKey, 'Event was not dispatched.', event || undefined);
    }
    return false;
  };
  const trackStandaloneEvent = (appName, eventName, metaProperties, standaloneProperties) => {
    const eventKey = helpers.convertEventNameToEventKey(eventName);
    const manualEventClass =
    // Attempts to get a manually provided eventClass if it matches the allowed event classes
    // otherwise it will simply fallback as "false" and the event class will be determined
    // by a simple regex matching below of a string containing certan matches for "view"
    schemas.eventClasses.includes(standaloneProperties.class) && standaloneProperties.class;

    // Attempts to retrieve the event class either from a manually provided eventClass
    // or by some basic regex matching to check if the event might be a view event type
    // otherwise we simply fallback to "interaction"
    const eventClass = manualEventClass || /(pageView|View|pageview)/.test(eventKey) ? 'view' : 'interaction';
    const definition = {
      name: eventName,
      namespace: appName,
      class: eventClass,
      version: 'v1',
      properties: {},
      meta: {}
    };
    return trackEventDefinition(`standalone:::${eventKey}`, definition, metaProperties, standaloneProperties);
  };
  const trackDictionaryEvent = (eventKey, metaProperties, filterProperties) => {
    const definition = getDefinition(eventKey);
    if (!definition) {
      return false;
    }

    // Removes all the unknown properties that aren't known to the event definition
    // and reports and warns to the owning team that they're using the tracker incorrectly
    const filteredProperties = filterProperties(definition);

    // Validates the given Properties and check if all the required properties are present
    // and if their types match correctly (when dictionary properties) or if they are valid structures
    const propertiesAreValid = validateProperties(eventKey, filteredProperties);
    if (!propertiesAreValid) {
      return false;
    }
    return trackEventDefinition(eventKey, definition, metaProperties, filteredProperties);
  };
  return {
    trackStandaloneEvent,
    trackDictionaryEvent
  };
};
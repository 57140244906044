import { UNINITIALIZED, PENDING, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
import { FETCH_CATEGORIZED_MODULES_FAILED, FETCH_CATEGORIZED_MODULES_REQUESTED, FETCH_CATEGORIZED_MODULES_SUCCEEDED, FETCH_CATEGORY_TYPES_FAILED, FETCH_CATEGORY_TYPES_REQUESTED, FETCH_CATEGORY_TYPES_SUCCEEDED } from '../actions/categorizationActions';
const initialState = {
  categoryTypeRequestStatus: UNINITIALIZED,
  categoryTypes: [],
  categorizedModulesRequestStatus: UNINITIALIZED,
  categorizedModules: []
};
export const categorizationReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    // CATEGORY_TYPES
    case FETCH_CATEGORY_TYPES_REQUESTED:
      return Object.assign({}, state, {
        categoryTypeRequestStatus: PENDING
      });
    case FETCH_CATEGORY_TYPES_SUCCEEDED:
      return Object.assign({}, state, {
        categoryTypeRequestStatus: SUCCEEDED,
        categoryTypes: payload.results
      });
    case FETCH_CATEGORY_TYPES_FAILED:
      return Object.assign({}, state, {
        categoryTypeRequestStatus: FAILED
      });
    // CATEGORIZED_MODULES
    case FETCH_CATEGORIZED_MODULES_REQUESTED:
      return Object.assign({}, state, {
        categorizedModulesRequestStatus: PENDING
      });
    case FETCH_CATEGORIZED_MODULES_SUCCEEDED:
      return Object.assign({}, state, {
        categorizedModulesRequestStatus: SUCCEEDED,
        categorizedModules: payload.results
      });
    case FETCH_CATEGORIZED_MODULES_FAILED:
      return Object.assign({}, state, {
        categorizedModulesRequestStatus: FAILED
      });
    default:
      return state;
  }
};
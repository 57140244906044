export let PaymentsEligibilityStatus;
(function (PaymentsEligibilityStatus) {
  PaymentsEligibilityStatus[PaymentsEligibilityStatus["ELIGIBLE"] = 0] = "ELIGIBLE";
  PaymentsEligibilityStatus[PaymentsEligibilityStatus["INELIGIBLE"] = 1] = "INELIGIBLE";
  PaymentsEligibilityStatus[PaymentsEligibilityStatus["ACCOUNT_EXISTS"] = 2] = "ACCOUNT_EXISTS";
})(PaymentsEligibilityStatus || (PaymentsEligibilityStatus = {}));
export let PaymentsEligiblityUnderwritingStatus;
(function (PaymentsEligiblityUnderwritingStatus) {
  PaymentsEligiblityUnderwritingStatus[PaymentsEligiblityUnderwritingStatus["IN_KYC"] = 0] = "IN_KYC";
  PaymentsEligiblityUnderwritingStatus[PaymentsEligiblityUnderwritingStatus["IN_UNDERWRITING"] = 1] = "IN_UNDERWRITING";
  PaymentsEligiblityUnderwritingStatus[PaymentsEligiblityUnderwritingStatus["APPROVED"] = 2] = "APPROVED";
  PaymentsEligiblityUnderwritingStatus[PaymentsEligiblityUnderwritingStatus["REJECTED"] = 3] = "REJECTED";
})(PaymentsEligiblityUnderwritingStatus || (PaymentsEligiblityUnderwritingStatus = {}));
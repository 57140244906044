import { getUserInfo } from 'ui-addon-upgrades/_core/common/api/getUserInfo';
export const getCanViewNewSeatRequestModal = () => {
  return getUserInfo().then(({
    gates
  }) => {
    return gates.includes('NewSeatRequestModal');
  }).catch(error => {
    console.error('Error fetching NewSeatRequestModal gate', error);
    return false;
  });
};
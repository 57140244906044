import { HISTORY_METHOD_CALLED } from './actionTypes';
const createAction = method => (location, state) => ({
  type: HISTORY_METHOD_CALLED,
  payload: {
    method,
    args: [location, state]
  }
});
export const push = createAction('push');
export const goBack = createAction('goBack');
export const replace = createAction('replace');
import { useCallback } from 'react';
import { MSG_TYPE_EMBEDDED_COMMENT, MSG_TYPE_REMOVE_EMBEDDED_COMMENT } from 'collaboration-sidebar/constants/IFrameMessageTypes';
export default function useCommentIndicatorsSync({
  setModuleReply,
  removeModuleReply
}) {
  const handleMessage = useCallback(({
    payload
  }) => {
    if (payload.type === MSG_TYPE_EMBEDDED_COMMENT) {
      setModuleReply(payload.moduleId, payload.comment);
    } else if (payload.type === MSG_TYPE_REMOVE_EMBEDDED_COMMENT) {
      if (payload.payload) {
        const broadcastPayload = payload.payload;
        removeModuleReply(broadcastPayload.moduleId);
      } else {
        // TODO: remove this after the sendBroadcastMessage(MSG_TYPE_REMOVE_EMBEDDED_COMMENT)
        // is fully deployed
        removeModuleReply(payload.moduleId);
      }
    }
  }, [setModuleReply, removeModuleReply]);
  return {
    handleMessage
  };
}
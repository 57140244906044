const getOptins = state => state.resources.optins.optins;
export const getRequestStatus = (state, name) => state.resources.optins.requestStatus[name];
export const getOptin = (state, name) => getOptins(state)[name];
export const getIsOptedIn = (state, name) => {
  const optin = getOptin(state, name) || {};
  const {
    optedIn
  } = optin;
  return optedIn;
};
export const getCanActionOptIn = (state, name) => (getOptin(state, name) || {}).actionsAllowed || false;
import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { SALESFORCE_FETCH_CAMPAIGN, SALESFORCE_FETCH_CAMPAIGNS, SALESFORCE_FETCH_INTEGRATION_STATUS } from 'ContentData/actions/ActionTypes';
const campaigns = (state = {}, action) => {
  switch (action.type) {
    case SALESFORCE_FETCH_CAMPAIGN:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }
    case SALESFORCE_FETCH_CAMPAIGNS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.reduce((hash, campaign) => {
          hash[campaign.id] = campaign;
          return hash;
        }, Object.assign({}, state));
      }
    default:
      return state;
  }
};
const integrationStatus = (state = null, action) => {
  switch (action.type) {
    case SALESFORCE_FETCH_INTEGRATION_STATUS:
      {
        const {
          response
        } = action;
        if (response) return response.integrationStatus;
        return state;
      }
    default:
      return state;
  }
};
export default combineReducers({
  campaigns,
  campaignsRequestStatus: createGenericRequestStatusReducer(SALESFORCE_FETCH_CAMPAIGN),
  integrationStatus
});
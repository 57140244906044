import { combineReducers } from 'redux';
import { createSimpleRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { CURRENCY_FETCH_HOME } from 'ContentData/actions/ActionTypes';
const currencyInfo = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_FETCH_HOME:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response;
      }
    default:
      return state;
  }
};
export default combineReducers({
  currencyInfo,
  requestStatus: createSimpleRequestStatusReducer(CURRENCY_FETCH_HOME)
});
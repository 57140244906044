export default Object.freeze({
  CONNECTED: 'CONNECTED',
  USER_DISABLED: 'USER_DISABLED',
  AUTH_ERROR: 'AUTH_ERROR',
  THIRD_PARTY_AVAILABILITY_ERROR: 'THIRD_PARTY_AVAILABILITY_ERROR',
  THIRD_PARTY_LICENSE_ERROR: 'THIRD_PARTY_LICENSE_ERROR',
  THIRD_PARTY_MISSING_SCOPE_ERROR: 'THIRD_PARTY_MISSING_SCOPE_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  DELAY: 'DELAY',
  DEPRECATED_AUTH_TOKEN: 'DEPRECATED_AUTH_TOKEN',
  // Deprecated
  INTEGRATED: 'INTEGRATED',
  INTEGRATED_AND_DISABLED: 'INTEGRATED_AND_DISABLED',
  NOT_INTEGRATED: 'NOT_INTEGRATED',
  REAUTHORIZE: 'REAUTHORIZE',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
});
import { PANEL_ANIMATION_MAPPING, COLLAPSED } from '../EPConstants';
export const getPatagoniaPanelAnimationType = (previousPanel, nextPanel) => {
  if (previousPanel === COLLAPSED && nextPanel === COLLAPSED) {
    throw new Error('Both panels cannot be collapsed during animation');
  }
  return PANEL_ANIMATION_MAPPING[previousPanel][nextPanel];
};

// EaseInOut math taken from https://easings.net/#easeInOutSine
export const easeInOutSine = x => {
  return -(Math.cos(Math.PI * x) - 1) / 2;
};
export const computeUpdatedWidth = (easingCoefficient, startWidth, endWidth) => {
  const widthAdjustment = (startWidth - endWidth) * easingCoefficient;
  return startWidth - widthAdjustment;
};
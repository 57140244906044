import { fetchContentAssistanceSettings, fetchBrandVoiceSettings } from 'ContentEditorUI/api/ContentAssistantApi';
import { GET_CONTENT_ASSISTANT_SETTINGS } from 'ContentEditorUI/redux/actions/actionTypes';
import { getHasAccessToBrandVoice } from 'ContentEditorUI/redux/selectors/contentAssistantSelectors';
export const getContentAssistantSettings = () => async (dispatch, getState) => {
  let contentAssistanceSettings;
  let brandVoiceSettings;
  try {
    const state = getState();
    contentAssistanceSettings = await fetchContentAssistanceSettings();
    if (getHasAccessToBrandVoice(state)) {
      brandVoiceSettings = await fetchBrandVoiceSettings();
    }
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: Object.assign({}, contentAssistanceSettings, {
        brandVoiceSettings
      })
    });
  } catch (error) {
    // Fallback to default settings when request fails
    const defaultSettings = {
      contentAssistanceEnabled: false,
      blogBrandVoiceEnabled: false
    };
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: contentAssistanceSettings || defaultSettings
    });
  }
};
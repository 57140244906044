import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET inbounddb-objects/v1/crm-objects/{objectTypeId}/{id}
 * @param {string} objectTypeId CRM object type ID
 * @param {string} id CRM object id
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch CRM object by type and id
 */
export function fetchCrmObject({
  objectTypeId,
  id,
  query
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`inbounddb-objects/v1/crm-objects/${objectTypeId}/${id}`, {
    query
  });
}

/**
 * POST crm-search/search
 * @param {string} objectTypeId CRM object type ID
 * @param {object} query Query params
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch all CRM objects (paginated)
 */
export function fetchCrmObjects({
  objectTypeId,
  query
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`crm-search/search`, {
    data: Object.assign({
      objectTypeId
    }, query)
  });
}

/**
 * GET crm-object-schemas/v3/schemas/{objectType}
 * @param {string} objectType CRM object type name
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch CRM object schema by type name
 */
export function fetchCrmObjectSchema(objectType, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`crm-object-schemas/v3/schemas/${objectType}`);
}

/**
 * GET crm/v3/properties/{objectType}/groups
 * @param {string} objectType CRM object type name
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch CRM object schema by type name
 */
export function fetchCrmObjectSchemaGroups(objectType, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`crm/v3/properties/${objectType}/groups`);
}

/**
 * GET inbounddb-meta/v1/object-types/for-portal
 * Used in the Design Manager
 * @returns {Promise} fetch CRM object types
 */
export function fetchCrmObjectTypes(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('inbounddb-meta/v1/object-types/for-portal');
}

/**
 * POST crm/v3/objects/{objectType}
 */
export function createCrmObject(data, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`crm/v3/objects/${data.objectType}`, {
    data: {
      properties: data.properties
    }
  });
}

/**
 * PATCH crm/v3/objects/{objectType}
 */
export function editCrmObject(data, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.patch(`crm/v3/objects/${data.objectType}/${data.objectId}`, {
    data: {
      properties: data.properties
    }
  });
}
'use es6';

import { buildDndAreaUniqueRowSelector } from '../../dom/dndAreaDomSelectors';
import { isFullWidthSection } from './forceFullWidthSection';
const isValidMaxWidthCenteringValue = styleValue => typeof styleValue === 'number';
export const hasMaxWidthCenteringValue = styleValue => !!styleValue && isValidMaxWidthCenteringValue(styleValue);
export const calculateMaxWidthSectionCenteringClasses = (treeNode, styleValue, allLayoutStyles) => {
  if (!isValidMaxWidthCenteringValue(styleValue)) {
    throw new Error(`Invalid max-width value: ${JSON.stringify(styleValue)}`);
  }
  if (isFullWidthSection(allLayoutStyles.forceFullWidthSection)) {
    throw new Error(`Cannot have a max-width _and_ forceFullWidthSection set at the same time`);
  }
  const rendererName = treeNode.getRendererCompatibleRowName();
  const domNodeSelector = buildDndAreaUniqueRowSelector(treeNode.getParent().getName(), treeNode.getName());
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-max-width-section-centering`,
    cssSelector: `.${rendererName}-max-width-section-centering > .row-fluid`,
    cssProperties: {
      'max-width': `${styleValue}px !important`,
      'margin-left': 'auto !important',
      'margin-right': 'auto !important'
    }
  }];
};
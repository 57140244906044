import { useEffect, useRef, useMemo } from 'react';
import { EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS } from 'collaboration-sidebar/constants/CollaborationSidebarStateProperties';
import createCommentInteractionsState from 'collaboration-sidebar/factories/CommentInteractions';
export default function useEmbeddedCommentsNavigation({
  commentableAreaRef,
  moduleId,
  onUpdateCollaborationSidebarState,
  navigateTo,
  embeddedCommentSidebarInteractions = createCommentInteractionsState()
}) {
  const {
    scrollTo,
    highlightModuleIdFromHover,
    highlightModuleIdFromClick
  } = embeddedCommentSidebarInteractions;
  const highlightModuleIdFromHoverRef = useRef(highlightModuleIdFromHover);
  const highlightRef = useRef(undefined);
  const isHighlightedBySidebar = useMemo(() => moduleId === highlightModuleIdFromHover || moduleId === highlightModuleIdFromClick, [moduleId, highlightModuleIdFromHover, highlightModuleIdFromClick]);
  useEffect(() => {
    if (scrollTo && scrollTo.moduleId && moduleId === scrollTo.moduleId && commentableAreaRef && commentableAreaRef.current) {
      // setTimeout with 0 and requestAnimationFrame to ensure the page has finished
      // rendering as much as possible before scrolling.
      setTimeout(() => {
        if (commentableAreaRef.current) {
          const {
            bottom,
            y,
            right,
            x
          } = commentableAreaRef.current.getBoundingClientRect();
          const scrollParent = commentableAreaRef.current.closest(`[
        data-commenting-scroll-container=true]`) || commentableAreaRef.current.closest(`[class^=UIScrollContainer]`);

          // Calculate the position to scroll to to put the commentable area in the middle of the screen.
          const getScrollPosition = (elementTopPosition, elementLeftPosition) => ({
            top: elementTopPosition + bottom - (bottom - y) / 2 - window.innerHeight / 2,
            left: elementLeftPosition + right - (right - x) / 2 - window.innerWidth / 2,
            behavior: 'smooth'
          });
          window.requestAnimationFrame(() => {
            if (scrollParent) {
              scrollParent.scrollTo(getScrollPosition(scrollParent.scrollTop, scrollParent.scrollLeft));
            } else {
              window.scrollTo(getScrollPosition(window.scrollY, window.scrollX));
            }
          });
        }
      }, 0);
      onUpdateCollaborationSidebarState({
        [EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS]: {
          highlightModuleIdFromClick: moduleId,
          highlightModuleIdFromHover
        }
      });
    }
    highlightModuleIdFromHoverRef.current = highlightModuleIdFromHover;
  }, [moduleId, scrollTo, commentableAreaRef, onUpdateCollaborationSidebarState, highlightModuleIdFromHover]);
  useEffect(() => {
    if (!moduleId && scrollTo && scrollTo.location && scrollTo.moduleId) {
      navigateTo(scrollTo.location, scrollTo.moduleId);
    }
  }, [moduleId, scrollTo, navigateTo]);

  // Clean up highlight timeout on unmount
  useEffect(() => {
    if (highlightModuleIdFromClick && !moduleId) {
      highlightRef.current = setTimeout(() => {
        onUpdateCollaborationSidebarState({
          [EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS]: {
            highlightModuleIdFromClick: null,
            highlightModuleIdFromHover: highlightModuleIdFromHoverRef.current
          }
        });
      }, 2000);
    }

    // Clear the timeout when this component unmounts to
    // avoid updates on an unmounted component.
    return () => clearTimeout(highlightRef.current);
  }, [moduleId, highlightModuleIdFromClick, onUpdateCollaborationSidebarState]);
  return {
    isHighlightedBySidebar
  };
}
import { combineReducers } from 'redux';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
const {
  CLEAR_ICONS,
  FETCH_ICONS
} = ActionTypes;
function icons(state = {}, action) {
  switch (action.type) {
    case CLEAR_ICONS:
      {
        return {};
      }
    case FETCH_ICONS:
      {
        const {
          result
        } = action;
        if (!result) return state;
        return result.results.reduce((acc, icon) => {
          acc[icon.name] = icon;
          return acc;
        }, Object.assign({}, state));
      }
    default:
      return state;
  }
}
function hasMoreIcons(state = false, action) {
  switch (action.type) {
    case CLEAR_ICONS:
      {
        return false;
      }
    case FETCH_ICONS:
      {
        const {
          result
        } = action;
        if (!result) return state;
        return result.hasMore;
      }
    default:
      return state;
  }
}
function totalIcons(state = 0, action) {
  switch (action.type) {
    case FETCH_ICONS:
      {
        const {
          result
        } = action;
        if (!result) return state;
        return result.total;
      }
    default:
      return state;
  }
}
export default combineReducers({
  icons,
  hasMoreIcons,
  totalIcons
});
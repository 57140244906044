'use es6';

import { createSelector } from 'reselect';
import { Set as ImmutableSet } from 'immutable';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getTestFlagsValuesMap = basicSelector(state => state.testFlags);
export const getTestFlags = createSelector(getTestFlagsValuesMap, testFlagsValuesMap => testFlagsValuesMap ? new ImmutableSet(testFlagsValuesMap.keys()) : new ImmutableSet());
export const makeGetHasTestFlag = testFlagToCheck => createSelector([getTestFlags], testFlags => testFlags && testFlags.has(testFlagToCheck));
export const makeGetHasTestFlagValue = testFlagToCheck => createSelector(getTestFlagsValuesMap, testFlagsValuesMap => testFlagsValuesMap && testFlagsValuesMap.get(testFlagToCheck));
export const DND_DO_NOT_WRAP_SINGLE_MODULE_IN_SECTION_FLAG = 'doNotWrapSingleModuleInSection';
export const getDoNotWrapSingleModuleInSectionFlag = makeGetHasTestFlag(DND_DO_NOT_WRAP_SINGLE_MODULE_IN_SECTION_FLAG);
const DONT_USE_PLACEHOLDERS_FOR_COMPUTED_CSS = 'dontUsePlaceholdersForComputedCss';
export const getDontUsePlaceholdersForComputedCss = makeGetHasTestFlag(DONT_USE_PLACEHOLDERS_FOR_COMPUTED_CSS);
const SHOW_STALE_MODULES_DEBUG = 'showStaleModules';
export const getShowStaleModulesDebug = makeGetHasTestFlag(SHOW_STALE_MODULES_DEBUG);
export const SECTIONS_POC = 'sectionsPoc';
export const getShouldDisplaySectionPocHelpers = makeGetHasTestFlag(SECTIONS_POC);
export const FORCE_TINYMCE_READONLY_ISSUE = 'forceTinymceReadonlyIssue';
export const getShouldForceTinymceReadonlyIssue = makeGetHasTestFlag(FORCE_TINYMCE_READONLY_ISSUE);
const READ_ONLY = 'readOnly';
export const getTestReadOnly = makeGetHasTestFlag(READ_ONLY);
export const DISABLE_CUSTOM_JITA_UI = 'disableCustomJitaUI';
export const getDisableCustomJitaUI = makeGetHasTestFlag(DISABLE_CUSTOM_JITA_UI);

// Gather all of the potential test flags into an array so we can log them in the console for easier discovery
export const ALL_POTENTIAL_TEST_FLAGS = [DND_DO_NOT_WRAP_SINGLE_MODULE_IN_SECTION_FLAG, SHOW_STALE_MODULES_DEBUG, SECTIONS_POC, READ_ONLY, DISABLE_CUSTOM_JITA_UI];
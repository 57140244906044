/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { FETCH_REVISIONS_STARTED, FETCH_REVISIONS_SUCCEEDED, FETCH_REVISIONS_FAILED, FETCH_SCHEDULED_VERSION_STARTED, FETCH_SCHEDULED_VERSION_SUCCEEDED, FETCH_SCHEDULED_VERSION_FAILED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fetchRevisions as fetchRevisionsRequest, selectRevision as selectRevisionRequest, fetchScheduledVersion as fetchScheduledVersionRequest } from 'ContentEditorUI/api/RevisionsApi';
import { getBaseApiUrl } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
import { getContentModelId, getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { showLoading } from 'ContentEditorUI/redux/actions/appActions';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getRevisionHref } from 'ContentEditorUI/redux/selectors/revisionsSelectors';
import { SLUG_EXISTS, PAGE_EXISTS } from 'ContentEditorUI/lib/modals/constants';
import { getBaseContentUrl } from 'ContentEditorUI/api/ContentApi';
export const fetchRevisions = (offset, limit) => (dispatch, getState) => {
  dispatch({
    type: FETCH_REVISIONS_STARTED
  });
  const state = getState();
  const baseContentUrl = getBaseContentUrl({
    baseUrl: getBaseApiUrl(state),
    categoryId: getCategoryId(state),
    subcategory: getSubcategory(state),
    contentId: getContentModelId(state)
  });
  fetchRevisionsRequest({
    baseContentUrl,
    limit,
    offset
  }).then(response => {
    dispatch({
      type: FETCH_REVISIONS_SUCCEEDED,
      response
    });
  }, error => {
    dispatch({
      type: FETCH_REVISIONS_FAILED,
      error
    });
  });
};
export const fetchScheduledVersion = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_SCHEDULED_VERSION_STARTED
  });
  const state = getState();
  const baseContentUrl = getBaseContentUrl({
    baseUrl: getBaseApiUrl(state),
    categoryId: getCategoryId(state),
    subcategory: getSubcategory(state),
    contentId: getContentModelId(state)
  });
  fetchScheduledVersionRequest(baseContentUrl).then(response => {
    dispatch({
      type: FETCH_SCHEDULED_VERSION_SUCCEEDED,
      response
    });
  }, error => {
    dispatch({
      type: FETCH_SCHEDULED_VERSION_FAILED,
      error
    });
  });
};
export const selectRevision = (selectedId, {
  shouldRestoreToBuffer,
  shouldRestoreScheduledUpdateToBuffer
}) => (dispatch, getState) => {
  const state = getState();
  const baseContentUrl = getBaseContentUrl({
    baseUrl: getBaseApiUrl(state),
    categoryId: getCategoryId(state),
    subcategory: getSubcategory(state),
    contentId: getContentModelId(state)
  });
  selectRevisionRequest({
    baseContentUrl,
    versionId: selectedId,
    shouldRestoreToBuffer,
    shouldRestoreScheduledUpdateToBuffer
  }).then(() => {
    // figure out if revision is drag drop or iceberger
    dispatch(showLoading());
    window.location = `${getRevisionHref(state, selectedId)}${window.SELENIUM_IS_RUNNING ? '?seleniumRunning=true' : ''}`;
  }, error => {
    let status = error.responseJSON ? error.responseJSON.status : '';
    if (status === SLUG_EXISTS || status === PAGE_EXISTS) {
      status = 'restoreSlugExists';
    } else {
      status = 'default';
    }
    FloatingAlertStore.addAlert({
      type: 'danger',
      titleText: I18n.text(`components.modals.revisionsViewModal.error.title`),
      message: I18n.text(`components.modals.revisionsViewModal.error.${status}`)
    });
  });
};
import { SET_MODULE_ERRORS, CONTENT_SAVE_REQUEST_SUCCEEDED, FETCH_MODULE_ERRORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
// @ts-expect-error not yet migrated
import { convertObjectToCamelCase } from 'ContentEditorUI/utils/helpers';
import produce from 'immer';
const EMPTY_LIST = Immutable.List();
export const moduleErrorImmerReducer = (state = [], {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case SET_MODULE_ERRORS:
      return response.errors.map(convertObjectToCamelCase);
    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          config: {
            setModuleErrorsAfterSave
          },
          response: {
            data: {
              errors = []
            }
          }
        } = payload;
        if (setModuleErrorsAfterSave) {
          return errors.map(convertObjectToCamelCase);
        }
        return draft;
      }
    case FETCH_MODULE_ERRORS_SUCCEEDED:
      return response;
    default:
      return draft;
  }
});
const moduleErrorReducer = (state = EMPTY_LIST, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case SET_MODULE_ERRORS:
      return Immutable.fromJS(response.errors.map(convertObjectToCamelCase));
    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          config: {
            setModuleErrorsAfterSave
          },
          response: {
            data: {
              errors = []
            }
          }
        } = payload;
        if (setModuleErrorsAfterSave) {
          return Immutable.fromJS(errors.map(convertObjectToCamelCase));
        }
        return state;
      }
    case FETCH_MODULE_ERRORS_SUCCEEDED:
      return Immutable.fromJS(response);
    default:
      return state;
  }
};
export default moduleErrorReducer;
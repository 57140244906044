export const AB_VERSION = Object.freeze({
  A: 'A',
  B: 'B'
});
export const WINNING_METRICS = Object.freeze({
  NONE: 'NONE',
  OPENS_BY_DELIVERED: 'OPENS_BY_DELIVERED',
  CLICKS_BY_DELIVERED: 'CLICKS_BY_DELIVERED',
  CLICKS_BY_OPENS: 'CLICKS_BY_OPENS'
});
export const RECOMMENDED_MINIMUM_AB_DURATION = 4;
export const AB_TYPES = ['email'];
export const AUTOMATED_AB = 'AUTOMATED_AB';
export const DEFAULT_AB = 'DEFAULT_AB';
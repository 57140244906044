import http from 'hub-http/clients/apiClient';
const THINGS_PEOPLE_COVER = 'keyword-research/v1/things-people-cover';
export const getThingsPeopleCover = ({
  phrase,
  limit = 10,
  countryCode = 'US'
}) => {
  return http.get(THINGS_PEOPLE_COVER, {
    query: {
      phrase,
      limit,
      countryCode
    }
  });
};
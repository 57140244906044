import { validateSkuId } from 'self-service-api/core/validators';
import { isSkuOwnedAdapter, isApiNameOwnedAdapter } from 'self-service-api/adapters/isProductOwnedAdapter';
import { getOwnedProducts } from 'self-service-api/api/getProducts';
export const getIsSkuOwned = skuId => {
  validateSkuId(skuId);
  return getOwnedProducts().then(ownedProducts => {
    return isSkuOwnedAdapter(ownedProducts, skuId);
  });
};
export const getIsApiNameOwned = apiName => {
  return getOwnedProducts().then(ownedProducts => {
    return isApiNameOwnedAdapter(ownedProducts, apiName);
  });
};
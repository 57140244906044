import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
const ONE_HOUR_IN_MINUTES = 60;

// adapted from https://git.hubteam.com/HubSpot/MeetingsUI/blob/master/meetings-utils/static/js/meetingName.js
export const getDefaultMeetingName = (meeting, forName = true) => {
  if (meeting.name) return meeting.name;
  let durations = [];
  if (meeting.durations) {
    durations = meeting.durations;
  } else if (meeting.customParams) {
    durations = meeting.customParams.durations;
  }
  const sortedDurations = durations.sort((a, b) => b - a);
  const durationText = I18n.formatList(sortedDurations.map(d => {
    const minutes = I18n.moment.duration(d).minutes();
    if (minutes <= ONE_HOUR_IN_MINUTES) {
      return unescapedText('contentUtils.durations.minute', {
        count: I18n.moment.duration(d).asMinutes()
      });
    }
    const hours = I18n.moment.duration(d).hours();
    const hourText = unescapedText('contentUtils.durations.hour', {
      count: hours
    });
    const minuteText = unescapedText('contentUtils.durations.minute', {
      count: minutes
    });
    return unescapedText('contentUtils.durations.combined', {
      hourText,
      minuteText
    });
  }));
  return forName ? unescapedText('contentUtils.durations.placeholderName', {
    durations: durationText
  }) : durationText;
};
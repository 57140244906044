import * as IconsClient from 'ContentData/api/Icons';
import { CLEAR_ICONS, FETCH_ICONS, FETCH_ICON, SAVE_ICON, FETCH_SELECTED_ICONS } from 'ContentData/actions/ActionTypes';
export function clearIcons() {
  return {
    type: CLEAR_ICONS
  };
}
export function saveIcon(iconData) {
  return {
    type: SAVE_ICON,
    iconData
  };
}
export function fetchSelectedIcons() {
  return {
    type: FETCH_SELECTED_ICONS
  };
}
export function fetchIcons(set, query) {
  const {
    request,
    receive,
    error
  } = fetchIcons;
  return dispatch => {
    dispatch(request());
    return IconsClient.fetch(set, query).then(result => {
      dispatch(receive(result));
    }).catch(__error => {
      dispatch(error(__error));
    });
  };
}
fetchIcons.request = () => {
  return {
    type: FETCH_ICONS
  };
};
fetchIcons.receive = result => {
  return {
    type: FETCH_ICONS,
    result
  };
};
fetchIcons.error = error => {
  return {
    type: FETCH_ICONS,
    error
  };
};
export function fetchIcon(iconSet, query) {
  const {
    request,
    receive,
    error
  } = fetchIcon;
  return dispatch => {
    dispatch(request(query));
    return IconsClient.fetch(iconSet, query).then(result => {
      dispatch(receive(result, query));
    }).catch(__error => {
      dispatch(error(__error, query));
    });
  };
}
fetchIcon.request = query => {
  return {
    type: FETCH_ICON,
    query
  };
};
fetchIcon.receive = (result, query) => {
  return {
    type: FETCH_ICON,
    query,
    result
  };
};
fetchIcon.error = (error, query) => {
  return {
    type: FETCH_ICON,
    query,
    error
  };
};
'use es6';

import I18n from 'I18n';
import RelativeDurationFormats from '../constants/RelativeDurationFormats';
export default ((duration, options) => {
  const format = options && options.format || RelativeDurationFormats.MEDIUM;
  const combined = options && options.combined;
  const seconds = Math.round(duration.asSeconds());
  if (seconds < 60) {
    const secondsAmount = {
      seconds: seconds > 0 ? seconds : 1
    };
    if (format === RelativeDurationFormats.SHORT) {
      return I18n.text('i18n.duration.shortForm.seconds', secondsAmount);
    }
    return I18n.text('i18n.duration.seconds', secondsAmount);
  }
  const minutes = Math.round(duration.asMinutes());
  if (minutes < 60) {
    if (format === RelativeDurationFormats.SHORT) {
      return I18n.text('i18n.duration.shortForm.minutes', {
        minutes
      });
    }
    return I18n.text('i18n.duration.minutes', {
      minutes
    });
  }
  const hours = Math.round(duration.asHours());
  if (hours < 24) {
    if (combined) {
      const flooredHours = Math.floor(duration.asHours());
      const flooredMins = Math.floor(duration.asMinutes());
      if (flooredMins % 60 > 0) {
        if (format === RelativeDurationFormats.SHORT) {
          return I18n.text('i18n.duration.shortForm.hoursAndMinutes', {
            hours: flooredHours,
            minutes: flooredMins % 60
          });
        } else {
          return I18n.text('i18n.duration.hoursAndMinutes', {
            hours: flooredHours,
            minutes: flooredMins % 60
          });
        }
      }
    }
    if (format === RelativeDurationFormats.SHORT) {
      return I18n.text('i18n.duration.shortForm.hours', {
        hours
      });
    }
    return I18n.text('i18n.duration.hours', {
      hours
    });
  }
  const days = Math.round(duration.asDays());
  if (format === RelativeDurationFormats.SHORT) {
    return I18n.text('i18n.duration.shortForm.days', {
      days
    });
  }
  return I18n.text('i18n.duration.days', {
    days
  });
});
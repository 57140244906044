import { useState, useCallback, useMemo } from 'react';
import productApprovalsApi from '../data/productApprovalsApi';
import { ApprovalOperation } from '../types/ApprovalOperation';
import { genericErrorAlert } from '../utils/alertUtils';
import { Metrics } from '../utils/metrics';
import { reportRequestException } from '../utils/sentryUtils';
const reportMetric = approvalOperation => {
  switch (approvalOperation) {
    case ApprovalOperation.REQUEST:
      Metrics.counter('request-created').increment();
      break;
    case ApprovalOperation.APPROVE:
      Metrics.counter('request-approved').increment();
      break;
    case ApprovalOperation.RECALL:
      Metrics.counter('request-recalled').increment();
      break;
    default:
      return;
  }
};
const useApprovalData = (approvalType, approvalAction, approvableObjectId, assetName) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [fetchError, setFetchError] = useState();
  const fetchLatest = useCallback(() => {
    setIsLoading(true);
    productApprovalsApi.fetchLatestApproval({
      approvalAction,
      approvalType,
      approvableObjectId: approvableObjectId
    }).then(approvalLatest => {
      setData(approvalLatest.approval);
    }).catch(errorResponse => {
      const errorReason = errorResponse.responseJSON && errorResponse.responseJSON.subCategory;
      setFetchError(errorReason || 'UNKNOWN_SUBCATEGORY');
      genericErrorAlert();
      reportRequestException('fetchLatestApproval', errorResponse);
    }).finally(() => {
      setIsLoading(false);
    }).catch(() => {
      console.error('Error fetching approval data');
    });
  }, [approvalAction, approvalType, approvableObjectId]);

  // Create, Approve, Cancel all share same handler logic
  const handleApiPromise = useCallback((approvalOperation, apiPromise) => apiPromise.then(response => {
    setData(response);
    reportMetric(approvalOperation);
    return response;
  }).catch(errorResponse => {
    reportRequestException(approvalOperation, errorResponse);
    throw errorResponse;
  }), []);
  const create = useCallback((note, approverOwnerIds, approvalRules) => {
    const payload = {
      title: assetName,
      approvalType,
      approvalAction,
      approvalRules,
      approvableObjectId: approvableObjectId,
      note,
      approverOwnerIds
    };
    const promise = productApprovalsApi.createApproval(payload);
    return handleApiPromise(ApprovalOperation.REQUEST, promise);
  }, [approvalType, approvalAction, approvableObjectId, assetName, handleApiPromise]);
  const approve = useCallback(note => {
    const promise = productApprovalsApi.approveApproval(data, note);
    return handleApiPromise(ApprovalOperation.APPROVE, promise);
  }, [data, handleApiPromise]);
  const recall = useCallback(note => {
    const promise = productApprovalsApi.cancelApproval(data, note);
    return handleApiPromise(ApprovalOperation.RECALL, promise);
  }, [data, handleApiPromise]);
  const reject = useCallback(note => {
    const promise = productApprovalsApi.rejectApproval(data, note);
    return handleApiPromise(ApprovalOperation.REJECT, promise);
  }, [data, handleApiPromise]);
  const update = useCallback(approverOwnerIds => {
    return productApprovalsApi.updateApproval(data, approverOwnerIds).then(response => {
      setData(response);
      return response;
    }).catch(errorResponse => {
      reportRequestException('updateApproval', errorResponse);
      throw errorResponse;
    });
  }, [data]);
  const api = useMemo(() => ({
    fetchLatest,
    create,
    recall,
    reject,
    approve,
    update
  }), [fetchLatest, create, recall, reject, approve, update]);
  return {
    data,
    isLoading,
    api,
    fetchError
  };
};
export default useApprovalData;
import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { PANTONE_FETCH_COLORS } from 'ContentData/actions/ActionTypes';
const pantone = (state = {}, action) => {
  switch (action.type) {
    case PANTONE_FETCH_COLORS:
      {
        const {
          response,
          error
        } = action;
        if (response) {
          return Object.assign({}, state, {
            pantoneColors: response,
            error: null
          });
        } else if (error) {
          return Object.assign({}, state, {
            error: error.responseJSON,
            pantoneColors: null
          });
        } else return state;
      }
    default:
      return state;
  }
};
export default combineReducers({
  pantone,
  pantoneColorsRequestStatus: createGenericRequestStatusReducer(PANTONE_FETCH_COLORS)
});
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { UNINITIALIZED } from 'ContentUtils/constants/RequestStatus';
import { getHasTheme } from 'ContentEditorUI/redux/selectors/themeSelectors';
const getCategorizations = basicSelector(state => state.categorizations);
export const getCategoryTypes = createSelector([getCategorizations], categorizations => categorizations.categoryTypes);
export const getShouldFetchCategoryTypes = createSelector([getCategorizations], categorizations => categorizations && categorizations.categoryTypeRequestStatus === UNINITIALIZED);
export const getShouldFetchCategorizedModules = createSelector([getCategorizations], categorizations => categorizations && categorizations.categorizedModulesRequestStatus === UNINITIALIZED);
export const getCategoryDisplayNameByType = createSelector([getCategoryTypes, getHasTheme], (categoryTypes, hasTheme) => {
  const categoryTypesMap = {
    ALL: 'All'
  };
  if (hasTheme) {
    categoryTypesMap.THEME = 'Theme';
  }
  categoryTypes.forEach(categoryType => {
    categoryTypesMap[categoryType.name] = categoryType.displayName;
  });
  return categoryTypesMap;
});
export const domainIsContextActiveDomain = domain => {
  return domain.hasOwnProperty('portalId');
};
export const domainIsContentEditDomain = domain => {
  return domain.hasOwnProperty('portal_id');
};
export const domainIsLegacy = domain => {
  return domainIsContentEditDomain(domain) ? !!domain.is_legacy_domain : !!domain.isLegacyDomain;
};
export const domainIsStaging = domain => {
  return domainIsContentEditDomain(domain) ? domain.full_category_key === '1:staged_page' : domain.fullCategoryKey === '1:staged_page';
};
const FREE_DOMAIN_REGEX = /^.+\.hubspotpagebuilder(qa)?(-[A-z0-9]+)?\..+/;
export const domainIsFreePageBuilder = domain => domain && !!domain.domain.match(FREE_DOMAIN_REGEX);
export const getProtocol = domain => {
  const isSslOnly = domainIsContentEditDomain(domain) ? domain.is_ssl_only : domain.isSslOnly;
  return isSslOnly ? 'https://' : 'http://';
};
export const getDisplayForDomain = domain => {
  const protocol = getProtocol(domain);
  const domainPath = domain.domain;
  return `${protocol}${domainPath}/`;
};
'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["type"];
import { ALIGNMENTS } from 'ContentUtils/constants/Alignments';
import SIZE_TYPES from 'ContentUtils/constants/BackgroundImageSizeTypes';
import { API_BACKGROUND_TYPES, getTreeNodeDomProperties } from '../helpers';
import { generateBackgroundColorCss } from './backgroundColor';
import { convertPositionValueToPositionStyleString, generateBackgroundImageCss } from './backgroundImage';
import { generateBackgroundGradientCss } from './backgroundLinearGradient';
export const calculateBreakpointBackgroundLayersClassesForTreeNode = (treeNode, styleValue, breakpoint) => {
  if (!styleValue || !Array.isArray(styleValue)) {
    console.warn('Background layers must be an array of background layer values');
    return [];
  }
  const backgroundImageArray = [];
  const backgroundPositionArray = [];
  const backgroundSizeArray = [];
  styleValue.forEach(layer => {
    const {
        type
      } = layer,
      layerStyleValue = _objectWithoutPropertiesLoose(layer, _excluded);
    let backgroundImage;
    let backgroundPosition = convertPositionValueToPositionStyleString(ALIGNMENTS.TOP_LEFT);
    let backgroundSize = SIZE_TYPES.auto;
    switch (type) {
      case API_BACKGROUND_TYPES.COLOR:
        {
          const backgroundColorCss = generateBackgroundColorCss(layerStyleValue);
          if (!backgroundColorCss) break;
          const {
            backgroundColor
          } = backgroundColorCss;
          backgroundImage = `linear-gradient(${backgroundColor}, ${backgroundColor})`;
          break;
        }
      case API_BACKGROUND_TYPES.IMAGE:
        {
          const backgroundImageCss = generateBackgroundImageCss(layerStyleValue);
          if (!backgroundImageCss) break;
          ({
            backgroundImage,
            backgroundPosition,
            backgroundSize
          } = backgroundImageCss);
          break;
        }
      case API_BACKGROUND_TYPES.GRADIENT:
        {
          const backgroundGradientCss = generateBackgroundGradientCss(layerStyleValue);
          if (!backgroundGradientCss) break;
          ({
            backgroundImage
          } = backgroundGradientCss);
          break;
        }
      default:
        return;
    }
    if (backgroundImage && backgroundPosition && backgroundSize) {
      backgroundImageArray.push(backgroundImage);
      backgroundPositionArray.push(backgroundPosition);
      backgroundSizeArray.push(backgroundSize);
    }
  });
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  const cssProperties = {
    'background-image': `${backgroundImageArray.join(', ')} !important`,
    'background-size': `${backgroundSizeArray.join(', ')} !important`,
    'background-position': `${backgroundPositionArray.join(', ')} !important`
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-layers`,
    cssSelector: `.${rendererName}-background-layers`,
    cssProperties,
    mediaQuery: breakpoint.mediaQuery
  }];
};
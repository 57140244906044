'use es6';

import { getInitialCollaborationSidebarState, mergeCollaborationSidebarState } from 'collaboration-sidebar';
import { UPDATE_COLLABORATION_SIDEBAR_STATE } from 'ContentEditorUI/redux/actions/actionTypes';

/*
  ContentEditorUI apps maintain their own collaborationSidebarState due to the
  need for it to work across multiple frames.

  Docs: https://product.hubteam.com/docs/marketing-orchestration/projects/collaboration-sidebar/comments/commentableAreasAcrossContexts.html
*/
export default function createCollaborationSidebarReducer(initialState = {}) {
  return (state = getInitialCollaborationSidebarState(initialState), {
    type,
    payload
  }) => {
    switch (type) {
      case UPDATE_COLLABORATION_SIDEBAR_STATE:
        {
          return mergeCollaborationSidebarState(state, payload);
        }
      default:
        return state;
    }
  };
}
import { useCallback, useState } from 'react';
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { DEFAULT_CONTENT_USAGE_LIMITS, DEFAULT_USAGE_LIMIT_DATA } from 'ContentEditorUI/context/ContentUsageLimitsContext';
const DEFAULT_PAGE_LIMITS_DATA = {
  landingPages: DEFAULT_USAGE_LIMIT_DATA,
  sitePages: DEFAULT_USAGE_LIMIT_DATA,
  sharedPages: DEFAULT_USAGE_LIMIT_DATA
};
const getUsageDataIfPromiseFulfilled = response => {
  return response.status === 'fulfilled' ? response.value : {
    quantityUsed: 0,
    limit: null
  };
};
const getPagesUsageDataIfPromiseFulfilled = response => {
  return response.status === 'fulfilled' ? response.value : DEFAULT_PAGE_LIMITS_DATA;
};
const fetchSharedPagesUsage = () => {
  return http.get('cospages/v2/pages/usage').then(response => {
    const limits = DEFAULT_PAGE_LIMITS_DATA;
    if (response) {
      response.forEach(limitsData => {
        let key;
        switch (limitsData.limitName) {
          case 'cms-landing-pages':
            key = 'landingPages';
            break;
          case 'cms-site-pages':
            key = 'sitePages';
            break;
          case 'cms-pages':
            key = 'sharedPages';
            break;
          default:
        }
        if (key) {
          limits[key] = {
            limit: limitsData.limit || null,
            quantityUsed: limitsData.quantityUsed
          };
        }
      });
    }
    return limits;
  });
};
const fetchContentUsageLimits = () => {
  const requests = [http.get(`blogs/v3/blog-posts/${PortalIdParser.get()}/usage`), http.get(`blog-settings/v1/blogs/${PortalIdParser.get()}/usage`), fetchSharedPagesUsage()];
  return Promise.allSettled(requests).then(responses => {
    const [blogPostsUsageResponse, blogUsageResponse, pagesUsageResponse] = responses;
    const dataFromSharedPageLimitApiResponse = getPagesUsageDataIfPromiseFulfilled(pagesUsageResponse);
    return {
      blogPosts: getUsageDataIfPromiseFulfilled(blogPostsUsageResponse),
      blog: getUsageDataIfPromiseFulfilled(blogUsageResponse),
      sharedPages: dataFromSharedPageLimitApiResponse.sharedPages,
      sitePages: dataFromSharedPageLimitApiResponse.sitePages,
      landingPages: dataFromSharedPageLimitApiResponse.landingPages
    };
  });
};
const useFetchContentUsageLimits = () => {
  const [contentUsageLimits, setContentUsageLimits] = useState(DEFAULT_CONTENT_USAGE_LIMITS);
  const [requestStatus, setRequestStatus] = useState('UNINITIALIZED');
  const fetchContentUsage = useCallback(() => {
    setRequestStatus('PENDING');
    return fetchContentUsageLimits().then(response => {
      setContentUsageLimits(response);
      setRequestStatus('SUCCEEDED');
    }, () => setRequestStatus('FAILED'));
  }, []);
  return {
    requestStatus,
    fetchContentUsage,
    contentUsageLimits
  };
};
export default useFetchContentUsageLimits;
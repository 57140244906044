'use es6';

import Row from './Row';
import Cell from './Cell';
import { cloneSubtree, getCellOrRow, concatenateName, newBlankSlate, mapAndSwapValue, remapNodeToTree, remapNodesToTree, removeCellOrRow, assertIsValidRow, assertIsValidCell, cloneAndModifyState, assertInternalStateIntegrity, getChildrenMinusThoseToDelete, validateBeforeAndAfterTargetParams } from './privateHelpers';
import { iterateFrom } from './helpers';
import StaticSection from '../LayoutDataTree/StaticSection';
import { getIsStaticSectionFromValue } from '../LayoutDataTree/helpers';

// let treeDebugCtr = 1;

class CellAndRowsTree {
  constructor({
    rootName,
    snapshot,
    thisProperties
  } = {}) {
    // Defaults, can be overridden by thisProperties
    this.shouldPreventEmptyRows = true;

    // Custom Cell and Row class implementations can be provided, but the intention is to only do
    // that for temporary purposes or special cases for now (e.g. to test very app specific logic
    // before deciding/figuring out how to push that into the library)
    this.CellClass = Cell;
    this.RowClass = Row;
    this.StaticSectionClass = StaticSection;

    // Hack to get around restriction about not using `this` until after super is called
    if (thisProperties) {
      Object.keys(thisProperties).forEach(key => {
        this[key] = thisProperties[key];
      });
    }
    if (snapshot) {
      this.resetToSnapshot(snapshot);
    } else {
      this._state = newBlankSlate(this, {
        rootName
      });
    }
  }
  clone(newState) {
    return new CellAndRowsTree({
      snapshot: newState || this.stateSnapshot()
    });
  }
  isMutableTree() {
    return this._isTemporaryMutableTree === true;
  }
  isEmpty() {
    return !this.getRootCell().hasRows();
  }
  makeTemporaryMutableClone(newState) {
    if (this.isMutableTree()) {
      return this;
    }
    const treeClone = this.clone(newState);
    treeClone._isTemporaryMutableTree = true;
    return treeClone;
  }
  getRootName() {
    return this._state.rootCellName;
  }
  getRootCell() {
    return this.findCell(this.getRootName());
  }
  createNewCell(...args) {
    return new this.CellClass(this, ...args);
  }
  createNewRow(...args) {
    return new this.RowClass(this, ...args);
  }
  createNewStaticSection(...args) {
    return new this.StaticSectionClass(this, ...args);
  }
  allStaticSectionModules() {
    const allStaticSections = this.allRows().filter(row => row.isStaticSection());
    let staticSectionModules = [];
    if (allStaticSections.length === 0) {
      // Short-circuit
      return [];
    }
    allStaticSections.forEach(staticSection => {
      const moduleChildren = staticSection.getModuleChildren();
      staticSectionModules = staticSectionModules.concat(moduleChildren);
    });
    return staticSectionModules;
  }
  findStaticSectionModuleByName(cellName) {
    return this.allStaticSectionModules().find(module => module.getName() === cellName);
  }
  hasCell(cellName) {
    return Boolean(this._state.columnsByName[cellName]) || this.hasStaticSectionModule(cellName);
  }
  hasStaticSectionModule(cellName) {
    return Boolean(this.findStaticSectionModuleByName(cellName));
  }
  findCell(cellName) {
    if (this.hasCell(cellName)) {
      return this._state.columnsByName[cellName] || this.findStaticSectionModuleByName(cellName);
    }
    throw new Error({
      message: 'No such cell',
      info: `No such cell ${cellName} in tree (root = ${this.getRootName()})`
    });
  }
  hasRow(rowName) {
    return !!this._state.rowsByName[rowName];
  }
  findRow(rowName) {
    if (this.hasRow(rowName)) {
      return this._state.rowsByName[rowName];
    }
    throw new Error({
      message: 'No such row',
      info: `No such row ${rowName} in tree (root = ${this.getRootName()})`
    });
  }

  // This updates the staticSection row - where module exists.
  modifyStaticSectionModuleValue(staticSectionModuleName, moduleValue) {
    const staticSectionModule = this.findCell(staticSectionModuleName);
    const previousStaticSection = staticSectionModule.getParent();
    const pathToModule = ['params', 'modules', staticSectionModuleName];

    // Only override the moduleValue (not the static section value)
    const newValue = Object.assign({}, previousStaticSection.getValue());
    // Mutates it.
    this._mergeIntoPath(newValue, moduleValue, pathToModule);
    return this.modifyRowValue(previousStaticSection.getName(), newValue);
  }
  modifyRowValue(rowName, value) {
    const previousRow = this.findRow(rowName);
    const newRow = previousRow.cloneAndChange({
      value
    });
    const newTree = cloneAndModifyState(this, {
      modifiedRows: [newRow]
    });
    return {
      tree: newTree,
      newRow: remapNodeToTree(newRow, newTree)
    };
  }
  mergeIntoRowValue(rowName, partialValue, {
    path = []
  } = {}) {
    const previousRow = this.findRow(rowName);
    const newValue = Object.assign({}, previousRow.getValue());
    this._mergeIntoPath(newValue, partialValue, path);
    return this.modifyRowValue(rowName, newValue);
  }
  modifyCellValue(cellName, value) {
    const previousCell = this.findCell(cellName);
    if (previousCell.isStaticSectionModule()) {
      // Static Sections edge case:
      return this.modifyStaticSectionModuleValue(cellName, value);
    }
    const newCell = previousCell.cloneAndChange({
      value
    });
    const newTree = cloneAndModifyState(this, {
      modifiedColumns: [newCell]
    });
    return {
      tree: newTree,
      newCell: remapNodeToTree(newCell, newTree)
    };
  }
  mergeIntoCellValue(cellName, partialValue, {
    path = []
  } = {}) {
    const previousCell = this.findCell(cellName);
    const newValue = Object.assign({}, previousCell.getValue());
    this._mergeIntoPath(newValue, partialValue, path);
    return this.modifyCellValue(cellName, newValue);
  }

  //NOTE Merging into an array by index isn't supported
  // TODO: Refactor this so it doesn't mutate.
  _mergeIntoPath(obj, partialValue, path) {
    // Iterate down the supplied path, cloning objects as we go
    let currObjToMergeInfo = obj;
    for (const key of path) {
      let newSubValue;
      const currMergeInfo = currObjToMergeInfo[key];
      if (currMergeInfo != null) {
        newSubValue = Array.isArray(currMergeInfo) ? [...currMergeInfo] : Object.assign({}, currMergeInfo);
      } else {
        newSubValue = Array.isArray(currMergeInfo) ? [] : {};
      }
      currObjToMergeInfo[key] = newSubValue;
      currObjToMergeInfo = newSubValue;
    }

    // And then merge into the last object we iterated to (still newValue if path === [])
    currObjToMergeInfo = Object.assign(currObjToMergeInfo, partialValue);
    return currObjToMergeInfo;
  }
  modifySpecificKeyInCellValue(cellName, key, valueForKey) {
    const previousCell = this.findCell(cellName);
    const {
      tree: newTree,
      newCell
    } = this.modifyCellValue(previousCell.getName(), Object.assign({}, previousCell.getValue(), {
      [key]: valueForKey
    }));
    return {
      tree: newTree,
      newCell
    };
  }

  // TODO make this an internal only helper (and rely on insertNew*At, move*To, and append/prepend funcs for public API)
  insertRow(cellNameToInsertIn, {
    destRowIndex,
    // The index to locate the cell _after_ the insert/move is done (not the index based on current columns)
    existingCellName,
    // Optional, the cell to move (to a row) instead of creating a new row
    existingRowName,
    // Optional, the row to move instead of creating a new row
    cellNameToClone,
    // Optional
    rowNameToClone,
    // Optional
    newRowName,
    // Optional, if unset a name is automatically generated
    newCellName,
    // Optional, if newCellValue is set a name is automatically generated
    newCellValue,
    newRowValue,
    originTree,
    // Optional, used only when moving nodes between trees

    // Internal stuff
    _treeToOperateOn
  }) {
    if (typeof destRowIndex === 'undefined') {
      throw new Error(`No destRowIndex provided to insertRow`);
    }
    if (existingRowName && newCellValue) {
      throw new Error(`Can't move existing row and insert a new cell at the same time`);
    }
    if (cellNameToClone && newCellValue) {
      throw new Error(`Can't clone existing cell and insert a new cell at the same time`);
    }
    if (rowNameToClone && newCellValue) {
      throw new Error(`Can't clone existing row and insert a new cell at the same time`);
    }
    if (originTree && originTree === this) {
      throw new Error('Do not pass originTree to insertRow when moving nodes within the same tree');
    }
    let tree = _treeToOperateOn || this;
    if (cellNameToInsertIn === existingCellName) {
      // Attempting to split a column with itself is a no-op
      return {
        tree: this,
        nothingChanged: true
      };
    }
    const cellToInsertIn = tree.findCell(cellNameToInsertIn);
    let modifiedCellToInsertIn = cellToInsertIn;
    let rowToMoveOrClone;
    let newOrMovedCell;
    let newGrandParentCell;
    let namesMoved = [];
    const modifiedColumns = [];
    const modifiedRows = [];
    let mapOfClonedToOldNodeName;

    // If moving an existing module or row...
    if (existingCellName) {
      if (originTree) {
        const existingCell = originTree.findCell(existingCellName);
        newOrMovedCell = existingCell;
        // Add all the children of this row to modifiedRows and modifiedColumns
        // so their treeRefs will be updated when we call cloneAndModifyState
        const {
          cells,
          rows
        } = originTree.allCellsAndRowsGroupedByType({
          fromName: existingCellName
        });
        modifiedColumns.push(...cells);
        modifiedRows.push(...rows);
        const deleteResult = originTree.removeCell(existingCellName, {
          automaticallyDeleteDescendents: true
        });
        originTree = deleteResult.tree;
      } else {
        const existingCell = tree.findCell(existingCellName);
        namesMoved = [existingCellName];
        newOrMovedCell = existingCell;

        // If moving an existing module, remove it from the old location first
        const deleteResult = tree.removeCell(existingCellName, {
          automaticallyDeleteDescendents: false,
          ancestorToNotAutoDelete: cellNameToInsertIn
        });
        tree = deleteResult.tree;
        modifiedCellToInsertIn = tree.findCell(cellNameToInsertIn);
      }
    } else if (existingRowName) {
      if (originTree) {
        rowToMoveOrClone = originTree.findRow(existingRowName);
        // Add all the children of this row to modifiedRows and modifiedColumns
        // so their treeRefs will be updated when we call cloneAndModifyState
        const {
          cells,
          rows
        } = originTree.allCellsAndRowsGroupedByType({
          fromName: existingRowName
        });
        modifiedColumns.push(...cells);
        modifiedRows.push(...rows);
        const deleteResult = originTree.removeRow(existingRowName, {
          automaticallyDeleteDescendents: true
        });
        originTree = deleteResult.tree;
      } else {
        const existingRowIndex = modifiedCellToInsertIn.getRowNames().indexOf(existingRowName);
        if (existingRowIndex !== -1 && existingRowIndex === destRowIndex) {
          // Moving a row to the place it already exists is a no-op
          return {
            tree: this,
            nothingChanged: true
          };
        }
        rowToMoveOrClone = tree.findRow(existingRowName);
        namesMoved = [existingRowName];
        const deleteResult = tree.removeRow(existingRowName, {
          automaticallyDeleteDescendents: false
        });
        tree = deleteResult.tree;
        modifiedCellToInsertIn = tree.findCell(cellNameToInsertIn);
      }
    } else if (rowNameToClone) {
      const cloneSource = tree.findRow(rowNameToClone);
      const cloneResult = cloneSubtree(tree, cloneSource);
      tree = cloneResult.tree;
      modifiedRows.push(...cloneResult.modifiedRows);
      modifiedColumns.push(...cloneResult.modifiedColumns);
      mapOfClonedToOldNodeName = cloneResult.mapOfClonedToOldNodeName;
      rowToMoveOrClone = cloneResult.clonedNode;
    } else if (cellNameToClone) {
      const cloneSource = tree.findCell(cellNameToClone);
      const cloneResult = cloneSubtree(tree, cloneSource);
      tree = cloneResult.tree;
      modifiedRows.push(...cloneResult.modifiedRows);
      modifiedColumns.push(...cloneResult.modifiedColumns);
      mapOfClonedToOldNodeName = cloneResult.mapOfClonedToOldNodeName;
      newOrMovedCell = cloneResult.clonedNode;
    }

    // When adding a row to an item with no rows, need to take "existing" content and move it to a row
    // It is a bit arbitrary to prevent this from happening on the root, but I think that is correct
    // because we assume that the root _always_ has rows and never actually has content itself
    if (!cellToInsertIn.isRoot() && cellToInsertIn.shouldSplitInsteadOfInsertingRow()) {
      const splitResult = this.splitCellInToFirstRowOfNewCellWrapper(modifiedCellToInsertIn, {
        _treeToOperateOn: tree
      });
      newGrandParentCell = splitResult.newGrandParentCell;
      tree = splitResult.tree;
      modifiedCellToInsertIn = newGrandParentCell;
    }
    if (!existingRowName && !rowNameToClone) {
      if (!newRowName) {
        const tempResult = tree._nextRowName();
        newRowName = tempResult.newName;
        tree = tempResult.tree;
      }

      // Create new cell (only if newCellValue was passed and there is not an existingCellName being moved)
      if (newOrMovedCell) {
        if (originTree) {
          // Reset the moved cell's parent and the treeRef
          newOrMovedCell = newOrMovedCell.cloneAndChange({
            parentRowName: newRowName,
            treeRef: tree
          });
        } else {
          // Reset the moved cell's parent
          newOrMovedCell = newOrMovedCell.cloneAndChange({
            parentRowName: newRowName
          });
        }
      } else if (newCellValue) {
        if (!newCellName) {
          const tempResult2 = tree._nextCellName({
            newCellValue
          });
          newCellName = tempResult2.newName;
          tree = tempResult2.tree;
        }
        newOrMovedCell = tree.createNewCell({
          name: newCellName,
          parentRowName: newRowName,
          value: newCellValue
        });
      }

      //handle creating new section
      if (!newRowValue) {
        newRowValue = {
          editorId: newRowName
        };
      } else if (!newRowValue.editorId) {
        newRowValue.editorId = newRowName;
      }
      const isStaticSection = getIsStaticSectionFromValue(newRowValue);
      if (isStaticSection) {
        const newRow = tree.createNewStaticSection({
          name: newRowName,
          value: newRowValue,
          parentCellName: modifiedCellToInsertIn.getName()
        });
        rowToMoveOrClone = newRow;
      } else {
        const newRow = tree.createNewRow({
          name: newRowName,
          columnNames: newOrMovedCell ? [newOrMovedCell.getName()] : [],
          parentCellName: modifiedCellToInsertIn.getName(),
          value: newRowValue
        });
        rowToMoveOrClone = newRow;
      }
    } else {
      // If we are moving or cloning an existing row, make sure that it's new parent is set correctly (even
      // when a cell is split to have a new grandparent and two row)
      // No need to set treeRef here when moving between trees because rowToMove gets added to modifiedRows

      const rowToMoveOrCloneValue = rowToMoveOrClone.getValue();

      //if cloning
      //rowToMoveOrCloneValue should already have value of the original node but adding for precaution
      if (!existingRowName && rowToMoveOrCloneValue) {
        const originalValue = rowToMoveOrCloneValue;
        const newClonedRowName = rowToMoveOrClone.getName();
        //update rowValue's editorId
        //if editorId is still of the original node
        if (!originalValue.editorId || originalValue.editorId !== newClonedRowName) {
          rowToMoveOrCloneValue.editorId = newClonedRowName;
        }
      }
      rowToMoveOrClone = rowToMoveOrClone.cloneAndChange({
        parentCellName: modifiedCellToInsertIn.getName(),
        value: rowToMoveOrCloneValue
      });
    }
    const newParentRows = modifiedCellToInsertIn.getRowNames().slice();
    if (destRowIndex === -1) {
      destRowIndex = modifiedCellToInsertIn.getRowNames().length + 1;
    }
    newParentRows.splice(destRowIndex, 0, rowToMoveOrClone.getName());
    modifiedCellToInsertIn = modifiedCellToInsertIn.cloneAndChange({
      rowNames: newParentRows
    });
    if (newOrMovedCell) {
      modifiedColumns.unshift(newOrMovedCell);
    }
    modifiedColumns.unshift(modifiedCellToInsertIn);
    modifiedRows.unshift(rowToMoveOrClone);
    const newTree = cloneAndModifyState(tree, {
      modifiedColumns,
      modifiedRows
    });
    const result = {
      tree: newTree,
      originTree,
      modifiedColumns: remapNodesToTree(modifiedColumns, newTree),
      modifiedRows: remapNodesToTree(modifiedRows, newTree),
      // Make sure we get the new row/cell that points to the new tree reference
      newRow: remapNodeToTree(rowToMoveOrClone, newTree)
    };
    if (newOrMovedCell) {
      result.newCell = remapNodeToTree(newOrMovedCell, newTree);
    }
    if (namesMoved) {
      result.namesMoved = namesMoved;
    }
    if (newGrandParentCell) {
      result.newGrandParentCell = remapNodeToTree(newGrandParentCell, newTree);
    }
    if (mapOfClonedToOldNodeName) {
      result.mapOfClonedToOldNodeName = mapOfClonedToOldNodeName;
    }
    return result;
  }
  prependRow(cellNameToInsertIn, {
    existingCellName,
    existingRowName,
    newRowName,
    newCellName,
    newCellValue,
    newRowValue,
    originTree,
    _treeToOperateOn
  } = {}) {
    return this.insertRow(cellNameToInsertIn, {
      destRowIndex: 0,
      existingCellName,
      existingRowName,
      newRowName,
      newCellName,
      newCellValue,
      newRowValue,
      originTree,
      _treeToOperateOn
    });
  }
  appendRow(cellNameToInsertIn, {
    existingCellName,
    existingRowName,
    newRowName,
    newCellName,
    newCellValue,
    newRowValue,
    originTree,
    _treeToOperateOn
  } = {}) {
    return this.insertRow(cellNameToInsertIn, {
      destRowIndex: -1,
      existingCellName,
      existingRowName,
      newRowName,
      newCellName,
      newCellValue,
      newRowValue,
      originTree,
      _treeToOperateOn
    });
  }
  removeRow(rowName, {
    automaticallyDeleteEmptyParents = true,
    automaticallyDeleteDescendents = true,
    ancestorToNotAutoDelete,
    // Internal stuff
    _treeToOperateOn
  } = {}) {
    const tree = _treeToOperateOn || this;
    const cellOrRowToDelete = this.findRow(rowName);
    const {
      tree: newTree,
      deletedColumns,
      deletedRows
    } = removeCellOrRow(tree, cellOrRowToDelete, {
      automaticallyDeleteEmptyParents,
      automaticallyDeleteDescendents,
      ancestorToNotAutoDelete,
      preventEmptiedRows: this.shouldPreventEmptyRows
    });
    return {
      tree: newTree,
      deletedColumns,
      deletedRows
    };
  }

  // TODO make this an internal only helper (and rely on insertNew*At, move*To, and append/prepend funcs for public API)
  insertColumn(rowNameToInsertIn, {
    destColumnIndex,
    // The index to locate the cell _after_ the insert/move is done (not the index based on current columns)
    existingCellName,
    // Optional, the cell to move instead of creating a new cell
    existingRowName,
    // Optional, the row to put into a newly created cell
    newCellName,
    // Optional, if not set a name is automatically generated
    cellNameToClone,
    // Optional
    newCellValue,
    originTree,
    // Optional, used only when moving nodes between trees

    // Internal stuff
    _treeToOperateOn,
    _removeCellOptions
  }) {
    if (typeof destColumnIndex === 'undefined') {
      throw new Error(`No destColumnIndex provided to insertColumn`);
    }
    if (originTree && originTree === this) {
      throw new Error('Do not pass originTree to insertColumn when moving nodes within the same tree');
    }
    if (existingCellName && existingRowName) {
      throw new Error('Do not pass in existingCellName AND existingRowName, pick one or the other');
    }
    if (cellNameToClone && newCellValue) {
      throw new Error(`Can't clone existing cell and insert a new cell at the same time`);
    }
    let tree = _treeToOperateOn || this;
    let cellToMove;
    let namesMoved;
    let rowToInsertIn = tree.findRow(rowNameToInsertIn);
    const modifiedColumns = [];
    const modifiedRows = [];
    let mapOfClonedToOldNodeName;
    if (existingCellName) {
      if (originTree) {
        // Handle moving between layout sections
        const existingCell = originTree.findCell(existingCellName);
        cellToMove = existingCell.cloneAndChange({
          parentRowName: rowNameToInsertIn,
          treeRef: tree
        });
        // Add all the children of this cell to modifiedRows and modifiedColumns
        // so their treeRefs will be updated when we call cloneAndModifyState
        const {
          cells,
          rows
        } = originTree.allCellsAndRowsGroupedByType({
          fromName: existingCellName
        });
        modifiedColumns.push(...cells);
        modifiedRows.push(...rows);
        _removeCellOptions = _removeCellOptions || {};
        _removeCellOptions.automaticallyDeleteDescendents = true;
        const deleteResult = originTree.removeCell(existingCellName, _removeCellOptions);
        originTree = deleteResult.tree;
      } else {
        const existingCellIndex = rowToInsertIn.getColumnNames().indexOf(existingCellName);
        if (existingCellIndex !== -1 && existingCellIndex === destColumnIndex) {
          // Moving a cell to the place it already exists is a no-op
          return {
            tree: this,
            nothingChanged: true
          };
        }
        const existingCell = tree.findCell(existingCellName);
        namesMoved = [existingCellName];
        cellToMove = existingCell.cloneAndChange({
          parentRowName: rowNameToInsertIn
        });
        _removeCellOptions = _removeCellOptions || {};
        _removeCellOptions.automaticallyDeleteDescendents = false;
        _removeCellOptions.ancestorToNotAutoDelete = rowNameToInsertIn;
        const deleteResult = tree.removeCell(existingCellName, _removeCellOptions);
        tree = deleteResult.tree;
        rowToInsertIn = remapNodeToTree(rowToInsertIn, tree);
      }
    } else if (cellNameToClone) {
      const cloneSource = tree.findCell(cellNameToClone);
      const cloneResult = cloneSubtree(tree, cloneSource);
      tree = cloneResult.tree;
      modifiedRows.push(...cloneResult.modifiedRows);
      modifiedColumns.push(...cloneResult.modifiedColumns);
      mapOfClonedToOldNodeName = cloneResult.mapOfClonedToOldNodeName;
      cellToMove = cloneResult.clonedNode;
      newCellName = cloneResult.clonedNode.getName();
    } else if (!newCellName) {
      // Create new cell name
      const tempResult = tree._nextCellName({
        newCellValue
      });
      newCellName = tempResult.newName;
      tree = tempResult.tree;
    }

    // Moving a row to the left or right of an existing column, creating a new wrapper cell
    // to house the row in the process
    if (existingRowName) {
      let rowToMove;
      const _removeRowOptions = {};
      if (originTree) {
        const existingRow = originTree.findRow(existingRowName);
        rowToMove = existingRow.cloneAndChange({
          parentCellName: newCellName,
          treeRef: tree
        });
        // Add all the children of this row to modifiedRows and modifiedColumns
        // so their treeRefs will be updated when we call cloneAndModifyState
        const {
          cells,
          rows
        } = originTree.allCellsAndRowsGroupedByType({
          fromName: existingRowName
        });
        modifiedColumns.push(...cells);
        modifiedRows.push(...rows);
        _removeRowOptions.automaticallyDeleteDescendents = true;
        const deleteResult = originTree.removeRow(existingRowName, _removeRowOptions);
        originTree = deleteResult.tree;
      } else {
        const existingRow = tree.findRow(existingRowName);
        rowToMove = existingRow.cloneAndChange({
          parentCellName: newCellName
        });
        _removeRowOptions.automaticallyDeleteDescendents = false;
        const deleteResult = tree.removeRow(existingRowName, _removeRowOptions);
        tree = deleteResult.tree;
      }
      modifiedRows.unshift(rowToMove);
      namesMoved = [rowToMove.getName()];

      // Create a new column cell and have it's rows be the one we moved
      cellToMove = tree.createNewCell({
        name: newCellName,
        parentRowName: rowNameToInsertIn,
        value: newCellValue,
        rowNames: [existingRowName]
      });
    }

    // Just creating an empty column
    if (!existingCellName && !existingRowName && !cellNameToClone) {
      cellToMove = tree.createNewCell({
        name: newCellName,
        parentRowName: rowNameToInsertIn,
        value: newCellValue
      });
    }
    const newParentColumns = rowToInsertIn.getColumnNames().slice();
    if (destColumnIndex === -1) {
      destColumnIndex = rowToInsertIn.getColumnNames().length + 1;
    }
    newParentColumns.splice(destColumnIndex, 0, cellToMove.getName());
    const modifiedRowToInsertIn = rowToInsertIn.cloneAndChange({
      columnNames: newParentColumns
    });
    modifiedColumns.unshift(cellToMove);
    modifiedRows.unshift(modifiedRowToInsertIn);
    const newTree = cloneAndModifyState(tree, {
      modifiedColumns,
      modifiedRows
    });
    const result = {
      tree: newTree,
      originTree,
      modifiedColumns: remapNodesToTree(modifiedColumns, newTree),
      modifiedRows: remapNodesToTree(modifiedRows, newTree),
      newCell: remapNodeToTree(cellToMove, newTree)
    };
    if (namesMoved) {
      result.namesMoved = namesMoved;
    }
    if (mapOfClonedToOldNodeName) {
      result.mapOfClonedToOldNodeName = mapOfClonedToOldNodeName;
    }
    return result;
  }
  appendColumn(rowNameToAppendTo, insertOptions = {}) {
    return this.insertColumn(rowNameToAppendTo, Object.assign({}, insertOptions, {
      destColumnIndex: -1
    }));
  }
  prependColumn(rowNameToAppendTo, insertOptions = {}) {
    return this.insertColumn(rowNameToAppendTo, Object.assign({}, insertOptions, {
      destColumnIndex: 0
    }));
  }
  splitCell() {
    throw new Error(`splitCell isn't implemented`);
  }
  removeCell(cellName, {
    automaticallyDeleteEmptyParents = true,
    automaticallyDeleteDescendents = true,
    ancestorToNotAutoDelete,
    _treeToOperateOn
  } = {}) {
    const cellToDelete = this.findCell(cellName);
    const {
      tree,
      deletedColumns,
      deletedRows
    } = removeCellOrRow(_treeToOperateOn || this, cellToDelete, {
      automaticallyDeleteEmptyParents,
      automaticallyDeleteDescendents,
      ancestorToNotAutoDelete,
      preventEmptiedRows: this.shouldPreventEmptyRows
    });
    return {
      tree,
      deletedColumns,
      deletedRows
    };
  }
  moveCellTo(existingCellName, {
    originTree,
    beforeCellName,
    afterCellName,
    beforeRowName,
    afterRowName,
    insideRowName
  }) {
    validateBeforeAndAfterTargetParams('moveCellTo', {
      beforeCellName,
      afterCellName,
      beforeRowName,
      afterRowName,
      insideRowName
    });
    let insideRow = insideRowName && this.findRow(insideRowName);

    // If using insideRow to move a cell to some row that already contains columns, treat that as inserting
    // the cell to the first column of of the row (by converting from `insideRow` to `beforeCellName`).
    // Plus that simplifies things further below, because that logic can assume insideRow is _only_ set if
    // the cell is being inserted into an empty row
    if (insideRow && insideRow.hasColumns()) {
      beforeCellName = insideRow.getColumnNames()[0];
      insideRow = insideRowName = undefined;
    }
    const targetCellName = beforeCellName || afterCellName;
    const targetRowName = beforeRowName || afterRowName;
    const targetName = targetCellName || targetRowName;
    const targetRowOrCell = targetName && getCellOrRow(this, targetName);
    const targetRowOrCellParent = targetName && targetRowOrCell.getParent();
    const childrenMinusThoseToDelete = getChildrenMinusThoseToDelete(this, {
      deletedName: originTree ? null : existingCellName,
      targetName
    });
    const targetIndex = childrenMinusThoseToDelete.indexOf(targetName);
    const isTargetingParentThatIsAutoDeleted = targetName && targetIndex === -1;
    if (!originTree && targetCellName === existingCellName || isTargetingParentThatIsAutoDeleted) {
      // Moving a cell before or after itself (or a parent that will auto-delete itself) is a no-op
      return {
        tree: this,
        nothingChanged: true
      };
    } else if (insideRowName) {
      return this.insertColumn(insideRowName, {
        destColumnIndex: 0,
        existingCellName,
        originTree
      });
    } else if (targetCellName) {
      return this.insertColumn(targetRowOrCellParent.getName(), {
        destColumnIndex: beforeCellName ? targetIndex : targetIndex + 1,
        existingCellName,
        originTree
      });
    } else {
      return this.insertRow(targetRowOrCellParent.getName(), {
        destRowIndex: beforeRowName ? targetIndex : targetIndex + 1,
        existingCellName,
        originTree
      });
    }
  }
  insertNewColumnAt({
    newCellName,
    // optional, if not set a name is automatically generated
    newCellValue = {},
    beforeCellName,
    afterCellName,
    beforeRowName,
    afterRowName
  }) {
    validateBeforeAndAfterTargetParams('insertNewColumnAt', {
      beforeCellName,
      afterCellName,
      beforeRowName,
      afterRowName
    });
    const targetCellName = beforeCellName || afterCellName;
    const targetRowName = beforeRowName || afterRowName;
    if (targetCellName) {
      const targetedCell = this.findCell(targetCellName);
      const targetParentRow = targetedCell.getParent();
      const targetColumnIndex = targetParentRow.indexOfColumn(targetCellName);
      return this.insertColumn(targetParentRow.getName(), {
        destColumnIndex: beforeCellName ? targetColumnIndex : targetColumnIndex + 1,
        newCellName,
        newCellValue
      });
    } else {
      const targetedRow = this.findRow(targetRowName);
      const targetParentCell = targetedRow.getParent();
      const targetRowIndex = targetParentCell.indexOfRow(targetRowName);
      return this.insertRow(targetParentCell.getName(), {
        destRowIndex: beforeRowName ? targetRowIndex : targetRowIndex + 1,
        newCellName,
        newCellValue
      });
    }
  }
  moveRowTo(existingRowName, {
    originTree,
    beforeCellName,
    afterCellName,
    beforeRowName,
    afterRowName,
    insideCellName,
    newWrapperCellValue
  }) {
    validateBeforeAndAfterTargetParams('moveRowTo', {
      beforeCellName,
      afterCellName,
      beforeRowName,
      afterRowName,
      insideCellName
    });
    let insideCell = insideCellName && this.findCell(insideCellName);

    // If using insideCell to move a row to the first row of some cell that already contains rows,
    // convert that to the proper beforeRowName. That way further down insideCell is _only_ used
    // if the cell is empty of rows
    if (insideCell && insideCell.hasRows()) {
      beforeRowName = insideCell.getRowNames()[0];
      insideCell = insideCellName = undefined;
    }
    const targetCellName = beforeCellName || afterCellName;
    const targetRowName = beforeRowName || afterRowName;
    const targetName = targetCellName || targetRowName;
    const targetNode = targetName && getCellOrRow(this, targetName);
    const targetNodeParent = targetName && targetNode.getParent();
    const childrenMinusThoseToDelete = getChildrenMinusThoseToDelete(this, {
      deletedName: originTree ? null : existingRowName,
      targetName
    });
    const targetIndex = childrenMinusThoseToDelete.indexOf(targetName);
    const isTargetingParentThatIsAutoDeleted = targetName && targetIndex === -1;
    if (!originTree && targetRowName === existingRowName || isTargetingParentThatIsAutoDeleted) {
      // Moving a row before or after itself is a no-op
      return {
        tree: this,
        nothingChanged: true
      };
    } else if (insideCellName) {
      // Moving a row into a cell (that may not have any existing rows to target)
      return this.insertRow(insideCellName, {
        destRowIndex: 0,
        existingRowName,
        originTree
      });
    } else if (targetCellName) {
      if (!newWrapperCellValue) {
        throw new Error('Must pass newWrapperCellValue when moving row to column position');
      }
      return this.insertColumn(targetNodeParent.getName(), {
        destColumnIndex: beforeCellName ? targetIndex : targetIndex + 1,
        newCellValue: newWrapperCellValue,
        existingRowName,
        originTree
      });
    } else {
      return this.insertRow(targetNodeParent.getName(), {
        destRowIndex: beforeRowName ? targetIndex : targetIndex + 1,
        existingRowName,
        originTree
      });
    }
  }
  insertNewRowAt({
    newCellName,
    // optional, if newCellValue is set a name is automatically generated
    newCellValue,
    beforeCellName,
    afterCellName,
    beforeRowName,
    afterRowName
  }) {
    validateBeforeAndAfterTargetParams('insertNewRowAt', {
      beforeCellName,
      afterCellName,
      beforeRowName,
      afterRowName
    });
    const targetCellName = beforeCellName || afterCellName;
    const targetRowName = beforeRowName || afterRowName;
    if (targetCellName) {
      throw new Error('Moving a row to a column is not yet supported');
      // const targetedCell = this.findCell(targetCellName);
      // const targetParentRow = targetedCell.getParent();
      // const targetColumnIndex = targetParentRow.indexOfColumn(targetCellName);
      //
      // return this.insertColumn(targetParentRow.getName(), {
      //   destColumnIndex: beforeCellName ? targetColumnIndex : targetColumnIndex + 1,
      //   newCellName,
      //   newCellValue,
      // });
      // }
    } else {
      const targetedRow = this.findRow(targetRowName);
      const targetParentCell = targetedRow.getParent();
      const targetRowIndex = targetParentCell.indexOfRow(targetRowName);
      return this.insertRow(targetParentCell.getName(), {
        destRowIndex: beforeRowName ? targetRowIndex : targetRowIndex + 1,
        newCellName,
        newCellValue
      });
    }
  }
  cloneNewCellBelow(cellNameToClone) {
    const targetedCell = this.findCell(cellNameToClone);
    const targetParentRow = targetedCell.getParent();
    const targetGrandParentCell = targetParentRow.getParent();
    const targetRowIndex = targetGrandParentCell.indexOfRow(targetParentRow.getName());
    let cellToInsertIn;
    let destRowIndex;
    if (targetedCell.shouldSplitInsteadOfInsertingRow() && targetParentRow.getNumberColumns() > 1) {
      cellToInsertIn = targetedCell;
      destRowIndex = 1;
    } else {
      cellToInsertIn = targetGrandParentCell;
      destRowIndex = targetRowIndex + 1;
    }
    return this.insertRow(cellToInsertIn.getName(), {
      destRowIndex,
      cellNameToClone
    });
  }
  cloneNewCellToRight(cellNameToClone) {
    const targetedCell = this.findCell(cellNameToClone);
    const targetParentRow = targetedCell.getParent();
    const targetColumnIndex = targetParentRow.indexOfColumn(cellNameToClone);
    return this.insertColumn(targetParentRow.getName(), {
      destColumnIndex: targetColumnIndex + 1,
      cellNameToClone
    });
  }
  cloneNewRowBelow(rowNameToClone) {
    const targetedRow = this.findRow(rowNameToClone);
    const targetParentCell = targetedRow.getParent();
    const targetRowIndex = targetParentCell.indexOfRow(rowNameToClone);
    return this.insertRow(targetParentCell.getName(), {
      destRowIndex: targetRowIndex + 1,
      rowNameToClone
    });
  }
  iterate(iterateFn) {
    iterateFrom(this.getRootCell(), ({
      cell,
      row,
      depth
    }) => {
      return iterateFn({
        cell,
        row,
        depth
      });
    }, {
      includeStart: true
    });
  }
  iterateFrom(fromName, {
    includeStart,
    iterateFn
  }) {
    if (!this.hasCell(fromName) && !this.hasRow(fromName)) {
      throw new Error(`Node: "${fromName}" does not exist in this tree, cannot iterate from.`);
    }
    const node = getCellOrRow(this, fromName);
    iterateFrom(node, ({
      cell,
      row,
      depth
    }) => {
      return iterateFn({
        cell,
        row,
        depth
      });
    }, {
      includeStart
    });
  }
  allCells({
    fromName,
    includeStart
  } = {}) {
    fromName = fromName ? getCellOrRow(this, fromName) : this.getRootCell();
    const result = [];
    iterateFrom(fromName, ({
      cell
    }) => {
      if (cell) {
        result.push(cell);
      }
    }, {
      includeStart
    });
    return result;
  }
  allLeafCells({
    fromName,
    includeStart
  } = {}) {
    return this.allCells({
      fromName,
      includeStart
    }).filter(cell => cell.isLeaf());
  }
  allRows({
    fromName,
    includeStart
  } = {}) {
    fromName = fromName ? getCellOrRow(this, fromName) : this.getRootCell();
    const result = [];
    iterateFrom(fromName, ({
      row
    }) => {
      if (row) {
        result.push(row);
      }
    }, {
      includeStart
    });
    return result;
  }
  allCellsAndRows({
    fromName,
    includeStart
  } = {}) {
    fromName = fromName ? getCellOrRow(this, fromName) : this.getRootCell();
    const result = [];
    iterateFrom(fromName, ({
      cell,
      row
    }) => {
      result.push(cell || row);
    }, {
      includeStart
    });
    return result;
  }
  allCellsAndRowsGroupedByType({
    fromName,
    includeStart
  } = {}) {
    fromName = fromName ? getCellOrRow(this, fromName) : this.getRootCell();
    const result = {
      cells: [],
      rows: []
    };
    iterateFrom(fromName, ({
      cell,
      row
    }) => {
      if (cell) {
        result.cells.push(cell);
      } else {
        result.rows.push(row);
      }
    }, {
      includeStart
    });
    return result;
  }
  stateSnapshot() {
    return Object.assign({}, this._state, {
      columnsByName: Object.assign({}, this._state.columnsByName),
      rowsByName: Object.assign({}, this._state.rowsByName)
    });
  }

  // Validates the whole tree, only really necessary after working with a mutable clone because
  // normally every incremental change is validated
  validateWholeTree() {
    iterateFrom(this.getRootCell(), ({
      cell,
      row
    }) => {
      if (cell && this.isInvalidCell) {
        assertIsValidCell(cell);
      } else if (row && this.isInvalidRow) {
        assertIsValidRow(row);
      }
    });
  }
  resetToSnapshot(stateSnapshot) {
    const columnsByName = {};
    const rowsByName = {};

    // Ugh, for the tree reference to be fully immutable, I need to reset each row and cell's
    // treeRef every change 😬
    Object.keys(stateSnapshot.columnsByName).forEach(colName => columnsByName[colName] = stateSnapshot.columnsByName[colName].cloneAndChange({
      treeRef: this
    }));
    Object.keys(stateSnapshot.rowsByName).forEach(rowName => rowsByName[rowName] = stateSnapshot.rowsByName[rowName].cloneAndChange({
      treeRef: this
    }));
    this._state = Object.assign({}, stateSnapshot, {
      columnsByName,
      rowsByName
    });
  }
  printTreeWithNames({
    indent = '  '
  } = {}) {
    iterateFrom(this.getRootCell(), ({
      cell,
      row,
      depth
    }) => {
      const node = cell || row;
      const whitespace = Array(depth).fill(indent).join('');
      console.log(`${whitespace}${node.getName()}`);
    }, {
      includeStart: true
    });
  }
  printTreeWithValues({
    indent = '  '
  } = {}) {
    iterateFrom(this.getRootCell(), ({
      cell,
      row,
      depth
    }) => {
      const whitespace = Array(depth).fill(indent).join('');
      if (row) {
        console.log(`${whitespace}${row.getName()} -`, row.getValue());
      } else if (cell) {
        console.log(`${whitespace}${cell.getName()} -`, cell.getValue());
      }
    }, {
      includeStart: true
    });
  }
  assertIntegrity() {
    assertInternalStateIntegrity(this);
  }

  // private methods, but intentionally in the class (instead of `privateHelpers`) so they can be overridden

  _nextAutoIncrementName(prefix) {
    const newName = concatenateName(prefix, this._state.nameCtr);
    const newTree = cloneAndModifyState(this, {
      nameCtr: this._state.nameCtr + 1
    });
    return {
      tree: newTree,
      newName
    };
  }
  _nextCellName({
    newCellValue = {}
  } = {}) {
    if (newCellValue == null) {
      throw new Error('_nextCellName requires `newCellValue to be passed in`');
    }
    const result = this._nextAutoIncrementName('cell');
    if (this.hasCell(result.newName)) {
      throw new Error(`Tree already has cell name ${result.newName}`, this);
    }
    return result;
  }
  _nextRowName() {
    const result = this._nextAutoIncrementName('row');
    if (this.hasRow(result.newName)) {
      throw new Error(`Tree already has row name ${result.newName}`, this);
    }
    return result;
  }
  splitCellInToFirstRowOfNewCellWrapper(cellToInsertIn, {
    newGrandParentValue = {},
    _treeToOperateOn
  } = {}) {
    const oldTree = _treeToOperateOn || this;
    const mutableTree = oldTree.makeTemporaryMutableClone();
    const {
      newName: newGrandParentCellName
    } = mutableTree._nextCellName({
      newCellValue: {}
    });
    const {
      newName: newParentRowName
    } = mutableTree._nextRowName();
    const existingContentToColumnForRow = cellToInsertIn.cloneAndChange({
      parentRowName: newParentRowName
    });
    const newParentRow = mutableTree.createNewRow({
      name: newParentRowName,
      columnNames: [existingContentToColumnForRow.getName()],
      parentCellName: newGrandParentCellName
    });
    const newGrandParentCell = mutableTree.createNewCell({
      name: newGrandParentCellName,
      rowNames: [newParentRowName],
      parentRowName: cellToInsertIn.getParentName(),
      value: newGrandParentValue
    });
    const parentRowOfCellToInsert = mutableTree.findRow(cellToInsertIn.getParentName());
    const modifiedParentRowOfCellToInsert = parentRowOfCellToInsert.cloneAndChange({
      columnNames: mapAndSwapValue(parentRowOfCellToInsert.getColumnNames(), cellToInsertIn.getName(), newGrandParentCellName)
    });
    cloneAndModifyState(mutableTree, {
      modifiedColumns: [newGrandParentCell, existingContentToColumnForRow],
      modifiedRows: [newParentRow, modifiedParentRowOfCellToInsert]
    });

    // Don't validate/clone if we already were a mutable tree before this function was called
    if (oldTree.isMutableTree()) {
      return {
        tree: oldTree,
        newGrandParentCell
      };
    }
    mutableTree.validateWholeTree();
    const finalTree = mutableTree.clone();
    return {
      tree: finalTree,
      newGrandParentCell
    };
  }
  cloneWholeTree({
    stripSmartContentData
  }) {
    const {
      modifiedColumns,
      modifiedRows,
      clonedNode
    } = cloneSubtree(this, this.getRootCell(), stripSmartContentData);
    const allNewColumns = [...modifiedColumns, clonedNode];
    const columnsByName = {};
    const rowsByName = {};
    allNewColumns.forEach(column => {
      columnsByName[column.getName()] = column;
    });
    modifiedRows.forEach(row => {
      rowsByName[row.getName()] = row;
    });
    const rootCellName = clonedNode.getName();
    const snapshot = {
      columnsByName,
      rowsByName,
      rootCellName,
      nameCtr: this._state.nameCtr + 1
    };
    const newTree = new this.constructor({
      snapshot
    });
    return newTree;
  }
}
export default CellAndRowsTree;
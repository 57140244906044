import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { DEFAULT } from 'ContentUtils/constants/Blogs';
export const getBlogs = state => state.resources.blogs.blogs;
export const getDefaultBlogId = state => state.resources.blogs.blogDefault.default;
export const getBlog = (state, props) => {
  const {
    id
  } = props;
  const blogId = id === DEFAULT ? getDefaultBlogId : id;
  return state.resources.blogs.blogs[blogId];
};
export const getBlogCache = (state, props) => state.resources.blogs.blogsCache[props.id];
export const getBlogsCache = state => state.resources.blogs.blogsCache;
export const getBlogsCacheRequestStatus = state => state.resources.blogs.blogsCacheRequestStatus;
export const getBlogFetchStatus = (state, props) => state.resources.blogs.requestStatus[props.id];
export const getDefaultBlogFetchStatus = state => state.resources.blogs.requestStatusDefault[DEFAULT];
export const getBlogOptions = createGenericOptionsSelector(getBlogsCache);
export const getDefaultBlogOptions = createGenericOptionsSelector(getBlogs);
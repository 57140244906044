export let SettingsModalSections;
(function (SettingsModalSections) {
  SettingsModalSections["general"] = "general";
  SettingsModalSections["template"] = "template";
  SettingsModalSections["advanced"] = "advanced";
})(SettingsModalSections || (SettingsModalSections = {}));
export let AdvancedSettingsColumns;
(function (AdvancedSettingsColumns) {
  AdvancedSettingsColumns["hs_canonical_url"] = "hs_canonical_url";
  AdvancedSettingsColumns["hs_head_html"] = "hs_head_html";
})(AdvancedSettingsColumns || (AdvancedSettingsColumns = {}));
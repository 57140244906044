import { combineReducers } from 'redux';
import iconsSelect from './IconsSelect';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import * as RequestStatusTypes from 'ContentUtils/constants/RequestStatus';
const {
  FETCH_ICON,
  FETCH_ICONS,
  FETCH_SELECTED_ICONS,
  SAVE_ICON
} = ActionTypes;
function isFetching(state = true, action) {
  switch (action.type) {
    case FETCH_ICONS:
      {
        const {
          result
        } = action;
        return !result;
      }
    default:
      return state;
  }
}
function requestStatus(state = {}, action) {
  switch (action.type) {
    case FETCH_ICON:
      {
        const {
          result,
          error,
          query
        } = action;
        const {
          search: name
        } = query;
        let status = RequestStatusTypes.PENDING;
        if (result) status = RequestStatusTypes.SUCCEEDED;
        if (error) status = RequestStatusTypes.FAILED;
        return Object.assign({}, state, {
          [name]: status
        });
      }
    default:
      return state;
  }
}
function icons(state = {}, action) {
  switch (action.type) {
    case FETCH_SELECTED_ICONS:
      {
        return state;
      }
    case SAVE_ICON:
      {
        const {
          iconData
        } = action;
        if (!iconData) return state;
        return Object.assign({}, state, {
          [iconData.name]: iconData,
          [`${iconData.name}:${iconData.type}`]: iconData
        });
      }
    case FETCH_ICON:
      {
        const {
          result
        } = action;
        if (!result) return state;
        return result.results.reduce((acc, icon) => {
          icon.styles.forEach(type => {
            const {
              unicode,
              viewPort,
              categories,
              name
            } = icon;
            const svg = icon.svg[type];
            const iconProperties = {
              name,
              unicode,
              viewPort,
              svg,
              type,
              categories
            };
            acc[icon.name] = iconProperties;
            acc[`${icon.name}:${type}`] = iconProperties;
          });
          return acc;
        }, Object.assign({}, state));
      }
    default:
      return state;
  }
}
export default combineReducers({
  iconsSelect,
  isFetching,
  requestStatus,
  icons
});
// This should stay in sync with the enum in EE. We should verify that with integration tests.
// https://git.hubteam.com/HubSpotProtected/EligibilityEngine/blob/master/EligibilityEngineCore/src/main/java/com/hubspot/eligibilityengine/core/enums/PurchaseMotion.java
export const BUY_TOUCHLESS = 'buyTouchless';
export const TALK_TO_SALES = 'talkToSales';
export const TRIAL = 'trial';
export const TALK_TO_SALES_FALLBACK = 'talkToSalesFallback';
export const BUY_ADDITIONAL_CONTACTS = 'buyAdditionalContacts';
export const BUY_ADDITIONAL_SEATS = 'buyAdditionalSeats';
export const ACTIVATE = 'activate';
export const PRICING_PAGE_REDIRECT = 'pricingPageRedirect';
export const REQUEST_SEAT = 'requestSeat';
export const ASSIGN_SEAT = 'assignSeat';
export const VIEW_ACCOUNT = 'viewAccount';
export const CALCULATE_PRICE = 'calculatePrice';
export const CALCULATE_CONTACTS = 'calculateContacts';
export const CALCULATE_SEATS = 'calculateSeats';
'use es6';

import createRequestClient from 'ContentEditorUI/utils/createRequestClient';
import getLang from 'I18n/utils/getLang';
const GLOBAL_MODULES_URL = 'content/api/v4/widgets/list-summaries';
const {
  contentId
} = window.parseEditorUrl();
function getUrl(id) {
  const language = getLang();
  return `content-editor/v1/combined-edit-data/${id}?userLanguage=${language}&hsEditorApp=page`;
}
export const fetchContentSchema = createRequestClient(() => getUrl(contentId, false), {
  timeout: 30000
}, 'content');
export const fetchGlobalModules = createRequestClient(GLOBAL_MODULES_URL, {}, 'globalModules');
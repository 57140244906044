import { CREATE_SUBTOPIC_FAILED, CREATE_SUBTOPIC_STARTED, CREATE_SUBTOPIC_SUCCEEDED, FETCH_TOPICS_FAILED, FETCH_TOPICS_STARTED, FETCH_TOPICS_SUCCEEDED } from '../actionTypes';
import { getTopicsByClusterId, postTopic } from './client';
import { showSubtopicLimitReachedError } from '../alerts';
export const fetchTopics = ({
  clusterId
}) => dispatch => {
  dispatch({
    type: FETCH_TOPICS_STARTED
  });
  return getTopicsByClusterId({
    clusterId
  }).then(response => {
    dispatch({
      type: FETCH_TOPICS_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: FETCH_TOPICS_FAILED,
      payload: {
        status: error.status
      }
    });
  });
};
export const createSubtopic = (selectedClusterId, phrase) => dispatch => {
  dispatch({
    type: CREATE_SUBTOPIC_STARTED
  });
  return postTopic(selectedClusterId, phrase).then(response => {
    dispatch({
      type: CREATE_SUBTOPIC_SUCCEEDED,
      payload: response && response[0]
    });
  }).catch(error => {
    dispatch({
      type: CREATE_SUBTOPIC_FAILED,
      payload: {
        status: error.status
      }
    });
    if (error.status === 400) {
      showSubtopicLimitReachedError();
    }
  });
};
'use es6';

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
export const createStoreWithMiddleware = compose(applyMiddleware(thunk))(createStore);
export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}
import { CATEGORY_IDS } from 'ContentUtils/constants/Categories';
export const ContentTypes = {
  LANDING_PAGE: 'landingPage',
  SITE_PAGE: 'sitePage',
  BLOG: 'blog',
  BLOG_POST: 'blogPost',
  KNOWLEDGE_BASE: 'knowledgeBase',
  KNOWLEDGE_ARTICLE: 'knowledgeArticle',
  CUSTOMER_PORTAL: 'customerPortal'
};
export const ContentGroupTypes = [ContentTypes.BLOG, ContentTypes.KNOWLEDGE_BASE, ContentTypes.CUSTOMER_PORTAL];
export const DataWallContentGroupTypes = [ContentTypes.BLOG];
export const DataWallContentTypes = [ContentTypes.BLOG_POST];
export const CategoryIdToAccessControlContentType = {
  [CATEGORY_IDS.LANDING_PAGE]: ContentTypes.LANDING_PAGE,
  [CATEGORY_IDS.SITE_PAGE]: ContentTypes.SITE_PAGE,
  [CATEGORY_IDS.BLOG_POST]: ContentTypes.BLOG_POST,
  // TODO: Check on KB article categoryId usage for migration per https://git.hubteam.com/HubSpot/content4j/pull/9883
  [CATEGORY_IDS.KNOWLEDGE_ARTICLE]: ContentTypes.KNOWLEDGE_ARTICLE
};
import { useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_SORT_STATE, PreferredSortDirs, CONTENT_ITEMS_PER_PAGE, CONTENT_MANAGEMENT_ACTION } from 'ContentEditorUI/constants/contentManagement';
// @ts-expect-error Untyped module
import { fetchContent as fetchContentEntities } from 'ContentEditorUI/api/ContentCrmSearchApi';
import Categories from 'ContentUtils/constants/Categories';
// @ts-expect-error Untyped module
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentTypeId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getContentTypeForUsageTracker } from '../utils/contentManagementPanel/contentTableUtils';
import useDomainFilterForContentManagementPanel from './useDomainFilterForContentManagementPanel';
const initialState = {
  contentTypeId: null,
  excludedContentIds: [],
  blogListingPageData: [],
  blogListingPageTotal: 0,
  blogListingPageStale: false,
  blogListingPageRequestStatus: 'UNINITIALIZED',
  blogListingPageFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  },
  blogPostsData: [],
  blogPostsTotal: 0,
  blogPostsStale: false,
  blogPostsRequestStatus: 'UNINITIALIZED',
  blogPostsFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  },
  landingPagesData: [],
  landingPagesTotal: 0,
  landingPagesStale: false,
  landingPagesRequestStatus: 'UNINITIALIZED',
  landingPagesFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  },
  sitePagesData: [],
  sitePagesTotal: 0,
  sitePagesStale: false,
  sitePagesRequestStatus: 'UNINITIALIZED',
  sitePagesFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  }
};
const getStateKeyPrefixFromContentType = contentTypeId => {
  switch (contentTypeId) {
    case Categories.blog_listing_page.id:
      return 'blogListingPage';
    case Categories.blog_post.id:
      return 'blogPosts';
    case Categories.site_page.id:
      return 'sitePages';
    default:
    case Categories.landing_page.id:
      return 'landingPages';
  }
};
export const getOppositeSortDir = sortDir => {
  switch (sortDir) {
    case 'DESC':
      return 'ASC';
    case 'ASC':
      return 'DESC';
    default:
      return 'NONE';
  }
};

// Actions
export const FETCH_CONTENT_ENTITIES_STARTED = 'FETCH_CONTENT_ENTITIES_STARTED';
export const FETCH_CONTENT_ENTITIES_SUCCEEDED = 'FETCH_CONTENT_ENTITIES_SUCCEEDED';
export const FETCH_CONTENT_ENTITIES_FAILED = 'FETCH_CONTENT_ENTITIES_FAILED';
export const UPDATE_EXCLUDED_CONTENT_IDS = 'UPDATE_EXCLUDED_CONTENT_IDS';
export const UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS = 'UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_CONTENT_TYPE_ID = 'UPDATE_CONTENT_TYPE_ID';
const requestReducer = (state, action) => {
  const prefix = getStateKeyPrefixFromContentType(action.payload.contentTypeId || state.contentTypeId);
  switch (action.type) {
    case FETCH_CONTENT_ENTITIES_STARTED:
      {
        return Object.assign({}, state, {
          contentTypeId: action.payload.contentTypeId,
          [`${prefix}RequestStatus`]: 'PENDING',
          [`${prefix}Stale`]: false
        });
      }
    case FETCH_CONTENT_ENTITIES_SUCCEEDED:
      {
        return Object.assign({}, state, {
          [`${prefix}RequestStatus`]: 'SUCCEEDED',
          [`${prefix}Data`]: action.payload.objects,
          [`${prefix}Total`]: action.payload.total,
          excludedContentIds: []
        });
      }
    case FETCH_CONTENT_ENTITIES_FAILED:
      {
        return Object.assign({}, state, {
          [`${prefix}RequestStatus`]: 'FAILED'
        });
      }
    case UPDATE_EXCLUDED_CONTENT_IDS:
      {
        return Object.assign({}, state, {
          excludedContentIds: action.payload.excludedContentIds,
          [`${prefix}Stale`]: true
        });
      }
    case UPDATE_CONTENT_TYPE_ID:
      {
        return Object.assign({}, state, {
          contentTypeId: action.payload.contentTypeId,
          [`${prefix}Stale`]: true
        });
      }
    case UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS:
      {
        const filtersKey = `${prefix}Filters`;
        return Object.assign({}, state, {
          [filtersKey]: Object.assign({}, state[filtersKey], action.payload),
          [`${prefix}Stale`]: true
        });
      }
    default:
      return state;
  }
};
const useContentForContentManagementPanel = () => {
  const [state, dispatch] = useReducer(requestReducer, initialState);
  const defaultContentTypeId = useSelector(getContentTypeId);
  const currentContentTypeId = state.contentTypeId || defaultContentTypeId;
  const isContentTypeFilterSameAsEditedContentType = defaultContentTypeId === currentContentTypeId;
  const domainFilter = useDomainFilterForContentManagementPanel({
    isContentTypeFilterSameAsEditedContentType,
    currentContentTypeId
  });
  const currentContentModelId = useSelector(getContentModelId);
  const prefix = getStateKeyPrefixFromContentType(currentContentTypeId);
  const filters = state[`${prefix}Filters`];
  const stale = state[`${prefix}Stale`];
  const data = state[`${prefix}Data`];
  const total = state[`${prefix}Total`];
  const requestStatus = state[`${prefix}RequestStatus`];
  const pageCount = Math.ceil(total / CONTENT_ITEMS_PER_PAGE) || 1;
  const fetchContent = useCallback(() => {
    dispatch({
      type: FETCH_CONTENT_ENTITIES_STARTED,
      payload: {
        contentTypeId: currentContentTypeId
      }
    });
    return fetchContentEntities(Object.assign({}, filters, {
      contentTypeId: currentContentTypeId,
      domainFilter,
      excludedContentIds: [currentContentModelId, ...state.excludedContentIds]
    })).then(response => dispatch({
      type: FETCH_CONTENT_ENTITIES_SUCCEEDED,
      payload: response
    }), error => dispatch({
      type: FETCH_CONTENT_ENTITIES_FAILED,
      payload: error
    }));
  }, [currentContentTypeId, currentContentModelId, domainFilter, filters, state.excludedContentIds]);
  const updateExcludedContentIdsFilter = useCallback(excludedContentIds => {
    dispatch({
      type: UPDATE_EXCLUDED_CONTENT_IDS,
      payload: {
        excludedContentIds
      }
    });
  }, []);
  const updateCurrentContentTypeId = useCallback(newContentTypeId => {
    dispatch({
      type: UPDATE_CONTENT_TYPE_ID,
      payload: {
        contentTypeId: newContentTypeId
      }
    });
    const contentTypeString = getContentTypeForUsageTracker(newContentTypeId);
    UsageTracker.trackEditorInteraction({
      action: CONTENT_MANAGEMENT_ACTION,
      event: `update-content-type-filter-to-${contentTypeString}`
    });
  }, []);
  const updateSearch = useCallback(search => {
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        query: search,
        page: 1
      }
    });
    const contentTypeString = getContentTypeForUsageTracker(currentContentTypeId);
    UsageTracker.trackEditorInteraction({
      action: CONTENT_MANAGEMENT_ACTION,
      event: `update-${contentTypeString}-search-filter`
    });
  }, [currentContentTypeId]);
  const updateSort = useCallback(columnName => {
    const filtersKey = `${prefix}Filters`;
    const {
      selectedColumn: currentSelectedColumn,
      sortDir: currentSortDir
    } = state[filtersKey].sorts;
    const newSortState = {
      selectedColumn: columnName,
      sortDir: currentSelectedColumn === columnName ? getOppositeSortDir(currentSortDir) : PreferredSortDirs[columnName]
    };
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        sorts: newSortState
      }
    });
    const contentTypeString = getContentTypeForUsageTracker(state.contentTypeId);
    UsageTracker.trackEditorInteraction({
      action: CONTENT_MANAGEMENT_ACTION,
      event: `update-${contentTypeString}-table-${columnName}-sort`
    });
  }, [prefix, state]);
  const onPaginate = useCallback(nextPage => {
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        page: nextPage
      }
    });
    const contentTypeString = getContentTypeForUsageTracker(currentContentTypeId);
    UsageTracker.trackEditorInteraction({
      action: CONTENT_MANAGEMENT_ACTION,
      event: `update-${contentTypeString}-table-page-number`
    });
  }, [currentContentTypeId]);
  return Object.assign({}, filters, {
    fetchContent,
    updateCurrentContentTypeId,
    updateSearch,
    data,
    contentTypeId: currentContentTypeId,
    stale,
    updateExcludedContentIdsFilter,
    requestStatus,
    updateSort,
    onPaginate,
    pageCount,
    total,
    shouldShowNowEditingTableRow: isContentTypeFilterSameAsEditedContentType
  });
};
export default useContentForContentManagementPanel;
import { PENDING, SUCCEEDED } from 'ContentUtils/constants/RequestStatus';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
export const getFieldDataForLabel = (value, field) => {
  const {
    type
  } = field;
  if (!value) return null;
  if (type === FieldTypes.ICON) {
    return {
      name: value.name,
      icon_set: field.icon_set
    };
  }
  if (type === FieldTypes.FORM && typeof value === 'object') {
    return value.form_id;
  }
  if (type === FieldTypes.VIDEO) {
    return value.player_id;
  }
  if (type === FieldTypes.SURVEY) {
    return value;
  }
  if (type === FieldTypes.URL) {
    return value;
  }
  if (type === FieldTypes.LINK) {
    return value;
  }
  if (type === FieldTypes.LOCATION) {
    return value;
  }
  if (type === FieldTypes.CRM_OBJECT) {
    return Object.assign({}, value || {}, {
      display_format: field.display_format,
      display_properties: field.display_properties,
      object_type: field.object_type
    });
  }
  if (type === FieldTypes.CRM_OBJECT_PROPERTY) {
    return Object.assign({}, value || {}, {
      object_type: field.object_type
    });
  }
  if (type === FieldTypes.TAG) {
    return {
      value,
      tagValue: field.tag_value
    };
  }
  if (type === FieldTypes.HUBDB_ROW) {
    const tableId = 'id';
    return {
      displayColumns: field.display_columns,
      displayFormat: field.display_format,
      [tableId]: field.table_name_or_id,
      rowId: value.id
    };
  }
  if (type === FieldTypes.PAYMENT) {
    return value.id;
  }
  return value;
};
export const shouldFetchResource = status => {
  return !(status === PENDING || status === SUCCEEDED);
};
import { SUCCEEDED } from '../requestStates';
export const evictArtifactsWithKey = (artifacts, requestKey) => {
  return Object.keys(artifacts).reduce((accumulator, artifactId) => {
    if (artifacts[artifactId].requestKey === requestKey) {
      return accumulator;
    }
    return Object.assign({}, accumulator, {
      [artifactId]: artifacts[artifactId]
    });
  }, {});
};
export const evictDefault = (artifacts = {}, action, generatedArtifact) => {
  if (generatedArtifact && generatedArtifact.requestState === SUCCEEDED) {
    const {
      requestState,
      requestKey
    } = generatedArtifact;
    if (requestState === SUCCEEDED) {
      return evictArtifactsWithKey(artifacts, requestKey);
    }
  }
  return artifacts;
};
'use es6';

import I18n from 'I18n';
import { List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import isFileManagerUrl from 'FileManagerLib/utils/isFileManagerUrl';
import getResizeImageUrl from 'FileManagerLib/utils/resize';
export const spliceInText = (currentString, start, end, stringToInsert) => {
  const preString = currentString.substring(0, start);
  const postString = currentString.substring(end, currentString.length);
  return preString + stringToInsert + postString;
};
export const toCamelCase = snakeCaseString => {
  // eslint-disable-next-line no-useless-escape
  return snakeCaseString.replace(/(\_\w)/g, m => m[1].toUpperCase());
};
export const toSnakeCase = camelCaseString => {
  const regex = new RegExp(/(_\w)/g);
  if (regex.test(camelCaseString)) {
    return camelCaseString.toLowerCase();
  }
  return camelCaseString.replace(/([A-Z])/g, m => `_${m.toLowerCase()}`);
};
export const convertObjectToCamelCase = obj => {
  return Object.keys(obj).reduce((result, key) => {
    result[toCamelCase(key)] = obj[key];
    return result;
  }, {});
};
const INVLID_SMART_QUOTES = /\u2018|\u2019|\u201c|\u201d/gi;
const INVLID_SMART_AND_CJK_PUNCTUATION = /[\u2014]|\u2026|[\u3000-\u303f]|[\u30fb-\u30ff]/gi;
const INVLID_SLUG_CHAR_MATCHER = /[^a-zA-Z0-9\-_/\s.\u00AE-\ud7ff]/gi;
export const slugify = str => {
  let slugified;
  // UTF8-sensitive character cleanup
  slugified = str.replace(INVLID_SMART_QUOTES, '');
  slugified = slugified.replace(INVLID_SMART_AND_CJK_PUNCTUATION, '-');
  slugified = slugified.replace(INVLID_SLUG_CHAR_MATCHER, '');
  slugified = slugified.replace(/_/gi, '-');
  slugified = slugified.replace(/\s/gi, '-');
  return slugified.toLowerCase();
};
export const sanitizeSlugInput = slug => {
  if (slug) {
    return slug.toLowerCase().trim().replace(/(\s+)|(-+)/g, '-');
  }
  return slug;
};
export const getBooleanOrNull = payload => {
  return typeof payload === 'boolean' ? payload : null;
};
const TYPES_FOR_WIDGET_IMAGES = List.of('image', 'linked_image', 'logo');
export const getModuleImages = (modules, defaultLogoSrc) => {
  return modules.filter(widget => widget.get('overrideable') && TYPES_FOR_WIDGET_IMAGES.find(type => type === widget.get('type'))).reduce((acc, imageWidget) => {
    if (imageWidget.getIn(['body', 'src'])) {
      return acc.add(imageWidget.getIn(['body', 'src']));
    } else if (imageWidget.get('type') === 'logo' && defaultLogoSrc) {
      // Ensure that logo modules without a specified src defaults to the settings logo
      return acc.add(defaultLogoSrc);
    }
    return acc;
  }, new ImmutableSet()).map(imgSrc => ImmutableMap({
    src: imgSrc
  }));
};
export const getPostBodyImages = postBody => {
  if (!postBody) {
    return ImmutableSet();
  }
  const docWithContent = new DOMParser().parseFromString(postBody, 'text/html');
  return ImmutableSet(docWithContent.getElementsByTagName('img')).map(img => ImmutableMap({
    alt: img.alt,
    src: img.src
  }));
};
const handleFeaturedImageWithImages = (images, featuredImage, featuredImageAltText) => {
  const featuredImageMap = ImmutableMap({
    alt: featuredImageAltText,
    featured: true,
    src: featuredImage
  });
  const alreadyInImagesIndex = images.findIndex(image => image.get('src') === featuredImage);
  if (alreadyInImagesIndex !== -1) {
    return images.mergeIn([alreadyInImagesIndex], featuredImageMap);
  }
  return images.unshift(featuredImageMap);
};
export const getImages = (rawImages, featuredImage, featuredImageAltText, availableThumbnailUrls = ImmutableMap(), auth) => {
  const seenImageSrcs = new Set();
  let images = rawImages.filter(img => {
    const imageSrc = img.get('src');
    if (seenImageSrcs.has(imageSrc)) {
      return false;
    }
    const isValidSrc = imageSrc && typeof imageSrc === 'string' && !imageSrc.includes('email-template-images/placeholder_200x200.png');
    if (isValidSrc) {
      seenImageSrcs.add(imageSrc);
    }
    return isValidSrc;
  }).toList();
  if (featuredImage) {
    images = handleFeaturedImageWithImages(images, featuredImage, featuredImageAltText);
  }
  return images.map(img => {
    // Use thumbnail version of FileManager-hosted images if possible
    // and dynamic resizing via query params for those that don't have thumbs
    const imgSrc = img.get('src');
    if (availableThumbnailUrls.has(imgSrc)) {
      return img.set('thumbSrc', availableThumbnailUrls.get(imgSrc));
    }
    if (isFileManagerUrl(imgSrc)) {
      return img.set('thumbSrc', getResizeImageUrl(imgSrc, {
        width: 190
      }, {}, auth));
    }
    return img;
  });
};
export const getImageUrls = images => images.map(image => image.get('src'));
const EMAIL_TYPES = List.of('instant', 'daily', 'weekly', 'monthly');
export const parseEnabledNotificationEmails = emails => {
  return emails.filter(email => email.get('isPublished') && EMAIL_TYPES.find(type => type === email.get('blogEmailType'))).map(email => email.get('blogEmailType')).sort((a, b) => {
    const aPos = EMAIL_TYPES.indexOf(a);
    const bPos = EMAIL_TYPES.indexOf(b);
    if (aPos < bPos) {
      return -1;
    } else if (aPos > bPos) {
      return 1;
    }
    return 0;
  });
};
export const getEnabledAndConnectedSocialAccountCount = socialAccounts => socialAccounts.filter(s => s.get('active') && s.get('autoPublish')).size;
export const getEnabledSocialAccountCount = socialAccounts => socialAccounts.filter(s => s.get('active')).size;
export const getPostTitlePlaceholder = () => {
  return I18n.text('postSettings.title.placeholder');
};
export const clearSelectedText = () => {
  if (window.getSelection && window.getSelection().toString().length > 0) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection && document.selection.toString().length > 0) {
    document.selection.empty();
  }
};
export const delayImportUntilDocumentReady = loader => () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (document.readyState === 'complete') {
        resolve(loader());
        clearInterval(interval);
      }
    }, 250);
  });
};
export const getPaneNameFromPath = pathname => {
  const paneName = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
  return isNaN(paneName) ? paneName : '';
};
export const isObject = obj => {
  const type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
};
export const omitWhere = (obj, predicate) => {
  return Object.keys(obj).reduce((current, key) => {
    if (!predicate(obj[key])) {
      current[key] = obj[key];
    }
    return current;
  }, {});
};

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}
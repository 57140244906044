import formatName from 'I18n/utils/formatName';
import { FETCH_USERS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const userReducer = (state = [], {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_USERS_SUCCEEDED:
      {
        return payload.map(user => Object.assign({}, user, {
          fullName: formatName({
            firstName: user.firstName,
            lastName: user.lastName
          })
        }));
      }
    default:
      return state;
  }
};
export default userReducer;
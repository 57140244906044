'use es6';

import reduceReducers from 'ContentEditorUI/utils/reduceReducers';
import baseSettingsReducer, { baseSettingsImmerReducer } from 'ContentEditorUI/redux/reducers/settings/baseSettingsReducer';
const reducedReducers = settingsSpecificReducer => {
  return reduceReducers(baseSettingsReducer, settingsSpecificReducer);
};
export const reducedReducersImmer = settingsSpecificReducer => {
  return reduceReducers(baseSettingsImmerReducer, settingsSpecificReducer);
};
export default reducedReducers;
import { useCallback } from 'react';
import { IS_OPEN } from '../constants/CollaborationSidebarStateProperties';
import useIsScreenWidthBelowThreshold from './useIsScreenWidthBelowThreshold';
export default function useSidebarOpenCloseHandlers({
  onUpdateCollaborationSidebarState,
  openLeftEditor,
  closeLeftEditor
}) {
  const isScreenWidthBelowThreshold = useIsScreenWidthBelowThreshold();
  const maybeCloseLeftEditor = useCallback(() => {
    if (closeLeftEditor && isScreenWidthBelowThreshold) {
      closeLeftEditor();
    }
  }, [isScreenWidthBelowThreshold, closeLeftEditor]);
  const onOpenSidebar = useCallback(() => {
    onUpdateCollaborationSidebarState({
      [IS_OPEN]: true
    });
    maybeCloseLeftEditor();
  }, [onUpdateCollaborationSidebarState, maybeCloseLeftEditor]);
  const handleClose = useCallback(() => {
    onUpdateCollaborationSidebarState({
      [IS_OPEN]: false
    });
    if (openLeftEditor && isScreenWidthBelowThreshold) {
      openLeftEditor();
    }
  }, [isScreenWidthBelowThreshold, onUpdateCollaborationSidebarState, openLeftEditor]);
  return {
    handleClose,
    onOpenSidebar,
    maybeCloseLeftEditor
  };
}
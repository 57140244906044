import { getElementModuleId } from './utils';
export const getH1Elements = ({
  userDOM
}) => {
  const h1 = userDOM.getElementsByTagName('h1');
  return (Array.from(h1) || []).map(h1Element => ({
    // textContent can be null if node is document/doctype
    textContent: h1Element.textContent || '',
    innerHTML: h1Element.innerHTML,
    moduleId: getElementModuleId(h1Element)
  }));
};
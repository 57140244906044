'use es6';

export const AVAILABLE_STYLESHET_URL = 'content/api/v4/templates/list-stylesheets';
export const HUBDB_TABLES_URL = 'hubdb/api/v1/tables';
export const DATA_QUERIES_URL = 'collector/v3/data-query/definitions/for-dynamic-pages';
export const SOCIAL_AUTO_PUBLISHING_MESSAGE_MAX_LENGTH = 280;
export const SLUG_FOR_TITLE_URL = 'content/api/v4/contents/slug-for-title';
export const STYLESHEET_RIGHT_COLUMN_WIDTH = '175px';
export const STYLESHEETS_URL_START = 'content/api/v4/landing-pages';
export const STYLESHEETS_URL_END = 'stylesheet-trace';
export const SUPPORTED_LANGUAGES_URL = 'content/api/v4/contents/supported-languages';
export const CRM_OBJECT_TYPES_URL = 'inbounddb-meta/v1/object-types/for-portal';
export const CRM_OBJECT_SCHEMA_URL = 'crm-object-schemas/v3/schemas';
import PropTypes from 'prop-types';
import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
// import * as ApiNames from 'self-service-api/constants/ApiNames';

export const upgradeDataPropsInterface = {
  upgradeData: PropTypes.shape({
    app: PropTypes.string.isRequired,
    screen: PropTypes.string.isRequired,
    upgradeProduct: PropTypes.oneOf(Object.values(UpgradeProducts)).isRequired,
    // apiName: PropTypes.oneOf(Object.values(ApiNames)).isRequired,
    uniqueId: PropTypes.string.isRequired
  }).isRequired
};
'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHasUndoRedoAccess } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIframeReady } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { togglePauseRedux } from 'ContentEditorUI/lib/debugging/pauseReduxDebugging';
import { routeToPage as routeToPageAction } from 'ContentEditorUI/redux/actions/routingActions';
import { getRoutingCurrentPage } from 'ContentEditorUI/redux/selectors/routingSelectors';
import { openModal as openModalAction, closeModal as closeModalAction } from 'ContentEditorUI/redux/actions/appActions';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { getOpenModal } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { getCanUseQuickSpot } from 'ContentEditorUI/redux/selectors/authSelectors';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
const mapStateToProps = (state, ownProps) => {
  return {
    hasUndoRedoAccess: ownProps.hasUndoRedoAccess || getHasUndoRedoAccess(state),
    iframeReady: getIframeReady(state),
    currentPage: getRoutingCurrentPage(state),
    currentOpenModal: getOpenModal(state),
    canUseQuickSpot: getCanUseQuickSpot(state)
  };
};
const mapDispatchToProps = {
  openModal: openModalAction,
  closeModal: closeModalAction,
  routeToPage: routeToPageAction
};
const isZPressed = keyCode => keyCode === 90;
const isPreviewOrRevision = page => {
  return page === 'preview' || page === 'revisions';
};
const isCtrlOrCmdPressed = event => {
  const ctrlPressed = event.ctrlKey; // for checking non-OSX
  const cmdPressed = event.metaKey; // cmd on OSX, Win key on Windows
  return cmdPressed || ctrlPressed;
};
const isUndoOrRedoShortcut = event => {
  return isZPressed(event.keyCode) && isCtrlOrCmdPressed(event);
};
const isSpotlightShortcut = event => {
  return event.key === 'k' && isCtrlOrCmdPressed(event);
};
const isRPressed = keyCode => keyCode === 82;
const isCtrlCmdAndOptionPressed = event => {
  return event.ctrlKey && event.metaKey && event.altKey;
};
const isPauseReduxShorcut = event => {
  return isRPressed(event.keyCode) && isCtrlCmdAndOptionPressed(event);
};
const isShiftPressed = event => event.shiftKey;
const onEscPressed = event => {
  return event.keyCode === 27;
};
class AppWideKeyboardShortcutsContainer extends Component {
  constructor(props) {
    super(props);
    this.handleKeyActions = this.handleKeyActions.bind(this);
  }
  componentDidMount() {
    const {
      hasUndoRedoAccess
    } = this.props;
    if (hasUndoRedoAccess) {
      this.attachUndoRedoEventListeners();
    }
  }
  componentWillUnmount() {
    const {
      hasUndoRedoAccess
    } = this.props;
    if (hasUndoRedoAccess) {
      this.removeUndoRedoEventListeners();
    }
  }
  attachUndoRedoEventListeners() {
    const {
      windowToUse
    } = this.props;
    const documentToUse = windowToUse ? windowToUse.document : window.document;
    documentToUse.addEventListener('keydown', this.handleKeyActions);
  }
  removeUndoRedoEventListeners() {
    const {
      windowToUse
    } = this.props;
    const documentToUse = windowToUse ? windowToUse.document : window.document;
    documentToUse.removeEventListener('keydown', this.handleKeyActions);
  }
  handleKeyActions(event) {
    const {
      iframeReady,
      redoAction,
      undoAction,
      routeToPage,
      currentPage,
      currentOpenModal,
      closeModal,
      openModal,
      canUseQuickSpot
    } = this.props;
    if (!iframeReady || event.defaultPrevented) {
      return;
    }
    if (isPreviewOrRevision(currentPage)) {
      if (onEscPressed(event)) {
        event.preventDefault();
        routeToPage('content');
      }
    } else if (isUndoOrRedoShortcut(event)) {
      event.preventDefault();
      if (isShiftPressed(event) && redoAction) {
        redoAction();
      } else if (undoAction) {
        undoAction();
      }
    } else if (isPauseReduxShorcut(event)) {
      event.preventDefault();
      togglePauseRedux();
    } else if (canUseQuickSpot && isSpotlightShortcut(event)) {
      event.preventDefault();
      openModal(MODAL_TYPES.SPOTLIGHT);
      UsageTracker.trackEditorInteraction({
        action: 'Quickspot action',
        event: 'open quickspot via shortcut'
      });
    } else if (canUseQuickSpot && currentOpenModal === MODAL_TYPES.SPOTLIGHT && onEscPressed(event)) {
      event.preventDefault();
      closeModal();
    }
  }
  render() {
    return null;
  }
}
AppWideKeyboardShortcutsContainer.propTypes = {
  hasUndoRedoAccess: PropTypes.bool.isRequired,
  iframeReady: PropTypes.bool.isRequired,
  windowToUse: PropTypes.object,
  redoAction: PropTypes.func,
  undoAction: PropTypes.func,
  routeToPage: PropTypes.func,
  currentPage: PropTypes.string,
  currentOpenModal: PropTypes.string,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  canUseQuickSpot: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(AppWideKeyboardShortcutsContainer);
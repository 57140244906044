export const CONTENT_ASSET_TYPE_CSS = 'CSS';
export const CONTENT_ASSET_TYPE_INLINE_CSS = 'INLINE_CSS';
export const CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS = 'INLINE_EDITOR_CSS';
export const CONTENT_ASSET_TYPE_INLINE_OTHER = 'INLINE_OTHER';
export const NO_CONTENT_ASSET_TYPE = 'NO_CONTENT_ASSET_TYPE';
export const CONTENT_ASSET_TYPE_LINKED_ASSET = 'LINKED_ASSET';
export const INLINE_EDITOR_CSS_ELEMENT_ID = 'hs_editor_style';
export const checkIfInlineEditorCssAsset = asset => {
  return asset.type === CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS;
};
export const checkIfLinkElement = element => {
  return element.tagName === 'LINK';
};
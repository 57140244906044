import { useCallback, useEffect, useState } from 'react';
export default function useIsWindowActive() {
  const [isWindowActive, setIsWindowActive] = useState(true);
  const onBlur = useCallback(() => setIsWindowActive(false), []);
  const onFocus = useCallback(() => setIsWindowActive(true), []);
  useEffect(() => {
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, [onBlur, onFocus]);
  return isWindowActive;
}
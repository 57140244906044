import { combineReducers } from 'redux';
import blogs from 'ContentData/reducers/Blogs';
import brandSettings from 'ContentData/reducers/Branding';
import crmObjects from 'ContentData/reducers/CrmObjects';
import ctas from 'ContentData/reducers/Ctas';
import dataQueryVariables from 'ContentData/reducers/DataQueries';
import emails from 'ContentData/reducers/Emails';
import embeds from 'ContentData/reducers/Embed';
import forms from 'ContentData/reducers/Forms';
import googleFonts from 'ContentData/reducers/GoogleFonts';
import goToWebinar from 'ContentData/reducers/GoToWebinar';
import hubdb from 'ContentData/reducers/HubDb';
import icons from 'ContentData/reducers/Icons';
import meetings from 'ContentData/reducers/Meetings';
import menus from 'ContentData/reducers/Menus';
import optins from 'ContentData/reducers/Optin';
import pages from 'ContentData/reducers/Pages';
import pantone from 'ContentData/reducers/Pantone';
import payments from 'ContentData/reducers/Payments';
import phoneNumbers from 'ContentData/reducers/PhoneNumbers';
import podcasts from 'ContentData/reducers/Podcasts';
import salesforce from 'ContentData/reducers/Salesforce';
import siteSettings from 'ContentData/reducers/SiteSettings';
import surveys from 'ContentData/reducers/Surveys';
import tags from 'ContentData/reducers/Tags';
import teams from 'ContentData/reducers/Teams';
import themeSettings from 'ContentData/reducers/ThemeSettings';
import users from 'ContentData/reducers/Users';
import validationErrors from 'ContentData/reducers/ValidationErrors';
import videos from 'ContentData/reducers/Videos';
import webInteractives from 'ContentData/reducers/WebInteractives';
import workflows from 'ContentData/reducers/Workflows';
import currency from 'ContentData/reducers/Currency';
export default combineReducers({
  blogs,
  brandSettings,
  crmObjects,
  ctas,
  currency,
  dataQueryVariables,
  emails,
  embeds,
  forms,
  googleFonts,
  goToWebinar,
  hubdb,
  icons,
  meetings,
  menus,
  optins,
  pages,
  pantone,
  payments,
  phoneNumbers,
  podcasts,
  salesforce,
  siteSettings,
  surveys,
  tags,
  teams,
  themeSettings,
  users,
  validationErrors,
  videos,
  webInteractives,
  workflows
});
import { produce } from 'immer';
import { OPEN_ADD_SECTION_PANEL, CLOSE_ADD_SECTION_PANEL, CUSTOM_LAYOUT_SECTION_ADDED, OPEN_CREATE_NEW_SECTION_PANEL, CLOSE_CREATE_NEW_SECTION_PANEL, SAVE_SECTION_REQUEST, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAILURE, REDIRECT_TO_DELETE_SECTIONS, FETCH_GENERATED_LAYOUT_HUBL_STARTED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS, UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO, OPEN_DELETE_SECTION_MODAL, CLOSE_DELETE_SECTION_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
const defaultState = {
  showAddSectionPanel: false,
  currentLayoutSectionToAddTo: null,
  currentCreateNewSectionInfo: null,
  showCreateNewSectionPanel: false,
  showDeleteSectionModal: false,
  sectionIdStagedForDeletion: null,
  saveNewSectionError: null,
  saveNewSectionModulesErrorCount: null,
  currentCopyFragmentAsHublInfo: null,
  addSectionMissingModuleSchemaErrors: 0
};
const sectionsReducer = (state = defaultState, action) => produce(state, draft => {
  switch (action.type) {
    case OPEN_ADD_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        const currentLayoutSectionToAddTo = {
          layoutSectionId,
          rowIdToInsertAround: rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        };
        draft.currentLayoutSectionToAddTo = currentLayoutSectionToAddTo;
        draft.showAddSectionPanel = true;
        return draft;
      }
    case CLOSE_ADD_SECTION_PANEL:
      {
        draft.showAddSectionPanel = false;
        draft.currentLayoutSectionToAddTo = null;
        return draft;
      }
    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        draft.showAddSectionPanel = false;
        draft.currentLayoutSectionToAddTo = null;
        draft.addSectionMissingModuleSchemaErrors = 0;
        return draft;
      }
    case UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        const currentLayoutSectionToAddTo = {
          layoutSectionId,
          rowIdToInsertAround: rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        };
        draft.currentLayoutSectionToAddTo = currentLayoutSectionToAddTo;
        return draft;
      }
    case OPEN_DELETE_SECTION_MODAL:
      {
        const {
          layoutSectionId
        } = action.payload;
        draft.showDeleteSectionModal = true;
        draft.sectionIdStagedForDeletion = layoutSectionId;
        return draft;
      }
    case CLOSE_DELETE_SECTION_MODAL:
      {
        draft.showDeleteSectionModal = false;
        draft.sectionIdStagedForDeletion = null;
        return draft;
      }
    case OPEN_CREATE_NEW_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        const currentCreateNewSectionInfo = {
          layoutSectionId,
          sectionName
        };
        draft.currentCreateNewSectionInfo = currentCreateNewSectionInfo;
        draft.showCreateNewSectionPanel = true;
        return draft;
      }
    case SAVE_SECTION_REQUEST:
      draft.saveNewSectionError = null;
      draft.saveNewSectionModulesErrorCount = null;
      return draft;
    case SAVE_SECTION_SUCCESS:
    case CLOSE_CREATE_NEW_SECTION_PANEL:
      {
        draft.showCreateNewSectionPanel = false;
        draft.currentCreateNewSectionInfo = null;
        return draft;
      }
    case SAVE_SECTION_FAILURE:
      {
        const {
          errorType,
          count
        } = action.payload;
        draft.saveNewSectionError = errorType;
        draft.saveNewSectionModulesErrorCount = count;
        return draft;
      }
    case REDIRECT_TO_DELETE_SECTIONS:
      draft.showCreateNewSectionPanel = false;
      draft.currentCreateNewSectionInfo = null;
      draft.showAddSectionPanel = true;
      return draft;
    case FETCH_GENERATED_LAYOUT_HUBL_STARTED:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        const currentCopyFragmentAsHublInfo = {
          layoutSectionId,
          sectionName
        };
        draft.currentCopyFragmentAsHublInfo = currentCopyFragmentAsHublInfo;
        return draft;
      }
    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const missingModuleSchemaCount = action.payload.moduleSchemaErrorCount;
        draft.addSectionMissingModuleSchemaErrors = missingModuleSchemaCount;
        return draft;
      }
    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        draft.addSectionMissingModuleSchemaErrors = 0;
        return draft;
      }
    default:
      return draft;
  }
});
export default sectionsReducer;
import I18n from 'I18n';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { InternalFields } from '../constants/types';
/**
 * Maps the HubDBColumns to CmV2 fields with the proper settings on a field type basis
 * @returns ModuleFields
 */
export function hubDBFieldtoCmvField(fields, rowId, columns, moduleValues, fieldOverrides) {
  return fields.map(field => {
    const correspondingColumn = columns.find(val => val.id.toString() === field.id);
    if (field.id && !InternalFields[field.id]) {
      field = Object.assign({}, field, {
        help_text: correspondingColumn && correspondingColumn['description']
      });
    }
    if (field.type === FieldTypes.IMAGE) {
      return Object.assign({
        showAlt: false,
        responsive: false,
        forceHideResponsive: false,
        resizable: true
      }, field);
    }
    if (field.type === FieldTypes.VIDEO) {
      return Object.assign({}, field);
    }
    if (field.type === FieldTypes.HUBDB_TABLE) {
      const tableChoices = fieldOverrides && fieldOverrides.foreignTableOptions || [];
      if (!tableChoices.some(choice => choice[0] === 0)) {
        tableChoices.unshift([0, I18n.text('StructuredContentLib.instanceEditing.childTableEmpty')]);
      }
      return Object.assign({}, field, {
        choices: tableChoices
      });
    }
    if (field.type === FieldTypes.HUBDB_ROW) {
      return Object.assign({}, field, {
        rowId,
        optionType: 'foreignid',
        immutableValue: moduleValues[field.name],
        foreignIds: correspondingColumn && correspondingColumn.foreignIds || []
      });
    } else {
      return field;
    }
  });
}

/**
 * Maps hubdb value to a CmV2 value since the BE api structure is different.
 * @returns Map of columns to their corresponding Cmv2 value for a given row.
 */
// TODO refactor mapInstanceValueToModuleValue to work with the default HubDB data model
export function mapHubDBValueToModuleValue(row, tableRowSchema) {
  const moduleValues = {};
  tableRowSchema.fields.forEach(field => {
    const fieldId = field.id;
    if (fieldId && row && (row.values[fieldId] || InternalFields[fieldId])) {
      const rowValue = row.values[fieldId];
      let value;
      switch (field.type) {
        case FieldTypes.IMAGE:
          {
            value = Object.assign({}, rowValue, {
              src: rowValue.url
            });
            break;
          }
        case FieldTypes.VIDEO:
          {
            value = {
              player_id: rowValue,
              player_type: 'hsvideo2'
            };
            break;
          }
        case FieldTypes.URL:
          {
            value = {
              href: rowValue
            };
            break;
          }
        case FieldTypes.LOCATION:
          {
            value = rowValue;
            break;
          }
        case FieldTypes.HUBDB_TABLE:
          {
            value = row.childTableId;
            break;
          }
        case FieldTypes.CHOICE:
          {
            if (Array.isArray(rowValue)) {
              value = rowValue.map(option_value => option_value.id.toString());
            } else {
              value = rowValue.id.toString();
            }
            break;
          }
        case FieldTypes.HUBDB_ROW:
          {
            if (rowValue.length > 0) value = rowValue;
            break;
          }
        case FieldTypes.TEXT:
          {
            if (fieldId === InternalFields.hs_name) {
              value = row.name;
            } else if (fieldId === InternalFields.hs_path) {
              value = row.path;
            } else {
              value = row.values[fieldId];
            }
            break;
          }
        default:
          {
            value = row.values[fieldId];
          }
      }
      moduleValues[field.name] = value;
    } else {
      if (field.type === FieldTypes.LOCATION) {
        moduleValues[field.name] = {
          lat: null,
          long: null
        };
      }
    }
  });
  return moduleValues;
}
export function mapInstanceValuesToModuleValues(instance, schema) {
  const moduleValues = {};
  schema.fields.forEach(field => {
    if (instance && instance.values[field.name] != null) {
      const instanceValue = instance.values[field.name];
      let value;
      switch (field.type) {
        case FieldTypes.IMAGE:
          {
            value = instanceValue;
            value.src = instanceValue.url;
            break;
          }
        case FieldTypes.VIDEO:
          {
            value = {
              player_id: instanceValue,
              player_type: 'hsvideo2'
            };
            break;
          }
        case FieldTypes.URL:
          {
            value = {
              href: instanceValue
            };
            break;
          }
        case FieldTypes.LOCATION:
          {
            value = instanceValue;
            break;
          }
        case FieldTypes.HUBDB_TABLE:
          {
            value = instance.childTableId;
            break;
          }
        case FieldTypes.CHOICE:
          {
            if (Array.isArray(instanceValue)) {
              value = instanceValue.map(option_value => option_value.id.toString());
            } else {
              value = instanceValue.id.toString();
            }
            break;
          }
        case FieldTypes.HUBDB_ROW:
          {
            if (instanceValue.length > 0) value = instanceValue;
            break;
          }
        case FieldTypes.TEXT:
        case FieldTypes.RICH_TEXT:
          {
            value = instanceValue;
            break;
          }
        default:
          {
            value = instanceValue;
          }
      }
      moduleValues[field.name] = value;
    } else {
      if (field.type === FieldTypes.LOCATION) {
        moduleValues[field.name] = {
          lat: null,
          long: null
        };
      }
    }
  });
  return moduleValues;
}
import { fromJS } from 'immutable';
import { BUILTIN_MODULE_MANUAL_ORDER, BLOG_DND_RESTRICTED_MODULES } from 'ContentEditorUI/lib/sidebar/constants';
import { COMMON_MODULE_TYPES, STARTER_TEMPLATE_MODULE_TYPES } from 'ContentUtils/constants/ModuleLists';
import { createSelector } from 'reselect';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message
import { getImmutableOrPlain } from 'ContentEditorUI/utils/dataHelpers';
import { getHasTheme, getThemePath, getParentThemePath } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { isModuleAPartOfTheme, filterOutParentThemeModulesOverriddenInChildTheme } from 'ContentEditorUI/lib/themeUtils';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { mapOverObject, invertObjectKeysAndValues } from 'ContentEditorUI/lib/collectionHelpers';
import getLangLocale from 'I18n/utils/getLangLocale';
import { getModuleDisplayName } from 'ContentUtils/helpers/ModuleHelpers';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message
import { getIsStarterPageTemplate } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getHasCtaAccess, getHasPaymentsReadAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { upgradeableModuleTypeToPQLMap, UPGRADABLE_MODULES } from 'ContentEditorUI/redux/constants';
import { indexBy } from 'underscore';
import { getIsBlogPost } from './contentReadOnlyDataSelectors';
export const getModuleSchemas = basicSelector(state => state.moduleSchemas);
export const buildFakeModuleSchemasSlice = moduleSchemas => ({
  moduleSchemas
});

// Useful pattern for `createSelector` factories. See ts_migration_tips.md for more details
const createModuleSchemaSelector = field => {
  return createSelector([getModuleSchemas], moduleSchemas => moduleSchemas[field]);
};
export const getModuleIdsByBuiltinType = createModuleSchemaSelector('builtinMapping');
export const getBuiltInTypesByModuleId = createSelector(getModuleIdsByBuiltinType, moduleIdsByBuiltinType => {
  if (moduleIdsByBuiltinType == null) {
    return {};
  }
  return invertObjectKeysAndValues(moduleIdsByBuiltinType);
});
const createModuleSchemaSelectorWithTranslatedDisplayNames = field => {
  // TODO: Fix ContentUtils ModuleSchema type to remove the cast to ContentUtilsModuleSchema
  //   .css and .js should be string
  return createSelector(getModuleSchemas, moduleSchemas => {
    return mapOverObject(moduleSchemas[field], m => {
      const originalDisplayName = m.displayName;
      const displayName = getModuleDisplayName({}, getLangLocale(), m);
      if (displayName) {
        return Object.assign({}, m, {
          displayName,
          originalDisplayName
        });
      }
      return undefined;
    });
  });
};
const getCustomModuleSchemasById = createModuleSchemaSelector('customModules');
export const getCustomModuleSchemas = createSelector([getCustomModuleSchemasById], customModulesById => Object.values(customModulesById));
const getAllAddableModuleSchemas = createModuleSchemaSelectorWithTranslatedDisplayNames('allAddable');
const getAllAddableModuleSchemasAsArray = createSelector(getAllAddableModuleSchemas, allAddableModuleSchemas => Object.values(allAddableModuleSchemas));
export const getAllModuleSchemas = createModuleSchemaSelectorWithTranslatedDisplayNames('all');
export const getBuiltinModuleSchemasById = createModuleSchemaSelectorWithTranslatedDisplayNames('builtInModules');
const getArrayOfBuiltInModuleSchemas = createSelector([getBuiltinModuleSchemasById], builtinModuleSchemasById => Object.values(builtinModuleSchemasById));
const getBuiltinModuleSchemasByModuleId = createSelector(getArrayOfBuiltInModuleSchemas, arrayOfBuiltInModuleSchemas => {
  const result = {};
  for (const schema of arrayOfBuiltInModuleSchemas) {
    if (schema.moduleId) {
      result[`${schema.moduleId}`] = schema;
    }
  }
  return result;
});
const getGlobalModuleSchemasById = createModuleSchemaSelector('globalModules');
export const getGlobalModules = createSelector([getGlobalModuleSchemasById], globalModuleSchemasById => Object.values(globalModuleSchemasById));

// Note, even though this is a "schema" selector it only includes the global groups and global partials
// in this page's template (and not _all_ global groups and partials available in the account)
export const getGlobalGroupInfo = createModuleSchemaSelector('globalGroupInfo');
export const getGlobalPartialInfo = createModuleSchemaSelector('globalPartialInfo');

// Expose global info also as immutable for the (few?) places where a module and global are used
// interchangably (and make that conversion only happen once, in these selectors)
export const getGlobalGroupInfoAsImmutable = createSelector(getGlobalGroupInfo, globalGroupInfo => fromJS(globalGroupInfo));
export const getGlobalPartialInfoAsImmutable = createSelector(getGlobalPartialInfo, globalPartialInfo => fromJS(globalPartialInfo));
export const getAddableCustomModuleSchemas = createSelector([getCustomModuleSchemas], customModuleSchemas => {
  return customModuleSchemas.filter(m => m.isAvailableForNewContent !== false);
});
const getSortDisplayName = schema => (schema.displayName || schema.label || '').toUpperCase();
const schemaDisplayNameSortComparator = (schema1, schema2) => {
  const name1 = getSortDisplayName(schema1);
  const name2 = getSortDisplayName(schema2);
  if (name1 < name2) {
    return -1;
  } else if (name1 > name2) {
    return 1;
  }
  return 0;
};
export const getAllSortedAddableModuleSchemas = createSelector([getAllAddableModuleSchemasAsArray], allAddableArray => Array.from(allAddableArray).sort(schemaDisplayNameSortComparator));
const getAllSortedAddableNonCommonModuleSchemas = createSelector([getAllAddableModuleSchemasAsArray, getBuiltInTypesByModuleId], (allAddableArray, builtInTypesByModuleIntegerId) => allAddableArray.filter(m => !COMMON_MODULE_TYPES.includes(builtInTypesByModuleIntegerId[m.moduleId])).sort(schemaDisplayNameSortComparator));
export const getAllSortedAddableNonCommonNonThemeModuleSchemas = createSelector([getAllSortedAddableNonCommonModuleSchemas], allNonCommonModules => allNonCommonModules.filter(m => !m.isThemeAsset));
export const getBuiltinModuleSchemaByType = createSelector([getModuleIdsByBuiltinType, getBuiltinModuleSchemasByModuleId], (moduleIdsByBuiltinType, builtinModuleSchemasByModuleId) => {
  return mapOverObject(moduleIdsByBuiltinType, moduleId => {
    return builtinModuleSchemasByModuleId[`${moduleId}`];
  });
});
export const getAlphabeticallySortedBuiltInModuleSchemas = createSelector([getArrayOfBuiltInModuleSchemas], arrayOfBuiltInModules => {
  return arrayOfBuiltInModules.filter(m => m.isAvailableForNewContent !== false).sort(schemaDisplayNameSortComparator);
});
export const getCustomSortedBuiltInModuleSchemas = createSelector([getAlphabeticallySortedBuiltInModuleSchemas, getBuiltInTypesByModuleId, getModuleIdsByBuiltinType], (builtinsSortedAlphabetically, builtInTypesByModuleIntegerId, moduleIdsByBuiltinType) => {
  const builtinsSortedManually = BUILTIN_MODULE_MANUAL_ORDER.map(moduleType => builtinsSortedAlphabetically.find(m => m.moduleId && m.moduleId === moduleIdsByBuiltinType[moduleType])).filter(m => !!m);
  const filteredBuiltinsSortedAlphabetically = builtinsSortedAlphabetically.filter(m => !BUILTIN_MODULE_MANUAL_ORDER.includes(builtInTypesByModuleIntegerId[m.moduleId]));
  return builtinsSortedManually.concat(filteredBuiltinsSortedAlphabetically);
});
export const getSortedStarterTemplateModules = (sortedBuiltinModules, builtInTypesByModuleIntegerId, upgradableModuleTypeToAccessMap) => {
  return sortedBuiltinModules.filter(m => m.isAvailableForNewContent !== false && STARTER_TEMPLATE_MODULE_TYPES.includes(builtInTypesByModuleIntegerId[m.moduleId]) && (!upgradableModuleTypeToAccessMap.hasOwnProperty(builtInTypesByModuleIntegerId[m.moduleId]) || upgradableModuleTypeToAccessMap[builtInTypesByModuleIntegerId[m.moduleId]]));
};
export const getUpgradableModuleTypeToAccessMap = createSelector([getHasCtaAccess, getHasPaymentsReadAccess], (hasCtaAccess, hasPaymentsAccess) => {
  return {
    [UPGRADABLE_MODULES.CTA]: hasCtaAccess,
    [UPGRADABLE_MODULES.PAYMENTS]: hasPaymentsAccess
  };
});
export const isRestrictedBlogModule = module => {
  if (!module.path) {
    return true;
  }
  // need as string here because `.pop()` could return undefined if the array is empty
  // But since we have a guarantee that path is a string, `.split` should never return an empty array
  return BLOG_DND_RESTRICTED_MODULES.includes(module.path);
};
export const getAddableCommonModules = createSelector([getCustomSortedBuiltInModuleSchemas, getBuiltInTypesByModuleId, getIsStarterPageTemplate, getUpgradableModuleTypeToAccessMap, getIsBlogPost], (sortedBuiltinModules, builtInTypesByModuleIntegerId, isStarterPageTemplate, upgradableModuleTypeToAccessMap, isBlogPost) => {
  if (isStarterPageTemplate) {
    return getSortedStarterTemplateModules(sortedBuiltinModules, builtInTypesByModuleIntegerId, upgradableModuleTypeToAccessMap);
  }
  const addableCommonModules = sortedBuiltinModules.filter(m => m.isAvailableForNewContent !== false && COMMON_MODULE_TYPES.includes(builtInTypesByModuleIntegerId[m.moduleId]) && (!upgradableModuleTypeToAccessMap.hasOwnProperty(builtInTypesByModuleIntegerId[m.moduleId]) || upgradableModuleTypeToAccessMap[builtInTypesByModuleIntegerId[m.moduleId]]));
  if (isBlogPost) {
    return addableCommonModules.filter(module => !isRestrictedBlogModule(module));
  }
  return addableCommonModules;
});
export const getUpgradableModulesWithPQL = createSelector([getCustomSortedBuiltInModuleSchemas, getUpgradableModuleTypeToAccessMap, getBuiltInTypesByModuleId, getIsStarterPageTemplate], (sortedBuiltinModules, upgradableModuleTypeToAccessMap, builtInTypesByModuleIntegerId, isStarterPageTemplate) => {
  const moduleList = isStarterPageTemplate ? STARTER_TEMPLATE_MODULE_TYPES : COMMON_MODULE_TYPES;
  const moduleSchemasWithPQLArray = [];
  sortedBuiltinModules.forEach(m => {
    const builtInType = builtInTypesByModuleIntegerId[m.moduleId];
    if (m.isAvailableForNewContent !== false && moduleList.includes(builtInType) && !upgradableModuleTypeToAccessMap[builtInType] && upgradeableModuleTypeToPQLMap[builtInType]) {
      moduleSchemasWithPQLArray.push({
        moduleSchema: m,
        pqlId: upgradeableModuleTypeToPQLMap[builtInType]
      });
    }
  });
  return moduleSchemasWithPQLArray;
});
export const getAllModuleSchemasAsArray = createSelector([getAllModuleSchemas], allModuleSchemas => Object.values(allModuleSchemas));

// Note, this Map will exclude any older module definitions (global v1, older v1 modules?) that
// do not have a `moduleId` set on them. In other words, it will exclude module definitions that would
// be correctly found by the various fall-through logic in getSchemaForModuleHelper.
//
// However it still is helpful, since it easily collects _most_ module definitions to aid in fixes like
// https://git.hubteam.com/HubSpot/ContentEditorUI/pull/10059
export const getAllModuleSchemasByModuleId = createSelector([getAllModuleSchemasAsArray], allModuleSchemasAsArray => {
  const result = {};
  for (const moduleSchema of allModuleSchemasAsArray) {
    if (moduleSchema.hasOwnProperty('moduleId')) {
      result[moduleSchema.moduleId] = moduleSchema;
    }
  }
  return result;
});
export const getAllModuleSchemasByPath = createSelector([getAllModuleSchemasAsArray], moduleSchemas => {
  return indexBy(moduleSchemas, schema => schema.path);
});
const isNotAThemeAssetOrInCurrentPagesTheme = (schema, hasTheme, themePath, parentThemePath) => {
  const isNotAThemeModule = !schema.isThemeAsset;
  const isModuleInPagesTheme = hasTheme && isModuleAPartOfTheme(schema.path, themePath, parentThemePath);
  return isNotAThemeModule || isModuleInPagesTheme;
};
export const getAllSortedAddableModuleSchemasForThisPagesTheme = createSelector([getAllSortedAddableModuleSchemas, getHasTheme, getThemePath, getParentThemePath], (sortedModules, hasTheme, themePath, parentThemePath) => {
  const common = sortedModules.filter(m => {
    return m.isAvailableForNewContent !== false && isNotAThemeAssetOrInCurrentPagesTheme(m, hasTheme, themePath, parentThemePath);
  });
  return filterOutParentThemeModulesOverriddenInChildTheme(common, themePath, parentThemePath);
});
export const getGlobalModulesForThisPagesTheme = createSelector([getGlobalModules, getHasTheme, getThemePath, getParentThemePath], (globalModules, hasTheme, themePath, parentThemePath) => {
  const filteredGlobalMOdules = globalModules.filter(m => {
    return m.isAvailableForNewContent !== false && isNotAThemeAssetOrInCurrentPagesTheme(m, hasTheme, themePath, parentThemePath);
  });
  return filterOutParentThemeModulesOverriddenInChildTheme(filteredGlobalMOdules, themePath, parentThemePath);
});
export const getAddableCustomModuleSchemasForThisPagesTheme = createSelector([getAddableCustomModuleSchemas, getHasTheme, getThemePath, getParentThemePath], (customModules, hasTheme, themePath, parentThemePath) => {
  const filteredModules = customModules.filter(m => {
    return isNotAThemeAssetOrInCurrentPagesTheme(m, hasTheme, themePath, parentThemePath);
  });
  return filterOutParentThemeModulesOverriddenInChildTheme(filteredModules, themePath, parentThemePath);
});
export const getAddableThemeModules = createSelector([getAllSortedAddableModuleSchemas, getHasTheme, getThemePath, getParentThemePath, getIsBlogPost], (sortedModules, hasTheme, themePath, parentThemePath, isBlogPost) => {
  if (!hasTheme) {
    return [];
  }
  let common = sortedModules.filter(m => m.isAvailableForNewContent !== false && isModuleAPartOfTheme(m.path, themePath, parentThemePath));
  if (isBlogPost) {
    common = common.filter(module => !isRestrictedBlogModule(module));
  }
  return filterOutParentThemeModulesOverriddenInChildTheme(common, themePath, parentThemePath);
});
export const getCustomModuleIds = createSelector([getCustomModuleSchemas], customModuleSchemas => customModuleSchemas.map(customModuleSchema => customModuleSchema.moduleId));

// Intentially choosing this to be one of the few situations we support passing in
// Immutable or plain JS objects
//
// TODO, we really need to optimize this function (and/or the things that call it). While profiling,
// I saw that _one_ mouse move ended up calling this function nearly 100 times!
// So even if it only takes a fraction of a millisecond, in aggregate that adds up (and leads me
// to believe what else could be being called way too much)
export const getSchemaForModuleHelper = (module, allModuleSchemasArray = [], moduleIdsByBuiltinType = {}) => {
  const moduleType = getImmutableOrPlain(module, 'type');
  switch (moduleType) {
    case 'js_module':
    case 'module':
    case 'custom_widget':
      {
        const findFirst = funcArr => {
          for (let i = 0; i < funcArr.length; i++) {
            const result = funcArr[i]();
            if (result) {
              return result;
            }
          }
          return null;
        };
        const findPropertyOnModuleOrBody = property => {
          const body = getImmutableOrPlain(module, 'body');
          if (body) {
            return getImmutableOrPlain(module, property) || getImmutableOrPlain(body, property);
          }
          return getImmutableOrPlain(module, property);
        };
        const schemaLookups = [() => {
          const moduleId = getImmutableOrPlain(module, 'module_id');
          if (moduleId) {
            return allModuleSchemasArray.find(m => `${moduleId}` === `${m.moduleId}`);
          }
          return undefined;
        }, () => {
          // This case represents a module not being found in the lookup. This case typically happens when a v1 module is converted to a v2 module
          // and the widget_name gets renamed. This default fallback ensures the rest of the code works appropriately without failing on missing
          // schemas and will label them as "not found" when a user clicks on them. More context in:
          // https://git.hubteam.com/HubSpot/ContentEditorUI/pull/6701
          const widgetName = findPropertyOnModuleOrBody('widget_name');
          const result = {
            fields: [],
            module_id: widgetName,
            name: widgetName,
            schemaVersion: 2,
            type: 'custom_widget'
          };
          return result;
        }];
        return findFirst(schemaLookups);
      }
    // Force image HUBL tag in v1 custom HUBL module to use the linked_image schema (since there
    // is no plain `image` builtin)
    case 'image':
      {
        const id = moduleIdsByBuiltinType.linked_image;
        return allModuleSchemasArray.find(m => `${m.moduleId}` === `${id}`);
      }
    // These are V1 global modules
    case 'global_widget':
      {
        let name;
        const body = getImmutableOrPlain(module, 'body');
        if (body && body.size > 0) {
          name = getImmutableOrPlain(body, 'global_widget_name');
        } else {
          name = getImmutableOrPlain(module, 'name');
        }
        if (name) {
          return allModuleSchemasArray.find(m => m.name === name);
        }
        return null;
      }
    case 'fake':
      {
        // Forcibly make sure that fake modules don't return a schema?
        return undefined;
      }
    default:
      {
        const id = moduleIdsByBuiltinType[moduleType];
        return allModuleSchemasArray.find(m => `${m.moduleId}` === `${id}`);
      }
  }
};
export const getSchemaForModule = basicSelectorWithStats((state, module) => {
  const allModuleSchemasArray = getAllModuleSchemasAsArray(state);
  const moduleIdsByBuiltinType = getModuleIdsByBuiltinType(state);
  return getSchemaForModuleHelper(module, allModuleSchemasArray, moduleIdsByBuiltinType);
});
const extractModuleArgHelper = (__state, module) => module;
export const makeGetSchemaForModule = () => {
  return createSelector([getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, extractModuleArgHelper], (allModuleSchemasArray, moduleIdsByBuiltinType, module) => getSchemaForModuleHelper(module, allModuleSchemasArray, moduleIdsByBuiltinType));
};
export const getIsDefaultFormModule = basicSelectorWithStats((state, schema) => {
  const mapping = getModuleIdsByBuiltinType(state);
  const imageModuleId = mapping.form;
  return imageModuleId && imageModuleId === schema.moduleId;
});
export const getSchemaForId = basicSelectorWithStats((state, id) => {
  const allModuleSchemas = getAllModuleSchemas(state);
  return allModuleSchemas[`${id}`];
});
export const getSchemaForModuleId = basicSelectorWithStats((state, moduleId) => {
  const allModuleSchemas = getAllModuleSchemas(state);
  let schemaForModuleId;
  for (const id in allModuleSchemas) {
    const schema = allModuleSchemas[id];
    if (schema.moduleId === moduleId) {
      schemaForModuleId = schema;
      break;
    }
  }
  return schemaForModuleId;
});
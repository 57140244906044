import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getOnboardingTourState = basicSelector(state => state.onboardingTourState);
const createOnboardingTourStateSelector = key => createSelector(getOnboardingTourState, onboardingTourState => onboardingTourState && onboardingTourState[key]);
export const getCanUseOnboardingTour = basicSelector(state => state && !!state.onboardingTourState);
export const getOnboardingTourIframe = createOnboardingTourStateSelector('currentTourIframe');
export const getOnboardingTourHasDraggedModule = createOnboardingTourStateSelector('hasDraggedModuleIn');
export const getOnboardingTourHasRenderedSidebarStep = createOnboardingTourStateSelector('hasRenderedSidebarStep');
export const getShowOnboardingTourQuitModal = createOnboardingTourStateSelector('showOnboardingTourQuitModal');
export const getFinishTourInpage = createOnboardingTourStateSelector('finishTourInPage');
export const getGoToStyleSection = createOnboardingTourStateSelector('goToStyleSection');
export const getProgressionToTrack = createOnboardingTourStateSelector('progressionToTrack');
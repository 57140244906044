'use es6';

import { CLONED_STATIC_SECTION_MAP_CLEARED, CLONED_MODULES_QUEUE_CLEARED, MERGE_MODULE_META_DATA, ADDED_MODULES_TO_FAKE_MODULES, ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES, INITIALIZE_FAKE_UNDOABLE_ITEMS, SET_DEPRECATED_STYLES_FLAGS, MERGE_MODULE_BODY, UPDATE_POST_BODY, UPDATE_POST_CONTENT_MODEL_TITLE, SET_MODULE_STYLES, SET_MODULE_BREAKPOINT_STYLES, LAYOUT_SECTION_COLUMNS_RESIZED, LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES, LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES, SET_BREAKPOINT_STYLES_FOR_TREE_NODE, ADDED_MODULES_TO_POST_BODY } from 'ContentEditorUI/redux/actions/actionTypes';
const ACTION_TYPES_TO_IGNORE = [CLONED_STATIC_SECTION_MAP_CLEARED, CLONED_MODULES_QUEUE_CLEARED, MERGE_MODULE_META_DATA, ADDED_MODULES_TO_FAKE_MODULES, ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES, INITIALIZE_FAKE_UNDOABLE_ITEMS, SET_DEPRECATED_STYLES_FLAGS];
const ACTION_TYPES_TO_GROUP = [MERGE_MODULE_BODY, UPDATE_POST_BODY, ADDED_MODULES_TO_POST_BODY, UPDATE_POST_CONTENT_MODEL_TITLE, SET_MODULE_STYLES, SET_MODULE_BREAKPOINT_STYLES, LAYOUT_SECTION_COLUMNS_RESIZED, LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES, LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES, SET_BREAKPOINT_STYLES_FOR_TREE_NODE];
export const isIgnoredAction = (type, extraActionTypesToIgnore = []) => {
  return ACTION_TYPES_TO_IGNORE.includes(type) || extraActionTypesToIgnore.includes(type);
};
export const isGroupedAction = type => {
  return ACTION_TYPES_TO_GROUP.includes(type);
};
'use es6';

import { FETCH_BRAND_COLORS_STARTED, FETCH_BRAND_COLORS_FAILED, FETCH_BRAND_COLORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fetchBrandColors as fetchBrandColorsViaApi } from 'ContentEditorUI/api/BrandSettingsApi';
const fetchBrandColorsSucceeded = response => ({
  type: FETCH_BRAND_COLORS_SUCCEEDED,
  payload: response
});
const fetchfetchBrandColorsFailed = error => ({
  type: FETCH_BRAND_COLORS_FAILED,
  error
});
export const fetchBrandColors = () => dispatch => {
  dispatch({
    type: FETCH_BRAND_COLORS_STARTED
  });
  const success = resp => dispatch(fetchBrandColorsSucceeded(resp));
  const error = resp => dispatch(fetchfetchBrandColorsFailed(resp));
  fetchBrandColorsViaApi().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
import { SHOULD_CLEAR_HEADER_ACTIVE_ITEM, UPDATE_HEADER_ACTIVE_ITEM } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  activeItem: '',
  shouldClear: false
};
const headerReducer = (state = initialState, action) => produce(state, draft => {
  const {
    payload,
    type
  } = action;
  switch (type) {
    case UPDATE_HEADER_ACTIVE_ITEM:
      {
        draft.activeItem = payload;
        return draft;
      }
    case SHOULD_CLEAR_HEADER_ACTIVE_ITEM:
      {
        draft.shouldClear = payload;
        return draft;
      }
    default:
      return draft;
  }
});
export default headerReducer;
import { uploadFileV3 } from 'FileManagerCore/api/Files';
import { ConfigureFileManagerFileAccess } from 'FileManagerLib/enums/FileAccess';
import { ApiMap } from '../ApiMap';
import { createApiCache } from './createApiCache';
const FileCache = {};
export function getFetchFileByUrl(http) {
  const withFileCache = createApiCache(http.get, ApiMap.FILE_RESOLVE.getUrl, FileCache);
  return function fetchFileByUrl(fullUrl) {
    const httpParams = {
      query: {
        url: fullUrl,
        allowRedirects: true
      }
    };
    return withFileCache('', httpParams);
  };
}
const base64ToBlob = (base64Str, mimeType) => {
  let str = '';
  try {
    str = atob(base64Str);
  } catch (e) {
    return new Blob([]);
  }
  const blobCharCodes = new Uint8Array(str.length);
  for (let i = 0; i < blobCharCodes.length; i++) {
    blobCharCodes[i] = str.charCodeAt(i);
  }
  return new Blob([blobCharCodes], {
    type: mimeType
  });
};
export const normalizeFile = (fileWrapper, useBase64 = false) => {
  const {
    file,
    base64
  } = fileWrapper;
  const fileData = file.size && !useBase64 ? file : base64ToBlob(base64, file.type);
  const fileName = file.name;
  return {
    fileData,
    fileName
  };
};
export function uploadFile(fileObj, progress, httpClient, uploadedFileAccess = ConfigureFileManagerFileAccess.VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE) {
  const {
    fileData,
    fileName
  } = fileObj;
  let file;

  // Constructing a new file here because when a File object
  // has been sent through an interframe Bus if we try and upload it
  // the server is returning a 500. If we take the Blob and construct
  // a new File object, it uploads fine. We are checking for "File" because IE.
  if (File) {
    file = new File([fileData], fileName);
  } else {
    file = fileData;
  }
  return uploadFileV3(file, uploadedFileAccess, {
    fileName
  }, {
    updateProgress: progress,
    httpClient
  }).then(response => response.toJSON());
}
export const getIframeUploadFile = (httpClient, uploadedFileAccess, fileWrapper, progress) => {
  const fileObj = normalizeFile(fileWrapper);
  return uploadFile(fileObj, progress, httpClient, uploadedFileAccess);
};
export const uploadImagesByUrl = (doc, apiClient, fileNameBaseline = '', fileAccess) => {
  return Promise.all(Array.from(doc.getElementsByTagName('img')).filter(img => !img.src.includes('hubspotusercontent')).map(element => {
    const originalUrl = element.src;
    const data = {
      folderPath: 'Rich Text Pasting',
      name: `${fileNameBaseline}-${Date.now()}`,
      overwrite: false,
      url: originalUrl,
      access: fileAccess
    };
    return apiClient.post(ApiMap.FILE_UPLOAD_URL.getUrl(), {
      data
    }).then(response => {
      return {
        originalUrl,
        fileManagerUrl: response.url
      };
    });
  }));
};
export const getLinks = ({
  userDOM
}) => {
  const linksElements = userDOM.querySelectorAll('body a');
  const links = linksElements && [...linksElements].map(link => {
    const {
      href,
      hreflang,
      rel,
      text
    } = link;
    return {
      href: href.toString(),
      hreflang,
      rel,
      text
    };
  });
  return links;
};
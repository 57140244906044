import { VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE, VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE, VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE, HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE, HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE, HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE, HIDDEN_IN_APP_SENSITIVE } from 'FileManagerCore/enums/InternalFileManagerFileAccess';
export const DrawerFileAccess = {
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE]: VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE,
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE]: VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE,
  [VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE]: VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE
};
export const DropZoneWithBrowseFileAccess = DrawerFileAccess;
export const ConfigureFileManagerFileAccess = DrawerFileAccess;
export const DropZoneNoBrowseFileAccess = {
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE]: VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE,
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE]: VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE,
  [HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE]: HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE,
  [HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE]: HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE,
  [VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE]: VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE,
  [HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE]: HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE,
  [HIDDEN_IN_APP_SENSITIVE]: HIDDEN_IN_APP_SENSITIVE
};
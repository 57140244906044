import { SHOW_LOADING, RELOAD_WITHOUT_WARNING, I18N_READY, SET_IFRAME_READY, SET_IFRAME_RENDERED, SET_IFRAME_LOADING, SET_IFRAME_HIDDEN, SET_ADD_MODULE_SIDEBAR_LOADED, HIDE_LOADING, IFRAME_CLICKED, SET_VALIDATION_POPOVER_OPEN, OPEN_POPOVER, DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON, CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON, CONTENT_SAVE_REQUEST_FAILED, ACTIVATE_DEVELOPER_MODE, UNIVERSAL_APP_IS_READY, TOGGLE_APPLY_CHANGES_MODAL_OPEN } from 'ContentEditorUI/redux/actions/actionTypes';
import { CLOSE_RICH_TEXT_EDITOR_FIREFOX, OPEN_RICH_TEXT_EDITOR_FIREFOX } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import produce from 'immer';

// @ts-expect-error Not typed
import { isLocked } from 'ContentEditorUI/utils/errorUtils';
const INITIAL_STATE = {
  hasManuallySetLoadingStatus: false,
  i18nReady: false,
  caresAboutNextIframeClick: false,
  iframeStartedLoading: null,
  iframeReady: false,
  iframeRendered: false,
  iframeHidden: true,
  addModuleSidebarLoaded: false,
  iframeCaresAboutNextWindowClick: false,
  exitPreviewAndLayoutButtonDisplayed: false,
  saveError: null,
  routingInProgress: null,
  developerMode: false,
  isUniversalAppReady: false,
  iframeStartedLoadingAt: null
};
const appStatusReducer = (state = INITIAL_STATE, action) => produce(state, draft => {
  const type = action.type;
  switch (type) {
    case SHOW_LOADING:
    case RELOAD_WITHOUT_WARNING:
      {
        draft.hasManuallySetLoadingStatus = true;
        return draft;
      }
    case I18N_READY:
      {
        draft.i18nReady = true;
        return draft;
      }
    case SET_IFRAME_READY:
      {
        draft.iframeReady = true;
        return draft;
      }
    case SET_IFRAME_RENDERED:
      {
        draft.iframeReady = true;
        draft.iframeRendered = true;
        return draft;
      }
    case SET_IFRAME_LOADING:
      {
        const {
          startedLoadingAt
        } = action.payload;
        draft.iframeReady = false;
        draft.iframeStartedLoadingAt = startedLoadingAt;
        return draft;
      }
    case SET_IFRAME_HIDDEN:
      {
        const {
          iframeHidden
        } = action.payload;
        draft.iframeHidden = iframeHidden;
        return draft;
      }
    case SET_ADD_MODULE_SIDEBAR_LOADED:
      {
        draft.addModuleSidebarLoaded = true;
        return draft;
      }
    case HIDE_LOADING:
      {
        draft.hasManuallySetLoadingStatus = false;
        return draft;
      }
    case IFRAME_CLICKED:
      {
        draft.caresAboutNextIframeClick = false;
        return draft;
      }
    case SET_VALIDATION_POPOVER_OPEN:
    case OPEN_POPOVER:
      {
        draft.caresAboutNextIframeClick = true;
        return draft;
      }
    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        const payload = action.payload;
        const routingInProgressOpts = payload ? payload.routingInProgress : null;
        draft.routingInProgress = routingInProgressOpts;
        return draft;
      }
    case OPEN_RICH_TEXT_EDITOR_FIREFOX:
      {
        draft.iframeCaresAboutNextWindowClick = true;
        return draft;
      }
    case CLOSE_RICH_TEXT_EDITOR_FIREFOX:
      {
        draft.iframeCaresAboutNextWindowClick = false;
        return draft;
      }
    case DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON:
      {
        draft.exitPreviewAndLayoutButtonDisplayed = true;
        return draft;
      }
    case CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON:
      {
        draft.exitPreviewAndLayoutButtonDisplayed = false;
        return draft;
      }
    case CONTENT_SAVE_REQUEST_FAILED:
      {
        const {
          error: {
            response
          }
        } = action;
        if (isLocked(response)) {
          draft.saveError = response.responseJSON || {};
          return draft;
        }
        return draft;
      }
    case ACTIVATE_DEVELOPER_MODE:
      {
        draft.developerMode = true;
        return draft;
      }
    case UNIVERSAL_APP_IS_READY:
      {
        draft.isUniversalAppReady = true;
        return draft;
      }
    default:
      return draft;
  }
});
export default appStatusReducer;
import { createSelector } from 'reselect';
import { getIsUngatedForInlineDataTokenEditing } from './authSelectors';
import { getDynamicPageDataSourceId } from './contentModelSelectors';
import { getPublishedTablesSortedByPublishedTime, getTablesUsedForPages } from '../../utils/hubdb';
import { mapInstanceValuesToModuleValues } from '../../utils/transformers';
import * as ActionTypes from './../actions/ActionTypes';
import { UNINITIALIZED } from 'ContentUtils/constants/RequestStatus';
import { DEFAULT as FALLBACK_STATE } from './../reducers/instanceEditingReducer';
import { createCanRedoSelector, createCanUndoSelector, createUndoCountSelector, createUndoRedoCountSelector } from './undoRedoSelectors';
const selectInstanceEditingSlice = state => state.structuredContent && state.structuredContent.instanceEditing;
export const selectInstanceEditingState = state => {
  if (state.structuredContent && state.structuredContent.instanceEditing) {
    return state.structuredContent.instanceEditing.present;
  } else {
    return FALLBACK_STATE;
  }
};
export const selectCanUndoInstanceEditingState = createCanUndoSelector(selectInstanceEditingSlice);
export const selectCanRedoInstanceEditingState = createCanRedoSelector(selectInstanceEditingSlice);
export const selectInstanceEditingUndoCount = createUndoCountSelector(selectInstanceEditingSlice);
export const selectInstanceEditingUndoRedoCount = createUndoRedoCountSelector(selectInstanceEditingSlice);
export const selectAllTables = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing.allTables);
export const getTableId = (state, props) => {
  if (props && props.tableId) {
    return props.tableId;
  }
  const instanceEditingState = selectInstanceEditingState(state);
  if (instanceEditingState.hubDbTable) {
    return instanceEditingState.hubDbTable.id;
  }
  return null;
};
export const selectRequestStatuses = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState.requestStatusTracker);
export const selectTableRows = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && instanceEditingState.tableRows);
export const selectCurrentInstance = createSelector([selectInstanceEditingState], instanceEditingState => {
  if (instanceEditingState.currentInstance) {
    return instanceEditingState.currentInstance;
  }
  return null;
});
export function createInstanceValueSelector(field) {
  return createSelector([selectCurrentInstance], currentInstance => {
    if (currentInstance) {
      return currentInstance.values[field];
    }
    return null;
  });
}
export const selectSelectedTable = createSelector([selectInstanceEditingState], instanceEditingState => {
  return instanceEditingState.hubDbTable;
});
export const selectHubDBTableSchemaColumns = createSelector([selectSelectedTable], selectedTable => {
  return selectedTable && selectedTable.columns || [];
});
export const selectAllTablesSortedByPublished = createSelector(selectAllTables, getPublishedTablesSortedByPublishedTime);
export const getSortByPublishedAndDynamic = createSelector(selectAllTablesSortedByPublished, getTablesUsedForPages);
export const selectForeignTableOptions = createSelector(
// @ts-expect-error TODO figure out type conflict with getSortByPublishedAndDynamic
[getSortByPublishedAndDynamic, getTableId], (tables, tableId) => {
  if (!tables) return [];
  return tables.filter(table => table.id.toString() !== tableId).map(table => {
    return [table.id, table.label];
  });
});
export const selectTableAsModuleSchema = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && Object.assign({}, instanceEditingState.tableSchema, {
  writable: true
}) || {});
export const selectTableInstances = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && instanceEditingState.tableRows);
export const selectActiveHubDbRowId = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && instanceEditingState.currentHubDbRowId || 0);
export const selectModuleValues = createSelector([selectCurrentInstance, selectTableAsModuleSchema], (currentInstance, moduleSchema) => {
  if (currentInstance) {
    return mapInstanceValuesToModuleValues(currentInstance, moduleSchema);
  }
  return {};
});
export const selectCurrentHubDbFieldsAsSimpleMap = createSelector([selectCurrentInstance, selectTableAsModuleSchema], (currentRow, tableSchema) => {
  const fields = [];
  if (currentRow) {
    tableSchema.fields.forEach(field => {
      const rowValueIndex = parseInt(field.id, 10);
      const value = currentRow.values[rowValueIndex];
      fields.push({
        name: field.name,
        id: field.id,
        type: field.type,
        value
      });
    });
  }
  return fields;
});
export const getInstanceEditingPreviewParams = createSelector([selectActiveHubDbRowId], hubDbRowId => {
  // Mostly a means by which downstream iframe url selectors continue to work
  // even if the instance editing reducer is not in this editor
  return hubDbRowId ? {
    hubdb_row_id: hubDbRowId
  } : {};
});
export const getIsInstanceEditingModeActive = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && instanceEditingState.instanceEditingModeActive);
export const getShouldShowInstanceEditingMode = createSelector(
// @ts-expect-error TODO figure out type conflict with getIsUngatedForInlineDataTokenEditing and getDynamicPageDataSourceId
[getIsUngatedForInlineDataTokenEditing, getDynamicPageDataSourceId], (isUngatedForInlineDataTokenEditing, dynamicPageDataSourceId) => {
  return isUngatedForInlineDataTokenEditing && !!dynamicPageDataSourceId;
});
export const selectInstanceEditingRequestStatus = createSelector([selectInstanceEditingState], instanceEditingState => {
  return instanceEditingState && instanceEditingState.requestStatusTracker;
});
const makeGetRequestStatus = (requestState, actionType) => {
  return requestState[actionType] || UNINITIALIZED;
};
export const selectModuleSchemaRequestStatus = createSelector([selectRequestStatuses], requestStatuses => makeGetRequestStatus(requestStatuses, ActionTypes.FETCH_MODULE_SCHEMA));
export const selectTableRequestStatus = createSelector([selectRequestStatuses], requestStatuses => makeGetRequestStatus(requestStatuses, ActionTypes.FETCH_HUBDB_TABLE));
export const selectFetchRowRequestStatus = createSelector([selectRequestStatuses], requestStatuses => makeGetRequestStatus(requestStatuses, ActionTypes.FETCH_ROW));
export const selectInstancePublishStatus = createSelector([selectCurrentInstance], instance => instance && instance.values.hs_state);
export const selectInlineEditingMetaData = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState && instanceEditingState.inlineEditingMetaData ? instanceEditingState.inlineEditingMetaData : {});
export const selectInlineEditingMetaDataForInstanceProperty = (state, instanceProperty) => {
  const inlineEditingMetaData = selectInlineEditingMetaData(state);
  return inlineEditingMetaData[instanceProperty];
};
export const selectContentTypeSchema = createSelector([selectInstanceEditingState], instanceEditingState => instanceEditingState === null || instanceEditingState === void 0 ? void 0 : instanceEditingState.contentTypeSchema);
export const selectContentTypeColumns = createSelector([selectContentTypeSchema], contentTypeSchema => {
  return (contentTypeSchema === null || contentTypeSchema === void 0 ? void 0 : contentTypeSchema.columns) || [];
});
export const selectInstanceValues = createSelector([selectCurrentInstance], currentInstance => currentInstance === null || currentInstance === void 0 ? void 0 : currentInstance.values);
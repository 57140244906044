export default function getKnowledgeBaseUrl(approvalType) {
  switch (approvalType) {
    case 'MARKETING_EMAIL':
      return 'https://knowledge.hubspot.com/email/request-approval-to-send-a-marketing-email-beta';
    case 'BLOG_POST':
    case 'LANDING_PAGE':
    case 'SITE_PAGE':
      return 'https://knowledge.hubspot.com/website-pages/approve-hubspot-content';
    case 'DEAL':
      return 'https://knowledge.hubspot.com/object-settings/pipeline-approvals-beta';
    default:
      return 'https://knowledge.hubspot.com/email/request-approval-to-send-a-marketing-email-beta';
  }
}
import { useCallback, useEffect, useState } from 'react';
export default function usePageIsVisible() {
  const [pageIsVisible, setPageIsVisible] = useState(document.visibilityState === 'visible');
  const handleVisibilityChange = useCallback(() => {
    setPageIsVisible(document.visibilityState === 'visible');
  }, []);
  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
  return pageIsVisible;
}
export const OWNER_CATEGORY = 'owner';
export const SITE_SETTINGS_CATEGORY = 'site_settings';
export const ALL_TOKEN_PATTERN = /{{\s*(company|contact|deal|line_item|owner|quote|site_settings|ticket)\.(\w+)(?:\.(\w+))?\s*}}/g;
export const PROPERTY_ONLY_TOKEN_PATTERN = /{{\s*(subscription_name)?\s*}}/g;
export const TOKEN_PATTERN_WITH_DEFAULT = /{{\s*personalization_token\(\s*["']{1}([^\\.\s]+)\.(\w+)\.?(\w+)?["']{1}\s*,\s*["']{1}(.*?)["']{1}\s*\)\s*}}/g;
export const FILTER_PATTERN_FRAGMENT = '(?:\\|(.*?))?\\s*';
export const hsPersonalizeConfig = {
  ALLOW_EMPTY_DEFAULTS: 'allowEmptyDefaults',
  HIDE_SITE_SETTINGS_CATEGORY: 'hideSiteSettingsCategory',
  INCLUDE_SUBSCRIPTION_TYPE_CATEGORY: 'includeSubscriptionTypeCategory',
  INCLUDE_INTERNAL_CONTACT_RECORD: 'includeInternalContactRecord',
  ALLOW_EMPTY_FALLBACKS: 'allowEmptyFallbacks',
  IS_EMAIL: 'isEmail',
  HIDE_FALLBACKS: 'hideFallbacks',
  SUPPORTED_CRM_OBJECT_FQNS: 'supportedCrmObjectFQNs',
  CUSTOM_OBJECT_PREFIXES: 'customObjectPrefixes',
  USE_FLEXIBLE_ASSOCIATIONS: 'useFlexibleAssociations',
  PROPERTY_FILTERS_CALLBACK: 'propertyFiltersCallback',
  USE_AS_UPGRADE_POINT: 'useAsUpgradePoint',
  TOKEN_PATHS_CONTEXT: 'dataTokenContext',
  UNSUPPORTED_TOKENS: 'unsupportedTokens'
};
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
export function fetchVideo2(videoId, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`video/v1/videos/${videoId}`);
}
export function fetchVideoByFileId(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`video/v1/videos/by-file-id/${id}`);
}
const MAX_TRIES = 10;
const POLL_INTERVAL_MS = 1000;
export function pollForUploadedVideo(fileId) {
  let tries = 0;
  let timeout;
  return new Promise((resolve, reject) => {
    const tryFetch = () => {
      fetchVideoByFileId(fileId).then(data => {
        clearTimeout(timeout);
        resolve(data);
      }).catch(err => {
        tries++;
        if (tries < MAX_TRIES) {
          timeout = setTimeout(tryFetch, POLL_INTERVAL_MS);
        } else {
          reject(err);
        }
      });
    };
    timeout = setTimeout(tryFetch, POLL_INTERVAL_MS);
  });
}
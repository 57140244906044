/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import defer from 'hs-promise-utils/defer';
import timeout from 'hs-promise-utils/timeout';
import { getIframeReady } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import enviro from 'enviro';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
const MAX_WAIT = 3000;
const logHandshakeStatus = status => {
  if (enviro.debug('DEBUG_INPAGE_HANDSHAKE')) {
    console.groupCollapsed('Inpage Handshake Status');
    console.log(status);
    console.groupEnd('Inpage Handshake Status');
  }
};
export default function makeWaitForPendingInpageUpdates(eventBus) {
  return function (state, maxWait = MAX_WAIT) {
    const deferred = defer();
    if (getIframeReady(state)) {
      const promise = timeout(eventBus.postMessage({
        action: 'checkForPendingInpageUpdates'
      }), maxWait, `Timed out waiting for response from inpage about pending updates`);
      promise.then(response => {
        logHandshakeStatus(response);
        deferred.resolve({
          iframeReady: true,
          response
        });
        if (response.error) {
          // We got a response from the iframe, but it had an error or timeout
          // waiting for the parent to acknowledge an update
          captureMessage(response.error);
        }
      }, error => {
        // We timed out waiting to hear back from the iframe
        logHandshakeStatus(error);
        deferred.resolve({
          iframeReady: true,
          error
        });
        captureMessage(error);
      });
    } else {
      logHandshakeStatus('No handshake performed because iframe not ready.');
      deferred.resolve({
        iframeReady: false
      });
    }
    return deferred.promise;
  };
}
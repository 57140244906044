'use es6';

const pageSettingsWarnings = shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct => {
  const warnings = {};
  if (shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct) {
    warnings.isSitePageOnPagebuilderDomainWithAdvancedAccess = {
      pane: 'settings',
      type: 'isSitePageOnPagebuilderDomainWithAdvancedAccess',
      target: 'slug'
    };
  }
  return warnings;
};
export default pageSettingsWarnings;
'use es6';

import enviro from 'enviro';
import { getAppLifecycleTimestamps } from 'ContentEditorUI/utils/lifecycleData';
export default class DevHelpers {
  constructor({
    eventBus
  }) {
    this.eventBus = eventBus;
    this.logToConsole();
  }
  setInterfameDebugFlags(value = true) {
    localStorage.INTERFRAME_DEBUG = localStorage.CEUI_DEBUG = value;
  }
  logToConsole() {
    if (!enviro.deployed('dev-help') || enviro.isQa('dev-help')) {
      console.groupCollapsed('Dev helpers...');
      console.log('Repo/Readme link: https://git.hubteam.com/HubSpot/ContentEditorUI\n\n');
      console.log('Various app, redux, and other globals are available on %cwindow.hubspot.ContentEditorUI', 'font-family: monospace;');
      console.log(window.hubspot.ContentEditorUI);
      console.log('\nInside the %ciframe %cvarious globals are available on %cwindow.hs.app\n', 'font-weight: bold;', 'font-normal: bold;', 'font-family: monospace;');
      console.log('You can enable the usage tracker debugger with: %cenviro.setDebug("USAGE_TRACKER_JS")\n', 'font-family: monospace;');
      console.log('You can enable the undo/redo debugger with: %cenviro.setDebug("DEBUG_UNDO_REDO");\n', 'font-family: monospace;');
      console.log('You can enable the inpage handshake debugger with: %cenviro.setDebug("DEBUG_INPAGE_HANDSHAKE");\n', 'font-family: monospace;');
      this.perAppLogToConsole();
      console.log('And there are more helpers on %cwindow.hubspot.ContentEditorUI.devHelpers%c...', 'font-family: monospace;', 'font-family: none;');
      console.log(this);
      console.log('');
      console.groupEnd('Dev helpers...');
    }
  }
  perAppLogToConsole() {
    // Override if desired
  }
  getAppLifecycleTimestamps() {
    return getAppLifecycleTimestamps();
  }
  getSortedAppLifecycleTimestamps() {
    const lifecycleData = getAppLifecycleTimestamps();
    return Object.keys(lifecycleData).map(lifecycle => [lifecycle, lifecycleData[lifecycle]]).sort(([__k1, timestamp1], [__k2, timestamp2]) => {
      // Sort smaller timestamps first
      return timestamp1 - timestamp2;
    });
  }

  // Sort and print out table of lifecycle timestamps
  printAppLifecycleTimestamps() {
    console.table(this.getSortedAppLifecycleTimestamps().map(([lifecycle, timestamp]) => [lifecycle, `${timestamp.toFixed(2)}ms`]));
  }
}
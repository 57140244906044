'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHasUnsavedChanges, getSaveRequestId, getIsSaveInProgress, getWasLastSaveSuccessful, getShouldSaveImmediately } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { setDirtyFlag } from 'ContentEditorUI/redux/actions/appActions';
import { getIsDraft } from 'ContentEditorUI/redux/selectors/publishSelectors';
const mapStateToProps = state => {
  return {
    hasUnsavedChanges: getHasUnsavedChanges(state),
    saveRequestId: getSaveRequestId(state),
    isSaving: getIsSaveInProgress(state),
    lastSaveSuccessful: getWasLastSaveSuccessful(state),
    shouldSaveImmediately: getShouldSaveImmediately(state),
    isDraft: getIsDraft(state)
  };
};
const mapDispatchToProps = {
  setDirtyFlag
};
class AutosaveContainer extends Component {
  constructor(props) {
    super(props);
    this._pendingDirtyState = false;
    this._pendingHardSave = false;
    this._pendingReloadAfterSave = false;
    this._currentValuesOfKeysForHardSave = {};
    this._currentValuesOfKeysToReload = {};
    this.handleSaveSuccessful = this.handleSaveSuccessful.bind(this);
    this.triggerAutoSave = this.triggerAutoSave.bind(this);
    this.shouldHardSave = this.shouldHardSave.bind(this);
    this.shouldReloadAfterSave = this.shouldReloadAfterSave.bind(this);
  }
  componentDidMount() {
    const {
      hasUnsavedChanges,
      saveData,
      canSave,
      autoSaveDebounced,
      keysForHardSave,
      keysToReload
    } = this.props;
    this._lastSavedData = saveData;
    keysForHardSave.forEach(key => {
      this._currentValuesOfKeysForHardSave[key] = saveData[key];
    });
    keysToReload.forEach(key => {
      this._currentValuesOfKeysToReload[key] = saveData[key];
    });
    if (hasUnsavedChanges && canSave) {
      autoSaveDebounced();
    }
  }
  componentDidUpdate(prevProps) {
    const {
      hasUnsavedChanges,
      saveRequestId
    } = this.props;
    this.props.validate();
    if (!this.props.canSave) {
      return;
    }
    if (!this.props.isSaving && prevProps.isSaving && this.props.lastSaveSuccessful) {
      this.handleSaveSuccessful();
    }
    if (hasUnsavedChanges && prevProps.saveRequestId !== saveRequestId) {
      this.triggerAutoSave();
    }
    const newSavedData = prevProps.saveData !== this.props.saveData;

    // Good logging info to keep track of the saving flow
    // console.table([
    //   ['has new data that should be saved', newSavedData],
    //   ['is already dirty', this.props.hasUnsavedChanges],
    //   ['is already saving', this.props.isSaving],
    // ]);

    if (newSavedData) {
      const needsToHardSave = this.shouldHardSave();
      const shouldReloadAfterSave = this.shouldReloadAfterSave();
      if (this.props.isSaving) {
        this._pendingDirtyState = true;
        this._pendingHardSave = needsToHardSave;
        this._pendingReloadAfterSave = shouldReloadAfterSave;
      } else {
        this.triggerAutoSave(needsToHardSave, shouldReloadAfterSave);
      }
    }
  }
  shouldHardSave() {
    const {
      saveData,
      keysForHardSave,
      isDraft
    } = this.props;
    let needsToHardSave = false;
    // Intentionally not short-circuiting based on isDraft here to keep the
    // current values of the hard save data up to date
    keysForHardSave.forEach(key => {
      if (saveData[key] !== this._currentValuesOfKeysForHardSave[key]) {
        needsToHardSave = true;
        this._currentValuesOfKeysForHardSave[key] = saveData[key];
      }
    });
    return needsToHardSave && isDraft;
  }
  shouldReloadAfterSave() {
    const {
      saveData,
      keysToReload
    } = this.props;
    let shouldReload = false;
    keysToReload.forEach(key => {
      if (saveData[key] !== this._currentValuesOfKeysToReload[key]) {
        shouldReload = true;
        this._currentValuesOfKeysToReload[key] = saveData[key];
      }
    });
    return shouldReload;
  }
  handleSaveSuccessful() {
    this._lastSavedData = this.props.saveData;
    // Our save was successful but since we have another dirty flag pending,
    // we'll want to trigger another save for some new data that had not been saved yet
    // Primarily just an edge case where the save takes longer than the debounced time
    // for the autosave
    if (this._pendingDirtyState) {
      this.triggerAutoSave(this._pendingHardSave, this._pendingReloadAfterSave);
      this._pendingDirtyState = false;
      this._pendingHardSave = false;
      this._pendingReloadAfterSave = false;
    }
  }
  triggerAutoSave(needsToHardSave, shouldReload) {
    const {
      autoSaveDebounced,
      shouldSaveImmediately,
      autoSave
    } = this.props;
    // Setting it to dirty, setting hard save, and setting it to from autoSaveContainer
    this.props.setDirtyFlag(true, needsToHardSave, true);
    if (shouldReload) {
      window.hubspot.ContentEditorUI.eventBus.trigger('saveAndTriggerRefresh', {
        buffer: !needsToHardSave
      });
    } else if (shouldSaveImmediately) {
      // Using redux state as a means of forcing a save to happen
      // immediately. Useful for InpageEditorUI to fire an action
      // that requires an immediate save, instead of waiting for the
      // debounce time out to fire.
      autoSave();
    } else {
      autoSaveDebounced();
    }
  }
  render() {
    return null;
  }
}
AutosaveContainer.propTypes = {
  hasUnsavedChanges: PropTypes.bool.isRequired,
  saveRequestId: PropTypes.number.isRequired,
  autoSaveDebounced: PropTypes.func.isRequired,
  autoSave: PropTypes.func.isRequired,
  shouldSaveImmediately: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  saveData: PropTypes.object.isRequired,
  lastSaveSuccessful: PropTypes.bool,
  setDirtyFlag: PropTypes.func.isRequired,
  canSave: PropTypes.bool.isRequired,
  keysForHardSave: PropTypes.array.isRequired,
  validate: PropTypes.func.isRequired,
  keysToReload: PropTypes.array.isRequired,
  isDraft: PropTypes.bool.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(AutosaveContainer);
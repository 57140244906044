import { combineReducers } from 'redux';
import { GOTOWEBINAR_FETCH_FUTURE_WEBINARS, GOTOWEBINAR_FETCH_SETTINGS, GOTOWEBINAR_FETCH_ELIGIBLE_FORMS } from 'ContentData/actions/ActionTypes';
function settings(state = {}, action) {
  switch (action.type) {
    case GOTOWEBINAR_FETCH_SETTINGS:
      {
        const {
          response
        } = action;
        if (response) return response;
        return state;
      }
    default:
      return state;
  }
}
function eligibleForms(state = new Set(), action) {
  switch (action.type) {
    case GOTOWEBINAR_FETCH_ELIGIBLE_FORMS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        /* IE11 does not support new Set(iterator) */
        return response.reduce((forms, guid) => {
          forms.add(guid);
          return forms;
        }, new Set());
      }
    default:
      return state;
  }
}
function webinars(state = {}, action) {
  switch (action.type) {
    case GOTOWEBINAR_FETCH_FUTURE_WEBINARS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.reduce((acc, webinar) => {
          acc[webinar.webinarKey] = webinar;
          return acc;
        }, {});
      }
    default:
      return state;
  }
}
export default combineReducers({
  settings,
  eligibleForms,
  webinars
});
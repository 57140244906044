export let AccountVerificationProgressStatus;
(function (AccountVerificationProgressStatus) {
  AccountVerificationProgressStatus["NEEDS_ACCOUNT_VERIFICATION"] = "NEEDS_ACCOUNT_VERIFICATION";
  AccountVerificationProgressStatus["NEEDS_USER_VERIFICATION"] = "NEEDS_USER_VERIFICATION";
  AccountVerificationProgressStatus["NEEDS_VERIFICATION"] = "NEEDS_VERIFICATION";
  AccountVerificationProgressStatus["PENDING_VERIFICATION"] = "PENDING_VERIFICATION";
  AccountVerificationProgressStatus["REJECTED_VERIFICATION"] = "REJECTED_VERIFICATION";
  AccountVerificationProgressStatus["ACCEPTED_VERIFICATION"] = "ACCEPTED_VERIFICATION";
  AccountVerificationProgressStatus["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(AccountVerificationProgressStatus || (AccountVerificationProgressStatus = {}));
export let AccountVerificationState;
(function (AccountVerificationState) {
  AccountVerificationState["PENDING"] = "PENDING";
  AccountVerificationState["ACCEPTED"] = "ACCEPTED";
  AccountVerificationState["REJECTED"] = "REJECTED";
})(AccountVerificationState || (AccountVerificationState = {}));
export let BannerType;
(function (BannerType) {
  BannerType["USER_VERIFICATION"] = "USER_VERIFICATION";
  BannerType["ACCOUNT_VERIFICATION"] = "ACCOUNT_VERIFICATION";
})(BannerType || (BannerType = {}));
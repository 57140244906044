export const TITLE_OPTIMIZATION_TYPES = {
  ADD_TITLE: 'ADD_TITLE',
  REMOVE_DOMAIN_FROM_TITLE: 'REMOVE_DOMAIN_FROM_TITLE',
  REMOVE_DUPLICATE_TITLE: 'REMOVE_DUPLICATE_TITLE',
  SHORTEN_TITLE: 'SHORTEN_TITLE'
};
export const MODULE_OPTIMIZATION_TYPES = {
  ADD_IMAGE_ALT_TEXT: 'ADD_IMAGE_ALT_TEXT'
};
export const META_DESCRIPTION_OPTIMIZATION_TYPES = {
  ADD_META_DESCRIPTION: 'ADD_META_DESCRIPTION',
  REMOVE_TITLE_FROM_META_DESCRIPTION: 'REMOVE_TITLE_FROM_META_DESCRIPTION',
  REMOVE_DUPLICATE_META_DESCRIPTION: 'REMOVE_DUPLICATE_META_DESCRIPTION',
  SHORTEN_META_DESCRIPTION: 'SHORTEN_META_DESCRIPTION'
};
'use es6';

import { buildDndAreaUniqueRowSelector } from '../../dom/dndAreaDomSelectors';
export const isFullWidthSection = styleValue => styleValue === true;
export const calculateForceFullWidthSectionCenteringClasses = (treeNode, styleValue) => {
  if (isFullWidthSection(styleValue)) {
    const domNodeSelector = buildDndAreaUniqueRowSelector(treeNode.getParent().getName(), treeNode.getName());
    const rendererName = treeNode.getRendererCompatibleRowName();
    return [{
      nodeName: treeNode.getName(),
      domNodeSelector,
      className: `${rendererName}-force-full-width-section`,
      cssSelector: `.${rendererName}-force-full-width-section > .row-fluid`,
      cssProperties: {
        'max-width': `none !important`
      }
    }];
  }
  return [];
};
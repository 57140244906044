import PortalIdParser from 'PortalIdParser';
import { stringify } from 'hub-http/helpers/params';
const buildPreviewUrl = (baseUrl, extraParams = {}) => {
  const params = Object.assign({
    portalId: PortalIdParser.get(),
    revisionPreview: true,
    _preview: true
  }, extraParams);
  return `${baseUrl}?${stringify(params)}`;
};
export const buildRevisionPreviewUrl = (previewDomain, contentId, revisionId, extraParams) => {
  return buildPreviewUrl(`//${previewDomain}/_hcms/preview/content/${contentId}/version/${revisionId}`, extraParams);
};
export const buildScheduledUpdateRevisionPreviewUrl = (previewDomain, contentId, revisionBufferId, extraParams) => {
  return buildPreviewUrl(`//${previewDomain}/_hcms/preview/content/${contentId}/buffer/${revisionBufferId}`, extraParams);
};
import produce from 'immer';
import { CLEAR_UPDATED_THEME_SETTINGS, FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_THEME_SUCCEEDED, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES, FETCH_CUSTOM_FONTS_SUCCEEDED, UPDATE_THEME_VARIANT } from 'ContentEditorUI/redux/actions/actionTypes';
import { getDataVariableKey } from 'theme-previewer-lib/utils/themeSettings';
const INITIAL_STATE = {
  hasTheme: false,
  hasThemeSettings: false
};
export const themeReducer = (state = INITIAL_STATE, action) => produce(state, draft => {
  const type = action.type;
  const payload = action.payload;
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const response = action.response;
        const {
          theme_path: themePath,
          parent_theme_path: parentThemePath,
          has_theme_settings: hasThemeSettings
        } = response;
        draft.hasTheme = !!themePath;
        draft.hasThemeSettings = !!hasThemeSettings;
        if (themePath) {
          if (!draft.theme) {
            draft.theme = {};
          }
          draft.theme.path = themePath;
        }
        if (parentThemePath) {
          if (!draft.theme) {
            draft.theme = {};
          }
          draft.theme.parentThemePath = parentThemePath;
        }
        return draft;
      }
    case FETCH_THEME_SUCCEEDED:
      {
        const response = action.response;
        const {
          theme,
          themeSettings
        } = response;

        // Selectively merging in results from the `theme/by-path` endpoint, as the shape
        // of the response is different than that of `combined-edit-data`, and because we don't
        // have a need for a majority of the data returned from this endpoint.
        const themeData = {
          name: theme.name,
          fields: theme.fields,
          messages: theme.messages
        };
        if (!draft.theme) {
          draft.theme = {};
        }
        Object.assign(draft.theme, themeData);
        draft.themeSettings = themeSettings;
        draft.overriddenThemeSettings = [];
        return draft;
      }
    case FETCH_CUSTOM_FONTS_SUCCEEDED:
      {
        const response = action.response;
        const customFonts = response;
        draft.customFonts = customFonts;
        return draft;
      }
    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
      {
        if (!(payload && payload.fieldKey)) {
          draft.overriddenThemeSettings = [];
          return draft;
        }
        if (!draft.overriddenThemeSettings) {
          draft.overriddenThemeSettings = [];
        }
        draft.overriddenThemeSettings.push(getDataVariableKey(payload.fieldKey));
        return draft;
      }
    case CLEAR_UPDATED_THEME_SETTINGS:
      {
        draft.overriddenThemeSettings = [];
        return draft;
      }
    case UPDATE_THEME_VARIANT:
      {
        if (draft.themeSettings) {
          draft.themeSettings.variantName = payload.themeVariant;
        }
        return draft;
      }
    default:
      return draft;
  }
});
export default themeReducer;
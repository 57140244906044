import { FETCH_THINGS_PEOPLE_COVER_FAILED, FETCH_THINGS_PEOPLE_COVER_STARTED, FETCH_THINGS_PEOPLE_COVER_SUCCEEDED } from '../actionTypes';
import { getThingsPeopleCover } from './client';
export const fetchThingsPeopleCover = ({
  phrase
}) => dispatch => {
  dispatch({
    type: FETCH_THINGS_PEOPLE_COVER_STARTED
  });
  return getThingsPeopleCover({
    phrase
  }).then(response => {
    dispatch({
      type: FETCH_THINGS_PEOPLE_COVER_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: FETCH_THINGS_PEOPLE_COVER_FAILED,
      payload: error
    });
  });
};
import { CollaborationSidebarStateProperties, TABS } from 'collaboration-sidebar';
import { getIsOnCommentingTab } from 'ContentEditorUI/redux/selectors/collaborationSidebarSelectors';
import { UPDATE_COLLABORATION_SIDEBAR_STATE } from './actionTypes';
const {
  IS_OPEN,
  TAB
} = CollaborationSidebarStateProperties;
export const updateCollaborationSidebarState = updates => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: updates
});
export const openCommentTab = () => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: {
    [IS_OPEN]: true,
    [TAB]: TABS.COMMENTS
  }
});
export const toggleCommentTab = () => (dispatch, getState) => {
  const state = getState();
  const isOnCommentingTab = getIsOnCommentingTab(state);
  dispatch({
    type: UPDATE_COLLABORATION_SIDEBAR_STATE,
    payload: isOnCommentingTab ? {
      [IS_OPEN]: false
    } : {
      [IS_OPEN]: true,
      [TAB]: TABS.COMMENTS
    }
  });
};
export const openApprovalsTab = () => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: {
    [IS_OPEN]: true,
    [TAB]: TABS.APPROVALS
  }
});
'use es6';

import { createSelector } from 'reselect';
import { CommentObjectType, SUPPORTED_HUBS } from 'collaboration-sidebar';
import { getIsLandingPage, getIsSitePage, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
export const mapStateToCollaborationSidebarConfig = createSelector([getIsLandingPage, getIsSitePage, getIsBlogListingPage], (isLandingPage, isSitePage, isBlogListingPage) => {
  if (isSitePage) {
    return {
      objectType: CommentObjectType.WEBSITE_PAGE,
      appHub: SUPPORTED_HUBS.CMS_HUB
    };
  } else if (isLandingPage) {
    return {
      objectType: CommentObjectType.LANDING_PAGE,
      appHub: [SUPPORTED_HUBS.CMS_HUB, SUPPORTED_HUBS.MARKETING_HUB]
    };
  } else if (isBlogListingPage) {
    return {
      objectType: CommentObjectType.BLOG_LISTING_PAGE,
      appHub: [SUPPORTED_HUBS.CMS_HUB, SUPPORTED_HUBS.MARKETING_HUB]
    };
  } else {
    return {};
  }
});
'use es6';

import http from 'hub-http/clients/apiClient';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';
const attributesEndpoint = 'users/v1/app/attributes';
const attributesBulkGetEndpoint = 'users/v1/app/attributes/bulk-get';
const parseAttributesResponseIntoObject = result => {
  return result.attributes.reduce((attrs, attr) => Object.assign({}, attrs, {
    [attr.key]: attr.value
  }), {});
};
export const getAttributes = (keys = []) => {
  return http.put(attributesBulkGetEndpoint, {
    data: {
      keys
    }
  }).then(parseAttributesResponseIntoObject);
};
export const setAttribute = (attribute, value) => {
  const body = {
    data: {
      key: attribute,
      value
    }
  };
  return http.post(attributesEndpoint, body);
};
export const fetchInitialUserAttributes = attributesToFetch => {
  const earlyRequest = getEarlyRequesterResultPromise('sharedEditorAttributes');
  const earlyRequestWithTranformationAndRetry = earlyRequest ? earlyRequest.then(parseAttributesResponseIntoObject).catch(() => getAttributes(attributesToFetch)) : null;
  return earlyRequestWithTranformationAndRetry || getAttributes(attributesToFetch);
};
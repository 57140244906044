/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import http from 'hub-http/clients/apiClient';
import { getAssetKeyFromAsset } from '../utils/multipleAssets';
import COMMENT_TYPES from '../constants/CommentTypes';
import { MODULES_WITH_REPLIES } from '../constants/CollaborationSidebarStateProperties';
const BASE_URL = 'commenting/v2/comments';
export const updateCommentMetaDescriptionOptions = (id, updatedMetaDescription) => http.patch(`${BASE_URL}/${id}`, {
  data: [{
    op: 'replace',
    path: '/metaDescription',
    value: updatedMetaDescription
  }]
});
const fetchCommentsPromises = {};
export const fetchComments = params => http.get(BASE_URL, {
  query: Object.assign({
    // The default limit on the BE is 200,
    // We need this to be a high number so we can
    // make sure we fetch all top level comments and all replies.
    // 500 is the maximum limit we can set.
    limit: 500
  }, params)
});
export const fetchCommentsCached = params => {
  const {
    objectType,
    objectId,
    refetch
  } = params;
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });
  if (!fetchCommentsPromises[key] || refetch) {
    fetchCommentsPromises[key] = fetchComments({
      objectType,
      objectId
    });
  }
  return fetchCommentsPromises[key];
};
export const updateCommentsCache = ({
  objectType,
  objectId,
  comments,
  updatedCommentId,
  modulesWithReplies,
  onUpdateCollaborationSidebarState
}) => {
  const updatedComment = comments.find(comment => comment.id === updatedCommentId);
  if (updatedComment && updatedComment.parentId && updatedComment.type === COMMENT_TYPES.DEEP_COMMENT) {
    onUpdateCollaborationSidebarState({
      [MODULES_WITH_REPLIES]: Object.assign({}, modulesWithReplies, {
        [updatedComment.parentId]: updatedComment
      })
    });
  }
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });
  if (key in fetchCommentsPromises) {
    fetchCommentsPromises[key] = Promise.resolve({
      results: comments,
      total: comments.length
    });
  }
};
export const fetchAssetLastUpdatedAt = ({
  objectType,
  objectId
}) => http.get(`commenting/v2/objects/${objectType}/${encodeURIComponent(objectId)}/last-updated-at`);
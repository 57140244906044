import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { FORMS_FETCH_FORM, FORMS_FETCH, FORMS_CREATE, FORMS_CLEAR_CACHE, FORMS_SAVE_FORM_CACHE, FORMS_SAVE_FORM_DRAFT, FORMS_UPDATE_FORM_DRAFT, FORMS_FETCH_FORM_DRAFT, FORMS_PROPERTIES_FETCH, FORMS_SUBSCRIPTIONS_FETCH, FORMS_DEFAULT_CONSENT_FETCH, FORMS_SAVE_FORM_DRAFT_REQUEST, FORMS_SAVE_FORM_DRAFT_FAIL, FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL, FORMS_FETCH_PARENT_PAGES_SUCCEEDED, FORMS_DISMISSED_PAGES_WARNING, FORMS_FETCH_WORKFLOW_DEPENDENCIES, FORMS_ADD_WORKFLOW_DEPENDENCY, FORMS_DELETE_WORKFLOW_DEPENDENCY } from 'ContentData/actions/ActionTypes';
function forms(state = {}, action) {
  switch (action.type) {
    case FORMS_FETCH_FORM:
    case FORMS_CREATE:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const newForm = Object.assign({}, state[response.guid] || {}, response);
        return Object.assign({}, state, {
          [response.guid]: newForm
        });
      }
    case FORMS_SAVE_FORM_CACHE:
      {
        const {
          form
        } = action;
        if (!form) return state;
        return Object.assign({}, state, {
          [form.guid]: form
        });
      }
    default:
      return state;
  }
}
function formsCache(state = {}, action) {
  switch (action.type) {
    case FORMS_FETCH:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.objects.reduce((hash, form) => {
          hash[form.guid] = form;
          return hash;
        }, Object.assign({}, state));
      }
    case FORMS_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }
    default:
      return state;
  }
}
function formsDrafts(state = {}, action) {
  switch (action.type) {
    case FORMS_UPDATE_FORM_DRAFT:
    case FORMS_FETCH_FORM_DRAFT:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.guid]: response
        });
      }
    case FORMS_SAVE_FORM_DRAFT:
      {
        const {
          response: {
            updatedAt,
            guid
          } = {}
        } = action;
        const oldDefinition = state[guid];
        return Object.assign({}, state, {
          [guid]: Object.assign({}, oldDefinition, {
            updatedAt
          })
        });
      }
    default:
      return state;
  }
}
function properties(state = {
  contacts: []
}, action) {
  switch (action.type) {
    case FORMS_PROPERTIES_FETCH:
      return {
        contacts: action.response
      };
    default:
      return state;
  }
}
function subscriptions(state = [], {
  type,
  response = []
} = {}) {
  switch (type) {
    case FORMS_SUBSCRIPTIONS_FETCH:
      return response;
    default:
      return state;
  }
}
function consentCopy(state = {}, {
  type,
  response = {}
} = {}) {
  switch (type) {
    case FORMS_DEFAULT_CONSENT_FETCH:
      return response.consentCopy || {};
    default:
      return state;
  }
}
function draftSaveRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case FORMS_FETCH_FORM_DRAFT:
      return RequestStatus.UNINITIALIZED;
    case FORMS_SAVE_FORM_DRAFT_REQUEST:
      return RequestStatus.PENDING;
    case FORMS_SAVE_FORM_DRAFT:
      return RequestStatus.SUCCEEDED;
    case FORMS_SAVE_FORM_DRAFT_FAIL:
      return RequestStatus.FAILED;
    case FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL:
      return RequestStatus.PRECONDITION_FAILED;
    default:
      return state;
  }
}
function formsCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case FORMS_FETCH:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;
    default:
      return state;
  }
}
function parentPages(state = {}, action) {
  switch (action.type) {
    case FORMS_FETCH_PARENT_PAGES_SUCCEEDED:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
}
function dismissedPagesWarning(state = false, action) {
  switch (action.type) {
    case FORMS_DISMISSED_PAGES_WARNING:
      return true;
    default:
      return state;
  }
}
function formWorkflowDependencies(state = {}, action) {
  switch (action.type) {
    case FORMS_FETCH_WORKFLOW_DEPENDENCIES:
      {
        const {
          formId,
          flowIds
        } = action;
        if (!flowIds) return state;
        return Object.assign({}, state, {
          [formId]: flowIds
        });
      }
    case FORMS_ADD_WORKFLOW_DEPENDENCY:
      {
        const {
          formId,
          flowId
        } = action;
        if (!formId || !flowId) return state;
        const flowIds = state[formId] || [];
        if (flowIds.includes(flowId)) return state;
        return Object.assign({}, state, {
          [formId]: [...flowIds, flowId]
        });
      }
    case FORMS_DELETE_WORKFLOW_DEPENDENCY:
      {
        const {
          formId,
          flowId,
          updatedHybrid,
          deleted
        } = action;
        if (!formId || !flowId) return state;
        if (updatedHybrid || deleted) {
          const flowIds = state[formId] || [];
          return Object.assign({}, state, {
            [formId]: flowIds.filter(_flowId => _flowId !== flowId)
          });
        }
        return state;
      }
    default:
      return state;
  }
}
function formWorkflowDependenciesDeleteRequestStatus(state = {}, action) {
  switch (action.type) {
    case FORMS_DELETE_WORKFLOW_DEPENDENCY:
      {
        const {
          formId,
          flowId,
          updatedHybrid,
          deleted,
          error
        } = action;
        if (!formId || !flowId) return state;
        let requestStatus = RequestStatus.PENDING;
        if (updatedHybrid || deleted) requestStatus = RequestStatus.SUCCEEDED;
        if (error) requestStatus = RequestStatus.FAILED;
        const formRequestStatuses = state[formId] || {};
        return Object.assign({}, state, {
          [formId]: Object.assign({}, formRequestStatuses, {
            [flowId]: requestStatus
          })
        });
      }
    default:
      return state;
  }
}
export default combineReducers({
  forms,
  requestStatus: createGenericRequestStatusReducer(FORMS_FETCH_FORM),
  draftRequestStatus: createGenericRequestStatusReducer(FORMS_FETCH_FORM_DRAFT),
  formsCacheRequestStatus,
  draftSaveRequestStatus,
  formsCache,
  formsDrafts,
  properties,
  subscriptions,
  consentCopy,
  parentPages,
  dismissedPagesWarning,
  formWorkflowDependencies,
  formWorkflowDependenciesDeleteRequestStatus
});
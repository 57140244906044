import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchContentAssistanceGeneratedContent, sendFeedbackToML } from '../api/contentAssistanceApi';
import { ERROR, FINISHED, NOT_STARTED, STARTED } from './constants';
import { useUsageTracker } from './useUsageTracker';
import { useHttpClient } from './useHttpClient';
// Used by internal "off the shelf" components
export const useFetchContentAssistanceContent = ({
  onSuccess,
  onError,
  language,
  objectId,
  basePath,
  pollingInterval,
  maxPollingAttempts
}) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(NOT_STARTED);
  const http = useHttpClient();
  const getGeneratedContent = useCallback(({
    commandId,
    parameters,
    resultCount,
    onSuccess: _onSuccess,
    onError: _onError
  }) => {
    const successHandler = _onSuccess || onSuccess;
    const errorHandler = _onError || onError;
    setRequest(STARTED);
    fetchContentAssistanceGeneratedContent({
      http,
      commandId,
      parameters,
      resultCount,
      language,
      objectId,
      basePath,
      pollingInterval,
      maxPollingAttempts
    }).then(response => {
      setResult(response);
      successHandler(response);
      setRequest(FINISHED);
    }).catch(e => {
      setRequest(ERROR);
      setError(e);
      errorHandler(e);
    });
  }, [http, language, objectId, onSuccess, onError, basePath, maxPollingAttempts, pollingInterval]);
  const reset = useCallback(() => {
    setResult([]);
    setRequest(NOT_STARTED);
    setError(null);
  }, []);
  const sendFeedback = useCallback(feedbackAnnotatedCompletions => {
    sendFeedbackToML({
      http,
      feedbackAnnotatedCompletions
    }).catch(() => {
      console.warn('Error sending feedback to ML');
    });
  }, [http]);
  return {
    request,
    error,
    result,
    reset,
    sendFeedback,
    fetch: getGeneratedContent
  };
};
const messageIsFromValidDomain = evt => {
  const {
    origin
  } = evt;
  return origin.includes('.hubspot.com') || origin.includes('.hubspotqa.com') ||
  // CMS Domains
  origin.includes('.hubspotpreviewqa-na1.com') || origin.includes('.hubspotpreviewqa-eu1.com') || origin.includes('.hubspotpreview-na1.com') || origin.includes('.hubspotpreview-eu1.com');
};
// A hook for use in a parent app that lives on the app.hubspot.com domain, it listens for post messages from other domains (CMS Editors is the primary use case, as the preview iframes are on portal specific domains)
export const useDelegatedFetchGeneratedContent = (childFrameName, usageTracker) => {
  const trackUsage = useUsageTracker({
    usageTracker,
    usageDescription: 'embeded-generation'
  });
  const http = useHttpClient();

  // Requesting completion
  useEffect(() => {
    // Listen for requests
    const listener = event => {
      if (messageIsFromValidDomain(event) && event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_REQUEST') {
        const {
          commandId,
          parameters,
          resultCount,
          language,
          objectId,
          basePath,
          maxPollingAttempts,
          pollingInterval
        } = event.data;
        fetchContentAssistanceGeneratedContent({
          http,
          commandId,
          parameters,
          resultCount,
          language,
          objectId,
          basePath,
          maxPollingAttempts,
          pollingInterval
        }).then(results => {
          window.frames[childFrameName].postMessage({
            type: 'CONTENT_ASSISTANCE_DELEGATED_RESPONSE',
            results
          }, '*');
        }).catch(error => {
          window.frames[childFrameName].postMessage({
            type: 'CONTENT_ASSISTANCE_DELEGATED_ERROR',
            error
          }, '*');
        });
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [http, childFrameName, trackUsage]);

  // Sending feedback to ML
  useEffect(() => {
    // Listen for requests
    const listener = event => {
      if (messageIsFromValidDomain(event) && event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_FEEDBACK') {
        const {
          feedbackAnnotatedCompletions
        } = event.data;
        sendFeedbackToML({
          http,
          feedbackAnnotatedCompletions
        }).catch(() => {
          console.warn('Error sending feedback to ML');
        });
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [http, childFrameName, trackUsage]);
};
const useSetupFetchGeneratedContent = () => {
  const resolveRef = useRef();
  const errorRef = useRef();
  // Send requests to parent frame
  const fetchGeneratedContent = ({
    commandId,
    parameters,
    resultCount = 1,
    language,
    objectId,
    basePath,
    pollingInterval,
    maxPollingAttempts
  }) => {
    const completionPromise = new Promise((resolve, error) => {
      resolveRef.current = resolve;
      errorRef.current = error;
    });
    // todo make this not be a wild card
    window.parent.postMessage({
      type: 'CONTENT_ASSISTANCE_DELEGATED_REQUEST',
      commandId,
      parameters,
      resultCount,
      language,
      objectId,
      basePath,
      pollingInterval,
      maxPollingAttempts
    }, '*');
    return completionPromise;
  };
  const sendCompletionFeedback = feedbackAnnotatedCompletions => {
    window.parent.postMessage({
      type: 'CONTENT_ASSISTANCE_DELEGATED_FEEDBACK',
      feedbackAnnotatedCompletions
    }, '*');
  };

  // Listen to responses
  useEffect(() => {
    const listener = event => {
      if (!messageIsFromValidDomain(event)) {
        return;
      }
      if (event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_RESPONSE') {
        if (resolveRef.current) {
          resolveRef.current(event.data.results);
        }
      }
      if (event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_ERROR') {
        if (errorRef.current) {
          errorRef.current(event.data.error);
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return {
    fetchGeneratedContent,
    sendCompletionFeedback
  };
};
export const useProxiedFetchGeneratedContent = ({
  onSuccess,
  onError,
  language,
  objectId,
  basePath,
  pollingInterval,
  maxPollingAttempts
}) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(NOT_STARTED);
  const {
    fetchGeneratedContent,
    sendCompletionFeedback
  } = useSetupFetchGeneratedContent();
  const fetchCommand = useCallback(({
    commandId,
    parameters,
    resultCount
  }) => {
    setRequest(STARTED);
    fetchGeneratedContent({
      commandId,
      parameters,
      resultCount,
      language,
      objectId,
      basePath,
      pollingInterval,
      maxPollingAttempts
    }).then(response => {
      setResult(response);
      onSuccess(response);
      setRequest(FINISHED);
    }).catch(e => {
      setRequest(ERROR);
      setError(e);
      onError(e);
    });
  }, [fetchGeneratedContent, language, objectId, onSuccess, onError, basePath, pollingInterval, maxPollingAttempts]);
  const reset = useCallback(() => {
    setResult([]);
    setRequest(NOT_STARTED);
    setError(null);
  }, []);
  const sendFeedback = feedback => {
    sendCompletionFeedback(feedback);
  };
  return {
    request,
    error,
    result,
    reset,
    sendFeedback,
    fetch: fetchCommand
  };
};
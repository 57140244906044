export const OPERATORS = Object.freeze({
  EQUAL: 'EQUAL',
  EMPTY: 'EMPTY',
  NOT_EMPTY: 'NOT_EMPTY',
  NOT_EQUAL: 'NOT_EQUAL',
  MATCHES_REGEX: 'MATCHES_REGEX'
});
export const ACCESS_OPERATORS = Object.freeze({
  HAS_ALL: 'HAS_ALL',
  HAS_ANY: 'HAS_ANY',
  HAS_NONE: 'HAS_NONE'
});
export const VISIBILITY_RULES = Object.freeze({
  SIMPLE: 'SIMPLE',
  ADVANCED: 'ADVANCED'
});
export const BOOLEAN_OPERATORS = Object.freeze({
  AND: 'AND',
  OR: 'OR'
});
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET ctas/v3/placements/{guid}
 */
export function fetchCta(guid, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`ctas/v3/placements/${guid}`, {
    query: {
      includeCtaInfo: true,
      includeMigrated: true
    }
  });
}
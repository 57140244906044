/**
 * It will be the responsiblity of each app to implement it's own reducer for `contentTypeLimit`.
 * The contract will be that the reducer returns a state which is a plain JS
 * object that has the `hasExceededContentTypeLimit` value on it.
 */

import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getContentTypeLimit = basicSelector(state => state.contentTypeLimit);
const getHasContentTypeLimitReducer = state => !!state.contentTypeLimit;
export const getHasContentTypeLimitReducerAndExceededLimit = createSelector([getHasContentTypeLimitReducer, getContentTypeLimit], (hasReducer, limit) => Boolean(hasReducer && limit.hasExceededContentTypeLimit));
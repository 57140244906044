import { SELECT_BREAKPOINT, TOGGLE_HIDDEN_ELEMENT_VISIBILITY } from 'ContentEditorUI/redux/actions/actionTypes';
export const selectBreakpoint = (breakpoint, isPatagonia) => ({
  payload: {
    selectedBreakpoint: breakpoint.name,
    previewWidth: breakpoint.previewWidth,
    isPatagonia
  },
  type: SELECT_BREAKPOINT
});
export const toggleHiddenElementVisibility = payload => ({
  type: TOGGLE_HIDDEN_ELEMENT_VISIBILITY,
  payload
});
'use es6';

import { Component } from 'react';
import { connect } from 'react-redux';
import { getTestFlagValuesObj } from 'ContentEditorUI/lib/testFlags';
import { updateTestFlags as updateTestFlagsAction } from 'ContentEditorUI/redux/actions/testFlagActions';
const mapDispatchToProps = {
  updateTestFlags: updateTestFlagsAction
};
class SyncHashTestFlagsToReduxContainer extends Component {
  constructor(...args) {
    super(...args);
    this.syncTestFlags = () => {
      const {
        updateTestFlags
      } = this.props;
      const testFlagValuesObj = getTestFlagValuesObj();
      updateTestFlags(testFlagValuesObj);
    };
  }
  componentDidMount() {
    this.syncTestFlags();
    window.addEventListener('hashchange', this.syncTestFlags, false);
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.syncTestFlags, false);
  }
  render() {
    return null;
  }
}
export default connect(undefined, mapDispatchToProps)(SyncHashTestFlagsToReduxContainer);
hns('I18n.translations.zh-tw', {
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "percentage": {
      "format": {
        "delimiter": ",",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": "byte",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "array": {
        "inclusive": {
          "wordsConnector": "、",
          "twoWordsConnector": "和",
          "lastWordConnector": "和"
        },
        "exclusive": {
          "wordsConnector": "、",
          "twoWordsConnector": "或",
          "lastWordConnector": "或"
        }
      }
    }
  }
});


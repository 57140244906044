import Url from 'urlinator/Url';
import { TypeToExtensions, FileSource, FileTypes, PATH_TO_VIDEO_HOSTING_INFO, VideoProviders, FILE_DETAILS_QUERY_PARAM_NAME } from '../Constants';
import { isNonDefaultHublet, getCdnHostnameForNonDefaultHublet } from './cdnHostnames';
import { isCanvaGenerated, isShutterstock } from './image';
import { getPrompt } from './imageGeneration';
import Immutable from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { getVideoOrImageThumbnailUrl, getPrivateThumbnailRedirectUrl } from './thumbnailUrls';
import { resize, getIsFileTooLargeForResizing } from './resize';
import { getIsFilePrivate, getIsFileExternal, getIsFileSensitive } from './fileAccessibility';
import { BYTES_IN_MEGABYTE } from '../constants/fileSizes';
import ThumbnailSizes from '../enums/ThumbnailSizes';
import { stringifyQueryObject } from './urls';
const HUBFS_CDN_URL_REGEX = /\/hubfs\/([0-9]+)\/(.*)/;
const HUBFS_DOMAIN_URL_REGEX = /\/hubfs\/(.*)/;
const LEGACY_CDN_URL_REGEX = /\/hub\/([0-9]+)\/(.*)/;
const LEGACY_DOMAIN_URL_REGEX = /\/hs-fs\/(.*)/;
const PORTAL_ID_SHARDED_CDN_REGEX = /([0-9]{1,})\.fs[1-9]{1,}\.hubspotusercontent(?:qa)?-(?:[A-z0-9]+)(?:-sl[0-9{1,}])?.net/;
export const FREE_DOMAIN_REGEX = /^.+\.hubspotpagebuilder(qa)?(-[A-z0-9]+)?\..+/;
const isOldCdn = hostname => hostname.startsWith('f.hubspotusercontent') || hostname.startsWith('fs.hubspotusercontent');
export function isCdn(hostname, __auth) {
  if (isNonDefaultHublet() && hostname === getCdnHostnameForNonDefaultHublet()) {
    return true;
  }
  return Boolean(hostname.match(PORTAL_ID_SHARDED_CDN_REGEX)) || Boolean(hostname.match(FREE_DOMAIN_REGEX)) || isOldCdn(hostname) || ['cdn2.hubspotqa.com', 'cdn2.hubspotqa.net', 'cdn2.hubspot.com', 'cdn2.hubspot.net'].includes(hostname);
}
export function splitNameAndExtension(file) {
  const name = file.name;
  // eslint-disable-next-line no-useless-escape
  const matches = name.match(/(.*)\.([^\.]+)$/);
  if (!matches) {
    return {
      extension: '',
      name
    };
  }
  return {
    name: matches[1],
    extension: matches[2].toLowerCase()
  };
}
export function getType(extensionOrType) {
  if (Object.keys(FileTypes).includes(extensionOrType)) {
    return extensionOrType;
  }
  const type = TypeToExtensions.findKey(extensions => extensions.includes(extensionOrType));
  if (type) {
    return type;
  }
  return FileTypes.OTHER;
}
export function isImage(file) {
  return file.type && file.type.indexOf('image/') === 0;
}
export function canPreviewLocalFile(localFile) {
  const {
    extension
  } = splitNameAndExtension(localFile);
  return isImage(localFile) && extension !== 'svg';
}
export function getIsVideoSelectedForUpload(file) {
  return file.type && file.type.indexOf('video/') === 0;
}
export function getIsDocumentSelectedForUpload(file) {
  const {
    extension
  } = splitNameAndExtension(file);
  return file.type && TypeToExtensions.get(FileTypes.DOCUMENT).includes(extension);
}
export function convertUrlToDomain(file, domain, auth) {
  const url = file.get('url');
  const parsed = new Url(url);
  const nextHostname = domain.get('domain');
  const pathname = parsed.pathname;
  if (!domain) {
    return file.get('url');
  }
  if (parsed.hostname === nextHostname) {
    return file.get('url');
  }
  const updates = {
    protocol: domain.get('isSslEnabled') ? 'https' : 'http',
    hostname: nextHostname
  };
  if (isCdn(parsed.hostname, auth) && !isCdn(nextHostname, auth)) {
    const matches = pathname.match(HUBFS_CDN_URL_REGEX);
    if (matches && matches.length) {
      updates.pathname = `/hubfs/${matches[2]}`;
    }
  } else if (!isCdn(parsed.hostname, auth) && isCdn(nextHostname, auth)) {
    const matches = pathname.match(HUBFS_DOMAIN_URL_REGEX);
    if (matches && matches.length) {
      updates.pathname = `/hubfs/${file.get('portal_id')}/${matches[1]}`;
    }
  }
  parsed.update(updates);
  return parsed.href;
}
function findAndReplaceHostingInfo(file, hostingInfoReplacement) {
  if (file.getIn(PATH_TO_VIDEO_HOSTING_INFO)) {
    return file.getIn(PATH_TO_VIDEO_HOSTING_INFO).map(hostingInfo => {
      if (hostingInfo.get('provider') === hostingInfoReplacement.get('provider')) {
        return hostingInfoReplacement;
      }
      return hostingInfo;
    });
  }
  return Immutable.List([hostingInfoReplacement]);
}
export function getUpdatedPlayerIdVideoFile(file, newHostingInfo) {
  return file.setIn(PATH_TO_VIDEO_HOSTING_INFO, findAndReplaceHostingInfo(file, newHostingInfo));
}
export function softDeleteVideoPlayerIdFromFile(file, oldPlayerId) {
  return file.setIn(PATH_TO_VIDEO_HOSTING_INFO, findAndReplaceHostingInfo(file, Immutable.Map({
    provider: VideoProviders.HUBSPOT_VIDEO_2,
    status: 'DISABLED',
    id: oldPlayerId
  })));
}
export function getFileType(file) {
  if (file.get('type')) {
    return file.get('type');
  }
  return getType(file.get('extension'));
}
export function getIsImage(file) {
  return getFileType(file) === FileTypes.IMG;
}
export function getIsVideo(file) {
  return getFileType(file) === FileTypes.MOVIE;
}
export function getIsSvg(file) {
  return getIsImage(file) && file.get('encoding') === 'svg';
}
export const getIsTemporaryUploadingFile = file => {
  return file.get('id') <= 0;
};
export const getIsFileBeingReplaced = file => {
  return file.has('progress') && !getIsTemporaryUploadingFile(file);
};
export const getIsSvgTooLarge = file => {
  return file.get('size') >= BYTES_IN_MEGABYTE;
};
export function getHasThumbnail(file) {
  if (getIsSvg(file) && (getIsSvgTooLarge(file) || getIsTemporaryUploadingFile(file))) {
    return false;
  }
  if (getIsFileExternal(file)) {
    return false;
  }
  if (getIsFileSensitive(file)) {
    return false;
  }
  const type = getFileType(file);
  return type === FileTypes.IMG || type === FileTypes.MOVIE;
}
export function getImageSrc(file, thumbSize, options = {}) {
  if (file.has('tempUrl')) {
    return file.get('tempUrl');
  }
  if (file.has('progress')) {
    return file.get('url');
  }
  if (file.get('encoding') === 'svg' && !getIsFilePrivate(file)) {
    return file.get('friendly_url');
  }
  return getVideoOrImageThumbnailUrl(file, thumbSize, options);
}
export function getResizedFilePreviewUrl(file, imageWidth, auth) {
  let url = file.get('default_hosting_url');
  if (getIsVideo(file)) {
    return getImageSrc(file, ThumbnailSizes.MEDIUM, {
      noCache: true
    });
  }
  if (getIsFilePrivate(file)) {
    return getPrivateThumbnailRedirectUrl(file, ThumbnailSizes.MEDIUM, {
      noCache: true
    });
  }

  // temp fix to load large image thumbs (JIRA: https://issues.hubspotcentral.com/browse/CG-16539)
  if (getIsFileTooLargeForResizing(file)) {
    return getImageSrc(file, ThumbnailSizes.MEDIUM, {
      noCache: true
    });
  }
  if (file.has('tempUrl')) {
    return file.get('tempUrl');
  }
  if (file.has('progress')) {
    return url;
  }
  url = `${url || file.get('friendly_url')}?t=${file.get('updated')}`;
  if (file.get('replaced')) {
    return url;
  }
  return resize(url, {
    // 2x width for retina
    width: imageWidth * 2
  }, undefined, auth);
}
export function getResizedFileThumbnailUrl(file, imageWidth, auth) {
  let url = file.get('default_hosting_url');
  if (getIsFilePrivate(file)) {
    return getPrivateThumbnailRedirectUrl(file, ThumbnailSizes.MEDIUM);
  }
  if (getIsVideo(file)) {
    return getImageSrc(file, ThumbnailSizes.MEDIUM, {
      noCache: true
    });
  }

  // temp fix to load large image thumbs (JIRA: https://issues.hubspotcentral.com/browse/CG-16539)
  if (getIsFileTooLargeForResizing(file)) {
    return getImageSrc(file, ThumbnailSizes.MEDIUM);
  }
  if (file.has('tempUrl')) {
    return file.get('tempUrl');
  }
  if (file.has('progress')) {
    return url;
  }
  if (file.get('encoding') === 'svg') {
    return file.get('default_hosting_url');
  }
  if (url && !url.includes('?')) {
    url = `${url}?t=${file.get('updated')}`;
  }
  if (file.get('replaced')) {
    return url;
  }
  return resize(url, {
    width: imageWidth
  }, undefined, auth);
}
export function isAnimation(file) {
  return Boolean(file.getIn(['meta', 'animated']));
}
export function getIsFileManagerUrl(url) {
  const parsed = new Url(url);
  const pathname = parsed.pathname;
  let matches = pathname.match(HUBFS_CDN_URL_REGEX);
  if (matches && matches.length) {
    return true;
  }
  matches = pathname.match(HUBFS_DOMAIN_URL_REGEX);
  if (matches && matches.length) {
    return true;
  }
  matches = pathname.match(LEGACY_CDN_URL_REGEX);
  if (matches && matches.length) {
    return true;
  }
  matches = pathname.match(LEGACY_DOMAIN_URL_REGEX);
  if (matches && matches.length) {
    return true;
  }
  return false;
}
export const getFileDetailsFileManagerAppLink = (file, queryParams = {}) => {
  queryParams[FILE_DETAILS_QUERY_PARAM_NAME] = file.get('id');
  return `/files/${PortalIdParser.get()}${stringifyQueryObject(queryParams)}`;
};
export function getShouldRenderFileUrl({
  file
}) {
  switch (getFileType(file)) {
    case FileTypes.IMG:
      return !isShutterstock(file) && file.get('url') && !file.get('temp');
    default:
      return true;
  }
}
export const getFileName = file => file.get('name');
export const getFileMetaTitle = file => file.getIn(['meta', 'title']);
export const getFileMetaDescription = file => file.getIn(['meta', 'description']);
export const getFileMetaTagsList = file => file.getIn(['meta', 'tags']) || Immutable.List();
export const getFileSource = file => {
  if (getPrompt(file)) {
    return FileSource.AI_GENERATED;
  } else if (isCanvaGenerated(file)) {
    return FileSource.CANVA;
  } else if (isShutterstock(file)) {
    return FileSource.SHUTTERSTOCK;
  }
  return null;
};
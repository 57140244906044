import { find, isEqual, isEmpty } from 'underscore';
import { na1 } from 'hubspot-url-utils/hublets';
import enviro from 'enviro';
import memoizeOne from 'react-utils/memoizeOne';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { CONTENT, DATA, STYLE, VISIBILITYGROUP } from 'ContentUtils/constants/CustomWidgetFieldGroupTabTypes';
import { userInfoSync } from 'hub-http/userInfo';
import getLang from 'I18n/utils/getLang';
import { getTranslatedFields, getIsBlogContentModule, getTranslatedBlogContentFields, getTranslation } from 'ContentUtils/helpers/TranslationsHelpers';
import { getContentSchemaFields } from 'ContentUtils/helpers/GraphqlHelpers';
import { getAliasedDefaultValue, getInheritedDefaultValue } from './utils/inheritance';
import { isVisible } from './utils/visibility';
import { getDeepValue, isNullOrUndefined, setDeepValue } from 'ContentUtils/helpers/ObjectHelpers';
import { getOccurrenceFieldKey } from './utils/occurrence';
const breakpointHasFieldsMap = {
  all: false
};
export const getBreakpointHasFields = breakpointName => {
  return breakpointHasFieldsMap[breakpointName];
};

// Finds field and returns array of ancestor fields leading to it
// i.e. If field is contained in group, returns [groupField, field]
export function getFieldPath(fields, fieldId) {
  const field = find(fields, _field => {
    return _field.id === fieldId;
  });
  if (field) return [field];
  for (const _field of fields) {
    if (_field.children) {
      const _path = getFieldPath(_field.children, fieldId);
      if (_path.length) {
        return [_field, ..._path];
      }
    }
  }
  return [];
}
export function getFieldFromKey(fields, key) {
  const keyWithoutIndices = key && key.filter(_keyName => typeof _keyName === 'string');
  if (!keyWithoutIndices || !keyWithoutIndices.length) {
    return null;
  }
  let field = null;
  keyWithoutIndices.forEach(_keyName => {
    if (field && field.children) {
      field = find(field.children, _field => _field.name === _keyName);
    } else if (!field) {
      field = find(fields, _field => _field.name === _keyName);
    }
  });
  return field;
}

// Get parent of field corresponding to key
export function getBackLinkKey(key) {
  if (!key) {
    return null;
  }
  let backLinkKey = null;
  const lastKeyItem = key[key.length - 1];
  if (typeof lastKeyItem === 'number') {
    backLinkKey = key.slice(0, key.length - 2);
  } else {
    backLinkKey = key.slice(0, key.length - 1);
  }
  return backLinkKey && backLinkKey.length ? backLinkKey : null;
}
export function isValueEmpty(field, value) {
  if (field.type === FieldTypes.COLOR && value === '#') {
    return true;
  }
  return isNullOrUndefined(value) || value === '';
}
const HIDE_FIELD_LABEL_TYPES = Object.freeze({
  [FieldTypes.BOOLEAN]: true,
  [FieldTypes.IMAGE]: true,
  [FieldTypes.CTA]: true,
  [FieldTypes.ICON]: true,
  [FieldTypes.SFDC_CAMPAIGN]: true,
  [FieldTypes.VIDEO]: true,
  [FieldTypes.FILE]: true,
  [FieldTypes.BACKGROUND_IMAGE]: true
});
export function hideFieldLabel(field, showFollowupEmail) {
  if (showFollowupEmail && field.type === FieldTypes.FOLLOW_UP_EMAIL) return true;
  return !!HIDE_FIELD_LABEL_TYPES[field.type];
}
export function setFieldIds(fields, prefix) {
  return fields.map(_field => {
    const newField = Object.assign({}, _field);
    if (!newField.id) {
      newField.id = prefix ? `${prefix}.${newField.name}` : newField.name;
    }
    if (newField.children) {
      newField.children = setFieldIds(newField.children, newField.id);
    }
    return newField;
  });
}
export function getKeyfromPathString(pathString = '') {
  const valueKey = [];
  pathString.split('.').forEach(key => {
    const index = key.match(/\[\d+\]/);
    if (index) {
      valueKey.push(key.replace(/\[\d+\]/, ''));
      const pathIndex = parseInt(index[0].replace(/\[|\]/, ''), 10);
      if (typeof pathIndex === 'number') {
        valueKey.push(pathIndex);
      }
    } else {
      valueKey.push(key);
    }
  });
  // Since inheritance can be from brandSettings or module
  return valueKey;
}
export function getFieldDefault({
  allFields,
  breakpoint,
  field,
  inheritanceData,
  moduleData,
  modulePath
}) {
  const {
    breakpoint_defaults: breakpointDefaults,
    inherited_value: inheritedValueFields,
    aliases_mapping: aliasesMapping
  } = field;
  let defaultValue = field.default;
  let inheritanceSource = {};
  if (breakpoint) {
    // Return null if no breakpoint default exist
    // context: https://git.hubteam.com/HubSpot/Content-Docs/pull/695/files#r2529619
    defaultValue = null;
    if (breakpointDefaults && breakpointDefaults[breakpoint]) {
      defaultValue = breakpointDefaults[breakpoint];
    }
  }

  // Inherited values
  if (inheritedValueFields) {
    const {
      breakpoint_styles: inheritedBreakpointStyles,
      default_value_path: baseDefaultValuePath,
      property_value_paths: basePropertyValuePaths
    } = inheritedValueFields;
    let defaultValuePath = baseDefaultValuePath;
    let propertyValuePaths = basePropertyValuePaths;
    if (breakpoint) {
      // If there's no breakpoint inheritance set up on a module return default
      if (!inheritedBreakpointStyles || !inheritedBreakpointStyles[breakpoint]) {
        return {
          defaultValue
        };
      }
      const {
        default_value_path: breakpointDefaultValuePath,
        property_value_paths: breakpointPropertyValuePaths
      } = inheritedBreakpointStyles[breakpoint];

      // if there's no inheritance set up for a specific breakpoint return default
      if (!breakpointDefaultValuePath && !breakpointPropertyValuePaths) {
        return {
          defaultValue
        };
      }

      // Otherwise use the breakpoint inheritance data to set up default values
      defaultValuePath = breakpointDefaultValuePath;
      propertyValuePaths = breakpointPropertyValuePaths;
    }

    // Set entire default value from inherited field
    if (defaultValuePath) {
      const {
        inheritanceType,
        inheritanceFieldKey,
        inheritanceFieldLabel,
        value: inheritedValue
      } = getInheritedDefaultValue({
        allFields,
        defaultValuePath,
        field,
        inheritanceData,
        moduleData,
        modulePath
      });
      if (!isNullOrUndefined(inheritedValue)) {
        defaultValue = inheritedValue;
        inheritanceSource = {
          inheritanceType,
          inheritanceFieldKey,
          inheritanceFieldLabel
        };
      }
    }

    // For object-type fields - set individual properties
    if (propertyValuePaths && typeof propertyValuePaths === 'object') {
      defaultValue = defaultValue && typeof defaultValue === 'object' ? defaultValue : {};
      Object.keys(propertyValuePaths).forEach(key => {
        const path = propertyValuePaths[key];
        const {
          inheritanceType,
          inheritanceFieldKey,
          inheritanceFieldLabel,
          value: inheritedValue
        } = getInheritedDefaultValue({
          allFields,
          defaultValuePath: path,
          field,
          inheritanceData,
          moduleData,
          modulePath
        });
        if (!isNullOrUndefined(inheritedValue)) {
          const keyPath = getKeyfromPathString(key);
          defaultValue = setDeepValue(defaultValue, keyPath, inheritedValue);
          inheritanceSource = {
            inheritanceType,
            inheritanceFieldKey,
            inheritanceFieldLabel
          };
        }
      });
    }
  }
  if (aliasesMapping) {
    defaultValue = getAliasedDefaultValue({
      defaultValue,
      field,
      moduleData,
      modulePath
    });
  }
  return {
    defaultValue,
    inheritanceSource
  };
}
function _setFieldDefaults({
  allFields,
  breakpoint,
  fieldsToSet,
  inheritanceData,
  moduleData,
  modulePath
}) {
  return fieldsToSet.map(_field => {
    const newField = Object.assign({}, _field);
    const {
      defaultValue,
      inheritanceSource
    } = getFieldDefault({
      allFields,
      breakpoint,
      field: _field,
      inheritanceData,
      moduleData,
      modulePath
    });
    if (defaultValue !== undefined) {
      const {
        inheritanceType,
        inheritanceFieldKey,
        inheritanceFieldLabel
      } = inheritanceSource || {};
      newField.default = defaultValue;
      if (inheritanceType) {
        newField.inherited_value = Object.assign({}, newField.inherited_value, {
          inheritanceType,
          inheritanceFieldKey,
          inheritanceFieldLabel
        });
      }
    }
    if (newField.children) {
      newField.children = _setFieldDefaults({
        allFields,
        breakpoint,
        fieldsToSet: newField.children,
        inheritanceData,
        moduleData,
        modulePath
      });
    }
    return newField;
  });
}
export const setFieldValuesAsDefaults = memoizeOne(_setFieldValuesAsDefaults, isEqual);
const FIELD_PROPERTIES_ORDER = ['label', 'name', 'id', 'type', 'inline_help_text', 'help_text', 'visibility', 'children'
// ... any other properties
// inherited_value
// default
];
function sortFieldProperties(field) {
  let sortedField = {};

  // Sorts the properties that exist in FIELD_PROPERTIES_ORDER in the correct order
  FIELD_PROPERTIES_ORDER.forEach(property => {
    if (Object.prototype.hasOwnProperty.call(field, property)) sortedField[property] = field[property];
  });

  // Puts the rest of the field's properties underneath the ones that are sorted
  // using FIELD_PROPERTIES_ORDER
  sortedField = Object.assign(sortedField, field);

  // Puts 'inheritance' and 'default' properties below all other properties
  if (Object.prototype.hasOwnProperty.call(sortedField, 'inherited_value')) {
    const inheritanceValue = sortedField.inherited_value;
    delete sortedField.inherited_value;
    sortedField.inherited_value = inheritanceValue;
  }
  if (Object.prototype.hasOwnProperty.call(sortedField, 'default')) {
    const defaultValue = sortedField.default;
    delete sortedField.default;
    sortedField.default = defaultValue;
  }
  return sortedField;
}
export function _setFieldValuesAsDefaults(fieldsToSet, moduleData, currFieldKey = []) {
  return fieldsToSet.map(_field => {
    const newField = Object.assign({}, _field);
    const _fieldKey = [...currFieldKey, _field.name];
    const fieldvalue = getDeepValue(moduleData, _fieldKey);
    if (fieldvalue !== undefined) newField.default = fieldvalue;
    if (newField.children) {
      newField.children = _setFieldValuesAsDefaults(newField.children, moduleData, _fieldKey);
    }
    return sortFieldProperties(newField);
  });
}
export const setFieldDefaults = memoizeOne(_setFieldDefaults, isEqual);

/*
Returns false when a field should be hidden entirely because the user lacks
proper access (rather than showing an alert explaining that they lack access)
*/
export const hasAccessToField = field => {
  switch (field.type) {
    case FieldTypes.PAYMENT:
      return enviro.getHublet() === na1;
    default:
      return true;
  }
};
export function getFieldGroupForTab(fields, tab) {
  return fields.find(_field => _field.type === FieldTypes.GROUP && _field.tab === tab);
}
export function getFilteredFields(fields, props, scopes, visibilityOptions = {}) {
  const {
    breakpoint,
    module,
    hideLockedFields,
    isFieldVisible
  } = props;
  const hydratedFields = hydrateFields(props);
  return fields.reduce((_filteredFields, _field) => {
    const {
      children,
      tab,
      locked,
      responsive_enabled = false,
      type
    } = _field;
    const scopesChecked = scopes || userInfoSync().user.scopes;
    const inContentTab = !tab || tab === CONTENT;
    const inStylesTab = tab && tab === STYLE;
    const isStylesGroup = inStylesTab && !visibilityOptions.isExternalTabGroup;
    if (responsive_enabled && !breakpointHasFieldsMap.all) {
      breakpointHasFieldsMap.all = true;
    }

    // If isFieldVisible exists, it should override the value
    // that is set by the locked field property. Eventually the idea
    // would be for isFieldVisible to replace the locked functionality.
    let shouldShowField = !(hideLockedFields && locked);
    if (shouldShowField && breakpoint && type !== FieldTypes.GROUP) {
      shouldShowField = responsive_enabled;
    }
    if (isFieldVisible) shouldShowField = isFieldVisible(_field);
    if ((inContentTab || inStylesTab) && !isStylesGroup && shouldShowField && !_field.editorAdded && isVisible(_field, hydratedFields, module, scopesChecked, visibilityOptions) && hasAccessToField(_field) && !(children && !getFilteredFields(children, props, scopesChecked, visibilityOptions).length)) {
      _filteredFields.push(_field);
    }
    return _filteredFields;
  }, []);
}
export function getDataFields(module, dataQueryVariableSchema) {
  if (!isEmpty(dataQueryVariableSchema)) {
    return getContentSchemaFields(dataQueryVariableSchema, module.dataQueryVariables);
  }
  return [];
}
export function doesTabHaveFields(tab, props, scopes, visibilityOptions = {}) {
  const fields = hydrateFields(props);
  let fieldsToCheck = [];
  if (tab && tab !== CONTENT) {
    const tabGroup = getFieldGroupForTab(fields, tab);
    if (tabGroup) {
      fieldsToCheck = tabGroup.children;
      visibilityOptions.isExternalTabGroup = true;
    }
  } else {
    fieldsToCheck = fields;
  }
  const scopesChecked = scopes || userInfoSync().user.scopes;
  const filteredFields = getFilteredFields(fieldsToCheck, props, scopesChecked, visibilityOptions);
  return filteredFields.length > 0;
}
export function hydrateFields(props) {
  const {
    breakpoint,
    inheritanceData,
    module,
    moduleSpec,
    tab,
    dataQueryVariableSchema
  } = props;
  const {
    messages = {},
    masterLanguage,
    moduleId,
    path: modulePath = ''
  } = moduleSpec;
  const inDataTab = tab && tab === DATA;
  if (inDataTab && !isEmpty(dataQueryVariableSchema)) {
    const {
      dataQueryVariables = {}
    } = module;
    return getContentSchemaFields(dataQueryVariableSchema, dataQueryVariables);
  }
  const currentUserLang = getLang();
  let fields = setFieldIds(moduleSpec.fields || []);
  fields = setFieldDefaults({
    allFields: fields,
    breakpoint,
    fieldsToSet: fields,
    inheritanceData,
    moduleData: module,
    modulePath
  });
  const {
    fieldLanguage = currentUserLang,
    contentLanguage = currentUserLang
  } = props;
  if (fieldLanguage === masterLanguage && contentLanguage === masterLanguage) return fields;
  const translationsArgs = [fields, /* Ensure master language messages is not used */
  fieldLanguage === masterLanguage ? null : getTranslation(messages, fieldLanguage), contentLanguage === masterLanguage ? null : getTranslation(messages, contentLanguage)];
  if (getIsBlogContentModule(moduleId)) {
    return getTranslatedBlogContentFields(...translationsArgs);
  }
  return getTranslatedFields(...translationsArgs);
}
export function getAvailableModuleTabs(props, scopes) {
  const {
    moduleSpec: {
      fields = []
    }
  } = props;
  const moduleTabs = {
    [CONTENT]: false
  };
  const moduleTabs_checked = {};
  fields.forEach(_field => {
    const _tab = _field.tab || CONTENT;
    //indicates that the editor added visibility field to style tab
    if (_field.internalId === VISIBILITYGROUP) {
      moduleTabs[STYLE] = true;
      moduleTabs_checked[STYLE] = true;
    } else {
      if (!moduleTabs_checked[_tab]) {
        moduleTabs[_tab] = doesTabHaveFields(_tab, props, scopes);
        moduleTabs_checked[_tab] = true;
      }
    }
  });
  return moduleTabs;
}

// Taken from UIComponents Props.js utils
/**
 * @param {Object} props
 * @return {Object} The subset of the given props whose names start with `aria-` or `data-`
 */
export const getAriaAndDataProps = props => {
  const propKeyRegex = /^((aria-)|(data-))/;
  return Object.keys(props).reduce((acc, key) => {
    if (!key) return acc;
    if (key.match(propKeyRegex)) acc[key] = props[key];
    return acc;
  }, {});
};
export const getModuleSlideInState = (prevFieldKey, currFieldKey) => {
  let shouldSlideInFromRight = false;
  let shouldSlideInFromLeft = false;
  if (!prevFieldKey || !currFieldKey) {
    // If a field key is null it means you are either going to or from the highest level
    shouldSlideInFromRight = !!(!prevFieldKey && currFieldKey);
    shouldSlideInFromLeft = !!(prevFieldKey && !currFieldKey);
  } else if (prevFieldKey.length === currFieldKey.length) {
    // Check to see if previous or next occurrence based on occurrence index
    const currOccurrence = getOccurrenceFieldKey(currFieldKey).pop();
    const prevOccurrence = getOccurrenceFieldKey(prevFieldKey).pop();
    shouldSlideInFromRight = currOccurrence > prevOccurrence;
    shouldSlideInFromLeft = currOccurrence < prevOccurrence;
  } else if (currFieldKey.length !== prevFieldKey.length) {
    // Check to see if you've gone up or down a level based on field key length
    shouldSlideInFromRight = currFieldKey.length > prevFieldKey.length;
    shouldSlideInFromLeft = currFieldKey.length < prevFieldKey.length;
  }
  return {
    shouldSlideInFromLeft,
    shouldSlideInFromRight
  };
};
function containsFormField(fields = []) {
  return fields.some(field => field.type === FieldTypes.FORM || field.children && containsFormField(field.children));
}
function getFollowupEmailFieldKey(fields = []) {
  for (const field of fields) {
    if (field.type === FieldTypes.FOLLOW_UP_EMAIL) return [field.name];
    if (field.children) {
      const keyFromChildren = getFollowupEmailFieldKey(field.children);
      if (keyFromChildren.length) return [field.name, ...keyFromChildren];
    }
  }
  return [];
}
export const setFieldSpecificProps = (field, {
  childTheme,
  contentId,
  contentCategoryName,
  module = {},
  moduleSpec = {},
  onFieldChange,
  pageContext,
  scrollToField
}) => {
  const {
    path
  } = moduleSpec;
  switch (field.type) {
    case FieldTypes.URL:
    case FieldTypes.LINK:
      {
        return Object.assign({}, field, {
          pageContext
        });
      }
    case FieldTypes.FONT:
      return Object.assign({}, field, {
        childTheme,
        isThemeAsset: moduleSpec.isThemeAsset
      });
    case FieldTypes.LOGO:
    case FieldTypes.COLOR:
      {
        return Object.assign({}, field, {
          contentId,
          contentCategoryName
        });
      }
    case FieldTypes.FOLLOW_UP_EMAIL:
      {
        const formId = module.form && module.form.form_id;
        const fieldProps = Object.assign({}, field, {
          formId,
          followupEmailEnabled: module.follow_up_type_simple,
          moduleContainsFormField: containsFormField(moduleSpec.fields)
        });
        if (path === '@hubspot/form' && contentId && formId) {
          fieldProps.revealFormField = () => scrollToField({
            fieldKey: ['form'],
            section: 'form-followup-email',
            dispatchEvent: true
          });
        }
        return fieldProps;
      }
    case FieldTypes.FORM:
      {
        const fieldProps = Object.assign({}, field, {
          contentId
        });
        if (path === '@hubspot/form') {
          return Object.assign({}, fieldProps, {
            clearLegacyFollowupEmail: callback => {
              onFieldChange(['follow_up_type_simple'], undefined, true);
              setTimeout(() => {
                onFieldChange(['simple_email_for_live_id'], undefined);
                if (callback) {
                  setTimeout(callback, 100);
                }
              }, 100);
            },
            legacyFollowupEmailId: module.follow_up_type_simple ? module.simple_email_for_live_id : null
          });
        } else {
          const followupEmailFieldKey = getFollowupEmailFieldKey(moduleSpec.fields);
          if (!followupEmailFieldKey.length) return Object.assign({}, fieldProps);
          const legacyFollowupEmailId = getDeepValue(module, followupEmailFieldKey);
          return Object.assign({}, fieldProps, {
            clearLegacyFollowupEmail: callback => {
              onFieldChange(followupEmailFieldKey, undefined);
              if (callback) {
                setTimeout(callback, 100);
              }
            },
            legacyFollowupEmailId
          });
        }
        return fieldProps;
      }
    default:
      return field;
  }
};
export function getAccordionFieldKeyToOpen(fieldKey, fields) {
  const lastIndex = fieldKey.length - 1;
  const fieldKeyMayPointToFieldInsideAccordion = typeof fieldKey[lastIndex - 1] === 'string' && typeof fieldKey[lastIndex] === 'string';
  if (!fieldKeyMayPointToFieldInsideAccordion) return null;
  let currentField;
  for (let i = 0; i < fieldKey.length; i++) {
    const keyItem = fieldKey[i];
    if (typeof keyItem === 'string') {
      currentField = (currentField ? currentField.children || [] : fields).find(_field => _field.name === keyItem);
      if (currentField && i === lastIndex - 1) {
        const currentFieldContainsNestedGroups = Boolean(currentField.children && currentField.children.find(_child => _child.type === FieldTypes.GROUP));
        if (!currentFieldContainsNestedGroups) {
          return fieldKey.slice(0, lastIndex);
        }
      }
    }
  }
  return null;
}
export function getFieldKeyToSet(fieldKey, fields) {
  if (fieldKey.length < 2) {
    return null;
  }
  if (typeof fieldKey[fieldKey.length - 1] === 'number') {
    return fieldKey;
  }
  let currentField;
  const currentFieldKey = [];
  for (let i = 0; i < fieldKey.length; i++) {
    const keyItem = fieldKey[i];
    if (fieldKey.slice(i).length === 1 && typeof keyItem === 'string') {
      break;
    }
    if (typeof keyItem === 'string') {
      currentField = (currentField ? currentField.children || [] : fields).find(_field => _field.name === keyItem);
      if (currentField) {
        const currentFieldContainsNestedGroups = Boolean(currentField.children && currentField.children.find(_child => _child.type === FieldTypes.GROUP));
        if (currentFieldContainsNestedGroups || currentField.occurrence) {
          currentFieldKey.push(keyItem);
        }
      }
    } else {
      currentFieldKey.push(keyItem);
    }
  }
  return currentFieldKey.length ? currentFieldKey : null;
}
export function scrollFieldIntoView(fieldKey, section) {
  const fieldEl = document.querySelector(`.cmv2-edit-form [data-field-key="${fieldKey.join('.')}"]`);
  if (fieldEl) {
    const sectionEl = section && fieldEl.querySelector(`[data-field-section="${section}"]`);
    if (sectionEl) {
      sectionEl.scrollIntoViewIfNeeded();
    } else {
      fieldEl.scrollIntoViewIfNeeded();
    }
  }
}
'use es6';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import postMessageMiddleware from 'ContentEditorUI/redux/middleware/postMessageMiddleware';
import errorHandlingMiddleware from 'ContentEditorUI/redux/middleware/errorHandlingMiddleware';
import usageTrackerMiddleware from 'ContentEditorUI/redux/middleware/usageTrackerMiddleware';
import { HISTORY_METHOD_CALLED } from './actions/actionTypes';
import { dynamicMiddlewares, addMiddleware } from 'ContentEditorUI/vendor/redux-dynamic-middlewares';

// Set up Redux dev tools - https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const routerMiddleware = history => () => next => action => {
  if (action.type === HISTORY_METHOD_CALLED) {
    const {
      payload: {
        method,
        args
      }
    } = action;
    history[method](...args);
  }
  return next(action);
};
export function configureStore(rootReducer, initialState, eventBus, history, syncConfig, stateSelectorForErrorHandling, middlewares = []) {
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, errorHandlingMiddleware(stateSelectorForErrorHandling), routerMiddleware(history), postMessageMiddleware(eventBus, syncConfig), usageTrackerMiddleware, dynamicMiddlewares, ...middlewares))(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}
export const injectDynamicMiddleware = newMiddleware => {
  addMiddleware(newMiddleware);
};
'use es6';

import Raven from 'raven-js';
import * as RequestClients from '../../api/requestClients';
import { onFetchContentSchemaSuccess, onFetchContentSchemaFailed } from 'ContentEditorUI/redux/actions/appActions';
import { getContentTypeName } from 'ContentEditorUI/redux/selectors/contentTypeSelector';
import { standardFetchCustomModules, fetchModuleSchemasFailed, fetchModuleSchemasSucceeded } from 'ContentEditorUI/redux/actions/moduleSchemaActions';
import { getContentAssistantSettings } from 'ContentEditorUI/redux/actions/contentAssistantActions';
export const fetchContentSchema = () => dispatch => {
  const contentSchemaRequest = RequestClients.fetchContentSchema();
  return contentSchemaRequest.then(result => dispatch(onFetchContentSchemaSuccess({
    result
  })), result => dispatch(onFetchContentSchemaFailed({
    result
  })));
};
export const combinedInitialRequestThunk = () => (dispatch, getState) => {
  const contentPromise = dispatch(fetchContentSchema());
  const globalPromise = RequestClients.fetchGlobalModules().then(response => ({
    response
  }), error => ({
    error
  }));
  const customModulePromise = dispatch(standardFetchCustomModules());
  return Promise.all([globalPromise, customModulePromise, contentPromise]).then(requests => {
    const [globalModules = {}, customModules = {}] = requests;
    const categoryName = getContentTypeName(getState());

    // Need to check for errors again, because we already dispatch the failure action for each
    // of the sub-requests, which turns it into a resolved promise again
    for (const request of requests) {
      if (!request) {
        Raven.captureMessage('request is not defined ', {
          extra: {
            requests
          }
        });
      }
      if (request && request.error) {
        return dispatch(fetchModuleSchemasFailed(request));
      }
    }
    return dispatch(fetchModuleSchemasSucceeded({
      categoryName,
      globalModules: globalModules.response,
      customModules: customModules.response
    }, {
      fromSuperStoreCache: customModules.fromSuperStoreCache
    }));
  });
};
export const contentAssistantThunk = () => dispatch => dispatch(getContentAssistantSettings());
'use es6';

import http from 'hub-http/clients/apiClient';
const URI = 'contentmembership/public-access/lists-registrations';
export default class ListRegistrationsAPI {
  static fetchRegistration(listId) {
    return http.get(URI, {
      query: {
        listId
      }
    });
  }
  static fetchListRegistrations(listIds) {
    return Promise.all(listIds.toArray().map(id => this.fetchRegistration(id))).then(responses => {
      return responses.reduce((acc, resp) => ({
        emailsNotSent: acc.emailsNotSent + resp.emailsNotSent,
        emailsSent: acc.emailsSent + resp.emailsSent,
        registered: acc.registered + resp.registered,
        unregistered: acc.unregistered + resp.unregistered
      }), {
        emailsNotSent: 0,
        emailsSent: 0,
        registered: 0,
        unregistered: 0
      });
    });
  }
}
/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

import { createSelector } from 'reselect';
import { isEmpty } from 'underscore';
import formatName from 'I18n/utils/formatName';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { getDefaultMeetingName } from 'ContentUtils/js/helpers/meetingsName';
import { getUsersForTeams } from 'ContentData/selectors/users';
function formatUserFullName(user) {
  const {
    email,
    firstName,
    lastName
  } = user;
  return formatName({
    firstName,
    lastName
  }) || email;
}
export const getMeeting = (state, props) => props.link ? state.resources.meetings.meetings[props.link] : undefined;
const getMeetings = state => state.resources.meetings.meetings;
export const getMeetingCache = (state, props) => props.link ? state.resources.meetings.meetingsCache[props.link] : undefined;
export const getMeetingsCache = state => state.resources.meetings.meetingsCache;
export const getMeetingsCacheRequestStatus = state => state.resources.meetings.meetingsCacheRequestStatus;
export const getMeetingsOptions = createSelector([getMeetingsCache, getUsersForTeams], (meetings, users) => {
  if (isEmpty(meetings)) return [];
  const userMap = users.reduce((dict, profile) => {
    dict[profile.id] = {
      options: [],
      text: formatUserFullName(profile)
    };
    return dict;
  }, {});
  Object.values(meetings).forEach(meeting => {
    if (userMap.hasOwnProperty(meeting.userId)) {
      userMap[meeting.userId].options.push({
        value: meeting.link,
        text: getDefaultMeetingName(meeting, true),
        help: meeting.link
      });
    }
  });
  return Object.values(userMap);
});
export const getDefaultMeetingsOptions = createGenericOptionsSelector(getMeetings, {
  getDisplayText: getDefaultMeetingName
});
export const getMeetingFetchStatus = (state, props) => props.link ? state.resources.meetings.requestStatus[props.link] : undefined;
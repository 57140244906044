import { PREVIEW_IFRAME_WRAPPER, SIDEBAR_IFRAME_WRAPPER, BAYMAX_SKELETON_CLASSNAME, SIDEBAR_IFRAME_SPINNER_CLASSNAME, PATAGONIA_SKELETON_CLASSNAME } from 'ContentEditorUI/constants/dom';
export const FOCUS_MODE_LOADING_OVERLAY_CLASS = 'hs-focus-mode-loading';

// Doing a bit of casting below, but it should be pretty safe
// given that we control where the classes are applied
export const getPreviewIframeWrapperElement = () => document.getElementsByClassName(PREVIEW_IFRAME_WRAPPER)[0];
export const getSidebarIframeWrapperElement = () => document.getElementsByClassName(SIDEBAR_IFRAME_WRAPPER)[0];
export const hideSidebarIframeWrapperElement = () => {
  const spinnerEl = getSidebarIframeWrapperElement();
  if (spinnerEl) {
    spinnerEl.style.display = 'none';
  }
};
const getBaymaxSkeletonRootElement = () => document.getElementsByClassName(BAYMAX_SKELETON_CLASSNAME)[0];
export const removeBaymaxSkeleton = () => {
  const el = getBaymaxSkeletonRootElement();
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};
const getPatagoniaSkeletonRootElement = () => document.getElementsByClassName(PATAGONIA_SKELETON_CLASSNAME)[0];
export const removePatagoniaSkeleton = () => {
  const el = getPatagoniaSkeletonRootElement();
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};
export const removeEarlyIframeWrappers = () => {
  const previewWrapper = getPreviewIframeWrapperElement();
  if (previewWrapper && previewWrapper.parentNode) {
    previewWrapper.parentNode.removeChild(previewWrapper);
  }
  const sidebarWrapper = getSidebarIframeWrapperElement();
  if (sidebarWrapper && sidebarWrapper.parentNode) {
    sidebarWrapper.parentNode.removeChild(sidebarWrapper);
  }
};
export const getSidebarIframeSpinnerElement = () => {
  return document.getElementsByClassName(SIDEBAR_IFRAME_SPINNER_CLASSNAME)[0];
};
export const showSidebarIframeSpinner = () => {
  const spinnerEl = getSidebarIframeSpinnerElement();
  if (spinnerEl) {
    spinnerEl.style.display = '';
  }
};
export const hideSidebarIframeSpinner = () => {
  const spinnerEl = getSidebarIframeSpinnerElement();
  if (spinnerEl) {
    spinnerEl.style.display = 'none';
  }
};
export const showPreviewLoadingOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').style.display = 'block';
};
export const hidePreviewLoadingOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').style.display = 'none';
};
export const showFocusModeOnOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').classList.add(FOCUS_MODE_LOADING_OVERLAY_CLASS);
};
export const hideFocusModeOnOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').classList.remove(FOCUS_MODE_LOADING_OVERLAY_CLASS);
};
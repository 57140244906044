import { combineReducers } from 'redux';
import { TAGS_FETCH_TAG, TAGS_FETCH_TAG_BY_SLUG, TAGS_FETCH_TAG_BY_NAME, TAGS_FETCH_TAGS, TAGS_SAVE_TAG_CACHE, TAGS_CLEAR_CACHE } from 'ContentData/actions/ActionTypes';
import { PENDING, SUCCEEDED, FAILED, UNINITIALIZED } from 'ContentUtils/constants/RequestStatus';
function createTagReducer(key) {
  return (state = {}, action) => {
    switch (action.type) {
      case TAGS_FETCH_TAG:
        {
          const {
            response
          } = action;
          if (!response) return state;
          return Object.assign({}, state, {
            [`${response[key]}`]: response
          });
        }
      case TAGS_FETCH_TAG_BY_SLUG:
      case TAGS_FETCH_TAG_BY_NAME:
        {
          const {
            response
          } = action;
          if (!(response && response.objects)) return state;
          const [tag] = response.objects;
          if (!tag) return state;
          return Object.assign({}, state, {
            [`${tag[key]}`]: tag
          });
        }
      case TAGS_SAVE_TAG_CACHE:
        {
          const {
            tag
          } = action;
          if (!tag) return state;
          return Object.assign({}, state, {
            [`${tag[key]}`]: tag
          });
        }
      default:
        return state;
    }
  };
}
function createTagCacheReducer(key) {
  return (state = {}, action) => {
    switch (action.type) {
      case TAGS_FETCH_TAGS:
        {
          const {
            response
          } = action;
          if (!(response && response.objects)) return state;
          return response.objects.reduce((hash, tag) => {
            hash[tag[key]] = tag;
            return hash;
          }, Object.assign({}, state));
        }
      case TAGS_CLEAR_CACHE:
        {
          if (Object.keys(state).length) return {};
          return state;
        }
      default:
        return state;
    }
  };
}
function tagsCacheRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case TAGS_FETCH_TAGS:
      if (action.response) return SUCCEEDED;
      if (action.error) return FAILED;
      return PENDING;
    default:
      return state;
  }
}
function requestStatus(state = {}, action) {
  switch (action.type) {
    case TAGS_FETCH_TAG:
    case TAGS_FETCH_TAG_BY_SLUG:
    case TAGS_FETCH_TAG_BY_NAME:
      {
        const {
          response,
          error,
          options: key
        } = action;
        let status = PENDING;
        if (response) status = SUCCEEDED;
        if (error) status = FAILED;
        return Object.assign({}, state, {
          [key]: status
        });
      }
    default:
      return state;
  }
}
export default combineReducers({
  tags: combineReducers({
    byId: createTagReducer('id'),
    bySlug: createTagReducer('slug'),
    byName: createTagReducer('name')
  }),
  tagsCache: combineReducers({
    byId: createTagCacheReducer('id'),
    bySlug: createTagCacheReducer('slug'),
    byName: createTagCacheReducer('name')
  }),
  tagsCacheRequestStatus,
  requestStatus
});
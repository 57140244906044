import { useCallback, useEffect, useState } from 'react';
import usersApi from '../data/usersApi';
export const useFetchUsersByScope = scope => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const sortUsers = unsortedUsers => {
    return unsortedUsers.sort((a, b) => a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase() ? -1 : 1);
  };
  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    usersApi.fetchUsersByScope(scope).then(response => {
      setIsLoading(false);
      setUsers(sortUsers(response));
    }).catch(e => {
      setIsLoading(false);
      console.error(e);
    });
  }, [scope]);
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  return {
    isLoading,
    users,
    fetchUsers
  };
};
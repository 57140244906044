'use es6';

import { getIsStarterPageTemplate, getIsAdvancedPageTemplateWithAccess } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getHasPersonalizationAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
export const mapStateToTinyMCEConfig = state => {
  const isStarterPageTemplate = getIsStarterPageTemplate(state);
  const isAdvancedPageTemplateWithAccess = getIsAdvancedPageTemplateWithAccess(state);
  const hasPersonalizationAccess = getHasPersonalizationAccess(state);
  return {
    useEmbed: isAdvancedPageTemplateWithAccess,
    usePersonalization: !isStarterPageTemplate,
    useSourceCode: isAdvancedPageTemplateWithAccess,
    usePersonalizationAsUpgradePoint: !isStarterPageTemplate && !hasPersonalizationAccess
  };
};
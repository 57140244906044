import { EDIT_FORM_VALIDATION_FINISHED } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  isValid: undefined
};
const formStatusReducer = (state = initialState, {
  type,
  valid
}) => {
  switch (type) {
    case EDIT_FORM_VALIDATION_FINISHED:
      return Object.assign({}, state, {
        isValid: valid
      });
    default:
      return state;
  }
};
export default formStatusReducer;
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { topicsGroupReducer } from './data/topicsGroupReducer';
import thunk from 'redux-thunk';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  name: 'Xray Panel Topics Group | HubSpot'
}) : compose;
export const configureTopicsGroupStore = (initialState = {}) => {
  return createStore(topicsGroupReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
};
export const topicsGroupStore = configureTopicsGroupStore();
export const useSelector = useReduxSelector;
export const useDispatch = useReduxDispatch;
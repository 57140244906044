import { useCallback, useSyncExternalStore } from 'react';
import { debounce } from 'underscore';
import { SOURCE_APP_IDS_FOR_TRACKING } from 'ContentEditorUI/utils/automationConstants';
class UsageTracker {
  constructor() {
    this.cloneCache = {};
    this.subscribers = new Set();
    this.callAllCallbacks = this.callAllCallbacks.bind(this);
  }
  init(tracker, appName = 'content-editor-ui') {
    this.tracker = tracker;
    this.appName = appName;
    this.defaultCloneOptions = {
      preserveTrackerProperties: false,
      preserveTrackerEvents: true
    };
    this.trackerAsBeacon = tracker.clone ? tracker.clone({}) : null;
    this.callAllCallbacks();
  }
  getTracker() {
    if (!this.tracker) {
      throw new Error('Trying to access tracker before it was initialized');
    }
    return this.tracker;
  }
  isInitialized() {
    return !!this.tracker;
  }
  cloneTracker(configOptions = {}) {
    const newTracker = this.tracker ? this.tracker.clone(Object.assign({}, this.defaultCloneOptions, configOptions)) : null;
    return newTracker;
  }
  getCachedClonedTracker(cacheKey, configOptions = {}) {
    if (!this.cloneCache[cacheKey]) {
      this.cloneCache[cacheKey] = this.cloneTracker(configOptions);
    }
    return this.cloneCache[cacheKey];
  }
  getAppName() {
    return this.appName;
  }
  track(event, properties = {}) {
    if (this.tracker) {
      this.tracker.track(event, properties);
    } else if (!window.jasmine) {
      console.error('Cannot track usage before tracker initialization.');
    }
  }
  trackEditorInteraction(properties) {
    this.track('editorInteraction', properties);
  }
  trackContextualAutomationInteraction(properties) {
    const maybeSourceApp = SOURCE_APP_IDS_FOR_TRACKING[this.getAppName()];
    const extraProps = maybeSourceApp ? {
      sourceApp: maybeSourceApp
    } : {};
    this.track('contentEditorAutomationInteraction', Object.assign({}, extraProps, properties));
  }
  createDebouncedTracker(event, properties, debounceTime) {
    return debounce(() => this.track(event, properties), debounceTime);
  }

  // NOTE Functionally no different than track, but helps clarify which events are public
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  trackPublicEvent(event, properties) {
    this.track(event, properties);
  }
  trackAsBeacon(event, properties) {
    if (this.trackerAsBeacon) {
      this.trackerAsBeacon.track(event, properties);
    }
  }
  subscribe(subscriber) {
    this.subscribers.add(subscriber);
    return () => {
      this.subscribers.delete(subscriber);
    };
  }
  callAllCallbacks() {
    this.subscribers.forEach(subscriber => {
      subscriber();
    });
  }
}
const usageTracker = new UsageTracker();
export default usageTracker;
const subscribeToUsageTracker = callback => {
  const unsubscribe = usageTracker.subscribe(callback);
  return unsubscribe;
};
export const useTracker = (cacheKey, configOptions = {}) => {
  const getTrackerSnapshot = useCallback(() => {
    if (!usageTracker.isInitialized()) return null;
    return cacheKey ? usageTracker.getCachedClonedTracker(cacheKey, configOptions) : usageTracker.getTracker();
  }, [cacheKey, configOptions]);
  return useSyncExternalStore(subscribeToUsageTracker, getTrackerSnapshot);
};
'use es6';

import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
const contentsApiUrl = '/content/api/v4/contents/';
export const fetch = id => {
  return http.get(`${contentsApiUrl}/${id}`, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get(),
      includeDeleted: true
    }
  });
};
export const DEFAULT_PAYWALL = 'DEFAULT_PAYWALL';
export const WORKFLOWS_PAYWALL = 'WORKFLOWS_PAYWALL';
export const PAYMENTS_PAYWALL = 'PAYMENTS_PAYWALL';
export const UPGRADE_MODAL = 'UPGRADE_MODAL';
export const TRIAL_BANNER = 'TRIAL_BANNER';
export const USAGE_LIMIT_BANNER = 'USAGE_LIMIT_BANNER';
export const REMOVE_BRANDING_BANNER = 'REMOVE_BRANDING_BANNER';
export const UPGRADE_PAGE = 'UPGRADE_PAGE';
export const TALK_TO_SALES_BUTTON = 'TALK_TO_SALES_BUTTON';
export const LEGACY_PRICING_PAGE = 'LEGACY_PRICING_PAGE';
export const GENERIC = 'GENERIC';
export const SEQUENCES_SOFT_PAYWALL = 'SEQUENCES_SOFT_PAYWALL';
export const UPGRADE_POINT_LOCATIONS = [DEFAULT_PAYWALL, WORKFLOWS_PAYWALL, PAYMENTS_PAYWALL, UPGRADE_MODAL, USAGE_LIMIT_BANNER, REMOVE_BRANDING_BANNER, UPGRADE_PAGE, SEQUENCES_SOFT_PAYWALL];
import { useState, useEffect } from 'react';
import http from 'hub-http/clients/apiClient';
const BASE_URL = 'owners/v2/owners';
export const useOwners = () => {
  const [owners, setOwners] = useState([]);
  useEffect(() => {
    http.get(BASE_URL).then(response => setOwners(response)).catch(() => {
      console.error('Error fetching owners');
    });
  }, []);
  return owners;
};
import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as blogsClient from 'ContentData/api/Blogs';
import { getBlogCache } from 'ContentData/selectors/blogs';
import { BLOGS_FETCH_BLOG, BLOGS_FETCH_BLOGS, BLOGS_FETCH_DEFAULT_BLOG, BLOGS_SAVE_BLOG_CACHE, BLOGS_CLEAR_CACHE, BLOGS_CREATE_BLOG_SUCCEEDED, BLOGS_CREATE_BLOG_FAILED } from 'ContentData/actions/ActionTypes';
import { DEFAULT } from 'ContentUtils/constants/Blogs';
const fetchBlogAction = createGenericFetchAction(BLOGS_FETCH_BLOG, blogsClient.fetchBlog);
const fetchBlogsAction = createGenericFetchAction(BLOGS_FETCH_BLOGS, blogsClient.fetchBlogs);
const fetchDefaultBlogAction = createGenericFetchAction(BLOGS_FETCH_DEFAULT_BLOG, blogsClient.fetchDefaultBlog);
export const fetchBlog = id => {
  return fetchBlogAction(id);
};
export const fetchBlogs = query => {
  return fetchBlogsAction(query);
};
export const fetchDefaultBlog = () => {
  return fetchDefaultBlogAction(DEFAULT);
};
export const saveCache = id => {
  return (dispatch, getState) => {
    const blog = getBlogCache(getState(), {
      id
    });
    dispatch({
      type: BLOGS_SAVE_BLOG_CACHE,
      blog
    });
  };
};
export const clearCache = () => {
  return {
    type: BLOGS_CLEAR_CACHE
  };
};
export const failCreateBlog = () => {
  return {
    type: BLOGS_CREATE_BLOG_FAILED
  };
};
export const succeedCreateBlog = blog => {
  return {
    type: BLOGS_CREATE_BLOG_SUCCEEDED,
    payload: blog
  };
};
import { createGenericCreateAction, createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as workflowsClient from 'ContentData/api/Workflows';
import { WORKFLOWS_FETCH_WORKFLOWS, WORKFLOWS_CREATE_WORKFLOW, WORKFLOWS_FETCH_WORKFLOW, WORKFLOWS_FETCH_SUMMARIES, WORKFLOWS_CLEAR_SUMMARIES } from 'ContentData/actions/ActionTypes';
const fetchWorkflowsAction = createGenericFetchAction(WORKFLOWS_FETCH_WORKFLOWS, workflowsClient.fetchWorkflows);
export const fetchWorkflows = () => {
  return fetchWorkflowsAction();
};
const fetchWorkflowAction = createGenericFetchAction(WORKFLOWS_FETCH_WORKFLOW, workflowsClient.fetchWorkflow);
export const fetchWorkflow = id => {
  return fetchWorkflowAction(id);
};
const createWorkflowAction = createGenericCreateAction(WORKFLOWS_CREATE_WORKFLOW, workflowsClient.createWorkflow);
export const createWorkflow = name => {
  return createWorkflowAction(name);
};
const fetchWorkflowSummariesAction = createGenericFetchAction(WORKFLOWS_FETCH_SUMMARIES, workflowsClient.fetchWorkflowSummaries);
export const fetchWorkflowSummaries = query => {
  return fetchWorkflowSummariesAction(query);
};
export const clearWorkflowSummaries = () => {
  return {
    type: WORKFLOWS_CLEAR_SUMMARIES
  };
};
'use es6';

import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
const themeUrl = 'designmanager/v1/themes';
const fontsUrl = 'content/fonts/v1';
export function fetchByPath(themePath) {
  return http.get(`${themeUrl}/by-path/${encodeURIComponent(themePath)}/settings`, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get()
    }
  });
}
export function fetchThemeCustomFonts(themePath) {
  return http.get(`${fontsUrl}/by-theme-path/${themePath}`, {
    query: {
      portalId: PortalIdParser.get()
    }
  });
}
'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import TemplateTypes from 'ContentUtils/constants/TemplateTypes';
import I18n from 'I18n';
const developerGetSectionsEndpoint = 'designmanager/v1/sections/coded';
const marketerSectionsEndpoint = 'content-editor/v1/marketer-section';
const SECTIONS_FETCH_LIMIT = 10;
const getNeedsToPaginate = ({
  isMarketer,
  total,
  nextSectionObjects,
  sections
}) => {
  if (!isMarketer) {
    // Only developer sections return the total count
    return total && total > sections.objects.length;
  }
  // Otherwise since the marketer sections endpoint doesn't return the total,
  // then we have to exhaustively fetch the remaining sections until there's
  // no more results
  return nextSectionObjects.length === SECTIONS_FETCH_LIMIT;
};
const fetchAllSections = ({
  isMarketer,
  themePath,
  templatePath,
  currentSectionObjects = [],
  offset = 0
}) => {
  const sectionsEndpoint = isMarketer ? marketerSectionsEndpoint : developerGetSectionsEndpoint;
  return http.get(sectionsEndpoint, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get(),
      templateTypeId: TemplateTypes.byName.section.id,
      themePath,
      templatePath,
      locale: I18n.lang,
      limit: SECTIONS_FETCH_LIMIT,
      offset
    }
  }).then(({
    objects: nextSectionObjects,
    themeLimit,
    total
  }) => {
    const sections = {
      objects: currentSectionObjects.concat(nextSectionObjects),
      themeLimit
    };
    const needsToPaginate = getNeedsToPaginate({
      isMarketer,
      total,
      nextSectionObjects,
      sections
    });
    if (needsToPaginate) {
      return fetchAllSections({
        isMarketer,
        themePath,
        templatePath,
        currentSectionObjects: sections.objects,
        offset: offset + SECTIONS_FETCH_LIMIT
      });
    }
    return sections;
  });
};
export function fetchMarketerSections(themePath) {
  return fetchAllSections({
    isMarketer: true,
    themePath
  });
}
export function fetchAddableSections(themePath, templatePath) {
  return Promise.all([fetchAllSections({
    isMarketer: false,
    themePath,
    templatePath
  }).then(devSections => {
    devSections.objects.forEach(section => {
      section.marketerSection = false;
    });
    return devSections;
  }), fetchAllSections({
    isMarketer: true,
    themePath,
    templatePath
  }).then(marketerSections => {
    marketerSections.objects.forEach(section => {
      section.marketerSection = true;
    });
    return marketerSections;
  })]).then(([devSections, marketerSections]) => {
    return {
      allSections: [...marketerSections.objects, ...devSections.objects],
      marketerSectionsCount: marketerSections.objects.length,
      marketerSectionsLimit: marketerSections.themeLimit
    };
  });
}
export function saveNewSection({
  name,
  description,
  sectionTree,
  screenshotUrl,
  themePath,
  isSmartSection
}) {
  return http.post(marketerSectionsEndpoint, {
    timeout: 15000,
    data: {
      name,
      description,
      sectionTree,
      screenshotUrl,
      themePath,
      isSmart: isSmartSection
    }
  });
}
export function deleteSection(sectionId) {
  const url = `${marketerSectionsEndpoint}/${sectionId}`;
  return http.delete(url, {
    timeout: 15000
  });
}
import { combineReducers } from 'redux';
import { clustersReducer } from './clusters/slice';
import { contentReducer } from './content/slice';
import { loadingReducer } from './loading/slice';
import { thingsPeopleCoverReducer } from './thingsPeopleCover/slice';
import { topicEditorReducer } from './topicEditor/slice';
import { topicsReducer } from './topics/slice';
export const topicsGroupReducer = combineReducers({
  content: contentReducer,
  loading: loadingReducer,
  topics: topicsReducer,
  topicEditor: topicEditorReducer,
  clusters: clustersReducer,
  thingsPeopleCover: thingsPeopleCoverReducer
});
import { useCallback, useEffect, useRef } from 'react';
import { fetchAssetLastUpdatedAt } from '../api/Commenting';
import usePageIsVisible from './useIsPageVisible';
import { getAssetKeyFromAsset } from 'collaboration-sidebar/utils/multipleAssets';
import useIsWindowActive from './useIsWindowActive';
export const POLL_INTERVAL_SIDEBAR_OPEN = 5000;
export const POLL_INTERVAL_SIDEBAR_CLOSED = 20000;
export default function usePollForCommentsChanges({
  objectId,
  objectType,
  onUpdateCollaborationSidebarState,
  hasSidebarPermission,
  assetsLastUpdated,
  isOpen
}) {
  const pageIsVisible = usePageIsVisible();
  const isWindowActive = useIsWindowActive();
  const pageIsVisibleAndActive = pageIsVisible && isWindowActive;
  const interval = useRef();

  // Use a ref instead of prop to use live value in setInterval.
  const assetsLastUpdatedRef = useRef(assetsLastUpdated);
  useEffect(() => {
    assetsLastUpdatedRef.current = assetsLastUpdated;
  });
  const fetchLastUpdated = useCallback(objectIdArg => {
    fetchAssetLastUpdatedAt({
      objectId: objectIdArg,
      objectType
    }).then(({
      updatedAt
    }) => {
      const assetKey = getAssetKeyFromAsset({
        objectId: objectIdArg,
        objectType
      });
      if (updatedAt !== assetsLastUpdatedRef.current[assetKey]) {
        onUpdateCollaborationSidebarState({
          assetsLastUpdated: Object.assign({}, assetsLastUpdatedRef.current, {
            [assetKey]: updatedAt
          })
        });
      }
    }).catch(() => {
      // do nothing. Polling shouldn't be visible to user
    });
  }, [objectType, onUpdateCollaborationSidebarState]);
  useEffect(() => {
    if (!objectId) {
      return () => {};
    }
    if (objectId && objectType && hasSidebarPermission && pageIsVisibleAndActive) {
      fetchLastUpdated(objectId);
      interval.current = setInterval(() => fetchLastUpdated(objectId), isOpen ? POLL_INTERVAL_SIDEBAR_OPEN : POLL_INTERVAL_SIDEBAR_CLOSED);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [objectType, objectId, pageIsVisibleAndActive, hasSidebarPermission, isOpen, fetchLastUpdated]);
}
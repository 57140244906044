import { RequestStatus } from '../../constants/types';
// Returns whether to set the meta status in the action
// Should true if status is defined & is PENDING or FAILED
export const hasPendingOrFailedStatus = action => {
  const {
    meta
  } = action;
  return meta && meta.status !== undefined && (meta.status === RequestStatus.FAILED || meta.status === RequestStatus.PENDING);
};
export const setRequestStatusState = (requestMap, action) => {
  const {
    meta,
    type
  } = action;
  if (!meta || !meta.status) {
    throw new Error('Calls to setRequestStatusState should have a meta status');
  } else {
    return Object.assign({}, requestMap, {
      [type]: meta.status
    });
  }
};
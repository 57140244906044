'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';
export const fetchPageLimitForContentType = contentType => {
  return getEarlyRequesterResultPromise(`initial-${contentType}-limit-fetch`, false) || http.get(`cospages/v2/${contentType}/${PortalIdParser.get()}/usage`);
};
export const fetchSharedPageLimit = () => {
  return getEarlyRequesterResultPromise('initial-shared-pages-limit-fetch', false) || http.get('cospages/v2/pages/usage');
};
export const fetchMlangPagesLimit = contentId => {
  return http.get(`cospages/v2/pages/${contentId}/multilang-usage`);
};
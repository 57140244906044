'use es6';

import { SET_HIGHLIGHTED_FIELD, SET_SETTING_OPTIMIZATION_TYPE, SET_MODULE_OPTIMIZATION_TYPE, SET_IMAGE_MODULES_FOR_ALT_TEXT, SET_XRAY_PAGE_TITLE, SET_XRAY_META_DESC, SET_XRAY_READABILITY, CLEAR_XRAY_READABILITY, SET_XRAY_INPAGE_GATHERER_ARTIFACTS, SET_XRAY_INPAGE_GATHERER_KEYS } from 'ContentEditorUI/redux/actions/actionTypes';
import { updateSelectedItem } from 'ContentEditorUI/redux/actions/selectionActions';
import { setScrollTo } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { clearInpageUIState } from 'ContentEditorUI/redux/actions/inpageActions';
import { routeToPage } from 'ContentEditorUI/redux/actions/routingActions';
import { toCamelCase } from 'ContentEditorUI/utils/helpers';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { openModal } from 'ContentEditorUI/redux/actions/appActions';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { SETTING_REC_TYPE_TO_FIELD_MAP } from 'ContentEditorUI/lib/xray/constants';
import { getModuleById } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getGathererKeys, getSettingsFieldForContentType } from 'ContentEditorUI/utils/xrayUtils';
import { getIsBlogPost } from '../selectors/contentReadOnlyDataSelectors';
import { GathererConfig, GathererExecutionLocations } from 'xray-recommendation-lib/gatherers/allGatherers';
export const setModuleHighlightedField = field => {
  return {
    type: SET_HIGHLIGHTED_FIELD,
    payload: {
      field
    }
  };
};
export const selectModuleForXray = moduleId => {
  return dispatch => {
    dispatch(updateSelectedItem(moduleId));
  };
};
export const setSettingOptimizationType = optimizationType => {
  return {
    type: SET_SETTING_OPTIMIZATION_TYPE,
    payload: {
      optimizationType
    }
  };
};
export const setModuleOptimizationType = optimizationType => {
  return {
    type: SET_MODULE_OPTIMIZATION_TYPE,
    payload: {
      optimizationType
    }
  };
};
export const openSettingsForXRay = optimizationType => {
  const field = SETTING_REC_TYPE_TO_FIELD_MAP[optimizationType];
  const camelCaseField = toCamelCase(field);
  return (dispatch, getState) => {
    const state = getState();
    const isUngatedForPatagonia = getIsUngatedForPatagonia(state);
    const isBlogPost = getIsBlogPost(state);
    const patagoniaScrollToField = getSettingsFieldForContentType(camelCaseField, isBlogPost);
    dispatch(setSettingOptimizationType(optimizationType));
    if (isUngatedForPatagonia) {
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
      dispatch(setScrollTo(patagoniaScrollToField));
    } else {
      dispatch(clearInpageUIState());
      dispatch(routeToPage('settings'));
      dispatch(setScrollTo(camelCaseField));
    }
  };
};
const setImageModulesForAltTextAction = imageModules => {
  return {
    type: SET_IMAGE_MODULES_FOR_ALT_TEXT,
    payload: {
      imageModules
    }
  };
};
export const setImageModulesForAltText = imageIds => {
  return (dispatch, getState) => {
    const state = getState();
    const imageModules = [];
    imageIds.forEach(id => {
      const module = getModuleById(state, id);
      imageModules.push(module.toJS());
    });
    dispatch(setImageModulesForAltTextAction(imageModules));
  };
};
export const setXrayPageTitle = pageTitle => {
  return {
    type: SET_XRAY_PAGE_TITLE,
    payload: {
      pageTitle
    }
  };
};
export const setXrayMetaDesc = metaDesc => {
  return {
    type: SET_XRAY_META_DESC,
    payload: {
      metaDesc
    }
  };
};
export const setXrayInpageGathererKeys = config => {
  const gathererKeys = getGathererKeys(config);
  const inpageGathererKeys = gathererKeys.filter(key => {
    const gatherer = GathererConfig[key];
    return gatherer.location === GathererExecutionLocations.INPAGE_EDITOR;
  });
  return {
    type: SET_XRAY_INPAGE_GATHERER_KEYS,
    payload: {
      inpageGathererKeys
    }
  };
};
export const setXrayInpageGathererArtifacts = artifacts => {
  return {
    type: SET_XRAY_INPAGE_GATHERER_ARTIFACTS,
    payload: {
      artifacts
    }
  };
};
export const setXrayReadability = (xrayReadabilityType, xrayReadabilityArr) => {
  return {
    type: SET_XRAY_READABILITY,
    payload: {
      xrayReadabilityType,
      xrayReadabilityArr
    }
  };
};
export const clearXRayReadability = () => {
  return {
    type: CLEAR_XRAY_READABILITY
  };
};
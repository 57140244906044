import { useState } from 'react';
import { COMMENT_LIST_PADDING_PX } from '../../../constants/sidebar';
const COMMENTER_FORM_HEIGHT = 190;
const EMPTY_COMMENTER_HEIGHT = COMMENTER_FORM_HEIGHT + COMMENT_LIST_PADDING_PX * 2;
export default function useMiniCommenterSize() {
  const [miniCommenterSize, setMiniCommenterSize] = useState(EMPTY_COMMENTER_HEIGHT);
  const [minHeightForPopover, setMinHeightForPopover] = useState(0); // popovers overflowing the iframe are cutoff, so we manually extend the iframe

  return {
    miniCommenterSize: Math.max(miniCommenterSize, minHeightForPopover),
    setMinHeightForPopover,
    onResizeMiniCommenter: setMiniCommenterSize
  };
}
'use es6';

import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getIframeAppState } from 'editor-iframe-lib/redux/utils/iframeStateSync';
import { getModulesInAnyLayoutSection } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import logSeleniumEvent from 'ContentEditorUI/utils/logSeleniumEvent';
import { setIframeReady } from 'ContentEditorUI/redux/actions/appActions';
import defaultConfig from 'ContentEditorUI/redux/inpageSync/defaultConfig';
import { getContentSchemaSchemaForInpage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getTinyMCEGlobals } from 'ContentEditorUI/redux/selectors/startupSelectors';
import EditorConfigSingleton, { STRIPPED_FUNCTION_INDICATOR } from 'ContentEditorUI/EditorConfigSingleton';
import { makeGetMapStateToTinyMCEConfig } from 'ContentEditorUI/utils/editorConfigSingletonUtils';
export const triggerInpageLoaded = () => {
  // Leave state around so selenium knows the iframe has reloaded
  logSeleniumEvent('finishIframeReloadAndBoostrap');
  window.hubspot.ContentEditorUI.store.dispatch(setIframeReady());
};
const getTinyMCEConfig = makeGetMapStateToTinyMCEConfig();
const parseFunctionsOffOfObject = (obj, destinationObj) => {
  Object.keys(obj).forEach(key => {
    const val = obj[key];
    if (typeof val === 'function') {
      destinationObj[key] = STRIPPED_FUNCTION_INDICATOR;
    } else if (typeof val === 'object' && !Array.isArray(val)) {
      const toWrite = {};
      parseFunctionsOffOfObject(val, toWrite);
      destinationObj[key] = toWrite;
    } else {
      destinationObj[key] = val;
    }
  });
};
const parseFunctionsOffOfFeatureConfig = () => {
  const featuresWithoutFunctions = {};
  const features = EditorConfigSingleton.get('features');
  parseFunctionsOffOfObject(features, featuresWithoutFunctions);
  return featuresWithoutFunctions;
};
const getEditorConfigForInpage = () => {
  if (!EditorConfigSingleton.getIsOnScalableEditor()) {
    return {};
  }
  return {
    features: parseFunctionsOffOfFeatureConfig(),
    mapStateToTinyMCEConfig: getTinyMCEConfig(),
    gatedFeatures: EditorConfigSingleton.get('gatedFeatures'),
    hasPermissionsToSave: EditorConfigSingleton.getHasPermissionsToSave()
  };
};
export const getInpageStartupMessage = () => {
  const state = window.hubspot.ContentEditorUI.store.getState();
  const schemaForInpage = getContentSchemaSchemaForInpage(state);
  const tinyMCEGlobals = getTinyMCEGlobals(state);
  const {
    gates
  } = state.auth;
  const message = {
    action: 'setContentSchema',
    schema: schemaForInpage,
    gates,
    bender: window.hubspot.bender,
    appName: UsageTracker.getAppName(),
    appState: getIframeAppState(state, defaultConfig),
    isOnScalableEditor: EditorConfigSingleton.getIsOnScalableEditor(),
    editorConfig: getEditorConfigForInpage(),
    // TODO: remove once the rich text InpageEditorUI editor is react-based
    // add can be connected via redux
    tinymceGlobalSettings: tinyMCEGlobals || {}
  };

  // Make sure to pass along redux modifications to layout_section_widgets
  const layoutSectionModules = getModulesInAnyLayoutSection(state);
  if ((layoutSectionModules != null ? layoutSectionModules.size : undefined) > 0) {
    message.schema.layout_section_widgets = layoutSectionModules.toJS();
  }
  return message;
};
import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
export default function getApiHostForEnvironment() {
  let apiUrl = getFullUrl('app-api');
  if (enviro.getShort('filemanager') === 'local') {
    apiUrl = getFullUrl('local', {
      envOverride: 'qa'
    });
  }
  return apiUrl.replace('https://', '');
}
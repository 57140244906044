import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
// Stores the hard-saved content model for the cases where we need that
// rather than the buffer (eg. differentiating scheduled vs published pages)
const hardSavedContentModelReducer = (state = {}, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const hardContentInfo = response.context.hard_content_info;
        return Object.assign(draft, {
          publishDate: hardContentInfo.publish_date,
          state: hardContentInfo.state,
          publishImmediately: hardContentInfo.publish_immediately,
          scheduledUpdateDate: response.content.scheduledUpdateDate
        });
      }
    case FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED:
      {
        return Object.assign(draft, payload);
      }
    default:
      return draft;
  }
});
export default hardSavedContentModelReducer;
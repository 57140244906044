'use es6';

import { createSelector } from 'reselect';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCategoryId, getIsEmail } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import ContentRoutes from 'ContentUtils/Routes';
import { CURRENT_REVISION_ID } from 'ContentEditorUI/api/RevisionsApi';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getHardSavedContentModelIsPublishedNotScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
import { getIsEmbeddedEmailEditorRoute } from 'ContentEditorUI/redux/selectors/routingSelectors';
export const getRevisionsData = basicSelector(state => state.revisions);
export const getScheduledRevision = createSelector(getRevisionsData, revisionsData => revisionsData.get('scheduledRevision'));
export const getRevisions = createSelector(getRevisionsData, revisionsData => revisionsData.get('objects'));
export const getRevisionsWithScheduledUpdate = createSelector(getRevisions, getScheduledRevision, getHardSavedContentModelIsPublishedNotScheduled, (revisionsList, scheduledRevision, isPublished) => {
  const revisions = revisionsList ? revisionsList.toJS() : [];
  return isPublished && scheduledRevision ? [scheduledRevision, ...revisions] : revisions;
});
export const getHasFirstRevisionForCurrentState = createSelector(getRevisions, revisions => revisions.size > 0 && revisions.getIn([0, 'id']) === CURRENT_REVISION_ID);
const getLimit = createSelector(getRevisionsData, revisionsData => revisionsData.get('limit'));
export const getCurrentOffset = createSelector(getRevisionsData, revisionsData => revisionsData.get('offset'));
export const getTotalRevisions = createSelector(getRevisionsData, getScheduledRevision, (revisionsData, scheduledRevision) => revisionsData.get('total') + (scheduledRevision ? 1 : 0));
export const getHasMoreRevisions = createSelector([getCurrentOffset, getLimit, getTotalRevisions], (offset, limit, total) => {
  return offset + limit < total;
});
export const getRevisionsError = createSelector(getRevisionsData, revisionsData => revisionsData.get('error'));
export const getRevisionHref = basicSelectorWithStats((state, revisionId) => {
  const id = getContentModelId(state);
  const isEmbeddedEmailEditorRoute = getIsEmbeddedEmailEditorRoute(state);
  const contentEditUrl = ContentRoutes.edit(id, getCategoryId(state));
  if (getIsEmail(state)) {
    const revisions = getRevisions(state);
    const revisionTemplateMode = revisions.find(r => r.get('id') === revisionId).getIn(['object', 'emailTemplateMode']);
    const url = revisionTemplateMode === 'DRAG_AND_DROP' ? ContentRoutes.editDragDropEmail(id) : contentEditUrl;
    if (isEmbeddedEmailEditorRoute) {
      return url.replace('edit', 'edit-csp');
    }
  }
  return contentEditUrl;
});
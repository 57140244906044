import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'hub-http/helpers/params';
export const useQueryParams = () => {
  const {
    search
  } = useLocation();
  const queryParams = useMemo(() => {
    if (!search) return {};
    try {
      return parse(search.substring(1));
    } catch (e) {
      console.error(`useQueryParams failed to parse ${search} with error ${e}`);
      return {};
    }
  }, [search]);
  return queryParams;
};
export const useReplaceQueryParams = () => {
  const history = useHistory();
  return useCallback(newParams => {
    history.replace({
      search: stringify(newParams)
    });
  }, [history]);
};
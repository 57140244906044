import I18n from 'I18n';
let emptyGroup;
export const getEmptyGroup = () => {
  emptyGroup = emptyGroup || {
    disabled: true,
    text: I18n.text('salesUI.UITypeahead.noOptions'),
    value: '__EMPTY_GROUP__'
  };
  return emptyGroup;
};
let noMatchesOption;
export const getNoMatchesOption = () => {
  noMatchesOption = noMatchesOption || {
    disabled: true,
    groupIdx: -1,
    text: I18n.text('salesUI.UITypeahead.noMatchesFound'),
    value: '__EMPTY_OPTION__'
  };
  return noMatchesOption;
};
export const getSearchRegExp = str => {
  return new RegExp(str.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
};
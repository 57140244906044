import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
import { getImagesWithEmptyAltText } from './verifyEmptyImageAltTextUtils';
export const verifyEmptyImageAltTextAudit = artifacts => {
  const images = artifacts[GathererKeys.IMAGES];
  const imagesWithEmptyAltText = getImagesWithEmptyAltText(images);
  let status;
  if (imagesWithEmptyAltText.length === 0) {
    status = CompletionStatuses.COMPLETE;
    return {
      status
    };
  } else {
    status = CompletionStatuses.INCOMPLETE;
    return {
      status,
      imagesWithEmptyAltText
    };
  }
};
'use es6';

import { Iterable } from 'immutable';
import { UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE, UPDATE_SUPERSTORE_USER_SETTING, UPDATE_SUPERSTORE_PER_CONTENT_SETTING, FETCH_USER_ATTRIBUTES_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { setAttribute } from 'ContentEditorUI/api/Attributes';
import { getUserSuperstoreInstance, getPerContentSuperstoreInstance } from 'ContentEditorUI/lib/superstore';

// Rough outline...
//
//  - We store various user settings in Redux
//  - Settings can be "global" user settings—settings applicable to any portal the user is attached to—
//    _or_ it can be "per content" user settings—settings only applicable to a specific content id
//  - All of these settings will be cached locally via Superstore
//  - But right now only some of the "global" user settings will be persisted via the UserAttributes service
//    in a HubSpot database (e.g. per-content settings only in Superstore)
//  - So this middleware is reponsible for "watching" redux for changes to user settings, saving them
//    to the correct superstore instance, and then also making API calls to the user attributes service
//    to persist those settings we want to be fully saved rather than merely cached
//  - Note, if some setting should be specific to one of the editors (e.g. just pages or just blog),
//    then it should have a key prefixed the app name (e.g. `pages.somePageSpecificSetting`)
//
// On app load we'll initialize things by...
//  - Kicking off an early API request to user attributes, to get every attribute we care about
//  - Open connections to superstore, and prefill user settings redux state with data in superstore
//  - When that early request finishes, update user settings redux state, which will automatically
//    update the superstore cache as well.
//
// ps: Each individual check/selector can decide whether it blocks and waits until the user attributes
//     are fetched _or_ whether it is fine to only optimistically look at superstore. In most (all?)
//     cases the "optimistic" approach is probably fine, particularly since a majority of the time
//     the user attributes fetch will have finished and be available in redux before other longer/bigger
//     API requests finish.

const setEditorUserAttributeViaApi = (key, value) => {
  // Value should already be "stringify-ied" by this point
  return setAttribute(key, value).catch(e => {
    // Intentionally a silent error since not really useful/actionable to the customer
    //
    // Though, one could argue that a user attributes API failure is a bit more important to
    // show in the UI than IndexedDB/localStorage failures.
    console.error(`Error while persisting user attribute ${key}=${value}`, e);
  });
};
const convertFromImmutableIfNeeded = value => Iterable.isIterable(value) ? value.toJS() : value;
const makeSuperStoreCacheError = key => {
  return e => {
    console.error(`Error whhile trying to cache attribute ${key}`, e);
  };
};
const cacheUserSettingInSuperstore = (key, value) => {
  getUserSuperstoreInstance().then(store => {
    return store.set(key, convertFromImmutableIfNeeded(value)).catch(makeSuperStoreCacheError(key));
  }).catch(e => {
    // Intentionally a silent error since not really useful/actionable to the customer
    console.error(`Error while setting user attribute ${key}=${value}`, e);
  });
};
const cachePerContentSettingInSuperstore = (contentId, key, value) => {
  getPerContentSuperstoreInstance(contentId).then(store => {
    return store.set(key, convertFromImmutableIfNeeded(value)).catch(makeSuperStoreCacheError(key));
  }).catch(e => {
    // Intentionally a silent error since not really useful/actionable to the customer
    console.error(`Error while setting per content (${contentId}) attribute ${key}=${value}`, e);
  });
};
const cacheAllUserAttributesInSuperstore = userAttributes => {
  getUserSuperstoreInstance().then(store => {
    const setPromises = Object.keys(userAttributes).map(key => {
      return store.set(key, userAttributes[key]);
    });
    return Promise.all(setPromises).catch(e => {
      console.error('Error in setting all user attribtues in superstore', e);
    });
  }).catch(e => {
    // Intentionally a silent error since not really useful/actionable to the customer
    console.error(`Error while loading user attributes into cache`, e);
  });
};
function syncAndCacheUserSettingsMiddleware({
  contentId
}) {
  if (contentId == null) {
    throw new Error('No contentId set in syncAndCacheUserSettingsMiddleware setup');
  }
  return () => next => action => {
    const result = next(action);
    if (action) {
      const {
        type,
        key,
        value,
        stringifiedValue,
        parsedAttributes
      } = action;

      // Settings to "permanently" store in UserAttributes DB and cache locally in superstore
      if (type === UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE) {
        setEditorUserAttributeViaApi(key, stringifiedValue != null ? stringifiedValue : value);
        cacheUserSettingInSuperstore(key, value);
      }

      // Settings only cached locally in superstore (not worth storing in UserAttributes DB)
      else if (type === UPDATE_SUPERSTORE_USER_SETTING) {
        cacheUserSettingInSuperstore(key, value);
      } else if (type === UPDATE_SUPERSTORE_PER_CONTENT_SETTING) {
        cachePerContentSettingInSuperstore(contentId, key, value);
      }

      // Update superstore with results from user attributes. Note, this only syncs the attributes
      // to superstore's DB. The locallyCachedSettings reducer has already "synchronously" been updated
      // by this point
      if (type === FETCH_USER_ATTRIBUTES_SUCCEEDED) {
        cacheAllUserAttributesInSuperstore(parsedAttributes);
      }
    }
    return result;
  };
}
export default syncAndCacheUserSettingsMiddleware;
// @ts-expect-error no type config yet
import Sanitize from 'sanitize';
export function configureSanitize(dom) {
  if (!Sanitize.Config) {
    Sanitize.Config = {};
  }
  Sanitize.Config.RELAXED = {
    dom,
    elements: ['a', 'b', 'blockquote', 'br', 'caption', 'cite', 'code', 'col', 'colgroup', 'dd', 'dl', 'dt', 'em', 'figure', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'li', 'ol', 'p', 'pre', 'q', 'small', 'strike', 'strong', 'sub', 'sup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'u', 'ul', 'font', 'div', 'span'],
    attributes: {
      __ALL__: ['style'],
      a: ['href', 'title', 'target'],
      blockquote: ['cite'],
      col: ['span', 'width'],
      colgroup: ['span', 'width'],
      figure: ['style'],
      ol: ['start', 'type'],
      q: ['cite'],
      table: ['summary', 'width'],
      td: ['abbr', 'axis', 'colspan', 'rowspan', 'width'],
      th: ['abbr', 'axis', 'colspan', 'rowspan', 'scope', 'width'],
      ul: ['type'],
      font: ['size'],
      span: ['data-at-mention', 'data-owner-id']
    },
    add_attributes: {
      a: {
        rel: 'nofollow noopener noreferrer',
        target: '_blank'
      }
    },
    protocols: {
      a: {
        href: ['ftp', 'http', 'https', 'mailto', Sanitize.RELATIVE]
      },
      blockquote: {
        cite: ['http', 'https', Sanitize.RELATIVE]
      },
      img: {
        src: ['http', 'https', Sanitize.RELATIVE]
      },
      q: {
        cite: ['http', 'https', Sanitize.RELATIVE]
      }
    },
    remove_contents: ['style', 'script']
  };
  Sanitize.Config.STRICT = {
    dom,
    elements: ['a', 'b', 'br', 'em', 'i', 'p', 'small', 'strong', 'span'],
    attributes: {
      __ALL__: ['style'],
      a: ['href', 'title', 'target']
    },
    add_attributes: {
      a: {
        rel: 'nofollow noopener noreferrer',
        target: '_blank'
      }
    },
    protocols: {
      a: {
        href: ['ftp', 'http', 'https', 'mailto', Sanitize.RELATIVE]
      }
    },
    remove_contents: ['style', 'script']
  };
  Sanitize.Config.TEXTONLY = {
    elements: []
  };
  return Sanitize;
}
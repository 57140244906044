'use es6';

export const FORM_DEFAULT_SUB_TYPE_VALUE = 'default';
export const FORM_BUTTON_SUB_TYPE_VALUE = 'button';
export const FORM_FIELDS_SUB_TYPE_VALUE = 'input';
export const FORM_LABEL_SUB_TYPE_VALUE = 'label';
export const FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE = 'buttonAlignment';
export const FORM_RICH_TEXT_SUB_TYPE_VALUE = 'richText';
export const FORM_INPUT_ERROR_SUB_TYPE_VALUE = 'inputError';
export const FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE = 'fieldAlignment';
export const FORM_HELP_TEXT_SUB_TYPE_VALUE = 'helpText';
export const FORM_SUB_TYPES = [FORM_BUTTON_SUB_TYPE_VALUE, FORM_FIELDS_SUB_TYPE_VALUE, FORM_LABEL_SUB_TYPE_VALUE, FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE, FORM_RICH_TEXT_SUB_TYPE_VALUE, FORM_INPUT_ERROR_SUB_TYPE_VALUE, FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE, FORM_HELP_TEXT_SUB_TYPE_VALUE];
export const ALL_FORM_CSS_TYPES = [FORM_DEFAULT_SUB_TYPE_VALUE, FORM_BUTTON_SUB_TYPE_VALUE, FORM_FIELDS_SUB_TYPE_VALUE, FORM_LABEL_SUB_TYPE_VALUE, FORM_BUTTON_ALIGNMENT_SUB_TYPE_VALUE, FORM_RICH_TEXT_SUB_TYPE_VALUE, FORM_INPUT_ERROR_SUB_TYPE_VALUE, FORM_FIELD_ALIGNMENT_SUB_TYPE_VALUE, FORM_HELP_TEXT_SUB_TYPE_VALUE];
export const FORM_DEFAULT_SUB_TYPE_LABEL_KEY = 'form';
export const FORM_BUTTON_SUB_TYPE_LABEL_KEY = 'button';
export const FORM_FIELDS_SUB_TYPE_LABEL_KEY = 'input';
export const styleOptions = {
  'font-family': [{
    value: 'sans-serif',
    labelKey: 'sans-serif'
  }, {
    value: 'Arial, sans-serif',
    labelKey: 'arial'
  }, {
    value: 'Times New Roman, serif',
    labelKey: 'times'
  }, {
    value: 'Helvetica, Trebuchet MS, sans-serif',
    labelKey: 'helvetica'
  }, {
    value: 'Tahoma, Geneva, sans-serif',
    labelKey: 'tahoma'
  }, {
    value: 'Georgia, serif',
    labelKey: 'georgia'
  }, {
    value: 'Palatino Linotype, Book Antiqua, Palatino, serif',
    labelKey: 'palatino'
  }, {
    value: 'Impact, Charcoal, sans-serif',
    labelKey: 'impact'
  }, {
    value: 'Verdana, Geneva, sans-serif',
    labelKey: 'verdana'
  }, {
    value: 'Courier New, Courier, monospace',
    labelKey: 'courierNew'
  }, {
    value: 'Lucida Console, Monaco, monospace',
    labelKey: 'lucidaConsole'
  }],
  'text-align': [{
    value: '',
    labelKey: 'clear'
  }, {
    value: 'left',
    labelKey: 'left'
  }, {
    value: 'center',
    labelKey: 'center'
  }, {
    value: 'right',
    labelKey: 'right'
  }, {
    value: 'justify',
    labelKey: 'justify'
  }],
  'border-style': [{
    value: 'none',
    labelKey: 'none'
  }, {
    value: 'solid',
    labelKey: 'solid'
  }, {
    value: 'dashed',
    labelKey: 'dashed'
  }, {
    value: 'dotted',
    labelKey: 'dotted'
  }, {
    value: 'double',
    labelKey: 'double'
  }, {
    value: 'groove',
    labelKey: 'groove'
  }, {
    value: 'inset',
    labelKey: 'inset'
  }, {
    value: 'outset',
    labelKey: 'outset'
  }, {
    value: 'ridge',
    labelKey: 'ridge'
  }],
  'font-weight': ['lighter', 'normal', 'bold'],
  formChildren: [{
    value: FORM_DEFAULT_SUB_TYPE_VALUE,
    labelKey: FORM_DEFAULT_SUB_TYPE_LABEL_KEY
  }, {
    value: FORM_BUTTON_SUB_TYPE_VALUE,
    labelKey: FORM_BUTTON_SUB_TYPE_LABEL_KEY
  }, {
    value: FORM_FIELDS_SUB_TYPE_VALUE,
    labelKey: FORM_FIELDS_SUB_TYPE_LABEL_KEY
  }]
};
export const fontStyleFields = {
  'font-family': {
    type: 'select'
  },
  'font-size': {
    type: 'number-px',
    class: 'style-field-step-wrapper'
  },
  color: {
    type: 'color'
  },
  'text-align': {
    type: 'select'
  },
  'line-height': {
    type: 'number-allow-unitless',
    class: 'style-field-step-wrapper'
  },
  'font-weight': {
    type: 'pill-buttons',
    class: 'style-field-wide'
  },
  'font-style': {
    type: 'button',
    class: 'style-field-narrow'
  },
  'font-variant': {
    type: 'button',
    class: 'style-field-narrow'
  }
};
export const otherStyleFields = {
  'background-color': {
    type: 'color',
    class: 'style-field-color'
  },
  opacity: {
    type: 'number-opacity'
  },
  'border-radius': {
    type: 'number-px',
    class: 'style-field-step-wrapper'
  },
  'border-color': {
    type: 'color',
    class: 'style-field-color'
  },
  'border-style': {
    type: 'select'
  },
  'border-width': {
    type: 'number-px',
    class: 'style-field-step-wrapper'
  }
};
export const CENTER_CONTENT_BUTTON = 'CENTER_CONTENT_BUTTON';
export const FULL_WIDTH_BUTTON = 'FULL_WIDTH_BUTTON';
export const DEPRECATED_STYLES = ['font-variant', 'font-weight', 'opacity'];
import { i18nWithNameSpace } from '../utils/i18n';
export const ANCHOR_CLASSNAME = 'mce-hs-anchor';
export const TEMP_ANCHOR_ATTRIBUTE = 'data-insert-anchor';
export const HS_ANCHOR_ATTRIBUTE = 'data-hs-anchor'; // Attribute that will persist through serialization

export const i18nKey = i18nWithNameSpace('TinymcePlugins.hsanchor');
export const AnchorBlockSelectors = {
  tier1: `iframe, video, div[data-hs-callout-type]`,
  tier2: `blockquote, pre`,
  tier3: `p, h1, h2, h3, h4, h5, h6`
};
export const ANCHOR_SETTINGS = 'hsanchor';
export const hsanchorConfig = {
  GET_ALL_CONTENT_ANCHORS: 'getAllContentAnchors'
};
import { isObject } from 'ContentUtils/helpers/ObjectHelpers';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { InternalFields, ColumnTypes } from '../constants/types';
import DefaultInstanceColumns from '../constants/enums/DefaultInstanceColumns';
import { AdvancedSettingsColumns } from '../constants/enums';
export function getPublishedTablesSortedByPublishedTime(tables) {
  return tables.filter(table => isObject(table) && table.publishedAt > 0).sort(table => table.publishedAt);
}
export function getTablesUsedForPages(tables) {
  return tables.filter(table => table.useForPages);
}
export function hasForeignIdColumn(table) {
  return table.columns.some(column => column.type === ColumnTypes.FOREIGN_ID);
}
export function mapModuleValueToHubdbValue(value, fieldType, field) {
  switch (fieldType) {
    case FieldTypes.URL:
      {
        return value.href;
      }
    case FieldTypes.VIDEO:
      {
        return value.player_id;
      }
    case FieldTypes.IMAGE:
      {
        return {
          url: value.src,
          height: value.height,
          width: value.width,
          type: 'image'
        };
      }
    case FieldTypes.BOOLEAN:
      {
        return value ? 1 : 0;
      }
    case FieldTypes.HUBDB_ROW:
      {
        return value.toJS();
      }
    case FieldTypes.LOCATION:
      {
        return Object.assign({}, value, {
          type: FieldTypes.LOCATION
        });
      }
    case FieldTypes.CHOICE:
      {
        if (field.multiple) {
          return value.map((option_id, order) => {
            const selectedChoice = field.choices && field.choices.find(choice => choice[0] === option_id.toString());
            return {
              id: option_id,
              order,
              name: selectedChoice && selectedChoice[1],
              type: 'option'
            };
          });
        } else {
          const selectedChoice = field.choices && field.choices.find(choice => choice[0] === value.toString());
          return {
            id: value,
            name: selectedChoice && selectedChoice[1],
            type: 'option'
          };
        }
      }
    default:
      return value;
  }
}
export function getPropertyFromInternalField(fieldId) {
  if (fieldId === InternalFields.hs_child_table_id) return 'childTableId';
  if (fieldId === InternalFields.hs_name) return 'name';
  return 'path';
}
const INVALID_SMART_QUOTES = /\u2018|\u2019|\u201c|\u201d/gi;
const INVALID_SMART_AND_CJK_PUNCTUATION = /[\u2014]|\u2026|[\u3000-\u303f]|[\u30fb-\u30ff]/gi;
const INVALID_SLUG_CHAR_MATCHER = /[^a-zA-Z0-9\-_/\s.\u00AE-\ud7ff]/gi;
export const slugify = str => {
  let slugified;
  // UTF8-sensitive character cleanup
  slugified = str.replace(INVALID_SMART_QUOTES, '');
  slugified = slugified.replace(INVALID_SMART_AND_CJK_PUNCTUATION, '-');
  slugified = slugified.replace(INVALID_SLUG_CHAR_MATCHER, '');
  slugified = slugified.replace(/_/gi, '-');
  slugified = slugified.replace(/\s/gi, '-');
  return slugified.toLowerCase();
};
export const STANDARD_COLUMNS = DefaultInstanceColumns;
export const ADVANCED_COLUMNS = AdvancedSettingsColumns;
export function getSettingsSectionName(propertyName) {
  return ADVANCED_COLUMNS[propertyName] ? 'advanced' : 'general';
}
export function getSettingsProperties(contentTypeColumns) {
  if (contentTypeColumns) {
    return contentTypeColumns.filter(property => property.editorVisibility === 'SETTINGS' || property.editorVisibility === 'BOTH');
  }
  return null;
}
export function getIsAdvancedColumn(propertyName) {
  return !!ADVANCED_COLUMNS[propertyName];
}
export function getIsStandardColumn(propertyName) {
  return !!STANDARD_COLUMNS[propertyName];
}
import { useContext } from 'react';
import TourContext from 'ui-shepherd-react/contexts/TourContext';
export const useTourContext = () => {
  return useContext(TourContext);
};
export const useIsLastStep = () => {
  const {
    tour
  } = useTourContext();
  const {
    steps
  } = tour.getConfig();
  return steps.indexOf(tour.getStep()) === steps.length - 1;
};
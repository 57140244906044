export const NAV_TABS_SHOW_SIDEBAR = {
  content: true,
  design: true,
  settings: false,
  optimize: true,
  publishing: false,
  preview: true,
  revisions: true
};
export const PATAGONIA_ADD_PANEL_CATEGORIES = {
  MODULES: 'MODULES',
  SECTIONS: 'SECTIONS',
  LAYOUTS: 'LAYOUTS'
};
export const PATAGONIA_ADD_PANEL_SUB_CATEGORIES = {
  MODULES: {
    THEME: 'THEME',
    COMMON: 'COMMON',
    ALL: 'ALL'
  },
  SECTIONS: {
    SAVED: 'SAVED',
    THEME: 'THEME',
    NO_THEME: 'NO_THEME'
  },
  LAYOUTS: {
    NONE: 'NONE',
    DEFAULT: 'NONE'
  }
};
// Default to the first instance, but can be overridden in sidebarStateReducer
export const PATAGONIA_DEFAULT_SELECTED_SUB_CATEGORIES = {
  MODULES: 'THEME',
  SECTIONS: 'SAVED',
  LAYOUTS: 'NONE'
};
export const PATAGONIA_ADD_PANEL_CATEGORIES_YAML_MAPPING = {
  MODULES: 'patagonia.sidebar.addPanel.categories.modules',
  MODULES_WITH_COUNT: 'patagonia.sidebar.addPanel.categories.modulesWithCount',
  SECTIONS: 'patagonia.sidebar.addPanel.categories.sections',
  SECTIONS_WITH_COUNT: 'patagonia.sidebar.addPanel.categories.sectionsWithCount',
  LAYOUTS: 'patagonia.sidebar.addPanel.categories.layouts',
  LAYOUTS_WITH_COUNT: 'patagonia.sidebar.addPanel.categories.layoutsWithCount'
};
export const PATAGONIA_ADD_PANEL_SUB_CATEGORIES_YAML_MAPPING = {
  THEME: 'patagonia.sidebar.addPanel.subcategories.theme',
  THEME_WITH_COUNT: 'patagonia.sidebar.addPanel.subcategories.themeWithCount',
  COMMON: 'patagonia.sidebar.addPanel.subcategories.common',
  ALL: 'patagonia.sidebar.addPanel.subcategories.all',
  ALL_WITH_COUNT: 'patagonia.sidebar.addPanel.subcategories.allWithCount',
  SAVED: 'patagonia.sidebar.addPanel.subcategories.saved'
};
export const PATAGONIA_TOOLS_MENU = [{
  panelName: 'optimizer',
  modal: false
}, {
  panelName: 'publish',
  modal: true
}, {
  panelName: 'test',
  modal: false
}];

//for phase 1 of module categorizations - for future phase icon key would most likely come from BE
export const MODULE_CATEGORIES_ICON_MAP = {
  TEXT: 'richText',
  MEDIA: 'insertVideo',
  FORMS_AND_BUTTONS: 'ctas',
  BODY_CONTENT: 'imageAlignmentLeft',
  FUNCTIONALITY: 'bulletList',
  DESIGN: 'backgroundColor',
  BLOG: 'blog',
  COMMERCE: 'cart',
  SOCIAL: 'lists',
  ALL: 'alignJustify',
  THEME: 'theme'
};
export const SIDEBAR_CATEGORIES_ICON_MAP = {
  MODULES: 'customModules',
  SECTIONS: 'partial',
  LAYOUTS: 'spacer'
};
export const PANELS_WITHOUT_SUBCATEGORY = ['LAYOUTS'];

//TODO type section when migrate sectionsReducer to immer
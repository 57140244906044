import { createSelector } from 'reselect';
import { SUCCEEDED, FAILED, PENDING } from 'ContentUtils/constants/RequestStatus';
export const getPaymentEligibility = state => state.resources.payments.paymentsEligibility;
export const getPaymentEligibilityFetchStatus = state => state.resources.payments.paymentsEligibilityRequestStatus;
export const getHasFetchedPaymentEligibility = createSelector([getPaymentEligibilityFetchStatus], paymentEligibilityFetchStatus => {
  return paymentEligibilityFetchStatus === SUCCEEDED || paymentEligibilityFetchStatus === FAILED;
});
export const getPayments = state => state.resources.payments.orderedPayments;
export const getPayment = (state, props) => state.resources.payments.payments.byId[props.id];
export const getPaymentsById = state => state.resources.payments.payments.byId;
export const getPaymentByUrl = (state, props) => state.resources.payments.payments.byUrl[props.url];
export const getPaymentFetchStatus = (state, props) => state.resources.payments.requestStatus[props.id];
export const getPaymentsFetchStatus = state => state.resources.payments.paymentsRequestStatus;
export const getIsLoading = createSelector([getPaymentEligibilityFetchStatus, getPaymentsFetchStatus, getHasFetchedPaymentEligibility], (paymentEligibilityFetchStatus, paymentsFetchStatus, hasFetchedPaymentEligibility) => {
  return paymentEligibilityFetchStatus === PENDING || hasFetchedPaymentEligibility && paymentsFetchStatus === PENDING;
});
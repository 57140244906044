const LIMIT = 1000;
const SENTENCE_REGEX = /[.?!]\s[^a-z]/g;
const HTML_REGEX = /<[^>]*>/g;
const SYLLABLE_REGEX = /[aiouy]+e*|e(?!d$|ly).|[td]ed|le$/g;
const punctuation = ['!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_', '`', '{', '|', '}', '~'];
const fleschReadabilityBaseConstants = {
  base: 206.835,
  sentenceLength: 1.015,
  syllablesPerWord: 84.6,
  syllableThreshold: 3
};
const getLegacyRound = (number, precision = 0) => {
  const k = Math.pow(10, precision);
  return Math.floor(number * k + 0.5 * Math.sign(number)) / k;
};
const getCharCount = text => {
  if (LIMIT > 0) text = text.split(' ').slice(0, LIMIT).join(' ');
  text = text.replace(/\s/g, '');
  return text.length;
};
const removePunctuation = text => {
  return text.split('').filter(c => punctuation.indexOf(c) === -1).join('');
};
const getLetterCount = text => {
  if (LIMIT > 0) text = text.split(' ').slice(0, LIMIT).join(' ');
  text = text.replace(/\s/g, '');
  return removePunctuation(text).length;
};
const getLexiconCount = (text, ignoreSample = false) => {
  if (!ignoreSample && LIMIT > 0) text = text.split(' ').slice(0, LIMIT).join(' ');
  text = removePunctuation(text);
  const lexicon = text.split(' ').length;
  return lexicon;
};
const getWords = text => {
  if (LIMIT > 0) text = text.split(' ').slice(0, LIMIT).join(' ');
  text = text.toLowerCase();
  text = removePunctuation(text);
  const words = text.split(' ');
  return words;
};
const getSyllableCount = text => {
  const syllables = getWords(text).reduce((a, c) => {
    return a + (c.match(SYLLABLE_REGEX) || [1]).length;
  }, 0);
  return syllables;
};
const getSentenceCount = text => {
  if (LIMIT > 0) text = text.split(' ').slice(0, LIMIT).join(' ');
  let ignoreCount = 0;
  const sentences = text.split(SENTENCE_REGEX);
  sentences.forEach(s => {
    if (getLexiconCount(s, true) <= 2) {
      ignoreCount += 1;
    }
  });
  return Math.max(1, sentences.length - ignoreCount);
};
const getAvgSentenceLength = text => {
  const avg = getLexiconCount(text) / getSentenceCount(text);
  return getLegacyRound(avg, 2);
};
const getAvgSyllablesPerWord = text => {
  const avg = getSyllableCount(text) / getLexiconCount(text, true);
  return getLegacyRound(avg, 2);
};
const getAvgLettersPerWord = text => {
  const avg = getLetterCount(text) / getLexiconCount(text, true);
  return getLegacyRound(avg, 2);
};
const getFleschReadingEase = text => {
  const sentenceLength = getAvgSentenceLength(text);
  const syllablesPerWord = getAvgSyllablesPerWord(text);
  return getLegacyRound(fleschReadabilityBaseConstants.base - fleschReadabilityBaseConstants.sentenceLength * sentenceLength - fleschReadabilityBaseConstants.syllablesPerWord * syllablesPerWord, 2);
};
const getFleschKincaidGrade = text => {
  const sentenceLength = getAvgSentenceLength(text);
  const syllablesPerWord = getAvgSyllablesPerWord(text);
  return getLegacyRound(0.39 * sentenceLength + 11.8 * syllablesPerWord - 15.59, 2);
};
const getReadingTime = text => {
  const wordsPerSecond = 4.17;
  return getLegacyRound(getLexiconCount(text, false) / wordsPerSecond, 2);
};
export function getReadabilityMetrics(text, withHtml = false) {
  let textToProcess = text;
  if (withHtml) {
    textToProcess = text.replace(HTML_REGEX, '');
  }
  return {
    fleschReadingEase: getFleschReadingEase(textToProcess),
    fleschKincaidGrade: getFleschKincaidGrade(textToProcess),
    readingTime: getReadingTime(textToProcess),
    averageSentenceLength: getAvgSentenceLength(textToProcess),
    characterCount: getCharCount(textToProcess),
    wordCount: getLexiconCount(textToProcess),
    wordLength: getAvgLettersPerWord(textToProcess)
  };
}
/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-declarations */

export default function logError(location, error, additionalProperties) {
  if (window.newrelic) {
    const {
      errorCode,
      errorMessage,
      message,
      responseJSON,
      status,
      data
    } = error;
    window.newrelic.addPageAction('ui-addon-upgrades-error', Object.assign({
      location,
      errorCode,
      errorMessage,
      message,
      responseJSON,
      status,
      data
    }, additionalProperties));
  }
}
const hasGate = (auth, gateName) => {
  if (!auth || !auth.gates) {
    return false;
  }
  return auth.gates.includes(gateName);
};
const hasScope = (auth, scopeName) => {
  if (!auth || !auth.user || !auth.user.scopes) {
    return false;
  }
  return auth.user.scopes.includes(scopeName);
};
export const filterUnauthorizedRecommendations = (recommendations, auth) => {
  const filteredRecommendations = recommendations.filter(rec => {
    const {
      gates,
      scopes
    } = rec;
    let isUngated = true;
    let hasScopeAccess = true;
    if (gates) {
      isUngated = gates.every(gate => hasGate(auth, gate));
    }
    if (scopes) {
      hasScopeAccess = scopes.every(scope => hasScope(auth, scope));
    }
    return isUngated && hasScopeAccess;
  });
  return filteredRecommendations;
};
import { fetchCategorizedModules, fetchCategoryTypes } from '../../api/CategorizationApi';
import { getShouldFetchCategorizedModules, getShouldFetchCategoryTypes } from '../selectors/categorizationSelectors';

// used with content/categories/v1/categories/meta
export const FETCH_CATEGORY_TYPES_REQUESTED = 'FETCH_CATEGORY_TYPES_REQUESTED';
export const FETCH_CATEGORY_TYPES_SUCCEEDED = 'FETCH_CATEGORY_TYPES_SUCCEEDED';
export const FETCH_CATEGORY_TYPES_FAILED = 'FETCH_CATEGORY_TYPES_FAILED';

// Used with content/categories/v1/categories/{assetType}
export const FETCH_CATEGORIZED_MODULES_REQUESTED = 'FETCH_CATEGORIZED_MODULES_REQUESTED';
export const FETCH_CATEGORIZED_MODULES_SUCCEEDED = 'FETCH_CATEGORIZED_MODULES_SUCCEEDED';
export const FETCH_CATEGORIZED_MODULES_FAILED = 'FETCH_CATEGORY_TYPES_FAILED';
const fetchCategoryTypesAction = () => dispatch => {
  dispatch({
    type: FETCH_CATEGORY_TYPES_REQUESTED
  });
  fetchCategoryTypes().then(response => dispatch({
    type: FETCH_CATEGORY_TYPES_SUCCEEDED,
    payload: response
  })).catch(e => {
    // TODO: Figure out actual error when we start using this.
    console.error(e);
    dispatch({
      type: FETCH_CATEGORY_TYPES_FAILED
    });
  });
};
const fetchCategorizedModulesAction = () => dispatch => {
  dispatch({
    type: FETCH_CATEGORIZED_MODULES_REQUESTED
  });
  fetchCategorizedModules().then(response => dispatch({
    type: FETCH_CATEGORIZED_MODULES_SUCCEEDED,
    payload: response
  })).catch(e => {
    // TODO: Figure out actual error when we start using this.
    console.error(e);
    dispatch({
      type: FETCH_CATEGORIZED_MODULES_FAILED
    });
  });
};
export const fetchCategorizationData = () => (dispatch, getState) => {
  const state = getState();
  const shouldFetchCategoryTypes = getShouldFetchCategoryTypes(state);
  const shouldFetchCategorizedModules = getShouldFetchCategorizedModules(state);
  if (shouldFetchCategoryTypes) {
    dispatch(fetchCategoryTypesAction());
  }
  if (shouldFetchCategorizedModules) {
    dispatch(fetchCategorizedModulesAction());
  }
};
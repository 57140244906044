'use es6';

import { createSelector } from 'reselect';
import { getDefaultType, getUneditableModules, getCellOrRowByIdHelper, getAllLayoutSectionTreesAsMap, getAllModuleKindsHelper, getModuleOrUneditableOrFakeModuleByIdHelper, getModulesByNameFromPostBody, getModulesWithSmartContent } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getSchemaForModule } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getModulesWithFollowupEmails } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { Iterable } from 'immutable';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
const getSelectionState = basicSelector(state => state.selection);
export const getHasSelectionState = createSelector([getSelectionState], selectionState => !!selectionState);
export const getPrevSelectedItemId = createSelector(getSelectionState, selectionState => selectionState.getIn(['previous', 'selectedId']));
export const getSelectedItemId = createSelector(getSelectionState, selectionState => selectionState.getIn(['current', 'selectedId']));
export const getSelectedContainerId = createSelector(getSelectionState, selectionState => selectionState.getIn(['current', 'selectedContainerId']));
export const getIsAnythingSelected = createSelector([getSelectedItemId], selectedId => !!selectedId);
export const getSelectedModule = createSelector(getAllModuleKindsHelper, getSelectedItemId, (allModuleKinds, selectedId) => getModuleOrUneditableOrFakeModuleByIdHelper(allModuleKinds, selectedId));
export const getHasSelectedModule = createSelector(getSelectedModule, selectedModule => !!selectedModule);
export const getSelectedModuleName = createSelector([getSelectedModule], selectedModule => selectedModule ? selectedModule.get('name') : undefined);
export const getSelectedModuleSmartDefinitionId = createSelector([getSelectedModule], selectedModule => selectedModule ? selectedModule.get('definition_id') : undefined);
export const getIsBuiltinRichTextModuleSelected = basicSelectorWithStats(state => {
  const selectedModuleName = getSelectedModuleName(state);
  const defaultType = getDefaultType(state, selectedModuleName);
  return defaultType === 'rich_text';
});
export const getIsBuiltinFormModuleSelected = basicSelectorWithStats(state => {
  const selectedModuleName = getSelectedModuleName(state);
  const defaultType = getDefaultType(state, selectedModuleName);
  return defaultType === 'form';
});
export const getSelectedModuleInTree = createSelector(getSelectedModule, getAllLayoutSectionTreesAsMap, (module, layoutSectionTrees) => {
  if (module && module.has('layout_section_id')) {
    const tree = layoutSectionTrees.get(module.get('layout_section_id'));
    if (tree) {
      return tree.findCell(module.get('name'));
    }
  }
  return undefined;
});
export const getHasSelectedUneditableModule = createSelector(getUneditableModules, getSelectedModuleName, (uneditableModules, moduleName) => !!uneditableModules.has(moduleName));

// Can we find this module in the inpage preview?
// If the developer has removed the wrapper or used `export_to_template_context`
// we won't be able to find it in the dom, so we want to show the sidebar editor in these cases
export const getSelectedModuleIsTargetableInPreview = createSelector(getSelectedModule, module => {
  return !module || !module.get('has_no_wrapper') && !module.get('export_to_template_context');
});
export const getSelectedRowOrColumn = createSelector(getSelectedItemId, getSelectedContainerId, getAllLayoutSectionTreesAsMap, (id, containerId, layoutSectionTrees) => {
  if (!id || !containerId) {
    return null;
  }
  const tree = layoutSectionTrees.get(containerId);
  if (tree) {
    const node = getCellOrRowByIdHelper(tree, id);
    // Sometimes on undo, selectedItemId still exists when the module itself has been removed
    // so we want to check if the node actually exists in the tree
    if (node && !node.isModule()) {
      return node;
    }
  }
  return null;
});
export const getHasRowOrColumnSelected = createSelector(getSelectedRowOrColumn, selectedRowOrColumn => !!selectedRowOrColumn);
export const getSelectedItemInTree = createSelector(getSelectedModuleInTree, getSelectedRowOrColumn, (module, rowOrColumn) => module || rowOrColumn);
export const getLayoutStylesForSelectedItem = createSelector(getSelectedItemInTree, node => node != null ? node.getLayoutStyleData() : null);
export const getSelectionInProgress = createSelector([getSelectionState], selectionState => selectionState.get('selectionInProgress'));
export const getSelectedModuleSchema = basicSelectorWithStats(state => {
  const selectedModule = getSelectedModule(state);
  if (!selectedModule) {
    return null;
  }
  const selectedModuleSchema = getSchemaForModule(state, selectedModule);
  return selectedModuleSchema;
});
export const getSelectedModuleBodyAsJSObject = createSelector([getSelectedModule], selectedModule => {
  if (!selectedModule) {
    return null;
  }
  const moduleBody = selectedModule.get('body');
  if (!moduleBody) {
    return null;
  }
  const isBodyImmutable = Iterable.isIterable(moduleBody);
  return isBodyImmutable ? moduleBody.toJS() : moduleBody;
});
const getFormId = (state, props) => props.formId;

/*
If the selected module contains a form field that has a form selected,
this selector will find out of there's already a followup email selected
for that form in another form field in the same module or another module
*/
export const makeGetFollowupEmailSelectedForForm = () => createSelector([getSelectedItemId, getModulesWithFollowupEmails, getFormId, getModulesWithSmartContent], (selectedModuleId, modulesWithFollowupEmails, formId, modulesWithSmartContent) => {
  if (!selectedModuleId || !modulesWithFollowupEmails || !formId) {
    return null;
  }
  for (const moduleId of Object.keys(modulesWithFollowupEmails)) {
    const formFieldsWithFollowupEmails = modulesWithFollowupEmails[moduleId] || [];
    for (const formFieldData of formFieldsWithFollowupEmails) {
      if (formFieldData.formId === formId && formFieldData.emailId) {
        let smartObject = null;
        const {
          criterionId
        } = formFieldData;
        if (criterionId) {
          // If the followup email was added to a form in a smart rule, provide the whole smart object
          // because updateSmartView needs all of the info in the object, not just the criterion ID
          const smartObjects = modulesWithSmartContent.getIn([moduleId, 'smart_objects']);
          smartObject = smartObjects ? smartObjects.toJS().find(_smartObject => _smartObject.criterion_id === criterionId) : null;
        }
        return Object.assign({}, formFieldData, {
          moduleId,
          inSameModule: moduleId === selectedModuleId,
          smartObject
        });
      }
    }
  }
  return null;
});
export const getIsPostBodyOrChildModuleSelected = (state, id) => id && (id === 'post_body' || getModulesByNameFromPostBody(state).has(id));
import I18n from 'I18n';
import { UnreachableI18nCaseError } from './i18nUtils';
export const getTranslatedApprovalType = approvalType => {
  switch (approvalType) {
    case 'MARKETING_EMAIL':
      return I18n.text('productApprovals.approvalTypes.MARKETING_EMAIL');
    case 'BLOG_POST':
      return I18n.text('productApprovals.approvalTypes.BLOG_POST');
    case 'SITE_PAGE':
      return I18n.text('productApprovals.approvalTypes.SITE_PAGE');
    case 'LANDING_PAGE':
      return I18n.text('productApprovals.approvalTypes.LANDING_PAGE');
    case 'DEAL':
      return I18n.text('productApprovals.approvalTypes.DEAL');
    case 'KNOWLEDGE_ARTICLE':
      return I18n.text('productApprovals.approvalTypes.KNOWLEDGE_ARTICLE');
    default:
      throw new UnreachableI18nCaseError(approvalType);
  }
};
export const getTranslatedApprovalTypeCapitalised = approvalType => {
  switch (approvalType) {
    case 'MARKETING_EMAIL':
      return I18n.text('productApprovals.approvalTypesCapitalised.MARKETING_EMAIL');
    case 'BLOG_POST':
      return I18n.text('productApprovals.approvalTypesCapitalised.BLOG_POST');
    case 'SITE_PAGE':
      return I18n.text('productApprovals.approvalTypesCapitalised.SITE_PAGE');
    case 'LANDING_PAGE':
      return I18n.text('productApprovals.approvalTypesCapitalised.LANDING_PAGE');
    case 'DEAL':
      return I18n.text('productApprovals.approvalTypesCapitalised.DEAL');
    case 'KNOWLEDGE_ARTICLE':
      return I18n.text('productApprovals.approvalTypesCapitalised.KNOWLEDGE_ARTICLE');
    default:
      throw new UnreachableI18nCaseError(approvalType);
  }
};
export const getTranslatedApprovalTypePlural = approvalType => {
  switch (approvalType) {
    case 'MARKETING_EMAIL':
      return I18n.text('productApprovals.approvalTypesPlural.MARKETING_EMAIL');
    case 'BLOG_POST':
      return I18n.text('productApprovals.approvalTypesPlural.BLOG_POST');
    case 'SITE_PAGE':
      return I18n.text('productApprovals.approvalTypesPlural.SITE_PAGE');
    case 'LANDING_PAGE':
      return I18n.text('productApprovals.approvalTypesPlural.LANDING_PAGE');
    case 'DEAL':
      return I18n.text('productApprovals.approvalTypesPlural.DEAL');
    case 'KNOWLEDGE_ARTICLE':
      return I18n.text('productApprovals.approvalTypesPlural.KNOWLEDGE_ARTICLE');
    default:
      throw new UnreachableI18nCaseError(approvalType);
  }
};
import { createSelector } from 'reselect';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { getPagesFromMenu } from '../utils/menus';
import { getPagesCache, getPagesFetchStatus } from './pages';
import { SUCCEEDED } from 'ContentUtils/constants/RequestStatus';
const PAGE_DRAFT_STATUS = 'DRAFT';
export const getMenus = state => state.resources.menus.menus;
const getMenusByName = state => state.resources.menus.menusByName;
export const getMenu = (state, props) => {
  const menusById = getMenus(state);
  const menusByName = getMenusByName(state);
  const {
    id: idOrName
  } = props;
  return menusById[idOrName] || menusByName[idOrName];
};
export const getMenuCache = (state, props) => state.resources.menus.menusCache.menus[props.id];
const getMenusCache = state => state.resources.menus.menusCache.menus;
export const getMenusCacheRequestStatus = state => state.resources.menus.menusCacheRequestStatus;
const getMenusCacheOrder = state => state.resources.menus.menusCache.order;
export const getMenuFetchStatus = (state, props) => state.resources.menus.requestStatus[props.id];
export const getDefaultMenuOptions = createGenericOptionsSelector(getMenus);
export const getMenuOptions = createSelector([getMenusCache, getMenusCacheOrder], (menus, order) => {
  return order.map(id => {
    const menu = menus[id];
    return {
      value: menu.id,
      text: menu.name
    };
  });
});
const getIdForMenu = (state, props) => {
  const {
    id: idOrName
  } = props;
  return idOrName;
};
export const getAllPages = createSelector([getMenus, getMenusByName, getIdForMenu, getPagesCache], (menusById, menusByName, idOrName, livePageData) => {
  const menu = menusById[idOrName] || menusByName[idOrName];
  if (menu && menu.pagesTree) {
    const pages = getPagesFromMenu(menu.pagesTree.children);
    // update page with most current data
    pages.forEach(page => {
      if (page.page_id && livePageData[page.page_id]) {
        // @ts-expect-error No types yet for pages selectors
        page.state = livePageData[page.page_id].state;
      }
    });
    return pages;
  }
  return [];
});
export const getPagesRequestCompleted = createSelector([getPagesFetchStatus], fetchStatus => fetchStatus === SUCCEEDED);
export const getDraftPages = createSelector([getAllPages], allPages => {
  if (!allPages) return [];
  return allPages.filter(page => page.state === PAGE_DRAFT_STATUS);
});
'use es6';

import { createSelector } from 'reselect';
import { getCanUseCustomSections } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getHasAnyLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getHasTheme } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { getCategoryTypes } from 'ContentEditorUI/redux/selectors/categorizationSelectors';
import { getCategoryToModulesMap } from 'ContentEditorUI/js/redux/selectors/categorizedModuleSchemaSelectors';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';

// What do we do when there's no place to add modules (i.e a page with all static modules)?
export const getPatagoniaAddSidebarCategories = createSelector([getCanUseCustomSections, getHasAnyLayoutSections], (canUseCustomSections, hasAnyLayoutSections) => {
  const shouldShowSections = canUseCustomSections && hasAnyLayoutSections;
  return Object.keys(PATAGONIA_ADD_PANEL_CATEGORIES).filter(category => {
    switch (category) {
      case PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS:
        return shouldShowSections;
      case PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS:
        return hasAnyLayoutSections;
      default:
        return true;
    }
  });
});
export const getNewSubCategoriesForModulesCategory = createSelector([getCategoryTypes, getCategoryToModulesMap, getHasTheme], (categoryTypes, categoryToModulesMap, hasTheme) => {
  const themeSubcategory = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.THEME;
  const hasThemeModules = hasTheme && categoryToModulesMap[themeSubcategory] && categoryToModulesMap[themeSubcategory].length > 0;
  const allModulesSubCategories = hasThemeModules ? [themeSubcategory] : [];
  categoryTypes.forEach(categoryType => {
    const categoryName = categoryType.name;
    if (categoryToModulesMap[categoryName] && categoryToModulesMap[categoryName].length) {
      allModulesSubCategories.push(categoryName);
    }
  });
  allModulesSubCategories.push(PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.ALL);
  return allModulesSubCategories;
});
export const getPatagoniaAddSidebarSubCategories = createSelector([getNewSubCategoriesForModulesCategory], newSubCategoriesForModulesCategory => {
  const sectionsSubCategories = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS;
  return {
    [PATAGONIA_ADD_PANEL_CATEGORIES.MODULES]: newSubCategoriesForModulesCategory,
    [PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS]: [sectionsSubCategories.SAVED, sectionsSubCategories.THEME],
    [PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS]: [PATAGONIA_ADD_PANEL_SUB_CATEGORIES.LAYOUTS.DEFAULT]
  };
});
import http from 'hub-http/clients/apiClient';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';
export default function createEarlyRequestPromiseOrMakeRequest(requestUrl, requestData, earlyRequestKey = null) {
  if (!requestUrl) {
    throw new Error('Request url is required in createEarlyRequestPromiseOrMakeRequest');
  }
  if (!earlyRequestKey) {
    return http.get(requestUrl, requestData);
  }
  const earlyRequestPromise = getEarlyRequesterResultPromise(earlyRequestKey);
  if (!earlyRequestPromise) {
    throw new Error(`Early request keys must map to early requests. Could not find a request with key ${earlyRequestKey}`);
  }
  return earlyRequestPromise.catch(() => http.get(requestUrl, requestData));
}
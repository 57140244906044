'use es6';

import { createSelector } from 'reselect';
import { getContentTypeLimit } from 'ContentEditorUI/redux/selectors/limitSelectors';
import { getIsLandingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getPageLimitRequestState, getMlangPagesLimitRequestState } from './pageRequestStatesSelectors';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
export const getPageLimitRequest = basicSelectorWithStats(state => {
  return getPageLimitRequestState(state);
});
export const getIsApplyingSharedPageLimit = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit.isApplyingSharedPageLimit;
});
export const getPageLimitLangKey = basicSelectorWithStats(state => {
  const isApplyingSharedPageLimit = getIsApplyingSharedPageLimit(state);
  if (isApplyingSharedPageLimit) {
    return 'page';
  }
  if (getIsLandingPage(state)) {
    return 'landingPage';
  } else if (getIsSitePage(state)) {
    return 'sitePage';
  }
  return null;
});
export const getShouldUseCmsStarterUpgradePoint = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return Boolean(pageLimit && pageLimit.pageLimit && pageLimit.pageLimit > 15 && pageLimit.quantityUsed < 40);
});
export const getNumberOfPagesOverLimit = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit ? pageLimit.quantityUsed - pageLimit.pageLimit : 0;
});
export const getPageLimitValue = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit;
});

// MULTI-LANGUAGE PAGE LIMITS

export const getMlangPagesLimitRequest = basicSelectorWithStats(state => {
  return getMlangPagesLimitRequestState(state);
});
export const getHasExceededMlangPagesLimit = createSelector([getContentTypeLimit], pageLimit => Boolean(pageLimit && pageLimit.mlangPagesLimit && pageLimit.mlangPagesQuantityUsed > pageLimit.mlangPagesLimit));
export const getHasReachedMlangPagesLimit = createSelector([getContentTypeLimit], pageLimit => Boolean(pageLimit && pageLimit.mlangPagesLimit && pageLimit.mlangPagesQuantityUsed >= pageLimit.mlangPagesLimit));
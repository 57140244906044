import useSetUpEmbeddedComments from 'collaboration-sidebar/features/deepCommenting/hooks/useSetUpEmbeddedComments';
import { useCollaborationSidebarState } from '../hooks';
export default function EmbeddedCommentSetup() {
  const {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState
  } = useCollaborationSidebarState();
  useSetUpEmbeddedComments({
    collaborationSidebarState,
    onUpdateCollaborationSidebarState
  });
  return null;
}
import { combineReducers } from 'redux';
import { VIDEOS_FETCH_VIDEO_2, VIDEOS_SAVE_VIDEO } from '../actions/ActionTypes';
import { createGenericRequestStatusReducer } from '../helpers/reduxHelpers';
const INITIAL_STATE = {
  videos: {},
  video2FetchStatus: {}
};
const videos = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIDEOS_FETCH_VIDEO_2:
      {
        const {
          response,
          options: playerId
        } = action;
        if (!response || !playerId) {
          return state;
        }
        return Object.assign({}, state, {
          [playerId]: response
        });
      }
    case VIDEOS_SAVE_VIDEO:
      {
        const {
          playerId,
          video
        } = action;
        if (!playerId || !video) {
          return state;
        }
        return Object.assign({}, state, {
          [playerId]: video
        });
      }
    default:
      return state;
  }
};
export default combineReducers({
  videos,
  video2FetchStatus: createGenericRequestStatusReducer(VIDEOS_FETCH_VIDEO_2)
});
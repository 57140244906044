import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import get from 'hs-lodash/get';
export const getModalLangKey = (postFixKey, modalDataType) => {
  const prefix = modalDataType || 'shared';
  return `ContentComponents.contentActionModals.${prefix}.${postFixKey}`;
};
export const getBulkActionModalHeaderMessageOptions = (actionTargetCount, actionTargetTranslationsCount) => {
  return {
    count: actionTargetCount + actionTargetTranslationsCount,
    translationsCount: actionTargetTranslationsCount
  };
};
export const getBulkActionModalBodyMessageOptions = (actionTargetCount, actionTargetTranslationsCount) => {
  const options = {
    count: actionTargetCount + actionTargetTranslationsCount,
    translationsCount: actionTargetTranslationsCount
  };
  return actionTargetCount === 1 && options.translationsCount === 1 ? {
    count: 1
  } : options;
};
const SNAKE_CASE_CONTENT_LANGUAGE = 'content_language';
const CAMEL_CASE_CONTENT_LANGUAGE = 'contentLanguage';
const BODY_OVERRIDE_BY_DOMAIN = 'bodyOverrideByDomain';
export const getDomainLanguageForGivenDomain = (domain, portalSettings) => {
  const camelCaseDomainKeyPath = [BODY_OVERRIDE_BY_DOMAIN, domain, CAMEL_CASE_CONTENT_LANGUAGE];
  const snakeCaseDomainKeyPath = [BODY_OVERRIDE_BY_DOMAIN, domain, SNAKE_CASE_CONTENT_LANGUAGE];
  if (ImmutableMap.isMap(portalSettings)) {
    return portalSettings.getIn(camelCaseDomainKeyPath) || portalSettings.getIn(snakeCaseDomainKeyPath);
  }
  return get(portalSettings, camelCaseDomainKeyPath) || get(portalSettings, snakeCaseDomainKeyPath);
};
export const getDomainLanguage = (page, portalSettings) => {
  const domainName = page.domain || page.resolvedDomain;
  return getDomainLanguageForGivenDomain(domainName, portalSettings);
};
export function getExistingGroupLanguages(contentId, contentModels, portalSettings, isBlogPost) {
  const selectedMasterPage = contentModels.find(({
    id
  }) => id === contentId);
  if (!selectedMasterPage) {
    // this would happen while searching and clicking through options
    // and in some weird state it would break if no master page is found
    return ImmutableSet();
  }
  const parentBlog = selectedMasterPage.parentBlog;
  // use the `language` property for selected page/group
  // or use the default language for selected master page's domain
  const selectedMasterPageLang = selectedMasterPage.language || isBlogPost && parentBlog && parentBlog.language || getDomainLanguage(selectedMasterPage, portalSettings);

  // collect all languages for currently selected multi-language group
  const existingGroupLanguages = Object.keys(selectedMasterPage.translatedContent).concat(selectedMasterPageLang);
  return ImmutableSet(existingGroupLanguages);
}
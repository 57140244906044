'use es6';

import http from 'hub-http/clients/apiClient';
import { createStack } from 'hub-http';
import hubapiStack from 'hub-http/stacks/hubapi';
import promiseClient from 'hub-http/adapters/promiseClient';
import { retry, onResponseError } from 'hub-http/middlewares/core';
import PortalIdParser from 'PortalIdParser';
const asyncRequestStack = createStack(
// polls every 1000ms up to 20 retires for a total of 20s
retry(response => {
  return response.status === 404;
}, {
  maxRetries: 20,
  onMaxAttemptsReached: onResponseError(err => Promise.reject(err)),
  delay: 1000
}), hubapiStack);
const pollingRequestClient = promiseClient(asyncRequestStack);
const SCREENSHOTS_URL = 'content-screenshots/v1/content-screenshots';
const buildPollingScreenshotsUrl = key => `content-screenshots/v1/content-screenshots/${key}`;
export const getAsyncScreenshot = (targetUrl, contentId, selector, dataOverrides = {}) => {
  const targetUrlObject = new URL(targetUrl);
  targetUrlObject.searchParams.append('hsLoginVerifyDisabled', 'true');
  const data = Object.assign({
    targetUrl: targetUrlObject.toString(),
    selector,
    portalId: PortalIdParser.get(),
    contentId
  }, dataOverrides);
  return http.post(SCREENSHOTS_URL, {
    data
  }).then(response => {
    const key = encodeURIComponent(response.s3Key);
    const {
      destinationUrl
    } = response;
    const pollingUrl = buildPollingScreenshotsUrl(key);
    return pollingRequestClient.get(pollingUrl).then(() => {
      return {
        webUrl: destinationUrl
      };
    });
  });
};
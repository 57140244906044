import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_TEMPLATE_INFO_SUCCESS, FETCH_TEMPLATE_PREVIEW_URL_SUCCESS, FETCH_ADDABLE_SECTIONS_SUCCESS, DELETE_SECTION_SUCCESS, FETCH_MARKETER_SECTIONS_SUCCESS, SAVE_SECTION_SUCCESS, CUSTOM_LAYOUT_SECTION_ADDED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS, FETCH_EMBEDS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import produce from 'immer';
import set from 'hs-lodash/set';
export const templateInfoImmerReducer = (state = {
  templateType: null,
  customSections: null,
  marketerSectionsCount: -1,
  marketerSectionsLimit: -1,
  previousInsertedSectionTree: null,
  embeds: []
}, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        draft.templateType = response.template_type;
        return draft;
      }
    case FETCH_TEMPLATE_INFO_SUCCESS:
      return Object.assign(draft, Object.assign({}, payload));
    case FETCH_TEMPLATE_PREVIEW_URL_SUCCESS:
      if (draft.userMeta) {
        return set(draft, ['userMeta', 'thumbnailPath'], payload.webUrl);
      }
      draft.userMeta = {
        thumbnailPath: payload.webUrl
      };
      return draft;
    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        draft.marketerSectionsCount = payload.marketerSectionsCount;
        draft.marketerSectionsLimit = payload.marketerSectionsLimit;
        draft.customSections = payload.allSections;
        return draft;
      }
    case DELETE_SECTION_SUCCESS:
      {
        const sectionToDelete = payload;
        const newSectionList = draft.customSections && draft.customSections.filter(section => section.id !== sectionToDelete);
        const currentMarketerSectionsCount = draft.marketerSectionsCount;
        draft.customSections = newSectionList;
        draft.marketerSectionsCount = currentMarketerSectionsCount - 1;
        return draft;
      }
    case FETCH_MARKETER_SECTIONS_SUCCESS:
      return Object.assign(draft, {
        marketerSectionsCount: payload.total,
        marketerSectionsLimit: payload.themeLimit
      });
    case SAVE_SECTION_SUCCESS:
      {
        const currentMarketerSectionsCount = draft.marketerSectionsCount;
        const marketerSectionsLimit = draft.marketerSectionsLimit;
        if (currentMarketerSectionsCount + 1 === marketerSectionsLimit) {
          UsageTracker.trackEditorInteraction({
            action: 'Sections action',
            event: 'hit-section-limit'
          });
        }
        let newSectionList = [payload];
        if (draft.customSections && draft.customSections.length) {
          newSectionList = [payload, ...draft.customSections];
        }
        draft.customSections = newSectionList;
        draft.marketerSectionsCount = currentMarketerSectionsCount + 1;
        return draft;
      }
    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        draft.previousInsertedSectionTree = null;
        return draft;
      }
    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const previousInsertedSectionTree = payload.previousTree;
        draft.previousInsertedSectionTree = previousInsertedSectionTree;
        return draft;
      }
    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        draft.previousInsertedSectionTree = null;
        return draft;
      }
    case FETCH_EMBEDS_SUCCEEDED:
      {
        draft.embeds = payload;
        return draft;
      }
    default:
      return draft;
  }
});
const templateReducer = (state = Immutable.Map({
  templateType: null,
  customSections: null,
  marketerSectionsCount: -1,
  marketerSectionsLimit: -1,
  previousInsertedSectionTree: null,
  embeds: []
}), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        return state.merge({
          templateType: response.template_type
        });
      }
    case FETCH_TEMPLATE_INFO_SUCCESS:
      return state.merge(Object.assign({}, payload));
    case FETCH_TEMPLATE_PREVIEW_URL_SUCCESS:
      if (state.get('userMeta')) {
        return state.setIn(['userMeta', 'thumbnailPath'], payload.webUrl);
      }
      return state.set('userMeta', Immutable.fromJS({
        thumbnailPath: payload.webUrl
      }));
    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        state = state.merge({
          marketerSectionsCount: payload.marketerSectionsCount,
          marketerSectionsLimit: payload.marketerSectionsLimit
        });
        return state.set('customSections', payload.allSections);
      }
    case DELETE_SECTION_SUCCESS:
      {
        const sectionToDelete = payload;
        const oldSectionList = state.get('customSections');
        const newSectionList = oldSectionList && oldSectionList.filter(section => section.id !== sectionToDelete);
        const currentMarketerSectionsCount = state.get('marketerSectionsCount');
        state = state.set('customSections', newSectionList);
        return state.set('marketerSectionsCount', currentMarketerSectionsCount - 1);
      }
    case FETCH_MARKETER_SECTIONS_SUCCESS:
      return state.merge({
        marketerSectionsCount: payload.total,
        marketerSectionsLimit: payload.themeLimit
      });
    case SAVE_SECTION_SUCCESS:
      {
        const currentMarketerSectionsCount = state.get('marketerSectionsCount');
        const marketerSectionsLimit = state.get('marketerSectionsLimit');
        if (currentMarketerSectionsCount + 1 === marketerSectionsLimit) {
          UsageTracker.trackEditorInteraction({
            action: 'Sections action',
            event: 'hit-section-limit'
          });
        }
        const oldSectionList = state.get('customSections');
        const newSectionList = [payload, ...oldSectionList];
        state = state.set('customSections', newSectionList);
        return state.set('marketerSectionsCount', currentMarketerSectionsCount + 1);
      }
    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        return state.set('previousInsertedSectionTree', null);
      }
    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const previousInsertedSectionTree = payload.previousTree;
        return state.set('previousInsertedSectionTree', previousInsertedSectionTree);
      }
    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        return state.set('previousInsertedSectionTree', null);
      }
    case FETCH_EMBEDS_SUCCEEDED:
      {
        return state.set('embeds', payload);
      }
    default:
      return state;
  }
};
export default templateReducer;
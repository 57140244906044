import * as Hubs from './Hubs';
import * as ProductLines from './ProductLines';
export const productLineToHub = {
  [ProductLines.MARKETING]: Hubs.HUB_MARKETING,
  [ProductLines.SALES]: Hubs.HUB_SALES,
  [ProductLines.SERVICE]: Hubs.HUB_SERVICE,
  [ProductLines.CMS]: Hubs.HUB_CMS,
  [ProductLines.OPERATIONS]: Hubs.HUB_OPERATIONS
};
export const hubToProductLine = {
  [Hubs.HUB_MARKETING]: ProductLines.MARKETING,
  [Hubs.HUB_SALES]: ProductLines.SALES,
  [Hubs.HUB_SERVICE]: ProductLines.SERVICE,
  [Hubs.HUB_CMS]: ProductLines.CMS,
  [Hubs.HUB_OPERATIONS]: ProductLines.OPERATIONS
};
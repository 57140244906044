import { DEDICATED_IP, MARKETING_HUB_ENTERPRISE, MARKETING_HUB_PROFESSIONAL, MARKETING_HUB_STARTER, SALES_HUB_STARTER, SALES_HUB_PROFESSIONAL, SERVICE_HUB_STARTER, SALES_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE } from './ApiNames';
const VersionNumbers = {
  [DEDICATED_IP]: {
    LEGACY: 1,
    EARLY_2020: 3
  },
  [MARKETING_HUB_STARTER]: {
    LEGACY_250: 2,
    NOV_2019: 3,
    MARKETING_CONTACTS: 4
  },
  [MARKETING_HUB_PROFESSIONAL]: {
    MARKETING_CONTACTS: 12
  },
  [MARKETING_HUB_ENTERPRISE]: {
    MARKETING_CONTACTS: 12
  },
  [SALES_HUB_STARTER]: {
    LEGACY: 1,
    MARCH_2020: 3
  },
  [SALES_HUB_PROFESSIONAL]: {
    LEGACY_BUSINESS_USER: 3,
    QUANTIFIABLE_SEAT: 7
  },
  [SALES_HUB_ENTERPRISE]: {
    QUANTIFIABLE_SEAT: 3
  },
  [SERVICE_HUB_STARTER]: {
    LEGACY: 1,
    MARCH_2020: 2
  },
  [SERVICE_HUB_PROFESSIONAL]: {
    QUANTIFIABLE_SEAT: 2
  },
  [SERVICE_HUB_ENTERPRISE]: {
    QUANTIFIABLE_SEAT: 3
  }
};
export default VersionNumbers;
'use es6';

import { START_ONBOARDING_TOUR_IN_PARENT, SHOW_ONBOARDING_TOUR_QUIT_MODAL, HIDE_ONBOARDING_TOUR_QUIT_MODAL, FINISH_TOUR_INPAGE, GO_TO_STYLE_SECTION, TRACK_LP_ONBOARDING_TOUR_PROGRESSION, CLEAR_PROGRESSION_TO_TRACK } from 'ContentEditorUI/redux/actions/actionTypes';
import { setSeenStateSettingToNow, setUserAttributeAlsoCachedInSuperstore } from 'ContentEditorUI/redux/actions/settingsActions';
import { HAS_SEEN_FREE_LP_ONBOARDING_TOUR_KEY, SHOULD_START_FREE_LP_ONBOARDING_TOUR } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import Raven from 'raven-js';
export const startOnboardingTourInParent = () => {
  return {
    type: START_ONBOARDING_TOUR_IN_PARENT
  };
};
export const showOnboardingTourQuitModal = () => {
  return {
    type: SHOW_ONBOARDING_TOUR_QUIT_MODAL
  };
};
export const hideOnboardingTourQuitModal = () => {
  return {
    type: HIDE_ONBOARDING_TOUR_QUIT_MODAL
  };
};
export const quitOnboardingTour = () => dispatch => {
  Raven.captureMessage('LP onboarding tour unexpectedly quit');
  dispatch(setSeenStateSettingToNow(HAS_SEEN_FREE_LP_ONBOARDING_TOUR_KEY));
  dispatch(setUserAttributeAlsoCachedInSuperstore(SHOULD_START_FREE_LP_ONBOARDING_TOUR, false));
};
export const finishTourInPage = () => {
  return {
    type: FINISH_TOUR_INPAGE
  };
};
export const goToStyleSection = () => {
  return {
    type: GO_TO_STYLE_SECTION
  };
};
export const trackLPOnbordingTourProgression = eventType => {
  return {
    type: TRACK_LP_ONBOARDING_TOUR_PROGRESSION,
    progressionToTrack: {
      action: 'LP Onboarding Tour Progression',
      event: eventType
    }
  };
};
export const clearTourProgressionToTrack = () => {
  return {
    type: CLEAR_PROGRESSION_TO_TRACK
  };
};
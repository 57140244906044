import enviro from 'enviro';
const isQa = enviro.isQa();
export const UE_APPROVAL_CREATED = 'UE_APPROVAL_CREATED';
export const UE_APPROVAL_CANCELED = 'UE_APPROVAL_CANCELED';
export const UE_APPROVAL_STEP_APPROVED = 'UE_APPROVAL_STEP_APPROVED';
export const UE_APPROVAL_UPDATED = 'UE_APPROVAL_UPDATED';
export const UE_APPROVAL_APPROVED = 'UE_APPROVAL_APPROVED';
export const UE_APPROVAL_CONCLUDED = 'UE_APPROVAL_CONCLUDED';
export const UE_APPROVAL_REJECTED = 'UE_APPROVAL_REJECTED';
export const UE_APPROVAL_CREATED_ID = isQa ? 911273 : 1679224;
export const UE_APPROVAL_CANCELED_ID = isQa ? 938548 : 1687686;
export const UE_APPROVAL_STEP_APPROVED_ID = isQa ? 931640 : 1686033;
export const UE_APPROVAL_UPDATED_ID = isQa ? 1131941 : 1758278;
export const UE_APPROVAL_APPROVED_ID = isQa ? 931134 : 1685956;
export const UE_APPROVAL_CONCLUDED_ID = isQa ? 1098959 : 1750479;
export const UE_APPROVAL_REJECTED_ID = isQa ? 1215418 : 1779749;
export const UENameToIdMap = {
  [UE_APPROVAL_CREATED]: UE_APPROVAL_CREATED_ID,
  [UE_APPROVAL_CANCELED]: UE_APPROVAL_CANCELED_ID,
  [UE_APPROVAL_STEP_APPROVED]: UE_APPROVAL_STEP_APPROVED_ID,
  [UE_APPROVAL_UPDATED]: UE_APPROVAL_UPDATED_ID,
  [UE_APPROVAL_APPROVED]: UE_APPROVAL_APPROVED_ID,
  [UE_APPROVAL_CONCLUDED]: UE_APPROVAL_CONCLUDED_ID,
  [UE_APPROVAL_REJECTED]: UE_APPROVAL_REJECTED_ID
};
export const UEIdToNameMap = {
  [UE_APPROVAL_CREATED_ID]: UE_APPROVAL_CREATED,
  [UE_APPROVAL_CANCELED_ID]: UE_APPROVAL_CANCELED,
  [UE_APPROVAL_STEP_APPROVED_ID]: UE_APPROVAL_STEP_APPROVED,
  [UE_APPROVAL_UPDATED_ID]: UE_APPROVAL_UPDATED,
  [UE_APPROVAL_APPROVED_ID]: UE_APPROVAL_APPROVED,
  [UE_APPROVAL_CONCLUDED_ID]: UE_APPROVAL_CONCLUDED,
  [UE_APPROVAL_REJECTED_ID]: UE_APPROVAL_REJECTED
};
import { ONBOARDING_TOURS_GROUP_KEY, ONBOARDING_TOURS_GUIDE, ONBOARDING_TOURS_SOURCE_KEY } from '../constants/onboardingTours';
import { get } from '../util/sessionStorage';
const PAYMENTS_GUIDE_SOURCE = '"payments_guide"';
const getOnboardingToursValue = (key, errorCallback) => get(key, errorCallback);
export const getOnboardingToursSource = errorCallback => getOnboardingToursValue(ONBOARDING_TOURS_SOURCE_KEY, errorCallback);
export const getOnboardingToursGuideObject = errorCallback => getOnboardingToursValue(ONBOARDING_TOURS_GUIDE, errorCallback);
export const getOnboardingToursGroupKey = errorCallback => getOnboardingToursValue(ONBOARDING_TOURS_GROUP_KEY, errorCallback);
export const isFromPaymentsGuide = errorCallback => getOnboardingToursSource(errorCallback) === PAYMENTS_GUIDE_SOURCE;
export const getOnboardingToursTask = errorCallback => {
  try {
    const obj = JSON.parse(getOnboardingToursGuideObject(errorCallback));
    return obj.key;
  } catch (error) {
    if (errorCallback) {
      errorCallback();
    } else {
      console.error('could not get guide object');
    }
  }
  return undefined;
};
import produce from 'immer';
import { SELECT_BREAKPOINT, FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_TEMPLATE_SCHEMA_SUCCEEDED, TOGGLE_HIDDEN_ELEMENT_VISIBILITY } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  activeBreakpointName: 'default',
  activeBreakpointPreviewWidth: null,
  breakpoints: [{
    name: 'default'
  }],
  showHiddenElements: false
};
export const styleBreakpointsReducer = (state = initialState, action) => produce(state, draft => {
  const {
    type,
    response,
    payload
  } = action;
  switch (type) {
    case SELECT_BREAKPOINT:
      if (payload.previewWidth) {
        const {
          units,
          value
        } = payload.previewWidth;
        const previewWidth = `${value}${units}`;
        draft.activeBreakpointName = payload.selectedBreakpoint;
        draft.activeBreakpointPreviewWidth = previewWidth;
        return draft;
      }
      draft.activeBreakpointName = payload.selectedBreakpoint;
      draft.activeBreakpointPreviewWidth = null;
      return draft;
    case FETCH_TEMPLATE_SCHEMA_SUCCEEDED:
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          breakpoint_config
        } = response;
        if (breakpoint_config) {
          const breakpoints = breakpoint_config.breakpoints;
          const newBreakpoints = [];
          breakpoints.forEach((breakpoint, index) => {
            // We are adding custom breakpoints support to themes, but will be holding off
            // on non-mobile breakpoints support in custom modules until we verify the approach.
            // Context: https://product.hubteam.com/docs/content/rfcs/0695-custom-breakpoints.html#timeline
            if (breakpoint.name === 'mobile' || breakpoint.name === 'default') {
              breakpoint.order = index;
              newBreakpoints.push(breakpoint);
            }
          });
          draft.breakpoints = newBreakpoints;
        }
        return draft;
      }
    case TOGGLE_HIDDEN_ELEMENT_VISIBILITY:
      if (typeof action.payload === 'boolean') {
        draft.showHiddenElements = action.payload;
      } else {
        draft.showHiddenElements = !draft.showHiddenElements;
      }
      return draft;
    default:
      return draft;
  }
});
export default styleBreakpointsReducer;
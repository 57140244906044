import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as paymentsClient from 'ContentData/api/Payments';
import { PAYMENTS_FETCH_PAYMENT, PAYMENTS_FETCH_PAYMENTS, PAYMENTS_FETCH_PAYMENT_ELIGIBILITY } from 'ContentData/actions/ActionTypes';
const fetchPaymentAction = createGenericFetchAction(PAYMENTS_FETCH_PAYMENT, paymentsClient.fetchPayment);
const fetchPaymentsAction = createGenericFetchAction(PAYMENTS_FETCH_PAYMENTS, paymentsClient.fetchPayments);
const fetchPaymentEligibilityAction = createGenericFetchAction(PAYMENTS_FETCH_PAYMENT_ELIGIBILITY, paymentsClient.fetchPaymentEligibility);
export function fetchPayment(id) {
  return fetchPaymentAction(id);
}
export function fetchPayments(query) {
  return fetchPaymentsAction(query);
}
export function fetchPaymentEligibility() {
  return fetchPaymentEligibilityAction();
}
/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'I18n';
import { findModuleById } from 'ContentEditorUI/data/moduleUtils';
import inpageActionTypes from 'ContentEditorUI/redux/actions/inpageActionTypes';
import { getModules, getDefaultTypeHelper } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getRenderLanguage } from 'ContentEditorUI/redux/selectors/languageSelectors';
import { setIframeNeedsRefresh as setIframeNeedsRefreshAction } from 'ContentEditorUI/redux/actions/inpageActions';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import AppModuleMarkupRequestManager from 'ContentUtils/helpers/AppModuleMarkupRequestManager';
import { optimisticallyAddCssAsset as optimisticallyAddCssAssetAction } from 'ContentEditorUI/redux/actions/contentAssetsActions';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getAppKey } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
const mapStateToProps = state => {
  return {
    modules: getModules(state),
    allModuleSchemasArray: getAllModuleSchemasAsArray(state),
    moduleIdsByBuiltinType: getModuleIdsByBuiltinType(state),
    builtInTypesByModuleId: getBuiltInTypesByModuleId(state),
    language: getRenderLanguage(state),
    contentId: getContentModelId(state),
    isBlogListingPage: getIsBlogListingPage(state),
    appKey: getAppKey(state)
  };
};
const mapDispatchToProps = {
  setIframeNeedsRefresh: setIframeNeedsRefreshAction,
  optimisticallyAddCssAsset: optimisticallyAddCssAssetAction
};
class ModuleMarkupRequestContainer extends Component {
  constructor(props) {
    super(props);
    this.handleFormatRequestData = module => {
      const {
        language,
        contentId
      } = this.props;
      const requestData = Object.assign({}, module, {
        language
      });
      if (this.moduleNeedsFullRenderContext()) {
        return Object.assign({}, requestData, {
          contentId
        });
      }
      return requestData;
    };
    this.handleHtmlReceivedForModule = ({
      moduleHtml,
      cssToAdd,
      moduleName,
      moduleVersion,
      options
    }) => {
      const {
        optimisticallyAddCssAsset
      } = this.props;
      const messagePayload = {
        action: 'htmlReceivedForModule',
        moduleHtml,
        moduleName,
        moduleVersion
      };

      // we keep track of _all_ css assets on a page. Firing this action will
      // eventually (maybe, depending on if it's new CSS) trigger a reordering of
      // css assets on the page
      optimisticallyAddCssAsset(cssToAdd);
      this.trackRequestResults('no-refresh-required', options.moduleType);
      this.bus.postMessage(messagePayload);
    };
    this.handleRenderError = (module, errors) => {
      // Put some more (non-translated) error info in the console
      console.error(`Module render errors for ${module.name} (${module.label}):\n - ${errors.map(error => error.message || error.errorType).join('\n - ')}`);
      FloatingAlertStore.addAlert({
        message: I18n.text('editor.errors.moduleRenderError', {
          applyChangesButton: I18n.text('editOverlay.baymaxApply'),
          moduleLabel: module.label || module.name
        }),
        titleText: I18n.text('editor.errors.moduleRenderErrorTitle'),
        type: 'warning',
        timeout: 10000,
        // If the module is frequently updated in succession, don't re-show the same warning
        // again and again
        id: `module-render-error-${module.name}`
      });
    };
    this.handleModuleHtmlRequestIsInvalid = ({
      moduleHasCss,
      moduleHasJs,
      moduleName,
      moduleVersion,
      options
    }) => {
      const {
        setIframeNeedsRefresh
      } = this.props;
      let eventToTrack;
      const {
        refreshOnFail,
        moduleType
      } = options;
      if (moduleHasCss && !moduleHasJs) {
        eventToTrack = 'CSS-refresh-required';
      } else if (moduleHasJs) {
        eventToTrack = 'JS-refresh-required';
      }

      // Don't track other module render issues (e.g. module source syntax errors)
      if (eventToTrack) {
        this.trackRequestResults(eventToTrack, moduleType);
      }
      if (refreshOnFail) {
        this.bus.trigger('saveAndTriggerRefresh');
      } else {
        // Fire redux action indicating a refresh will be needed when
        // back button is clicked on mofos
        setIframeNeedsRefresh();

        // Tell inpage app that no markup will be coming
        this.bus.postMessage({
          action: 'moduleHtmlRequiresRefresh',
          moduleName,
          moduleVersion,
          moduleHasJs,
          moduleHasCss
        });
      }
    };
    this.handleModuleHtmlRequest = ({
      message
    }) => {
      const {
        modules,
        allModuleSchemasArray,
        moduleIdsByBuiltinType,
        builtInTypesByModuleId,
        appKey
      } = this.props;
      const {
        moduleName,
        moduleVersion,
        refreshOnFail,
        smartRuleModuleBody
      } = message;
      const module = findModuleById(modules, moduleName);
      const schema = getSchemaForModuleHelper(module, allModuleSchemasArray, moduleIdsByBuiltinType);
      let moduleType = 'custom';
      if (schema && schema.default === true) {
        moduleType = getDefaultTypeHelper(module, schema, builtInTypesByModuleId);
      }
      this.maybeAutoSaveAndRequestModuleMarkup(() => {
        this.markupRequestManager.requestModuleMarkup({
          module: module ? module.toJS() : null,
          moduleName,
          moduleVersion,
          options: {
            refreshOnFail,
            moduleType,
            query: {
              hsEditorApp: appKey
            }
          },
          schema,
          smartRuleModuleBody
        });
      });
    };
    this.markupRequestManager = new AppModuleMarkupRequestManager({
      onFormatRequestData: this.handleFormatRequestData,
      onHtmlReceivedForModule: this.handleHtmlReceivedForModule,
      onModuleHtmlRequestIsInvalid: this.handleModuleHtmlRequestIsInvalid,
      onRenderError: this.handleRenderError,
      allowCss: true
    });
  }
  componentDidMount() {
    this.bus = window.hubspot.ContentEditorUI.eventBus;
    this.bus.on(`post:${inpageActionTypes.MODULE_HTML_REQUESTED}`, this.handleModuleHtmlRequest);
  }
  trackRequestResults(event, moduleType) {
    UsageTracker.trackEditorInteraction({
      action: 'Refresh Action',
      event,
      moduleType
    });
  }
  moduleNeedsFullRenderContext() {
    // Temporary until CMV2 spec is updated so a module can specify on its own that it needs the entire page context
    return this.props.isBlogListingPage;
  }
  maybeAutoSaveAndRequestModuleMarkup(requestModuleMarkup) {
    if (this.moduleNeedsFullRenderContext()) {
      this.props.autoSave().then(requestModuleMarkup);
    } else {
      requestModuleMarkup();
    }
  }
  render() {
    return null;
  }
}
ModuleMarkupRequestContainer.propTypes = {
  allModuleSchemasArray: PropTypes.array.isRequired,
  moduleIdsByBuiltinType: PropTypes.object.isRequired,
  builtInTypesByModuleId: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  modules: PropTypes.object.isRequired,
  setIframeNeedsRefresh: PropTypes.func.isRequired,
  optimisticallyAddCssAsset: PropTypes.func.isRequired,
  contentId: PropTypes.number.isRequired,
  isBlogListingPage: PropTypes.bool.isRequired,
  autoSave: PropTypes.func,
  appKey: PropTypes.string.isRequired
};
ModuleMarkupRequestContainer.defaultProps = {
  autoSave: Promise.resolve()
};
export default connect(mapStateToProps, mapDispatchToProps)(ModuleMarkupRequestContainer);
'use es6';

import { createSelector } from 'reselect';
import { getHasStagingDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getIsABVariant } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getCanSchedule } from 'page-editor-ui/redux/selectors/authSelectors';
import { getCurrentTabId } from 'ContentEditorUI/redux/selectors/routingSelectors';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { NAV_TABS_SHOW_SIDEBAR } from 'ContentEditorUI/constants/Sidebar';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
export const getShouldHidePublishingTab = createSelector([getIsABVariant, getHasStagingDomain, getCanSchedule, getIsBlogListingPage], (isABVariant, hasStagingDomain, canSchedule, isBlogListingPage) => {
  return isABVariant || hasStagingDomain || !canSchedule || isBlogListingPage;
});
export const getPageEditorTabs = createSelector([getShouldHidePublishingTab], shouldHidePublishingTab => {
  return [{
    id: 'content',
    langKey: 'components.tabBar.content',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['content'],
    hidden: false
  }, {
    id: 'settings',
    langKey: 'components.tabBar.settings',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['settings'],
    hidden: false
  }, {
    id: 'optimize',
    langKey: 'components.tabBar.optimize',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['optimize'],
    sidebarId: SIDEBAR_PANELS.optimizer,
    hidden: false
  }, {
    id: 'publishing',
    langKey: 'components.tabBar.publishingOptions',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['publishing'],
    hidden: shouldHidePublishingTab
  }, {
    // Not an an actual tab in the tab bar, but preview does "act" like one (with its own route)
    id: 'preview',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['preview'],
    hidden: true
  }, {
    id: 'revisions',
    showSidebar: NAV_TABS_SHOW_SIDEBAR['revisions'],
    hidden: true
  }];
});
export const getCurrentTab = createSelector([getCurrentTabId, getPageEditorTabs], (currentTabId, pageEditorTabs) => {
  const currentTab = pageEditorTabs.find(tab => tab.id === currentTabId);
  if (currentTab) {
    return currentTab;
  }
  return pageEditorTabs[0];
});
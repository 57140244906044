const APP_START_TIME_STAMP = 'APP_START_TIME_STAMP';
const IS_FEEDBACK_IN_SESSION = 'IS_FEEDBACK_IN_SESSION';
const HAS_DISMISSED_FEEDBACK = 'HAS_DISMISSED_FEEDBACK';
const SHOW_BADGE_TIME = 5 * 60 * 1000;
const HIDE_BADGE_TIME = 12 * 60 * 1000;
export const setAppStartTime = () => {
  const currentTimeStamp = Date.now();
  try {
    window.sessionStorage.setItem(APP_START_TIME_STAMP, JSON.stringify(currentTimeStamp));
  } catch (error) {
    console.log(error);
  }
  return currentTimeStamp;
};
export const getAppStartTime = () => {
  try {
    const appStartTime = window.sessionStorage.getItem(APP_START_TIME_STAMP);
    return appStartTime ? parseInt(JSON.parse(appStartTime), 10) : undefined;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
const getTimeFromAppStart = () => {
  const appStartTime = getAppStartTime();
  if (!appStartTime) {
    return 0;
  }
  const currentTime = Date.now();
  const timeDiff = currentTime - appStartTime;
  return timeDiff;
};
export const getShowFeedbackTime = () => {
  const timeDiffFromAppStart = getTimeFromAppStart();
  return SHOW_BADGE_TIME - timeDiffFromAppStart;
};
export const getHideFeedbackTime = () => {
  const timeDiffFromAppStart = getTimeFromAppStart();
  return HIDE_BADGE_TIME - timeDiffFromAppStart;
};
export const setInFeedbackSession = isInSession => {
  try {
    window.sessionStorage.setItem(IS_FEEDBACK_IN_SESSION, JSON.stringify(isInSession));
    return isInSession;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getInFeedbackSession = () => {
  try {
    const isInFeedbackSession = window.sessionStorage.getItem(IS_FEEDBACK_IN_SESSION) || '';
    return isInFeedbackSession ? Boolean(JSON.parse(isInFeedbackSession)) : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const setHasDismissedFeedback = hasDismissed => {
  try {
    window.sessionStorage.setItem(HAS_DISMISSED_FEEDBACK, JSON.stringify(hasDismissed));
    return hasDismissed;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getHasDismissedFeedback = () => {
  try {
    const hasDismissedFeedback = window.sessionStorage.getItem(HAS_DISMISSED_FEEDBACK) || '';
    return hasDismissedFeedback ? Boolean(JSON.parse(hasDismissedFeedback)) : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
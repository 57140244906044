import { CLEAR_ACTIVE_HUBDB_ROW, SET_ACTIVE_HUBDB_ROW, SET_HUBDB_ROWS_AND_SCHEMA, UPDATE_TABLE_ROWS, UPDATE_DATA_AUTHORING_MODE } from 'ContentEditorUI/redux/actions/actionTypes';
const instanceEditingReducer = (state = {
  instanceEditing: {
    instanceEditingModeActive: false,
    currentHubDbRowId: null,
    tableSchema: null,
    tableRows: [],
    dataAuthoringModeActive: true
  }
}, {
  type,
  payload
}) => {
  switch (type) {
    case SET_ACTIVE_HUBDB_ROW:
      {
        const {
          rowId
        } = payload;
        const {
          instanceEditing
        } = state;
        return {
          instanceEditing: Object.assign({}, instanceEditing, {
            currentHubDbRowId: rowId,
            instanceEditingModeActive: true
          })
        };
      }
    case CLEAR_ACTIVE_HUBDB_ROW:
      {
        const {
          instanceEditing
        } = state;
        return {
          instanceEditing: Object.assign({}, instanceEditing, {
            currentHubDbRowId: null,
            instanceEditingModeActive: false
          })
        };
      }
    case SET_HUBDB_ROWS_AND_SCHEMA:
      {
        const {
          rows,
          tableSchema,
          hubDbTable
        } = payload;
        const {
          instanceEditing
        } = state;
        return {
          instanceEditing: Object.assign({}, instanceEditing, {
            tableSchema,
            tableRows: rows,
            hubDbTable
          })
        };
      }
    case UPDATE_TABLE_ROWS:
      {
        const {
          rows
        } = payload;
        const {
          instanceEditing
        } = state;
        return {
          instanceEditing: Object.assign({}, instanceEditing, {
            tableRows: rows
          })
        };
      }
    case UPDATE_DATA_AUTHORING_MODE:
      {
        const {
          instanceEditing
        } = state;
        return {
          instanceEditing: Object.assign({}, instanceEditing, {
            dataAuthoringModeActive: !instanceEditing.dataAuthoringModeActive
          })
        };
      }
    default:
      return state;
  }
};
export default instanceEditingReducer;
import { REQUESTED_MODULE_DELETE, DELETED_MODULE, CLOSE_DELETE_REQUEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  moduleIdToDelete: null
};
export const deleteRequestReducer = (state = initialState, action) => produce(state, draft => {
  const {
    payload,
    type
  } = action;
  if (!payload) {
    return draft;
  }
  const {
    moduleId
  } = payload;
  switch (type) {
    case REQUESTED_MODULE_DELETE:
      {
        draft.moduleIdToDelete = moduleId;
        return draft;
      }
    case DELETED_MODULE:
    case CLOSE_DELETE_REQUEST_MODAL:
      {
        draft.moduleIdToDelete = null;
        return draft;
      }
    default:
      {
        return draft;
      }
  }
});
export default deleteRequestReducer;
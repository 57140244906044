import useApprovalConfig from './useApprovalConfig';
import useApprovalData from './useApprovalData';
import useApprovalMeta from './useApprovalMeta';
import { useApprovalSkipConfig } from './useApprovalSkipConfig';
export const useApproval = props => {
  const {
    approvalType,
    approvalAction,
    approvableObjectId,
    assetName
  } = props;
  const approvalConfig = useApprovalConfig(approvalType, approvalAction);
  const approvalData = useApprovalData(approvalType, approvalAction, approvableObjectId, assetName);
  const approvalSkipConfig = useApprovalSkipConfig(approvalAction, approvalType);
  const api = Object.assign({
    fetchConfig: approvalConfig.fetchConfig,
    fetchSkipConfig: approvalSkipConfig.fetchSkipConfig
  }, approvalData.api);
  const meta = useApprovalMeta(Object.assign({}, props, {
    approvalData,
    approvalConfig,
    approvalSkipConfig
  }));
  return {
    api,
    data: approvalData.data,
    config: approvalConfig.config,
    meta,
    skipConfig: approvalSkipConfig.skipConfig
  };
};
import { createSelector } from 'reselect';
import { memoize } from 'underscore';
import unescapedText from 'I18n/utils/unescapedText';
import { CrmObjectTypes } from 'ContentUtils/constants/CrmObjectTypes';
import { CrmObjectMetaTypes } from 'ContentUtils/enums/CrmObjectMetaTypes';
import { getCrmObjectLabel } from 'ContentData/helpers/crmObjectHelpers';
import { tryParseNumber } from 'ContentData/helpers/reduxHelpers';
import { FAILED } from 'ContentUtils/constants/RequestStatus';
import { sortBy } from 'underscore';
import { CRM_OBJECT_FOLDER_NAME_PROP } from '../constants/crmObjects';
export const getCrmObjectTypes = state => state.resources.crmObjects.crmObjectTypes;
let optionsByValue = {};
const createCrmObjectTypeOptions = crmObjectTypes => {
  optionsByValue = {};
  const valueDuplicates = {};
  const typesBySimpleName = {};
  const labelDuplicates = {};
  const typesByLabel = {};
  return Object.keys(crmObjectTypes).map(fullyQualifiedName => {
    const objectType = crmObjectTypes[fullyQualifiedName];
    let text = objectType.name;
    const objectTypeKey = objectType.name.toUpperCase();
    const builtinType = objectType.metaType === CrmObjectMetaTypes.HUBSPOT && CrmObjectTypes[objectTypeKey];
    if (builtinType) {
      text = unescapedText(`ContentComponents.crmObjectTypes.${builtinType}.singular`);
    } else if (objectType.singularForm) {
      text = objectType.singularForm;
    }
    if (typesByLabel[text]) {
      labelDuplicates[text] = objectType;
    } else {
      typesByLabel[text] = objectType;
    }
    const lowerCaseSimpleName = objectType.name.toLowerCase();
    if (typesBySimpleName[lowerCaseSimpleName]) {
      valueDuplicates[lowerCaseSimpleName] = objectType;
    } else {
      typesBySimpleName[lowerCaseSimpleName] = objectType;
    }
    return {
      text,
      value: fullyQualifiedName
    };
  })
  // For label duplicates add (HubSpot)/(Portal-specific) afterwards
  // For builtin types or portal-specific non-duplicates, use simple name
  .map(option => {
    const value = option.value;
    const objectType = crmObjectTypes[value];
    if (labelDuplicates[option.text]) {
      option.text = objectType.metaType === CrmObjectMetaTypes.HUBSPOT ? unescapedText('designdata.crmObjects.duplicteTypeOptions.builtinLabel', {
        typeLabel: option.text
      }) : unescapedText('designdata.crmObjects.duplicteTypeOptions.portalSpecificLabel', {
        typeLabel: option.text
      });
    }

    // FQN values are case insensitive so we should lowercase them
    option.value = value.toLowerCase();
    optionsByValue[value] = option;
    if (objectType.metaType === CrmObjectMetaTypes.HUBSPOT) {
      // Builtin types should use lowercased simple names because builtin
      // simple names are case-insensitive
      option.value = objectType.name.toLowerCase();
    } else if (objectType.metaType === CrmObjectMetaTypes.PORTAL_SPECIFIC && !valueDuplicates[objectType.name.toLowerCase()]) {
      // Portal specific types with unique simple names should use simple names
      // but not lowercased because portal-specific simple names are case-sensitive
      // prepend p_ to designate as portal-specific and avoid builtin type shadowing
      option.value = `p_${objectType.name}`;
    }

    // If option value is now the simple name, we should save it under the
    // simple name key alongside its FQN key for getSelectedCrmObjectType
    optionsByValue[option.value] = option;
    return option;
  }).sort((objectTypeA, objectTypeB) => {
    if (objectTypeA.text < objectTypeB.text) {
      return -1;
    }
    if (objectTypeA.text > objectTypeB.text) {
      return 1;
    }
    return 0;
  });
};
const memoizedCreateCrmObjectTypeOptions = memoize(createCrmObjectTypeOptions, crmObjectTypes => Object.keys(crmObjectTypes).join('.'));

// used by DM
export const getCrmObjectTypeOptions = createSelector([getCrmObjectTypes], crmObjectTypes => memoizedCreateCrmObjectTypeOptions(crmObjectTypes));
export const getCrmObjectSchema = (state, props) => state.resources.crmObjects.crmObjectSchemas[props.objectType];
export const getCrmObjectSchemaGroups = (state, props) => state.resources.crmObjects.crmObjectSchemaGroups[props.objectType];

//used in product field for the create/edit form
export const getCrmObjectSchemaUpdateForm = createSelector([getCrmObjectSchema, getCrmObjectSchemaGroups], (crmObjectSchema, crmObjectSchemaGroups) => {
  if (crmObjectSchema && crmObjectSchema.properties && crmObjectSchemaGroups && crmObjectSchemaGroups.length) {
    const crmObjectSchemaGrouped = crmObjectSchema.properties.reduce((hash, property) => {
      //filter this out to match product lib
      if (property.name === CRM_OBJECT_FOLDER_NAME_PROP) {
        return hash;
      }
      const existingGroupIndex = hash.findIndex(group => group.name === property.groupName);
      if (existingGroupIndex >= 0) {
        hash[existingGroupIndex].allPropertyDefinitions.push(property);
      } else {
        const initGroup = crmObjectSchemaGroups.find(group => group.name === property.groupName);
        if (initGroup) {
          initGroup.allPropertyDefinitions = [property];
          hash.push(initGroup);
        }
      }
      return hash;
    }, []);
    return sortBy(crmObjectSchemaGrouped, 'displayOrder');
  } else return undefined;
});

// used by DM
export const getSelectedCrmObjectType = (state, props) => {
  const crmObjectSchema = getCrmObjectSchema(state, props);
  const {
    objectType
  } = props || {};
  // If schema fetch succeeded we just need to figure out if we're grabbing the option by simple name or FQN
  // If portal-specific, prepend p_ and maintain typecase in case value was added before p_ designation update
  if (crmObjectSchema) {
    const builtinOptionKey = objectType.toLowerCase();
    const portalOptionkey = `p_${objectType}`;
    const option = optionsByValue[builtinOptionKey] || optionsByValue[portalOptionkey];
    if (option) return option.value;
  }
  return objectType;
};
export const getCrmObjectPropertyOptions = createSelector([getCrmObjectSchema], crmObjectSchema => {
  const {
    properties
  } = crmObjectSchema || {};
  if (!properties) return [];
  return properties.reduce((acc, property) => {
    if (!property.hidden) {
      acc.push({
        text: property.label,
        value: property.name
      });
    }
    return acc;
  }, []);
});
const getSelectedCrmObjectProperty = (state, props) => {
  const {
    objectType,
    property
  } = props || {};
  const propertiesByObjectType = state.resources.crmObjects.selectedCrmObjectProperties[objectType];
  return propertiesByObjectType && propertiesByObjectType[property] || {
    text: property
  };
};
export const getCrmObjectPropertyLabel = createSelector([getCrmObjectSchema, getSelectedCrmObjectProperty], (crmObjectSchema, property) => {
  if (property && property.value) return property.text;
  let propertyLabel = '';
  const {
    properties
  } = crmObjectSchema || {};
  if (properties) {
    propertyLabel = (properties.find(item => property.text === item.name) || {}).label;
  }
  return propertyLabel;
});
export const getCrmObjects = (state, props) => state.resources.crmObjects.crmObjects[props.objectType] || {};
export const getCrmObjectsWithPropertiesObject = (state, props) => state.resources.crmObjects.crmObjectsWithPropertiesObject[props.objectType] || {};
export const getCrmObject = (state, props) => {
  const crmObjects = state.resources.crmObjects.crmObjects[props.objectType];
  return crmObjects && crmObjects[props.id];
};
const getCrmObjectsCacheOrder = (state, props) => state.resources.crmObjects.crmObjectsCacheOrder[props.objectType] || [];
export const getCrmObjectCache = (state, props) => {
  const cacheOrder = state.resources.crmObjects.crmObjectsCache[props.objectType];
  return cacheOrder && cacheOrder[props.id];
};
export const getCrmObjectsCache = (state, props) => state.resources.crmObjects.crmObjectsCache[props.objectType] || {};
export const getCrmObjectsCacheOrdered = createSelector([getCrmObjectsCache, getCrmObjectsCacheOrder], (crmObjectsCache, order) => order.map(id => crmObjectsCache[id]));

//T ODO refactor this status to not also save ids?
// https://git.hubteam.com/HubSpot/ContentUILib/pull/4005#discussion_r2756364
export const getCrmObjectsCacheRequestStatus = state => state.resources.crmObjects.crmObjectsCacheRequestStatus;
export const getCrmObjectFetchStatus = (state, props) => {
  const requestStatuses = state.resources.crmObjects.requestStatus[props.objectType];
  return requestStatuses && requestStatuses[props.id];
};
export const getCrmObjectSchemaFetchStatus = (state, props) => {
  return state.resources.crmObjects.schemaRequestStatus[props.objectType];
};
export const getCrmObjectFetchFailed = createSelector([getCrmObjectFetchStatus], fetchStatus => fetchStatus === FAILED);
export const getCrmObjectSchemaFetchFailed = createSelector([getCrmObjectSchemaFetchStatus], fetchStatus => fetchStatus === FAILED);
const getDisplayOptionsFromProps = (state, props) => {
  return props ? {
    displayFormat: props.displayFormat,
    displayProperties: props.displayProperties
  } : {};
};
export const getCrmObjectsOptions = createSelector([getCrmObjectsCache, getCrmObjectsCacheOrder, getCrmObjectSchema, getDisplayOptionsFromProps], (crmObjectsCache, order, crmObjectSchema, displayOptions) => order.reduce((hash, id) => {
  const crmObject = crmObjectsCache[id];
  hash.push({
    value: id,
    text: getCrmObjectLabel(crmObject, crmObjectSchema, displayOptions)
  });
  return hash;
}, []));
export const getDefaultCrmObjectsOptions = createSelector([getCrmObjects, getCrmObjectSchema, getDisplayOptionsFromProps], (crmObjects, crmObjectSchema, displayOptions) => Object.keys(crmObjects).reduce((hash, id) => {
  const crmObject = crmObjects[id];
  hash.push({
    value: tryParseNumber(id),
    text: getCrmObjectLabel(crmObject, crmObjectSchema, displayOptions)
  });
  return hash;
}, []));
export const getEditCrmObjectRequestStatus = (state, props) => state.resources.crmObjects.editCrmObjectRequestStatus[props.objectType] || {};
export const getCrmObjectsCacheTotal = (state, props) => state.resources.crmObjects.crmObjectsCacheTotal[props.objectType];
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useApprovalConditions } from './useApprovalConditions';
import { useApprovalContext } from './useApprovalContext';
import unifiedEventsApi from '../data/unifiedEventsApi';
import { reportRequestException } from '../utils/sentryUtils';
import { checkUnifiedEventsPropertiesUtils } from '../utils/unifiedEventsResponseShapeUtils';
export default function useUnifiedEvents() {
  const {
    meta
  } = useApprovalContext();
  const {
    isFetchComplete
  } = useApprovalConditions();
  const [events, setEvents] = useState([]);
  const [after, setAfter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadedData, setHasLoadedData] = useState(false);
  const {
    approvalType,
    approvalStatus,
    stepCount,
    pendingCount,
    approvedCount,
    approvableObjectId
  } = meta;
  const fetchUnifiedEvents = useCallback(afterQuery => {
    if (!approvableObjectId) {
      return;
    }
    setIsLoading(true);
    unifiedEventsApi.fetchUnifiedEvents(approvalType, approvableObjectId, afterQuery).then(res => {
      res.results.forEach(event => checkUnifiedEventsPropertiesUtils(event));
      if (afterQuery) {
        setEvents(prev => [...prev, ...res.results]);
      } else {
        setEvents(res.results);
      }
      if (res.paging && res.paging.next && res.paging.next.after) {
        setAfter(res.paging.next.after);
      } else {
        setAfter(undefined);
      }
    }).catch(err => {
      reportRequestException('fetchUnifiedEvents', err);
    }).finally(() => {
      setIsLoading(false);
      setHasLoadedData(true);
    });
  }, [approvableObjectId, approvalType]);
  const loadMore = useCallback(() => {
    fetchUnifiedEvents(after);
  }, [fetchUnifiedEvents, after]);
  useEffect(() => {
    // Only load once data has loaded or else
    // the other dependencies will change triggering two fetches
    // on page load.
    if (!isFetchComplete) {
      return;
    }
    fetchUnifiedEvents();
  }, [fetchUnifiedEvents, approvableObjectId, approvalType, isFetchComplete,
  // When status, or count of steps change, we assume a change has occured.
  pendingCount, approvedCount, stepCount, approvalStatus]);
  const approvalEventsMap = useMemo(() => events.reduce((prev, current) => {
    if (!prev[current.objectId]) {
      prev[current.objectId] = [current];
    } else {
      prev[current.objectId].push(current);
    }
    return prev;
  }, {}), [events]);
  const approvalEventsGrouped = Object.keys(approvalEventsMap).map(key => approvalEventsMap[key]);
  return {
    events,
    approvalEventsMap,
    approvalEventsGrouped,
    after,
    loadMore,
    isLoading,
    hasLoadedData,
    approvalType
  };
}
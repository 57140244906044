'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_PUBLISH_DATE, UPDATE_PUBLISH_IMMEDIATELY, UPDATE_ORIGINAL_PUBLISH_DATE } from 'ContentEditorUI/redux/actions/actionTypes';

// Note, our API response has `publishDateLocalTime` too, but I'm choosing to ignore it for now. It
// seems un-to-rarely-used

const basePublishReducer = (state = ImmutableMap(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        const {
          publishImmediately,
          publishDate
        } = content;
        return state.merge({
          originalPublishDate: publishDate,
          publishDate,
          publishImmediately
        });
      }
    case UPDATE_PUBLISH_DATE:
      return state.set('publishDate', payload);
    case UPDATE_PUBLISH_IMMEDIATELY:
      return state.set('publishImmediately', payload.publishImmediately).set('publishDate', payload.publishDate);
    case UPDATE_ORIGINAL_PUBLISH_DATE:
      return state.set('originalPublishDate', payload);
    default:
      {
        return state;
      }
  }
};
export default basePublishReducer;
import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { WEB_INTERACTIVES_FETCH_CALL_TO_ACTION, WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION, WEB_INTERACTIVES_SAVE_CALL_TO_ACTION_CACHE, WEB_INTERACTIVES_CLEAR_CALL_TO_ACTION_CACHE } from 'ContentData/actions/ActionTypes';
import { PENDING, UNINITIALIZED, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
function webInteractives(state = {}, action) {
  switch (action.type) {
    case WEB_INTERACTIVES_FETCH_CALL_TO_ACTION:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }
    case WEB_INTERACTIVES_SAVE_CALL_TO_ACTION_CACHE:
      {
        const {
          callToAction
        } = action;
        if (!callToAction) return state;
        return Object.assign({}, state, {
          [callToAction.id]: callToAction
        });
      }
    default:
      return state;
  }
}
function webInteractivesCache(state = {}, action) {
  switch (action.type) {
    case WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          objects
        } = response;
        if (!objects) return state;
        return objects.reduce((hash, page) => {
          hash[page.id] = page;
          return hash;
        }, Object.assign({}, state));
      }
    case WEB_INTERACTIVES_CLEAR_CALL_TO_ACTION_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }
    default:
      return state;
  }
}
function webInteractivesCacheOrder(state = [], action) {
  switch (action.type) {
    case WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        return response.objects.reduce((hash, page) => {
          hash.push(page.id);
          return hash;
        }, [...state]);
      }
    case WEB_INTERACTIVES_CLEAR_CALL_TO_ACTION_CACHE:
      {
        if (state.length) return [];
        return state;
      }
    default:
      return state;
  }
}
function webInteractivesRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case WEB_INTERACTIVES_FETCH_CALLS_TO_ACTION:
      {
        const {
          response,
          error
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return requestStatus;
      }
    default:
      return state;
  }
}
export default combineReducers({
  webInteractives,
  webInteractivesCache,
  webInteractivesCacheOrder,
  webInteractivesRequestStatus,
  requestStatus: createGenericRequestStatusReducer(WEB_INTERACTIVES_FETCH_CALL_TO_ACTION)
});
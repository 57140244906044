export const SUPPORTED_HUBS = {
  MARKETING_HUB: 'MARKETING_HUB',
  SERVICE_HUB: 'SERVICE_HUB',
  CMS_HUB: 'CMS_HUB',
  SALES_HUB: 'SALES_HUB',
  OPS_HUB: 'OPS_HUB'
};
export const SUPPORTED_HUBS_REQUIRED_SCOPES = {
  [SUPPORTED_HUBS.MARKETING_HUB]: 'commenting-access-marketing-hub',
  [SUPPORTED_HUBS.SERVICE_HUB]: 'commenting-access-service-hub',
  [SUPPORTED_HUBS.CMS_HUB]: 'commenting-access-cms-hub',
  [SUPPORTED_HUBS.OPS_HUB]: 'commenting-access-ops-hub',
  [SUPPORTED_HUBS.SALES_HUB]: 'commenting-access-sales-hub'
};
'use es6';

import { fetchByPath, fetchThemeCustomFonts } from 'ContentEditorUI/api/ThemesApi';
import { FETCH_THEME_FAILED, FETCH_THEME_SUCCEEDED, FETCH_THEME_STARTED, FETCH_CUSTOM_FONTS_FAILED, FETCH_CUSTOM_FONTS_SUCCEEDED, FETCH_CUSTOM_FONTS_STARTED, UPDATE_THEME_VARIANT } from 'ContentEditorUI/redux/actions/actionTypes';

// REQUEST FAILURES ---------------------->

const fetchFailure = (type, error) => {
  return {
    type,
    error
  };
};

// REQUEST SUCCESSES --------------------->

const fetchSuccess = (type, response) => {
  return {
    type,
    response
  };
};

// REQUESTS ------------------------------>

export const fetchThemeAndSettingsByPath = themePath => dispatch => {
  dispatch({
    type: FETCH_THEME_STARTED
  });
  fetchByPath(themePath).then(resp => {
    if (resp) {
      dispatch(fetchSuccess(FETCH_THEME_SUCCEEDED, resp));
    } else {
      dispatch(fetchFailure(FETCH_THEME_FAILED, resp));
    }
  }).catch(resp => dispatch(fetchFailure(FETCH_THEME_FAILED, resp)));
};
export const fetchCustomFontsByThemePath = themePath => dispatch => {
  dispatch({
    type: FETCH_CUSTOM_FONTS_STARTED
  });
  fetchThemeCustomFonts(themePath).then(resp => {
    if (resp) {
      dispatch(fetchSuccess(FETCH_CUSTOM_FONTS_SUCCEEDED, resp));
    } else {
      dispatch(fetchFailure(FETCH_CUSTOM_FONTS_FAILED, resp));
    }
  }).catch(resp => dispatch(fetchFailure(FETCH_CUSTOM_FONTS_FAILED, resp)));
};

// UPDATES ------------------------------->

export const updateThemeVariantName = themeVariant => dispatch => {
  dispatch({
    type: UPDATE_THEME_VARIANT,
    payload: {
      themeVariant
    }
  });
};
import { FETCH_PORTAL_SCOPES_SUCCESS, FETCH_PORTAL_INFO_SUCCESS, FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS, FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS, FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS, FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS, FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS, FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  portalInfo: {},
  scopes: [],
  multiDomainPublishingScopeChangeInfo: {},
  customDomainsPurgeDateForFreeLP: null,
  dynamicPageAccessPurgeDate: null,
  sitePagesPasswordProtectionPurgeDate: null,
  landingPagesPasswordProtectionPurgeDate: null,
  audienceAccessPurgeDate: null
};
const portalReducer = (state = initialState, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case FETCH_PORTAL_SCOPES_SUCCESS:
      draft.scopes = payload.scopes;
      return draft;
    case FETCH_PORTAL_INFO_SUCCESS:
      draft.portalInfo = payload;
      return draft;
    case FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS:
      draft.multiDomainPublishingScopeChangeInfo = payload;
      return draft;
    case FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS:
      draft.customDomainsPurgeDateForFreeLP = payload.date;
      return draft;
    case FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS:
      draft.dynamicPageAccessPurgeDate = payload.date;
      return draft;
    case FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS:
      draft.sitePagesPasswordProtectionPurgeDate = payload.date;
      return draft;
    case FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS:
      draft.landingPagesPasswordProtectionPurgeDate = payload.date;
      return draft;
    case FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS:
      draft.audienceAccessPurgeDate = payload.date;
      return draft;
    default:
      return draft;
  }
});
export default portalReducer;
import { combineReducers } from 'redux';
import { PODCAST_EPISODES_FETCH, PODCAST_SHOWS_FETCH } from '../actions/ActionTypes';
import { createSimpleRequestStatusReducer } from '../helpers/reduxHelpers';
const INITIAL_STATE = {
  episodes: null,
  shows: null
};
const podcasts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PODCAST_EPISODES_FETCH:
      {
        if (action.response) {
          return Object.assign({}, state, {
            episodes: action.response.results.map(item => item.values)
          });
        }
        return state;
      }
    case PODCAST_SHOWS_FETCH:
      {
        if (action.response) {
          return Object.assign({}, state, {
            shows: action.response.results.map(item => item.values)
          });
        }
        return state;
      }
    default:
      return state;
  }
};
export default combineReducers({
  podcasts,
  episodesFetchStatus: createSimpleRequestStatusReducer(PODCAST_EPISODES_FETCH),
  showsFetchStatus: createSimpleRequestStatusReducer(PODCAST_SHOWS_FETCH)
});
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { DEFAULT_ICON_SET } from 'ContentUtils/constants/IconSets';

/**
 * GET /content-icons/v1
 *
 * @param {String} searchTerm
 * @param {Number} offset
 * @param {Number} limit
 *
 * @returns {Array} request for list of icons
 */
export function fetch(iconSet, query, ...rest) {
  // SeamlessJS in the design manager can override 'set' to be its setter function
  if (!iconSet || typeof iconSet !== 'string') {
    iconSet = DEFAULT_ICON_SET;
  }
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`content-icons/v1/sets/${iconSet}`, {
    query
  });
}
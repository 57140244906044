'use es6';

import http from 'hub-http/clients/apiClient';
const URI = 'cos-domains/v1';
export const fetchDomainExcludedReason = (domain, userId, contentTypeCategoryId) => {
  return http.get(`${URI}/permitted-domains/exclusion-reason/${domain}`, {
    query: {
      contentTypeCategoryId,
      userId
    }
  }).then(({
    filteringReason
  }) => filteringReason);
};
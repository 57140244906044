'use es6';

import { SAVED_MODULE, OPENED_PANEL } from 'ContentEditorUI/redux/actions/actionTypes';
const justSavedModuleReducer = (state = '', {
  type,
  payload
}) => {
  switch (type) {
    case OPENED_PANEL:
      return '';
    case SAVED_MODULE:
      return payload.id;
    default:
      return state;
  }
};
export default justSavedModuleReducer;
'use es6';

import getLang from 'I18n/utils/getLang';
const knowledgeBaseDomain = 'knowledge.hubspot.com';
const langRegex = new RegExp(/knowledge.hubspot.com\/\w{2}\//);
const getKnowledgeLang = lang => {
  const swapLangs = {
    ja: 'jp',
    zh: 'zh-tw'
  };
  return swapLangs[lang] ? swapLangs[lang] : lang;
};
const ignoreLangs = {};
export default (url => {
  const urlBaseMatch = langRegex.test(url);
  const knowledgeLang = getKnowledgeLang(getLang());
  if (ignoreLangs[knowledgeLang] || urlBaseMatch || knowledgeLang === 'en') {
    return url;
  }
  return url.replace(knowledgeBaseDomain, `${knowledgeBaseDomain}/${knowledgeLang}`);
});
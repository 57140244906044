'use es6';

import Categories from 'ContentUtils/constants/Categories';
export const getCategoryName = (categoryId, isSitePage) => {
  let category;

  // "Landing Pages" and "Site Pages" share a category ID of 1
  // "Site Pages" then have a sub_category of "site_page"
  if (categoryId === Categories.landing_page.id) {
    if (isSitePage) {
      category = Categories.site_page;
    } else {
      category = Categories.landing_page;
    }
  } else {
    category = Categories[categoryId];
  }
  return category ? category.name : null;
};
export const getCategoryNameFromRawContentSchema = contentSchema => {
  const {
    categoryId,
    subcategory
  } = contentSchema.content || {};
  return getCategoryName(categoryId, subcategory === 'site_page');
};
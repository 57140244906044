import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
const MAX_LINKS_COUNT = 300;
export const decreaseOutboundLinkCountAudit = artifacts => {
  const links = artifacts[GathererKeys.LINKS];
  const linksCount = links ? links.length : 0;
  let status;
  if (linksCount < MAX_LINKS_COUNT) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }
  return {
    status,
    linksCount,
    maxLinksCount: MAX_LINKS_COUNT
  };
};
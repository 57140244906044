import { createSelector } from 'reselect';
export const getCta = (state, props) => state.resources.ctas.ctas[props.guid];
export const getCtas = state => state.resources.ctas.ctas;
export const getCtaFetchStatus = (state, props) => state.resources.ctas.requestStatus[props.guid];
const createCtaOptionsSelector = selector => createSelector([selector], resources => {
  return Object.keys(resources).map(guid => {
    const option = resources[guid];
    const {
      name
    } = option;
    const defaultCta = option.ctaInfo.filter(_cta => {
      return _cta.isDefault;
    })[0] || option.ctaInfo[0] || {};
    const {
      image
    } = defaultCta;
    return {
      value: guid,
      text: name,
      image
    };
  });
});
export const getDefaultCtasOptions = createCtaOptionsSelector(getCtas);
export const makeGetSelectedCtaImage = (ctaSelector = getCta) => {
  return createSelector([ctaSelector], cta => {
    if (!cta) return null;
    const defaultCta = cta.ctaInfo.filter(_cta => {
      return _cta.isDefault;
    })[0] || cta.ctaInfo[0] || {};
    return defaultCta.image;
  });
};
import { FETCH_BRAND_COLORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const brandSettingsReducer = (state = {}, action) => produce(state, draft => {
  const type = action.type;
  switch (type) {
    case FETCH_BRAND_COLORS_SUCCEEDED:
      {
        draft.colors = action.payload;
        return draft;
      }
    default:
      return draft;
  }
});
export default brandSettingsReducer;
import http from 'hub-http/clients/apiClient';
const URL = 'content-embed/v1/embed';
const EMBED_FETCH_LIMIT = 10;
const fetchAllEmbeds = async (currentEmbeds = [], offset = 0) => {
  const {
    embedData,
    totalCount
  } = await http.get(`${URL}/data`, {
    query: {
      limit: EMBED_FETCH_LIMIT,
      offset
    }
  });
  const embeds = currentEmbeds.concat(embedData);
  if (totalCount > embeds.length) {
    return fetchAllEmbeds(embeds, offset + EMBED_FETCH_LIMIT);
  }
  return {
    embedData: embeds,
    totalCount
  };
};
export function fetchEmbeds() {
  return fetchAllEmbeds();
}
'use es6';

export const pauseRedux = () => {
  console.log('Redux paused!');
  window.HS_PAUSE_REDUX = true;
};
export const restartRedux = () => {
  console.log('Redux resumed');
  delete window.HS_PAUSE_REDUX;
};
export const togglePauseRedux = () => {
  const isInPreviewFrame = window.location.search.includes('inpageEditorUI=true');
  if (isInPreviewFrame) {
    if (window.HS_PAUSE_REDUX) {
      restartRedux();
    } else {
      pauseRedux();
    }
  } else {
    window.hubspot.ContentEditorUI.eventBus.postMessage({
      action: 'togglePauseRedux'
    });
  }
};
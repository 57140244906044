'use es6';

import { ALIGNMENTS } from 'ContentUtils/constants/Alignments';
import SIZE_TYPES from 'ContentUtils/constants/BackgroundImageSizeTypes';
import { getTreeNodeDomProperties } from '../helpers';
export const BG_IMAGE_SIZE_OPTIONS = [SIZE_TYPES.cover, SIZE_TYPES.contain, SIZE_TYPES.auto];
export const convertPositionValueToPositionStyleString = positionValue => {
  switch (positionValue) {
    case ALIGNMENTS.TOP_LEFT:
      return 'left top';
    case ALIGNMENTS.TOP_CENTER:
      return 'center top';
    case ALIGNMENTS.TOP_RIGHT:
      return 'right top';
    case ALIGNMENTS.MIDDLE_LEFT:
      return 'left center';
    case ALIGNMENTS.MIDDLE_CENTER:
      return 'center center';
    case ALIGNMENTS.MIDDLE_RIGHT:
      return 'right center';
    case ALIGNMENTS.BOTTOM_LEFT:
      return 'left bottom';
    case ALIGNMENTS.BOTTOM_CENTER:
      return 'center bottom';
    case ALIGNMENTS.BOTTOM_RIGHT:
      return 'right bottom';
    default:
      throw new Error(`Invalid background position value: ${positionValue}`);
  }
};
export const isValidBackgroundImageValue = backgroundImage => backgroundImage && backgroundImage.imageUrl && backgroundImage.backgroundSize && backgroundImage.backgroundPosition;
export const generateBackgroundImageCss = styleValue => {
  if (!styleValue) {
    console.warn('Background image value must be an object in the format { imageUrl, backgroundSize, backgroundPosition, colorOverlay }');
    return null;
  }
  if (!isValidBackgroundImageValue(styleValue)) {
    console.warn('Background image value is not valid `imageUrl`, `backgroundSize`, and `backgroundPosition` keys are required');
    return null;
  }
  const {
    imageUrl,
    backgroundSize,
    backgroundPosition,
    colorOverlay
  } = styleValue;
  if (backgroundSize && !BG_IMAGE_SIZE_OPTIONS.includes(backgroundSize)) {
    console.warn(`Invalid Background image size value: ${backgroundSize}`);
    return null;
  }
  const cssProperties = {
    backgroundImage: `url('${imageUrl}')`,
    backgroundSize,
    backgroundPosition: `${convertPositionValueToPositionStyleString(backgroundPosition)}`,
    backgroundRepeat: 'no-repeat'
  };
  if (colorOverlay) {
    console.warn('TODO, implement color overlay BG style');
  }
  return cssProperties;
};
export const calculateBackgroundImageClasses = (treeNode, styleValue) => {
  const backgroundImageCss = generateBackgroundImageCss(styleValue);
  if (!backgroundImageCss) return [];
  const {
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat
  } = backgroundImageCss;
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  const cssProperties = {
    'background-image': `${backgroundImage} !important`,
    'background-size': `${backgroundSize} !important`,
    'background-position': `${backgroundPosition} !important`,
    'background-repeat': `${backgroundRepeat} !important`
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-image`,
    cssSelector: `.${rendererName}-background-image`,
    cssProperties
  }];
};
import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
export const addTitleAudit = artifacts => {
  const pageTitle = artifacts[GathererKeys.PAGE_TITLE];
  let status;
  if (pageTitle === '' || pageTitle === undefined) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }
  return {
    status
  };
};
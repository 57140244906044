'use es6';

import DEEP_LINK_QUERIES from 'ContentEditorUI/data/pageSettingsDeepLinkQueries';
import { POST_DEEP_LINK_QUERIES } from 'ContentEditorUI/data/postSettingsDeepLinkQueries';
export const SETTINGS_FIELDS = {
  HTML_TITLE: 'htmlTitle',
  META_DESCRIPTION: 'metaDescription'
};
export const SETTING_REC_TYPE_TO_FIELD_MAP = {
  ADD_TITLE: SETTINGS_FIELDS.HTML_TITLE,
  SHORTEN_TITLE: SETTINGS_FIELDS.HTML_TITLE,
  REMOVE_DOMAIN_FROM_TITLE: SETTINGS_FIELDS.HTML_TITLE,
  ADD_META_DESCRIPTION: SETTINGS_FIELDS.META_DESCRIPTION,
  REMOVE_TITLE_FROM_META_DESCRIPTION: SETTINGS_FIELDS.META_DESCRIPTION,
  SHORTEN_META_DESCRIPTION: SETTINGS_FIELDS.META_DESCRIPTION
};
export const PAGE_SETTINGS_TAB_FIELD = {
  htmlTitle: DEEP_LINK_QUERIES.GENERAL.TITLE,
  metaDescription: DEEP_LINK_QUERIES.GENERAL.META_DESCRIPTION
};
export const POST_SETTINGS_TAB_FIELD = {
  htmlTitle: POST_DEEP_LINK_QUERIES.GENERAL.TITLE,
  metaDescription: POST_DEEP_LINK_QUERIES.METADATA.META_DESCRIPTION
};
module.exports = {
  "xrayPageView": {
    "name": "page view",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "sectionView": {
    "name": "section view",
    "class": "view",
    "properties": {
      "section": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedFixLink": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "recommendationType": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "expandedRecommendationGroup": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "group": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedLearnMoreLink": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "recommendationType": "string",
      "linkAddress": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedCreateTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  },
  "clickedAttachTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  },
  "clickedDetachTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  }
};
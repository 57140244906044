// Copy of https://git.hubteam.com/HubSpot/Commenting/blob/master/CommentingBase/src/main/java/com/hubspot/commenting/base/CommentObjectType.java
// Remember to update also the objectType array prop for collabSidebarInteraction and collabSidebarUsage
// here https://git.hubteam.com/HubSpot/collaboration-sidebar/blob/master/collaboration-sidebar-embeddable/static/events.yaml
// as well as mapObjectTypeIdToCommentObjectType below if there is a corresponding crm object
const CommentObjectType = {
  ENGAGEMENT: 'ENGAGEMENT',
  UNKNOWN: 'UNKNOWN',
  FLOW_ACTION: 'FLOW_ACTION',
  COMMENTING_SANDBOX: 'COMMENTING_SANDBOX',
  MARKETING_EMAIL: 'MARKETING_EMAIL',
  FORM: 'FORM',
  LEAD_FLOW: 'LEAD_FLOW',
  LANDING_PAGE: 'LANDING_PAGE',
  WEBSITE_PAGE: 'WEBSITE_PAGE',
  BLOG_POST: 'BLOG_POST',
  SOCIAL_BROADCAST: 'SOCIAL_BROADCAST',
  AD_CAMPAIGN: 'AD_CAMPAIGN',
  CAMPAIGN: 'CAMPAIGN',
  CTA: 'CTA',
  WORKFLOW: 'WORKFLOW',
  REPORTING_DASHBOARD: 'REPORTING_DASHBOARD',
  BLOG_LISTING_PAGE: 'BLOG_LISTING_PAGE',
  MARKETING_EVENT: 'MARKETING_EVENT',
  WEB_INTERACTIVE: 'WEB_INTERACTIVE',
  MARKETING_SMS: 'MARKETING_SMS',
  USER_AUDIT_LOG: 'USER_AUDIT_LOG',
  OBJECT_LIST: 'OBJECT_LIST',
  DEAL: 'DEAL',
  KNOWLEDGE_ARTICLE: 'KNOWLEDGE_ARTICLE'
};
export default CommentObjectType;
export const objectTypeIdToCommentObjectTypeMap = {
  '0-3': CommentObjectType.DEAL,
  '0-4': CommentObjectType.ENGAGEMENT,
  '0-25': CommentObjectType.LANDING_PAGE,
  '0-28': CommentObjectType.FORM,
  '0-29': CommentObjectType.MARKETING_EMAIL,
  '0-31': CommentObjectType.AD_CAMPAIGN,
  '0-35': CommentObjectType.CAMPAIGN,
  '0-38': CommentObjectType.WEBSITE_PAGE,
  '0-39': CommentObjectType.BLOG_POST,
  '0-42': CommentObjectType.CTA,
  '0-44': CommentObjectType.WORKFLOW,
  '0-45': CommentObjectType.OBJECT_LIST,
  '0-54': CommentObjectType.MARKETING_EVENT,
  '0-71': CommentObjectType.SOCIAL_BROADCAST,
  '0-91': CommentObjectType.WEB_INTERACTIVE,
  '0-104': CommentObjectType.BLOG_LISTING_PAGE,
  '0-124': CommentObjectType.MARKETING_SMS,
  '0-151': CommentObjectType.KNOWLEDGE_ARTICLE
};
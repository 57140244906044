import { useEffect, useState, useMemo } from 'react';
import UICardGrid from 'UIComponents/card/UICardGrid';
import UIMultiColumnGroup from 'UIComponents/layout/UIMultiColumnGroup';
import UIMultiColumnItem from 'UIComponents/layout/UIMultiColumnItem';
const TBODY_ELEMENT_TYPE = 'TBODY';
const THEAD_ELEMENT_TYPE = 'THEAD';
const TR_ELEMENT_TYPE = 'TR';
export default function useCommentableAreaStyles({
  commentableAreaRef,
  children
}) {
  const [isTableChild, setIsTableChild] = useState(false);
  const [isGridChild, setIsGridChild] = useState(false);
  useEffect(() => {
    // Checks if parent is UICardGrid so we can add extra styles usually passed by a > * combinator
    if (commentableAreaRef.current && commentableAreaRef.current.parentNode) {
      const parent = commentableAreaRef.current.parentNode;
      setIsGridChild(parent.className.includes(UICardGrid.displayName));
    }

    // Checks if parent is a UIMultiColumn and if the child is a UIMultiColumn group or item to apply border styles
    // that would usually collapse together
    if (commentableAreaRef.current && commentableAreaRef.current.parentNode && children) {
      const isChildMultiColumn = children.type && (children.type.name === UIMultiColumnGroup.name || children.type.name === UIMultiColumnItem.name);
      if (isChildMultiColumn) {
        commentableAreaRef.current.parentNode.style.borderTop = 0;
      }
    }

    // Checks if parent is a table display to reapply table display to the
    // commentable area
    if (commentableAreaRef.current && commentableAreaRef.current.parentNode) {
      const parentNode = commentableAreaRef.current.parentNode;
      const parentStyles = getComputedStyle(parentNode);
      const inlineParentStyles = parentNode.style;
      setIsTableChild(parentStyles.display === 'table' || inlineParentStyles && inlineParentStyles.display === 'table' || parentNode.nodeName === TBODY_ELEMENT_TYPE || parentNode.nodeName === THEAD_ELEMENT_TYPE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentableAreaRef.current]);
  const isTr = useMemo(() => {
    if (commentableAreaRef.current) {
      return commentableAreaRef.current.nodeName === TR_ELEMENT_TYPE;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentableAreaRef.current]);
  return {
    isTableChild,
    isGridChild,
    isTr
  };
}
'use es6';

import { TITLE_OPTIMIZATION_TYPES, META_DESCRIPTION_OPTIMIZATION_TYPES } from 'ContentEditorUI/constants/OptimizationTypes';
import { useOpenPatagoniaSettingsModalIfNecessary, useOpenPatagoniaPublishingModalIfNecessary } from 'ContentEditorUI/components/settings/utils';

// Map each optimization type to its settings modal {pane}.{field} for deep linking.
const optimizationDeepLinkConfig = Object.fromEntries([...Object.values(TITLE_OPTIMIZATION_TYPES).map(optType => [optType, 'general.title']), ...Object.values(META_DESCRIPTION_OPTIMIZATION_TYPES).map(optType => [optType, 'general.metaDescription'])]);
function PatagoniaModalQueryChecker() {
  useOpenPatagoniaSettingsModalIfNecessary(optimizationDeepLinkConfig);
  useOpenPatagoniaPublishingModalIfNecessary();
  return null;
}
export default PatagoniaModalQueryChecker;
/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { FETCH_PAGE_LIMIT_FAILED, FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_PAGE_LIMIT_STARTED, FETCH_MLANG_PAGES_LIMIT_STARTED, FETCH_MLANG_PAGES_LIMIT_FAILED, FETCH_MLANG_PAGES_LIMIT_SUCCEEDED } from '../actions/actionTypes';
import { fetchMlangPagesLimit as fetchMlangPagesLimitApi } from '../../api/PageLimitApi';
import { getIsLandingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getIsUngatedForSharedPageLimits } from 'ContentEditorUI/redux/selectors/authSelectors';
import { fetchSharedPageLimit } from '../../api/PageLimitApi';

// REQUEST FAILURES ---------------------->

const fetchPageLimitFailed = error => {
  return {
    type: FETCH_PAGE_LIMIT_FAILED,
    payload: error
  };
};
const fetchMlangPagesLimitFailed = error => {
  return {
    type: FETCH_MLANG_PAGES_LIMIT_FAILED,
    payload: error
  };
};

// REQUEST SUCCESSES --------------------->

const fetchPageLimitSucceeded = limit => {
  return {
    type: FETCH_PAGE_LIMIT_SUCCEEDED,
    payload: limit
  };
};
const fetchMlangPagesLimitSucceeded = limit => {
  return {
    type: FETCH_MLANG_PAGES_LIMIT_SUCCEEDED,
    payload: limit
  };
};

// REQUESTS ------------------------------>

function parseLimitValue(limitsData) {
  let limitValue = undefined;
  if (limitsData) {
    if (limitsData.limit) {
      limitValue = limitsData.limit;
    } else if (limitsData.pageLimit) {
      limitValue = limitsData.pageLimit;
    }
  }
  return limitValue;
}
export const fetchPageLimit = () => (dispatch, getState) => {
  const isLandingPage = getIsLandingPage(getState());
  const isUngatedForSharedPageLimits = getIsUngatedForSharedPageLimits(getState());
  const contentType = isLandingPage ? 'landing-pages' : 'site-pages';
  dispatch({
    type: FETCH_PAGE_LIMIT_STARTED
  });
  const success = resp => {
    if (resp) {
      const limitName = isLandingPage ? 'cms-landing-pages' : 'cms-site-pages';
      const sharedPageLimitData = resp.find(limitsData => limitsData.limitName === 'cms-pages');
      const isApplyingSharedPageLimit = isUngatedForSharedPageLimits && sharedPageLimitData;
      const limitDataForContentType = isApplyingSharedPageLimit ? sharedPageLimitData : resp.find(limitsData => limitsData.limitName === limitName);
      dispatch(fetchPageLimitSucceeded({
        pageLimit: parseLimitValue(limitDataForContentType),
        quantityUsed: limitDataForContentType.quantityUsed,
        isApplyingSharedPageLimit
      }));
      dispatch(fetchPageLimitSucceeded(resp));
    } else {
      dispatch(fetchPageLimitFailed(resp));
    }
  };
  const error = resp => dispatch(fetchPageLimitFailed(resp));
  fetchSharedPageLimit(contentType).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchMlangPagesLimit = contentId => dispatch => {
  dispatch({
    type: FETCH_MLANG_PAGES_LIMIT_STARTED
  });
  const success = resp => {
    if (resp) {
      dispatch(fetchMlangPagesLimitSucceeded(resp));
    } else {
      dispatch(fetchMlangPagesLimitFailed(resp));
    }
  };
  const error = resp => dispatch(fetchMlangPagesLimitFailed(resp));
  fetchMlangPagesLimitApi(contentId).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
'use es6';

import _ from 'underscore';

// This function is very similar to CEUI/moduleSelectors/getDefaultTypeHelper.
// We should aim to eventually consolidate these functions, and there was a previous attempt
// at https://git.hubteam.com/HubSpot/ContentEditorUI/pull/4177#discussion_r559754.
//
// This attempt was reverted because it created more complexity than it removed
// (we still have to hack modules for IPEUI for `custom_widget`, and we don't
// have access to the module's schema in IPEUI).
function getDefaultType(module, builtInTypesByModuleId, customModuleIds = []) {
  if (customModuleIds.includes(module.module_id)) {
    return 'custom_widget';
  }
  return builtInTypesByModuleId[module.module_id] || module.type;
}
export const getDefaultTypeForTestingOnly = getDefaultType;
export function makeSchemaWidgetOverrideable(schemaWidget) {
  return Object.assign({}, schemaWidget, {
    overrideable: true
  });
}

// These functions are similar to very similar to CEUI/data/ModuleTransforms/normalized*
// The are separate historically because sharing them created more complexity than it removed
// and we don't have access to schemaWidget in IPEUI.
//
// (more info in `moduleTransformHelper.js`)
const findIntegerId = widget => typeof widget.key === 'number' ? widget.key : undefined;
export const normalizedKey = widget => {
  return findIntegerId(widget) || widget.name;
};
const getMainRenderLanguage = renderLanguage => {
  //some chosen language does not have subregional version (i.e. it-ch) so we fall back to its main (it) language
  return renderLanguage && renderLanguage.includes('-') ? renderLanguage.split('-')[0] : '';
};
export const getLanguageMessagesToUse = (messages, renderLanguage) => {
  const mainRenderLanguage = getMainRenderLanguage(renderLanguage);
  return messages[renderLanguage] || messages[mainRenderLanguage];
};
export const normalizedId = widget => {
  const integerId = findIntegerId(widget);
  if (widget.name) {
    return widget.name;
  } else if (integerId) {
    return `widget_${findIntegerId(widget)}`;
  }
  return undefined;
};
export function normalizedType(schemaWidget, builtInTypesByModuleId, customModuleIds) {
  return Object.assign({}, schemaWidget, {
    type: getDefaultType(schemaWidget, builtInTypesByModuleId, customModuleIds),
    widgetType: schemaWidget.type
  });
}
export function processModuleForIPEUI({
  builtInTypesByModuleId,
  customModuleIds,
  makeOverrideable,
  module
}) {
  const id = normalizedId(module);
  const key = normalizedKey(module);
  return Object.assign({}, module, makeOverrideable ? makeSchemaWidgetOverrideable(module) : {}, {
    id,
    key
  }, normalizedType(module, builtInTypesByModuleId, customModuleIds));
}
export function getFieldValue(widget, schema, fieldName, renderLanguage = null, defaultValue = '', smartRuleId = null) {
  const {
    body,
    smart_objects,
    widgetType
  } = widget;
  if (smartRuleId && smart_objects) {
    const smartRule = smart_objects.find(smartObject => smartObject.criterion_id === smartRuleId);
    if (smartRule && smartRule.body && typeof smartRule.body[fieldName] !== 'undefined') {
      return smartRule.body[fieldName];
    }
  }
  if (body && typeof body[fieldName] !== 'undefined') {
    return body[fieldName];
  }
  if (widgetType === 'custom_widget' || widgetType === 'module') {
    if (!schema) {
      return defaultValue;
    }
    if (schema && schema.messages && renderLanguage) {
      const languageMessagesToUse = getLanguageMessagesToUse(schema.messages, renderLanguage);
      const translationKey = `${fieldName}.default`;
      if (languageMessagesToUse && languageMessagesToUse[translationKey]) {
        return languageMessagesToUse[translationKey].message;
      }
    }
    const {
      fields
    } = schema;
    const field = _.findWhere(fields, {
      name: fieldName
    });
    if (!field) {
      return defaultValue;
    }
    return field.default;
  }
  return defaultValue;
}
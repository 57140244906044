'use es6';

import { getIsUngatedForFeature } from 'ContentEditorUI/utils/editorConfigSingletonUtils';
let editorConfig = null;
// This is solely for backwards compatibility
let isOnScalableEditor = false;

// When adding a feature flag, make sure to add it to the FEATURE_FLAGS
// in CEUI/redux/constants
const DEFAULT_FEATURE_FLAGS = {
  aiGeneratedContent: true,
  breakpoints: true,
  collaborationSidebar: null,
  cssAssets: true,
  hideLockedEditFormFields: true,
  inlineStyles: false,
  sections: true,
  shouldUseNopeZonesForAllModules: false,
  shouldFocusSingleStaticModule: false,
  smartContent: true,
  templateTypes: true,
  undoRedo: true,
  quickSpot: false
};
let gates = [];
let isInIframe = false;
let hasPermissionsToSave = false;
export const STRIPPED_FUNCTION_INDICATOR = 'CEUI:FEATURE_FLAG_VALUE_THAT_WAS_A_FUNCTION';
const getKeyOffOfCurrentConfigObject = (currentObject, key) => {
  if (typeof currentObject !== 'object') {
    console.log('Current object: ', currentObject);
    throw new Error(`Current item (logged above) is not an object. Thus, cannot index it with the given key ${key}`);
  }
  if (!currentObject.hasOwnProperty(key)) {
    console.log('Current object: ', currentObject);
    throw new Error(`Cannot find key ${key} on current object`);
  }
  return currentObject[key];
};
const checkForEditorConfigExistence = () => {
  if (!editorConfig) {
    throw new Error('Editor config has not been set yet! Make sure to call set(editorConfig) before trying to access any data off of the config');
  }
};
const featureFlagValueIsStrippedFunction = value => isInIframe && typeof value === 'string' && value === STRIPPED_FUNCTION_INDICATOR;
export default {
  set: config => {
    if (editorConfig) {
      throw new Error('Editor config should only be set once!');
    }
    editorConfig = Object.freeze(config);
  },
  get: key => {
    checkForEditorConfigExistence();
    return editorConfig[key];
  },
  getIn: keys => {
    checkForEditorConfigExistence();
    let currentIndexingItem = editorConfig;
    keys.forEach(key => {
      currentIndexingItem = getKeyOffOfCurrentConfigObject(currentIndexingItem, key);
    });
    return currentIndexingItem;
  },
  getFeatureFlagOrDefault: (featureFlag, makeFlagTruthy = true) => {
    if (!isOnScalableEditor) {
      return DEFAULT_FEATURE_FLAGS[featureFlag];
    }
    const features = editorConfig.features;
    const gatedFeatures = editorConfig.gatedFeatures;
    if (gatedFeatures && gatedFeatures.hasOwnProperty(featureFlag)) {
      const gate = gatedFeatures[featureFlag];
      const isUngatedForFeature = getIsUngatedForFeature(featureFlag, gates, gate);
      if (!isUngatedForFeature) {
        return false;
      }
    }
    const featureOnEditorConfig = features[featureFlag];
    if (featureOnEditorConfig === undefined) {
      throw new Error(`Feature flags must be exhaustive, missing a value for the following feature flag: ${featureFlag}`);
    }
    if (featureFlagValueIsStrippedFunction(featureOnEditorConfig)) {
      throw new Error(`Cannot access a function feature flag config value inside of the iframe. Looking for feature flag: ${featureFlag}`);
    }
    if (makeFlagTruthy) {
      return !!featureOnEditorConfig;
    }
    return featureOnEditorConfig;
  },
  getIsOnScalableEditor: () => {
    return isOnScalableEditor;
  },
  getComponents: () => {
    checkForEditorConfigExistence();
    return editorConfig.components;
  },
  setIsOnScalableEditor: value => {
    isOnScalableEditor = value;
  },
  getHasIframe: () => {
    return editorConfig.infra.iframe !== false;
  },
  setGates: authGates => {
    gates = authGates;
  },
  setIsInIframe: value => {
    isInIframe = value;
  },
  setHasPermissionsToSave: value => {
    hasPermissionsToSave = value;
  },
  //comes from collaboration sidebar config and
  //not recommended to be used for scope outside of deep commenting for now
  getHasPermissionsToSave: () => {
    return hasPermissionsToSave;
  },
  log: () => {
    console.log('Current editorConfig:', editorConfig);
  }
};
'use es6';

import { FETCH_LAYOUT_DATA_FAILED, FETCH_LAYOUT_DATA_SUCCEEDED, FETCH_LAYOUT_DATA_STARTED } from 'ContentEditorUI/redux/actions/actionTypes';
import http from 'hub-http/clients/apiClient';
import { LAYOUT_URL } from 'ContentEditorUI/lib/layoutData/constants';

// REQUEST FAILURES ---------------------->

export const fetchLayoutFailed = error => dispatch => {
  dispatch({
    type: FETCH_LAYOUT_DATA_FAILED,
    payload: error
  });
};

// REQUEST SUCCESSES --------------------->

export const fetchLayoutSucceeded = layout => dispatch => {
  dispatch({
    type: FETCH_LAYOUT_DATA_SUCCEEDED,
    payload: layout
  });
};

// REQUESTS ------------------------------>

export const fetchLayout = id => dispatch => {
  dispatch({
    type: FETCH_LAYOUT_DATA_STARTED
  });
  const success = resp => dispatch(fetchLayoutSucceeded(resp));
  const error = resp => dispatch(fetchLayoutFailed(resp));
  http.get(LAYOUT_URL + id).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
'use es6';

import { Iterable } from 'immutable';

// Way to lookup data in Immutable or plain JS object. The intention is _not_ to use this everywhere,
// it is better to stick with one data type or the other if at all possible. So the real intention
// for this is enable core helper functions—that unfortunately need to deal with Immutables and plain
// JS objects—to do it more easily.
export const getImmutableOrPlain = (object, key, defaultValue) => {
  const isImmutable = Iterable.isIterable(object);
  if (isImmutable) {
    return object.get(key, defaultValue);
  }
  return object.hasOwnProperty(key) ? object[key] : defaultValue;
};
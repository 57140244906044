import Raven from 'raven-js';
import { getErrorSubCategoryFromError } from './errorUtils';
import enviro from 'enviro';
const sentryPrefix = 'ui-product-approvals';
export function captureException(exception, extra) {
  Raven.captureException(exception, extra);
}
class ApprovalRequestException extends Error {
  constructor(name = '', message = '', ...args) {
    super(message, ...args);
    this.message = message;
    this.name = `ApprovalRequestException - ${name}`;
  }
}
export function getRequestErrorMessage(error, actionName = '') {
  const env = enviro.isQa() ? 'QA' : 'PROD';
  let errorMessage = `[${sentryPrefix}][${env}][${error.status}]${actionName && `[${actionName}]`}`;
  if (error.status === 0) {
    return null;
  }
  if (error.responseJSON) {
    if (error.responseJSON.subCategory) {
      const subCategory = getErrorSubCategoryFromError(error);
      errorMessage += ` ${subCategory}`;
    }
    if (error.responseJSON.message) {
      errorMessage += ` ${error.responseJSON.message}`;
    }
  }
  return errorMessage;
}
export function reportRequestException(requestName, errorResponse, extra = {}) {
  const requestErrorMessage = getRequestErrorMessage(errorResponse, requestName);
  if (!requestErrorMessage) {
    return;
  }
  try {
    const approvalException = new ApprovalRequestException(requestName, requestErrorMessage);
    captureException(approvalException, {
      level: 'error',
      extra: Object.assign({}, errorResponse.responseJSON, extra)
    });
  } catch (e) {
    captureException(new Error('Unhandled request exception'), {
      level: 'error',
      extra: Object.assign({}, errorResponse.responseJSON, extra)
    });
  }
}
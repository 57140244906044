import { createSelector } from 'reselect';
export function createUndoableSelector(selector, key) {
  return createSelector([selector], result => {
    if (result) {
      return result[key];
    }
    return null;
  });
}
export function createCanUndoSelector(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.past.length > 0;
    }
    return false;
  });
}
export function createCanRedoSelector(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.future.length > 0;
    }
    return false;
  });
}

// CEUI uses this for tracking, maybe useful? Would have to include the past/future action types in the redux state as well
// export function createLastUndoableActionTypeSelector<InnerState, AppState>(
//   selector: Selector<AppState, UndoableState<InnerState>>
// ) {
//   return createSelector([selector], result => {
//     if (result && result.has('pastActionTypes')) {
//       return result.get('pastActionTypes').last();
//     }
//     return '';
//   });
// }

// export const createLastUndoneActionTypeSelector = selector => {
//   return createSelector([selector], result => {
//     if (result && result.has('futureActionTypes')) {
//       return result.get('futureActionTypes').last();
//     }
//     return '';
//   });
// };

export function createRedoCountSelector(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.redoCount;
    }
    return 0;
  });
}
export function createUndoCountSelector(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.undoCount;
    }
    return 0;
  });
}
export function createUndoRedoCountSelector(selector) {
  return createSelector([selector], result => {
    if (result) {
      const redoCount = result.redoCount || 0;
      const undoCount = result.undoCount || 0;
      return undoCount + redoCount;
    }
    return 0;
  });
}
export const ColumnTypes = Object.freeze({
  TEXT: 'TEXT',
  RICHTEXT: 'RICHTEXT',
  URL: 'URL',
  IMAGE: 'IMAGE',
  SELECT: 'SELECT',
  MULTISELECT: 'MULTISELECT',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  NUMBER: 'NUMBER',
  CURRENCY: 'CURRENCY',
  BOOLEAN: 'BOOLEAN',
  LOCATION: 'LOCATION',
  PATH: 'PATH',
  FOREIGN_ID: 'FOREIGN_ID',
  FOREIGN_TABLE: 'FOREIGN_TABLE',
  VIDEO: 'VIDEO',
  FILE: 'FILE',
  CTA: 'CTA'
});
export const DISPLAY_COLUMN_EXCLUSIONS = Object.freeze({
  RICHTEXT: ColumnTypes.RICHTEXT,
  MULTISELECT: ColumnTypes.MULTISELECT,
  BOOLEAN: ColumnTypes.BOOLEAN,
  LOCATION: ColumnTypes.LOCATION,
  FOREIGN_ID: ColumnTypes.FOREIGN_ID,
  FOREIGN_TABLE: ColumnTypes.FOREIGN_TABLE,
  VIDEO: ColumnTypes.VIDEO
});
'use es6';

import PortalIdParser from 'PortalIdParser';
export function identifyUserForFullStory(user, appString) {
  const fullName = `${user.first_name} ${user.last_name}`;
  const email = user.email;
  const portalId = PortalIdParser.get();
  if (window.FS && typeof window.FS.identify === 'function') {
    window.FS.identify(user.user_id, {
      displayName: fullName,
      email,
      portalId,
      app_str: appString
    });
  }
}
import produce from 'immer';
import { GET_CONTENT_ASSISTANT_SETTINGS } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  contentAssistanceEnabled: false,
  blogBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  emailBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  smsBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  pageBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  blogBrandVoiceEnabled: false
};
export const contentAssistantReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_CONTENT_ASSISTANT_SETTINGS:
      {
        draft.contentAssistanceEnabled = action.payload.contentAssistanceEnabled;
        draft.blogBrandVoiceEnabled = action.payload.blogBrandVoiceEnabled;
        const {
          brandVoiceSettings
        } = action.payload;
        if (brandVoiceSettings) {
          const {
            channelSettings
          } = brandVoiceSettings || {};
          // TODO: Update state to have a single `isSetUp` property since that's not specific to apps
          draft.blogBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes('BLOG')
          };
          draft.emailBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes('EMAIL')
          };
          draft.smsBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes('SMS')
          };
          draft.pageBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes('PAGES')
          };
        }
        return draft;
      }
    default:
      return draft;
  }
});
export default contentAssistantReducer;
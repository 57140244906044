import { FETCH_SUPPORTED_LANGUAGES_SUCCEEDED, FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_RENDER_LANGUAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  supportedLanguages: [],
  renderLanguage: null
};
export const languageReducer = (state = initialState, action) => produce(state, draft => {
  const {
    payload,
    type,
    response
  } = action;
  switch (type) {
    case FETCH_SUPPORTED_LANGUAGES_SUCCEEDED:
      draft.supportedLanguages = payload;
      return draft;
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      draft.renderLanguage = response.render_language;
      return draft;
    case UPDATE_RENDER_LANGUAGE:
      draft.renderLanguage = payload;
      return draft;
    default:
      return draft;
  }
});
export default languageReducer;
export const IMAGE_GENERATION_MAX_PROMPT_LENGTH = 400;
export const IMAGE_GENERATION_BATCH_SIZE = 4;
export const IMAGE_GENERATION_V3_BATCH_SIZE = 2;
export const IMAGE_GENERATION_V3_COMMAND = 'test_generate_image_v2';
export const IMAGE_VARIATIONS_COMMAND = 'image_variation_v1';
export const IMAGE_GENERATION_POLLING_INTERVAL = 1000;
export const IMAGE_GENERATION_POLLING_MAX_ATTEMPTS = 30;
export const GENERATED_IMAGE_FOLDER_PATH = 'AI-Generated Media/Images';
export const GeneratedImageDimensions = {
  SQUARE: 'SQUARE',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT'
};
export const GeneratedImageDimensionResolutions = {
  [GeneratedImageDimensions.SQUARE]: {
    width: 1024,
    height: 1024
  },
  [GeneratedImageDimensions.LANDSCAPE]: {
    width: 1792,
    height: 1024
  },
  [GeneratedImageDimensions.PORTRAIT]: {
    width: 1024,
    height: 1792
  }
};

// values are purposefully english as we append them in english as `in {{ value }} style`
export const STYLE_OPTIONS = ['abstract', 'vector graphics', 'pop art', 'minimalist', 'digital art', '3D', 'watercolor', 'oil painting', 'ink print', 'black and white sketch', 'retrowave', 'cinematic'];
'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES } from 'ContentEditorUI/redux/actions/actionTypes';
const themePageOverridesReducer = (state = null, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        return content.themeSettingsValues || null;
      }
    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
      {
        return payload.values;
      }
    default:
      return state;
  }
};
export default themePageOverridesReducer;
import { CompletionStatuses, ViewportTagIssues } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
const isMobileFriendly = viewportTag => {
  const {
    content
  } = viewportTag;
  return !content || !content.includes('width=');
};
export const addViewportTagAudit = artifacts => {
  const metaTags = artifacts[GathererKeys.META_TAGS];
  const viewportTags = metaTags && metaTags.filter(metaTag => metaTag.name === 'viewport');
  let viewportTagIssue;
  if (!viewportTags || viewportTags.length === 0) {
    viewportTagIssue = ViewportTagIssues.NO_VIEWPORT_FOUND;
  } else if (viewportTags.length > 1) {
    viewportTagIssue = ViewportTagIssues.MULTIPLE_VIEWPORT_TAGS_FOUND;
  } else if (viewportTags[0] && isMobileFriendly(viewportTags[0])) {
    viewportTagIssue = ViewportTagIssues.VIEWPORT_FOUND_WITH_INCORRECT_CONTENT;
  }
  if (viewportTagIssue) {
    return {
      status: CompletionStatuses.INCOMPLETE,
      viewportTagIssue
    };
  }
  return {
    status: CompletionStatuses.COMPLETE
  };
};
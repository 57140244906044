'use es6';

import { createSelector } from 'reselect';
import { getInitialUserAttributesRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestCompleted } from 'redux-request-state/util/requestStateCheckers';
import { getShouldStartFreeLPOnboardingTour as getLocalShouldStartTour, getHasSeenScopesHaveChangedModal as getLocalSeenScopesHaveChangedModal, getHasSeenOnboardingModalFromSuperStore, getHasSeenPatagoniaFeedbackCountFromSuperStore, getHasRespondedPatagoniaFeedbackFromSuperStore } from './localCachedSettingsSelectors';
import { getAllLayoutSectionTreesAsMap } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getFinishTourInpage } from 'ContentEditorUI/redux/selectors/onboardingTourStateSelectors';
import { getIsLandingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getSettings = basicSelector(state => state.baseSettings || state.pageSettings);
export const getHasFetchedInitialUserAttributes = createSelector(getInitialUserAttributesRequest, initialUserAttributesRequest => requestCompleted(initialUserAttributesRequest));
export const getShouldStartFreeLPOnboardingTour = createSelector(getLocalShouldStartTour, getHasFetchedInitialUserAttributes, getAllLayoutSectionTreesAsMap, getFinishTourInpage, getIsLandingPage, (shouldStartFreeLPOnboardingTour, hasFetchedInitialUserAttributes, allLayoutSectionTreesAsMap, finishTourInPage, isLandingPage) => !hasFetchedInitialUserAttributes ? null : !!shouldStartFreeLPOnboardingTour && isLandingPage && allLayoutSectionTreesAsMap && Object.keys(allLayoutSectionTreesAsMap).length > 0 && !finishTourInPage);
export const getHasSeenScopesHaveChangedModal = createSelector([getLocalSeenScopesHaveChangedModal], seenState => !!seenState);
export const getHasSeenOnboardingModalLocal = createSelector([getHasSeenOnboardingModalFromSuperStore], seenState => {
  return !!seenState;
});
export const getHasSeenPatagoniaFeedbackCount = createSelector([getHasSeenPatagoniaFeedbackCountFromSuperStore], hasSeenPatagoniaFeedbackCount => {
  return parseInt(hasSeenPatagoniaFeedbackCount, 10) || 0;
});
export const getHasRespondedPatagoniaFeedback = createSelector([getHasRespondedPatagoniaFeedbackFromSuperStore], hasResponded => {
  return Boolean(hasResponded);
});
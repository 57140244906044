'use es6';

import makeSaveActions from 'ContentEditorUI/js/redux/actions/makeSaveActions';
import { getSaveData } from 'page-editor-ui/redux/selectors/saveSelectors';
import bus from 'ContentEditorUI/coffee/lib/eventBus';
import makeWaitForPendingInpageUpdates from 'ContentEditorUI/utils/makeWaitForPendingInpageUpdates';
import { clearSaveAlerts } from 'ContentEditorUI/utils/errorUtils';
const actions = makeSaveActions({
  serialize: getSaveData,
  wait: makeWaitForPendingInpageUpdates(bus)
});
export const save = opts => {
  clearSaveAlerts();
  return actions.save(opts);
};
export const autoSave = actions.autoSave;
export const autoSaveDebounced = actions.autoSaveDebounced;
import http from 'hub-http/clients/apiClient';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';
export const CURRENT_REVISION_ID = -1;
export function fetchRevisions({
  baseContentUrl,
  limit = 15,
  offset = 0
}) {
  const earlyRequest = getEarlyRequesterResultPromise('versionsCounted');
  return earlyRequest || http.get(`${baseContentUrl}/versions-counted`, {
    query: {
      limit,
      offset
    }
  });
}
export function selectRevision({
  baseContentUrl,
  versionId,
  shouldRestoreToBuffer,
  shouldRestoreScheduledUpdateToBuffer
}) {
  const bufferPostfix = shouldRestoreToBuffer ? 'restore-to-buffer' : 'restore';
  const data = shouldRestoreScheduledUpdateToBuffer ? {
    scheduledBufferId: versionId
  } : {
    versionId
  };
  return http.post(`${baseContentUrl}/${shouldRestoreScheduledUpdateToBuffer ? 'scheduled' : 'versions'}/${bufferPostfix}`, {
    data
  });
}
export function fetchScheduledVersion(baseContentUrl) {
  return http.get(`${baseContentUrl}/scheduled`);
}
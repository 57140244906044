'use es6';

import { EDIT_SIDEBAR_ACTIVE_TAB_UPDATED, SET_APPLY_CHANGES_BUTTON_OPEN, SELECTED_FIELD_UPDATED } from 'ContentEditorUI/redux/actions/actionTypes';
export const editSidebarActiveTabUpdated = activeTab => ({
  type: EDIT_SIDEBAR_ACTIVE_TAB_UPDATED,
  payload: {
    activeTab
  }
});
export const setApplyChangesButtonOpen = value => ({
  type: SET_APPLY_CHANGES_BUTTON_OPEN,
  payload: {
    value
  }
});
export const updateSelectedField = selectedField => ({
  type: SELECTED_FIELD_UPDATED,
  payload: {
    selectedField
  }
});
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_SITE_SETTINGS, UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
import set from 'hs-lodash/set';
const siteSettingsReducer = (state = {}, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return response.settings;
    case UPDATE_SITE_SETTINGS:
      return payload;
    case UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE:
      return set(draft, ['bodyOverrideByDomain', payload.domain], {
        contentLanguage: payload.language
      });
    default:
      return draft;
  }
});
export default siteSettingsReducer;
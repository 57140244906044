'use es6';

import { useEffect } from 'react';
import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const BASE_KEY = `ContentComponents.abPattern.testConcludedAlert`;
export const AB_CANCELED_PARAM = 'abTestCanceled';
export const AB_ENDED_WITH_WINNER_PARAM = 'abTestEndedWithWinner';
const addAlert = (key, options = {}) => {
  FloatingAlertStore.addAlert({
    titleText: I18n.text(`${BASE_KEY}.${key}.title`),
    message: I18n.text(`${BASE_KEY}.${key}.message`, options),
    timeout: 4000,
    type: 'success'
  });
};
const AbTestConcludedAlert = () => {
  useEffect(() => {
    const {
      location,
      history
    } = window;
    if (!location.search) return;
    const params = new URLSearchParams(location.search);
    if (params.has(AB_CANCELED_PARAM)) {
      addAlert(AB_CANCELED_PARAM);
      params.delete(AB_CANCELED_PARAM);
    } else if (params.has(AB_ENDED_WITH_WINNER_PARAM)) {
      addAlert(AB_ENDED_WITH_WINNER_PARAM, {
        winner: params.get(AB_ENDED_WITH_WINNER_PARAM)
      });
      params.delete(AB_ENDED_WITH_WINNER_PARAM);
    } else {
      return;
    }

    // Remove only the A/B param from the URL
    const search = params.toString() && `?${params}`;
    history.replaceState(history.state, '', `${location.pathname}${search}`);
  }, []);
  return null;
};
export default AbTestConcludedAlert;
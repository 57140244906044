import { useEffect } from 'react';
export default function useModuleScrollAndHighlight({
  collaborationSidebarState,
  onScrollToModule,
  onHighLightModuleChange
}) {
  const {
    embeddedCommentSidebarInteractions: {
      scrollTo,
      highlightModuleIdFromHover,
      highlightModuleIdFromClick
    }
  } = collaborationSidebarState;
  useEffect(() => {
    if (scrollTo && scrollTo.moduleId && onScrollToModule) {
      onScrollToModule(scrollTo.moduleId);
    }
  }, [scrollTo, onScrollToModule]);
  const highlightedModuleId = highlightModuleIdFromHover || highlightModuleIdFromClick;
  useEffect(() => {
    if (onHighLightModuleChange) {
      onHighLightModuleChange(highlightedModuleId);
    }
  }, [onHighLightModuleChange, highlightedModuleId]);
}
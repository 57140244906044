/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { setAttribute, fetchInitialUserAttributes as fetchInitialUserAttributesFromApi } from 'ContentEditorUI/api/Attributes';
import { UPDATE_USER_ATTRIBUTE } from 'ContentEditorUI/redux/actions/actionTypes';
import emptyFunction from 'react-utils/emptyFunction';
import { FETCH_USER_ATTRIBUTES_SUCCEEDED, FETCH_USER_ATTRIBUTES_FAILED, FETCH_USER_ATTRIBUTES_STARTED, FETCH_USERS_STARTED, FETCH_USERS_SUCCEEDED, FETCH_USERS_FAILED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fetch } from 'ContentEditorUI/api/UsersApi';
import { getUsersRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestFailedOrUninitialized } from 'redux-request-state/util/requestStateCheckers';
import { parseUserAttributes } from 'ContentEditorUI/lib/userAndLocalCacheSettings/helpers';
import EditorConfigSingleton from '../../EditorConfigSingleton';
export const setUserAttribute = (key, value, onComplete = emptyFunction, ignoreUpdate = false) => dispatch => {
  setAttribute(key, value).then(() => {
    if (!ignoreUpdate) dispatch({
      type: UPDATE_USER_ATTRIBUTE,
      key,
      value
    });
    onComplete();
  });
};
const getUserAttributesParser = () => {
  if (!EditorConfigSingleton.getIsOnScalableEditor()) {
    return parseUserAttributes;
  }
  const apiConfig = EditorConfigSingleton.get('api');
  if (apiConfig && apiConfig.parseUserAttributes) {
    return apiConfig.parseUserAttributes;
  }
  return parseUserAttributes;
};
const fetchInitialUserAttributesSuccess = result => {
  const userAttributesParser = getUserAttributesParser();
  return {
    type: FETCH_USER_ATTRIBUTES_SUCCEEDED,
    attributes: result,
    parsedAttributes: userAttributesParser(result)
  };
};
const fetchInitialUserAttributesFailure = error => {
  return {
    type: FETCH_USER_ATTRIBUTES_FAILED,
    payload: error
  };
};
export const fetchInitialUserAttributes = arrayOfAttributesToFetch => dispatch => {
  dispatch({
    type: FETCH_USER_ATTRIBUTES_STARTED
  });
  const success = resp => dispatch(fetchInitialUserAttributesSuccess(resp));
  const error = resp => dispatch(fetchInitialUserAttributesFailure(resp));
  return fetchInitialUserAttributesFromApi(arrayOfAttributesToFetch).then(success, error);
};

// Very similar to email's custom fetchUsers which is the same but will also show a
// floating alert on request failure
export const fetchUsers = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (requestFailedOrUninitialized(getUsersRequest(state))) {
      dispatch({
        type: FETCH_USERS_STARTED
      });
      fetch().then(resp => {
        dispatch({
          type: FETCH_USERS_SUCCEEDED,
          payload: resp
        });
      }).catch(resp => {
        dispatch({
          type: FETCH_USERS_FAILED,
          payload: resp
        });
      });
    }
  };
};
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { ALL } from 'ContentUtils/enums/TagDefaultValueTypes';
export const valueIsArray = field => field.type === FieldTypes.EMAIL || field.type === FieldTypes.SIMPLE_MENU || field.type === FieldTypes.CHOICE && field.multiple;
export const valueIsObject = field => field && (field.type === FieldTypes.AUDIO || field.type === FieldTypes.ALIGNMENT || field.type === FieldTypes.BACKGROUND_IMAGE || field.type === FieldTypes.BORDER || field.type === FieldTypes.COLOR || field.type === FieldTypes.CRM_OBJECT || field.type === FieldTypes.EMBED || field.type === FieldTypes.FONT || field.type === FieldTypes.FORM || field.type === FieldTypes.GRADIENT || field.type === FieldTypes.HUBDB_ROW || field.type === FieldTypes.ICON || field.type === FieldTypes.IMAGE || field.type === FieldTypes.LINK || field.type === FieldTypes.LOCATION || field.type === FieldTypes.LOGO || field.type === FieldTypes.PAYMENT || field.type === FieldTypes.PODCAST || field.type === FieldTypes.SIMPLE_MENU || field.type === FieldTypes.SPACING || field.type === FieldTypes.SURVEY || field.type === FieldTypes.TAG && field.tag_value === ALL || field.type === FieldTypes.TEXT_ALIGNMENT || field.type === FieldTypes.URL || field.type === FieldTypes.VIDEO);

// If occurrence.default is set, then default value could be an array
// and we just want to grab the scalar value from that array
export const getDefaultScalarValue = field => {
  if (Array.isArray(field.default)) {
    const firstValue = field.default[0];
    // email field values are arrays of strings (email addresses)
    // so check if it's an array of arrays
    if (valueIsArray(field)) {
      return Array.isArray(firstValue) ? firstValue : field.default;
    }
    return firstValue;
  }
  return field.default;
};
export const getDefaultValue = field => {
  if (field.type === FieldTypes.GROUP) {
    const result = {};
    field.children.forEach(childField => {
      if (childField.default) {
        result[childField.name] = getDefaultValue(childField);
      }
    });
    if (field.occurrence) {
      const count = field.default || field.min;
      return new Array(count).fill(result);
    }
    return result;
  } else if (field.occurrence) {
    const scalarValue = getDefaultScalarValue(field);
    const count = field.default || field.min;
    return new Array(count).fill(scalarValue);
  } else {
    return field.default;
  }
};

/**
 * Walks the tree of values and handles each single value;
 *
 * @param {Array} fields An array of fields
 * @param {Object} values An object with the values for each field
 * @param {Function} handle A callback to process a single value. Takes the form `(path, field, value) => {}`
 * @param {Array} path (Optional) An array of representing the path to this level.
 */
export const walkTree = (fields, values, handle, path = []) => {
  if (!fields) {
    return;
  }
  fields.forEach(field => {
    let value = values ? values[field.name] : undefined;
    if (value === undefined) {
      value = getDefaultValue(field);
    }
    if (field.occurrence) {
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          if (field.type === FieldTypes.GROUP) {
            walkTree(field.children, v, handle, [...path, field.name, i]);
          } else {
            handle([...path, field.name, i], v, field);
          }
        });
      }
    } else if (field.type === FieldTypes.GROUP) {
      walkTree(field.children, value, handle, [...path, field.name]);
    } else if (value !== undefined) {
      handle([...path, field.name], value, field);
    }
  });
};
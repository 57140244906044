'use es6';

import { getLayoutSectionsAsJson, getSchemaLayoutSectionsAsJson, getAllLayoutSectionTreesAsMap } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import DevHelpers from 'ContentEditorUI/devHelpers';
import { ALL_POTENTIAL_TEST_FLAGS } from 'ContentEditorUI/redux/selectors/testFlagSelectors';
import { setPermanentTestFlag, removePermanentTestFlag } from 'ContentEditorUI/lib/testFlags';
class PageEditorDevHelpers extends DevHelpers {
  perAppLogToConsole() {
    // Pages specific dev helper info...
    //
    // Performance overlay not working with React 16 yet
    // console.log(
    //   'You can enable the performance overlay via: %cwindow.hubspot.ContentEditorUI.devHelpers.setDnDPerfDebugFlag()\n',
    //   'font-family: monospace;'
    // );
    console.log(`All flags available to test:%c\n  ${ALL_POTENTIAL_TEST_FLAGS.join('\n  ')}`, 'font-family: monospace;');
    console.log(`\nExample of what do append to the URL to turn on all of them:\n %c#testFlags=${ALL_POTENTIAL_TEST_FLAGS.join(',')}`, 'font-family: monospace;');
    console.log(`\nAnd you can persist them across sessions with:\n %chubspot.ContentEditorUI.devHelpers.setPermanentTestFlag('flagName')\n %c(and %cremovePermanentTestFlag('flagName')%c to remove)`, 'font-family: monospace;', 'font-family: initial;', 'font-family: monospace;', 'font-family: initial;');
    console.log('');
  }
  setPermanentTestFlag(testFlagString) {
    setPermanentTestFlag(testFlagString);
  }
  removePermanentTestFlag(testFlagString) {
    removePermanentTestFlag(testFlagString);
  }
  setDnDPerfDebugFlag(value = true) {
    this.eventBus.postMessage({
      action: 'setDebugFlag',
      flag: 'DND-AREA-PERF_DEBUG',
      value
    });
    console.warn(`Note, you'll need to refresh the tab to pick up the debug flag change`);
  }
  getLayoutSectionsAsJson() {
    const store = window.hubspot.ContentEditorUI.store;
    return getLayoutSectionsAsJson(store.getState());
  }
  getSchemaLayoutSectionsAsJson() {
    const store = window.hubspot.ContentEditorUI.store;
    return getSchemaLayoutSectionsAsJson(store.getState());
  }
  getAllTrees() {
    const store = window.hubspot.ContentEditorUI.store;
    return getAllLayoutSectionTreesAsMap(store.getState()).toObject();
  }
  getAllTreesAsArray() {
    const treeObj = this.getAllTrees();
    return Object.keys(treeObj).map(treeName => treeObj[treeName]);
  }
  assertAndPrintTrees({
    printValues = false
  } = {}) {
    const trees = this.getAllTreesAsArray();
    trees.forEach(tree => {
      console.log('Asserting integrity of', tree.getRootName(), '...');
      tree.assertIntegrity();
    });
    trees.forEach(tree => {
      console.log('');
      if (printValues) {
        tree.printTreeWithValues();
      } else {
        tree.printTreeWithNames();
      }
    });
  }
  assertAndPrintTreesWithValues() {
    return this.assertAndPrintTrees({
      printValues: true
    });
  }
}
export default function setupDevHelpers({
  eventBus
}) {
  window.hubspot.ContentEditorUI.devHelpers = new PageEditorDevHelpers({
    eventBus
  });
}
import { combineReducers } from 'redux';
import { WHATSAPP_PHONE_NUMBERS_FETCH } from 'ContentData/actions/ActionTypes';
import { UNINITIALIZED, PENDING, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
const whatsappNumbers = (state = [], action) => {
  switch (action.type) {
    case WHATSAPP_PHONE_NUMBERS_FETCH:
      {
        const {
          response
        } = action;
        if (!response || !response.phoneNumbers) return state;
        return [...state, ...response.phoneNumbers];
      }
    default:
      return state;
  }
};
const whatsappNumbersRequestStatus = (state = UNINITIALIZED, action) => {
  switch (action.type) {
    case WHATSAPP_PHONE_NUMBERS_FETCH:
      {
        const {
          response,
          error
        } = action;
        if (response) return SUCCEEDED;
        if (error) return FAILED;
        return PENDING;
      }
    default:
      return state;
  }
};
export default combineReducers({
  whatsappNumbers,
  whatsappNumbersRequestStatus
});
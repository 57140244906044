export const SALES = 'sales';
/**
 * @deprecated prefer SALES_PROFESSIONAL
 */
export const SALES_PRO = 'sales-pro';
export const SALES_STARTER = 'sales-starter';
export const SALES_PROFESSIONAL = 'sales-professional';
export const SALES_ENTERPRISE = 'sales-enterprise';
export const SALES_STARTER_USER = 'sales-starter-user';
export const SALES_PROFESSIONAL_USER = 'sales-professional-user';
export const SALES_ENTERPRISE_USER = 'sales-enterprise-user';
export const MARKETING = 'marketing';
export const MARKETING_STARTER = 'marketing-starter';
export const MARKETING_STARTER_EMAIL = 'marketing-starter-email';
export const MARKETING_BASIC = 'marketing-basic';
export const MARKETING_PRO = 'marketing-pro';
export const MARKETING_ENTERPRISE = 'marketing-enterprise';
export const STARTER_CONTACTS = 'starter-contacts';
export const BASIC_CONTACTS = 'basic-contacts';
export const MARKETING_STARTER_CONTACTS = 'marketing-starter-contacts';
export const MARKETING_PROFESSIONAL_CONTACTS = 'marketing-professional-contacts';
export const MARKETING_ENTERPRISE_CONTACTS = 'marketing-enterprise-contacts';
export const SERVICE = 'service';
export const SERVICE_STARTER = 'service-starter';
export const SERVICE_PROFESSIONAL = 'service-professional';
export const SERVICE_ENTERPRISE = 'service-enterprise';
export const SERVICE_STARTER_USER = 'service-starter-user';
export const SERVICE_PROFESSIONAL_USER = 'service-professional-user';
export const SERVICE_ENTERPRISE_USER = 'service-enterprise-user';
export const ADS = 'ads';
export const ADS_CAPACITY = 'ads-capacity';
export const WEBSITE = 'website';
export const REPORTING = 'reporting';
export const DEDICATED_IP = 'dedicated-ip';
export const TRANSACTIONAL_EMAIL = 'transactional-email';
export const ADDITIONAL_ACCOUNT = 'additional-account';
export const BRAND_DOMAIN = 'brand-domain';
export const WORKFLOWS_ADDON = 'workflows-addon';
export const LISTS_ADDON = 'lists-addon';
export const MARKETING_SMS_ADDON = 'marketing-sms';
export const CUSTOM_SSL = 'custom-ssl';
export const ESIGNATURES_LIMIT_INCREASE = 'esignatures-limit-increase';
export const ESIGNATURE_LIMIT_INCREASE = 'esignature-limit-increase';
export const DOMAINS_LIMIT_INCREASE = 'domains-limit-increase';
export const SANDBOX_LIMIT_INCREASE = 'sandbox-limit-increase';
export const CALLING_LIMIT_INCREASE = 'calling-limit-increase';
export const PHONE_LIMIT_INCREASE = 'phone-limit-increase';
export const TEAMS_LIMIT_INCREASE = 'teams-limit-increase';
export const WHATSAPP_LIMIT_INCREASE = 'whatsapp-limit-increase';
export const VIDEO_LIMIT_INCREASE = 'video-limit-increase';
export const MARKETING_SMS_LIMIT_INCREASE = 'marketing-sms-limit-increase';
export const WORKFLOWS_LIMIT_INCREASE = 'workflows-limit-increase';
export const USERS_LIMIT_INCREASE = 'users-limit-increase';
export const CUSTOM_OBJECTS_LIMIT_INCREASE = 'custom-objects-limit-increase';
export const CALCULATED_PROPERTIES_LIMIT_INCREASE = 'calculated-properties-limit-increase';
export const CUSTOM_PROPERTIES_LIMIT_INCREASE = 'custom-properties-limit-increase';
export const CRM_OBJECT_LIMIT_INCREASE = 'crm-object-limit-increase';
export const CRM_CONTACT_RECORDS_LIMIT_INCREASE = 'non-marketing-contacts-limit-increase';
export const DESIGNATED_TECHNICAL_SUPPORT = 'designated-technical-support';
export const IN_PERSON_TRAINING = 'in-person-training';
export const STARTER_KIT = 'starter-kit';
export const SALES_PROFESSIONAL_ONBOARDING = 'sales-professional-onboarding';
export const SERVICE_PROFESSIONAL_ONBOARDING = 'service-professional-onboarding';
export const INBOUND_CONSULTING_BLOCK = 'inbound-consulting-block';
export const TECHNICAL_CONSULTING_BLOCK = 'technical-consulting-block';
export const CMS_STARTER = 'cms-starter';
export const CMS_ENTERPRISE = 'cms-enterprise';
export const CMS_PROFESSIONAL = 'cms-professional';
export const OPERATIONS_STARTER = 'operations-starter';
export const OPERATIONS_PROFESSIONAL = 'operations-professional';
export const OPERATIONS_ENTERPRISE = 'operations-enterprise';

// Used only for free product activation
export const CRM = 'crm';
export const SALES_FREE = 'sales-free';
export const MARKETING_FREE = 'marketing-free';
export const SERVICE_FREE = 'service-free';

// Used for partner-program inquiries
export const PARTNER_PROGRAM = 'partner-program';

// For growth-suite purchases
export const SUITE_STARTER = 'suite-starter';
export const SUITE_PROFESSIONAL = 'suite-professional';
export const SUITE_ENTERPRISE = 'suite-enterprise';

// Marketing+ bundle
export const MARKETING_PLUS_PROFESSIONAL = 'marketing-plus-professional';
export const MARKETING_PLUS_ENTERPRISE = 'marketing-plus-enterprise';

// Used when feature can be unlocked by any enterprise SKU
export const ENTERPRISE = 'enterprise';
export const BUNDLE = 'bundle';

// Used to designate upgrades not associated with a product
// ONLY FOR USE WITH UIContactSalesButton, see ui-addon-upgrades PR#384
export const GENERAL = 'general';
export const CORE_SEATS_STARTER = 'core-seats-starter';
export const CORE_SEATS_PROFESSIONAL = 'core-seats-professional';
export const CORE_SEATS_ENTERPRISE = 'core-seats-enterprise';
'use es6';

function logSeleniumEvent(event, data) {
  if (window.SELENIUM_IS_RUNNING) {
    window.SELENIUM_APP_STATE = window.SELENIUM_APP_STATE || {};
    const state = window.SELENIUM_APP_STATE;
    const isPassingNumber = typeof data === 'number';
    const isPushingTimestamp = !data || isPassingNumber;
    const isPassingString = typeof data === 'string';
    if (!state[event] && !isPassingString) {
      state[event] = isPushingTimestamp ? [] : {};
    }
    if (isPassingString) {
      state[event] = data;
    } else if (!isPushingTimestamp) {
      Object.assign(state[event], data);
    } else if (isPassingNumber) {
      state[event].push(data);
    } else {
      state[event].push(Date.now());
    }
  }
}
export default logSeleniumEvent;
'use es6';

import { getRoutingLocation, getRoutingCurrentPage } from 'ContentEditorUI/redux/selectors/routingSelectors';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { MODULE_REDUCER_CHANGE_REDO, MODULE_REDUCER_CHANGE_UNDO, LOCATION_CHANGE } from 'ContentEditorUI/redux/actions/actionTypes';
import { getLastUndoneActionType, getLastUndoableActionType } from '../selectors/moduleSelectors';
const usageTrackerMiddleware = store => next => action => {
  switch (action.type) {
    case LOCATION_CHANGE:
      {
        // Do not track this screen change if it is the initial page load
        if (getRoutingLocation() && UsageTracker.isInitialized()) {
          let subscreen = getRoutingCurrentPage() || 'content';
          if (['publishing', 'send', 'rssSchedule'].includes(subscreen)) {
            subscreen = 'publish-send';
          }
          UsageTracker.track('editorViewInteraction', {
            subscreen
          });
        }
        return next(action);
      }
    case MODULE_REDUCER_CHANGE_REDO:
      UsageTracker.trackEditorInteraction({
        action: 'Undo or Redo Changes',
        event: 'redo',
        actionType: getLastUndoneActionType(store.getState())
      });
      return next(action);
    case MODULE_REDUCER_CHANGE_UNDO:
      UsageTracker.trackEditorInteraction({
        action: 'Undo or Redo Changes',
        event: 'undo',
        actionType: getLastUndoableActionType(store.getState())
      });
      return next(action);
    default:
      return next(action);
  }
};
export default usageTrackerMiddleware;
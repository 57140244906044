import { createSelector } from 'reselect';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import IntegrationStatuses from 'ContentUtils/constants/IntegrationStatuses';
export const getCampaign = (state, props) => state.resources.salesforce.campaigns[props.id];
export const getCampaigns = state => state.resources.salesforce.campaigns;
export const getCampaignsOptions = createGenericOptionsSelector(getCampaigns);
export const getCampaignFetchStatus = (state, props) => state.resources.salesforce.campaignsRequestStatus[props.id];
export const getIntegrationStatus = (state, props) => props.integrationStatus || state.resources.salesforce.integrationStatus;
export const getIsIntegrated = createSelector([getIntegrationStatus], integrationStatus => {
  return integrationStatus === IntegrationStatuses.CONNECTED;
});
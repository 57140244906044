import { CompletionStatuses } from '../../../../../constants';
export const addTopicPhraseToMetaDescriptionAudit = (metaDescription, topicPhrase) => {
  if (!topicPhrase) {
    return {
      status: CompletionStatuses.NOT_APPLICABLE
    };
  }
  if (!metaDescription) {
    return {
      status: CompletionStatuses.BLOCKED,
      topicPhrase
    };
  }
  let status;
  const metaDescriptionLowerCase = metaDescription.toLowerCase();
  const topicPhraseLowerCase = topicPhrase.toLowerCase().trim();
  if (metaDescriptionLowerCase.includes(topicPhraseLowerCase)) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }
  return {
    status,
    topicPhrase
  };
};
import { createMetricsFactory } from 'metrics-js';
export const Metrics = createMetricsFactory('pricing-pages-ui');
export const trackPricingPagePql = app => {
  if (app && app === 'pricing-pages') {
    Metrics.counter('talk-to-sales-pql').increment();
  }
};
export const UIAUMetrics = createMetricsFactory('ui-addon-upgrades', {
  library: 'ui-addon-upgrades'
});
export const trackPqlFailure = app => {
  Metrics.counter('pql-failure', {
    app
  }).increment();
};
export const trackFailedApiNameMap = (location, apiName, upgradeProduct) => {
  if (location) {
    UIAUMetrics.counter('failed-api-name-map', {
      location,
      apiName,
      upgradeProduct
    }).increment();
  }
};
export const trackTrialActivation = (app, upgradeProduct) => {
  UIAUMetrics.counter('trial-activation', {
    app,
    upgradeProduct
  }).increment();
};
export const trackPurchaseMotionFailure = ({
  uniqueId,
  apiName,
  purchaseMotion
}) => {
  UIAUMetrics.counter('purchase-motion-failure', {
    uniqueId,
    apiName,
    purchaseMotion
  }).increment();
};
import { START_ONBOARDING_TOUR_IN_PARENT, SHOW_ONBOARDING_TOUR_QUIT_MODAL, HIDE_ONBOARDING_TOUR_QUIT_MODAL, FINISH_TOUR_INPAGE, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, GO_TO_STYLE_SECTION, TRACK_LP_ONBOARDING_TOUR_PROGRESSION, CLEAR_PROGRESSION_TO_TRACK } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  currentTourIframe: 'inpage',
  hasDraggedModuleIn: false,
  showOnboardingTourQuitModal: false,
  finishTourInPage: false,
  goToStyleSection: false,
  progressionToTrack: {}
};
const onboardingTourStateReducer = (state = initialState, {
  type,
  progressionToTrack
}) => produce(state, draft => {
  switch (type) {
    case START_ONBOARDING_TOUR_IN_PARENT:
      {
        draft.currentTourIframe = 'parent';
        return draft;
      }
    case SHOW_ONBOARDING_TOUR_QUIT_MODAL:
      draft.showOnboardingTourQuitModal = true;
      return draft;
    case HIDE_ONBOARDING_TOUR_QUIT_MODAL:
      draft.showOnboardingTourQuitModal = false;
      return draft;
    case FINISH_TOUR_INPAGE:
      draft.finishTourInPage = true;
      return draft;
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
      return Object.assign(draft, {
        currentTourIframe: 'parent',
        finishTourInPage: true
      });
    case GO_TO_STYLE_SECTION:
      draft.goToStyleSection = true;
      return draft;
    case TRACK_LP_ONBOARDING_TOUR_PROGRESSION:
      draft.progressionToTrack = progressionToTrack;
      return draft;
    case CLEAR_PROGRESSION_TO_TRACK:
      draft.progressionToTrack = {};
      return draft;
    default:
      return draft;
  }
});
export default onboardingTourStateReducer;
import Raven from 'raven-js';
import enviro from 'enviro';
const NEW_RELIC_PAGE_ACTION_EVENT = 'FileManagerLibEvent';
const RAVEN_REQUEST_DATA_LENGTH_LIMIT = 1000;
const isDeployed = () => {
  return enviro.deployed('sentry') || enviro.deployed('newrelic');
};
const isDebug = Boolean(enviro.debug('newrelic'));
export function logNewRelicPageAction(eventName, attrs = {}) {
  if (!(window.newrelic && window.newrelic.addPageAction)) {
    return;
  }
  if (isDebug) {
    console.log('[newrelic addPageAction]', eventName, attrs);
  }
  attrs.eventName = eventName;
  window.newrelic.addPageAction(NEW_RELIC_PAGE_ACTION_EVENT, attrs);
}
export function logNewRelicError(error, attrs = {}) {
  if (!(window.newrelic && window.newrelic.noticeError)) {
    return;
  }
  if (isDebug) {
    console.log('[newrelic noticeError]', error, attrs);
  }
  attrs.fromFileManager = true;
  window.newrelic.noticeError(error, attrs);
}
export function reportMessage(message, extra = {}) {
  console.warn(message, extra);
  if (!isDeployed()) {
    return;
  }
  Raven.captureMessage(message, {
    extra,
    level: 'info'
  });
  logNewRelicPageAction('log', Object.assign({}, extra, {
    message
  }));
}
function isHubHttpError(err) {
  return Boolean(typeof err.options === 'object');
}
export const isFailedRequestError = err => typeof err.status === 'number';
export const isJsonErrorResponse = err => isFailedRequestError(err) && Boolean(err.responseJSON) && typeof err.responseJSON === 'object';
export function reportError(err, extra = {}, {
  reportToNewRelic = true
} = {}) {
  console.error(err);
  if (!isDeployed()) {
    return;
  }
  const actionName = extra.action || extra.type || 'UNKNOWN';
  let tags = {
    actionName
  };
  if (isFailedRequestError(err)) {
    const level = 'warning';
    tags.status = err.status;
    if (err instanceof XMLHttpRequest) {
      const errorMessage = `Failed quickFetch request for ${actionName} with status: ${err.status}`;
      logNewRelicPageAction('quickFetchFailure', Object.assign({}, extra, tags, {
        isError: true
      }));
      Raven.captureMessage(errorMessage, {
        level,
        tags,
        extra
      });
    } else if (isHubHttpError(err)) {
      if (typeof err.options === 'object') {
        tags = Object.assign({}, tags, {
          requestUrl: err.options.url,
          requestMethod: err.options.method
        });
        if (err.options.data && typeof err.options.data === 'string') {
          extra.requestBody = err.options.data.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
        }
      }
      if (typeof err.responseText === 'string') {
        extra.responseBody = err.responseText.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
      }
      logNewRelicPageAction('hubHttpFailure', Object.assign({}, extra, tags, {
        isError: true
      }));
      if (reportToNewRelic) {
        logNewRelicError(err);
      }
      const errorMessage = `Failed hub-http request for ${actionName} with status: ${err.status}`;
      Raven.captureMessage(errorMessage, {
        level,
        tags,
        extra
      });
    }
    return;
  }
  Raven.captureException(err, {
    extra,
    tags
  });
  if (reportToNewRelic) {
    logNewRelicError(err, Object.assign({}, extra));
  }
}
export function mockCurrentApp(currentApp) {
  window._currentApp = currentApp;
}
export function getCurrentApp() {
  if (window._currentApp) {
    return window._currentApp;
  }
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore the type for window.hubspot is not available in all consumers
    window.hubspot &&
    // @ts-expect-error No hubspot type in global window yet
    window.hubspot.bender &&
    // @ts-expect-error No hubspot type in global window yet
    window.hubspot.bender.currentProject
  );
}
export function getCurrentAppVersion() {
  if (window._currentApp) {
    return null;
  }
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore the type for window.hubspot is not available in all consumers
    window.hubspot &&
    // @ts-expect-error No hubspot type in global window yet
    window.hubspot.bender &&
    // @ts-expect-error No hubspot type in global window yet
    window.hubspot.bender.currentProjectVersion
  );
}
export function serializeFileInfo(file) {
  return {
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size
  };
}
export const logBadConversionAssetData = (failureMessage, error = '') => {
  console.warn(`[Video HubL Tag] ${failureMessage}. HubL tag will ignore it. ${error}`);
};